// @flow
import {
  LOGINS_BY_ORGANISATION_LOAD_FAILED,
  LOGINS_BY_ORGANISATION_LOAD_STARTED,
  LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL,
  UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL,
} from '../actions/actionTypes';

import type { LoginsByOrganisationState } from '../types/state/LoginsByOrganisation';
import type { LoginsByOrganisationAction } from '../actions/loginsByOrganisationActions';

export const initialState: LoginsByOrganisationState = {
  isBusy: false,
  error: null,
  loginsByOrganisation: [],
  viewState: {
    pageNumber: 1,
    pageCount: 1,
    sortModel: [],
  },
};

export default function loginsByOrganisationReducer(
  state: LoginsByOrganisationState = initialState,
  action: LoginsByOrganisationAction
): LoginsByOrganisationState {
  switch (action.type) {
    case LOGINS_BY_ORGANISATION_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL: {
      return {
        ...state,
        isBusy: false,
        error: null,
        loginsByOrganisation: action.payload.data._embedded.loginsByOrganisation,
      };
    }
    case LOGINS_BY_ORGANISATION_LOAD_FAILED:
      return {
        ...state,
        isBusy: false,
        error: action.error,
      };
    case UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL: {
      const { newSortModel } = action.payload;
      const ignoredParams = ['page'];

      return {
        ...state,
        viewState: {
          ...state.viewState,
          sortModel: newSortModel.filter(sort => ignoredParams.indexOf(sort.id) === -1),
        },
      };
    }
    default:
      return state;
  }
}
