// @flow
/* eslint-env browser */
import moment from 'moment';
import { parse } from 'json2csv';
import FileSaver from 'file-saver';

import { Notification } from '@nats/webclient-common';

import columnMappings from './exportViewColumnMapping';

import type { ExportData, ExportColumns } from '../types/ExportViewTypes';

export const PARSING_ERROR = 'Failed to parse export data.';
export const DOWNLOAD_ERROR = 'Failed to download CSV data.';

const displayErrorNotification = (error: string) => {
  Notification.error(error, { autoClose: true });
};

export const saveCSVFile = (pageTitle: string, csvData: string) => {
  const dateTime = moment().utc().format('YYYYMMDD_HHmm');

  const filename = `${pageTitle.replace(/ /g, '')}_${dateTime}.csv`;
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

  try {
    FileSaver.saveAs(blob, filename);
  } catch (error) {
    displayErrorNotification(DOWNLOAD_ERROR);
  }
};

export const parseExportData = (pageTitle: string, exportData: ExportData): ?string => {
  // Parse JSON export data to CSV

  try {
    const exportColumns: ExportColumns = columnMappings[pageTitle];

    if (!exportColumns) {
      throw Error('No column mapping found for page.');
    }

    return parse(exportData, { fields: exportColumns });
  } catch (error) {
    displayErrorNotification(PARSING_ERROR);
    return null;
  }
};

export const exportToCSV = (pageTitle: string, exportData: ExportData) => {
  const csvData = parseExportData(pageTitle, exportData);

  if (csvData) {
    // Download CSV data
    saveCSVFile(pageTitle, csvData);
  }
};
