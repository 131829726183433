// @flow

import { sortModelToUrl } from '@nats/webclient-common/lib/helpers/Urls';
import { helper, Notification } from '@nats/webclient-common';
import environment from '../environment';
import { apiGet, apiPost } from '../api/api';
import { handleApiError } from './errorActions';
import { exportToCSV } from '../utilities/exportToCSV';
import userMapperModule from '../utilities/csvMappers/usersScreen';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { ExportUsersResponse, ExportMailboxesByUsers } from '../types/User';

const USERS_URL = `${environment.identityServiceUrl}/api/v1/users`;
const ORGANISATIONS_MAILBOXES_URL = `${environment.messagesServiceUrl}/api/v1/mailboxes-user`;

export function exportMailboxesByUsers(): ThunkAction {
  return async function action(dispatch: Function, getState: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve message service data; config not provided');
    }

    if (!environment.identityServiceUrl) {
      throw new Error('Unable to retrieve identity service details data; config not provided');
    }

    try {
      // Get an unpaginated list of users
      const {
        userState: { viewState },
      } = getState();
      const selectionParams = viewState.sortModel.length !== 0 ? { sort: sortModelToUrl(viewState.sortModel) } : {};
      const filterParams = helper.Urls.filterModelToUrlParams(viewState.filterModel);

      if (filterParams.type) {
        filterParams.type = filterParams.type.trim().replace(/ /g, '_');
      }

      const usersResponseObj: ExportUsersResponse = await apiGet(USERS_URL, {
        ...filterParams,
        ...selectionParams,
      });

      const userList = usersResponseObj.data._embedded.users;

      const uniqueIds = userList.map(user => user.id);

      // Use unpaginated list of usersIds to get list of mailboxes for each user
      const mailboxDetailsResponse: ExportMailboxesByUsers = await apiPost(ORGANISATIONS_MAILBOXES_URL, [...uniqueIds]);

      const mailboxDetails = mailboxDetailsResponse.data._embedded.mailboxesByUser;
      const sortBy = viewState.sortModel.length !== 0 ? viewState.sortModel[0].desc : false;
      exportToCSV('Mailboxes_By_User', userMapperModule.mailboxesByUserMapper(mailboxDetails, userList, sortBy));
    } catch (err) {
      Notification.error(`Unable to export Mailboxes by User`);
      dispatch(handleApiError(err));
      throw err;
    }
  };
}
