import { string, object, number, ref } from 'yup';
import { notOnlyWhitespace } from '@nats/nats-service-sdk/lib/validation/common';
import { applicationTypes } from '@nats/nats-service-sdk/lib/types/Applications';

import { toTitleCase, formatFieldName } from '../../../utilities/format';

const MAX_INT = 2147483647;

const nameSchema = string()
  .label('Organisation name')
  .strict()
  .typeError('Organisation name must be a string')
  .required()
  .max(100, 'Organisation name must be a maximum of 100 characters')
  .matches(
    /^[a-z0-9-_ ]+$/i,
    'Organisation name must contain only alphanumeric characters, spaces, underscores and hyphens'
  )
  .test(
    'organisation-name-not-empty',
    'Organisation name must contain at least one alphanumeric character',
    notOnlyWhitespace
  );
const positiveIntSchema = message =>
  number().typeError(message).required(message).min(0, message).max(MAX_INT, message);

const getApplicationLicenceSchemas = () => {
  return Object.keys(applicationTypes).reduce((acc, app) => {
    const appTitleCase = toTitleCase(app);
    const appFormLabel = formatFieldName(app);

    return {
      ...acc,
      [`${appFormLabel}Limit`]: positiveIntSchema(`${appTitleCase} limit must be a valid number`).min(
        ref(`${appFormLabel}Used`),
        'Limit cannot be below the number of used licences'
      ),
      [`${appFormLabel}Used`]: positiveIntSchema(`${appTitleCase} usage must be a valid number`),
    };
  }, {});
};

export const editOrganisationValidationSchema = object()
  .noUnknown()
  .shape({
    name: nameSchema,
    ...getApplicationLicenceSchemas(),
  });
