// @flow

import type { UserServiceConfig, Records } from '~/types/MessageFacade';

import environment from '../environment';
import { apiGet, apiDelete, apiPost, apiPut } from './api';
import {
  loadMessageFacadeStarted,
  loadMessageFacadeSuccessful,
  loadMessageFacadeFailed,
} from '../actions/messageFacadeAction';
import type { ThunkAction } from '../types/ReduxActionTypes';
import { handleApiError } from '../actions/errorActions';

export const FACADE_ENDPOINTS_URL = `${environment.messagesFacadeApiUrl}/v1/admin/facadeConfig`;
export type LoadMessageFacadeResponse = {
  data: Records,
};
export type UserFormValues = {
  userConfigRecord: UserServiceConfig,
};

export function loadMessageFacadeUsers(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!FACADE_ENDPOINTS_URL) {
      throw new Error('Unable to retrieve Facade endpoints; config not provided');
    }
    dispatch(loadMessageFacadeStarted());
    try {
      const response = await apiGet(FACADE_ENDPOINTS_URL);
      dispatch(loadMessageFacadeSuccessful(response));
    } catch (error) {
      dispatch(loadMessageFacadeFailed(error));
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export async function createFacadeUser(userConfigRecord: UserServiceConfig) {
  if (!FACADE_ENDPOINTS_URL) {
    throw new Error('Unable to create Message Facade user data; config not provided');
  }
  return apiPost(FACADE_ENDPOINTS_URL, userConfigRecord);
}

export async function editFacadeUser(userConfigRecord: UserServiceConfig) {
  if (!FACADE_ENDPOINTS_URL) {
    throw new Error('Unable to edit Facade user data; config not provided');
  }
  const url = `${FACADE_ENDPOINTS_URL}/${userConfigRecord.Id}`;

  return apiPut(url, userConfigRecord);
}

export function deleteMessageFacadeUser(userId: string) {
  return apiDelete(`${FACADE_ENDPOINTS_URL}/${userId}`);
}
