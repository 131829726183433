// @flow
import React from 'react';

import User from './user/User';
import SoapUser from '../../soap-users/SoapUser';
import Mailbox from '../../mailboxes/Mailbox';
import ReferenceData from './ReferenceData';
import SoapEndpoint from './SoapEndpoint';
import Organisation from './organisation/Organisation';
import AuthorityApplication from './AuthorityApplication';
import MessageProfile from './messageProfile/MessageProfile';
import LoginsByOrganisation from './LoginsByOrganisation';

export const FORM_CODE = {
  USER: 'ACCOUNTS_USER',
  SOAP_USER: 'SOAP_USER',
  REFERENCE_DATA: 'REFERENCE_DATA',
  MESSAGE_MAILBOX: 'MESSAGE_MAILBOX',
  SOAP_ENDPOINT: 'SOAP_ENDPOINT',
  ORGANISATION: 'ORGANISATION',
  AUTHORITY_APPLICATION: 'AUTHORITY_APPLICATION',
  MESSAGE_PROFILES: 'MESSAGE_PROFILES',
  LOGINS_BY_ORGANISATION: 'LOGINS_BY_ORGANISATION',
};

export type FormCode = $Values<typeof FORM_CODE>;

export const FORM_MODE = {
  CREATE: 'CREATE',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};
export type FormMode = $Keys<typeof FORM_MODE>;

export const getTitle = (formCode: FormCode, formMode: FormMode, props: Object = {}): string => {
  const titleMap = {
    ACCOUNTS_USER: {
      CREATE: 'Create User',
      VIEW: `View ${props.username}`,
      EDIT: `Edit ${props.username}`,
    },
    SOAP_USER: {
      CREATE: 'Create SOAP User',
      VIEW: `View ${props.username}`,
      EDIT: `Edit ${props.username}`,
    },
    REFERENCE_DATA: {
      CREATE: '',
      VIEW: `View ${props.dataArea}`,
      EDIT: `Upload ${props.dataArea}`,
    },
    MESSAGE_MAILBOX: {
      CREATE: 'Create Mailbox',
      VIEW: `View ${props.name}`,
      EDIT: `Edit ${props.name}`,
    },
    SOAP_ENDPOINT: {
      CREATE: '',
      VIEW: `View ${props.endpoint}`,
      EDIT: '',
    },
    ORGANISATION: {
      CREATE: 'Create Organisation',
      VIEW: `View ${props.name}`,
      EDIT: `Edit ${props.name}`,
    },
    AUTHORITY_APPLICATION: {
      CREATE: '',
      VIEW: `View ${props.authorityName} ${props.application}`,
      EDIT: `Edit ${props.authorityName} ${props.application}`,
    },
    MESSAGE_PROFILES: {
      CREATE: 'Create Message Profile',
      VIEW: `View ${props.roleName}`,
      EDIT: `Edit ${props.roleName}`,
    },
    LOGINS_BY_ORGANISATION: {
      CREATE: '',
      VIEW: `View ${props.username}`,
      EDIT: '',
    },
  };

  return formCode in titleMap ? titleMap[formCode][formMode] : `Unrecognised Form: ${formCode}`;
};

const getForm = (formCode: FormCode) => {
  const formMap = {
    ACCOUNTS_USER: User,
    SOAP_USER: SoapUser,
    REFERENCE_DATA: ReferenceData,
    MESSAGE_MAILBOX: Mailbox,
    SOAP_ENDPOINT: SoapEndpoint,
    ORGANISATION: Organisation,
    AUTHORITY_APPLICATION: AuthorityApplication,
    MESSAGE_PROFILES: MessageProfile,
    LOGINS_BY_ORGANISATION: LoginsByOrganisation,
  };

  return formCode in formMap ? formMap[formCode] : () => <div>Please contact technical support.</div>;
};

const getFormFromType = (formCode: FormCode, formMode: FormMode, props: {} = {}, initialFormValues?: {}) => {
  const code = formCode.toUpperCase();

  const title = getTitle(code, formMode, props);
  const FormComponent = getForm(code);

  return [
    title,
    <FormComponent {...props} key={JSON.stringify(props)} mode={formMode} initialFormValues={initialFormValues} />,
  ];
};

export default getFormFromType;

export const getErrorMessage = (errorMap: {}, errorCode: string, messageOverride: ?string) => {
  if (errorCode in errorMap) {
    return errorMap[errorCode];
  }

  if (errorCode && !messageOverride && errorCode.toUpperCase() === errorCode) {
    return `An error has occurred (${errorCode})`;
  }

  if (errorCode && !messageOverride) {
    return `An error has occurred: ${errorCode}`;
  }

  return messageOverride || 'An error occurred';
};

export const parseFormikErrors = (errors: { [string]: string }): Array<string> => {
  if (!errors) {
    return [];
  }

  const errorKeys = Object.keys(errors);
  if (errorKeys.length === 0) {
    return [];
  }

  return errorKeys.map(errorField => {
    let errorMessage = errors[errorField];

    if (typeof errorMessage !== 'string') {
      // this happens when no error message has been provided for a custom Yup matcher
      errorMessage = `${errorField} is invalid`;
    }

    return errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
  });
};
