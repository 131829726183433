// @flow
/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import { DataTable, Notification } from '@nats/webclient-common';

import { setPageTitle, setSelectedRecord } from '../actions/appActions';
import { selectPanel } from '../actions/panelActions';
import { loadReferenceData } from '../api/referenceDataApi';
import { FORM_CODE, FORM_MODE } from '../components/forms/getFormFromType';
import { formatDisplayDate, formatFileSize } from '../utilities/format';
import { exportToCSV } from '../utilities/exportToCSV';
import referenceDataMapperModule from '../utilities/csvMappers/referenceDataMapper';

import type { State as Store } from '../types/ReduxStateType';
import type {
  ReferenceDataType,
  LoadReferenceDataResponse,
  ReferenceDataTableRecord,
} from '../types/ReferenceDataType';
import type { PageHeaderControls, ReferenceDataState } from '../types/state';
import type { FormCode, FormMode } from '../components/forms/getFormFromType';
import { setPageHeaderControls } from '../actions/headerControlActions';
import { setModalForm, toggleModalVisible } from '../actions/modalActions';

type State = {
  apiErrorNotificationId: string | null,
};

type Props = {
  referenceData: ReferenceDataState,
  selectedRecord: ?ReferenceDataType,
  setPageTitle: string => void,
  loadReferenceData: void => Promise<LoadReferenceDataResponse>,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: ReferenceDataType => mixed,
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModalForm: (FormCode, FormMode, {}) => void,
  pageTitle: string,
};

class ReferenceDataScreen extends Component<Props, State> {
  tableColumns = [
    {
      id: 'dataArea',
      Header: 'Data Area',
      accessor: ({ dataArea }: ReferenceDataType): string => dataArea.replace(/_/g, ' '),
    },
    {
      id: 'currentFileName',
      Header: 'Current File Name',
      accessor: ({ currentFileName }: ReferenceDataType): string | null => currentFileName,
    },
    {
      id: 'currentFileSize',
      Header: 'Current File Size',
      accessor: ({ currentFileSize }: ReferenceDataType): string => formatFileSize(currentFileSize),
      minWidth: 50,
    },
    {
      id: 'fileExtension',
      Header: 'File Extension',
      accessor: ({ fileExtension }: ReferenceDataType): string | null => fileExtension,
      minWidth: 50,
    },
    {
      id: 'lastUploadedTimestamp',
      Header: 'Last Uploaded (UTC)',
      accessor: ({ lastUploadedTimestamp }: ReferenceDataType): string => formatDisplayDate(lastUploadedTimestamp),
      minWidth: 50,
    },
    {
      id: 'lastUploadedUsername',
      Header: 'By User',
      accessor: ({ lastUploadedUsername }: ReferenceDataType): string | null => lastUploadedUsername,
      minWidth: 50,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      apiErrorNotificationId: null,
    };

    this.props.setPageHeaderControls(this.getSubheaderControls());
  }

  async componentDidMount() {
    this.props.setPageTitle('Reference Data');

    try {
      await this.props.loadReferenceData();
    } catch (error) {
      const notificationId = Notification.error('Reference data could not be loaded.', { autoClose: false });

      this.setState({ apiErrorNotificationId: notificationId });
    }
  }

  componentWillUnmount() {
    this.clearErrorNotification();
    this.props.setPageHeaderControls([]);
  }

  onUploadClick = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    const dataArea = selectedRecord.dataArea.replace(/_/g, ' ');

    this.props.toggleModalVisible();
    this.props.setModalForm(FORM_CODE.REFERENCE_DATA, FORM_MODE.EDIT, {
      dataArea,
    });
  };

  exportViewClickHandler = () => {
    const { pageTitle, referenceData } = this.props;
    const dataToExport = referenceDataMapperModule.mapReferenceData(referenceData.referenceData);

    exportToCSV(pageTitle, dataToExport);
  };

  getSubheaderControls = (): PageHeaderControls => {
    const { selectedRecord, referenceData } = this.props;
    const { apiErrorNotificationId } = this.state;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled:
        referenceData.isBusy ||
        !!apiErrorNotificationId ||
        !!referenceData.error ||
        referenceData.referenceData.length === 0,
    };

    const uploadControls = {
      name: 'Upload',
      icon: ['fas', 'upload'],
      onClick: this.onUploadClick,
      isDisabled: false,
    };

    return selectedRecord ? [[exportControls, uploadControls]] : [[exportControls]];
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props, prevProps)) {
      const controls = this.getSubheaderControls();
      this.props.setPageHeaderControls(controls);
    }
  }

  selectRow = (referenceDataRecord: ReferenceDataTableRecord) => {
    if (!referenceDataRecord) {
      return;
    }

    const { _original: referenceData } = referenceDataRecord;
    const dataArea = referenceData.dataArea.replace(/_/g, ' ');

    this.props.setSelectedRecord(referenceData);

    this.props.selectPanel(FORM_CODE.REFERENCE_DATA, { dataArea });
  };

  isRowSelected = (dataArea: string): boolean =>
    !!this.props.selectedRecord && this.props.selectedRecord.dataArea === dataArea;

  clearErrorNotification = () => {
    if (this.state.apiErrorNotificationId) {
      Notification.dismiss(this.state.apiErrorNotificationId);
    }
  };

  render() {
    const { referenceData } = this.props;

    return (
      <DataTable
        columns={this.tableColumns}
        tableData={referenceData.referenceData}
        isLoading={referenceData.isBusy}
        sortable={false}
        filterable={false}
        viewState={{ pageNumber: 1, pageCount: 1 }}
        updateFilterModel={noop}
        updatePageNumber={noop}
        onRowSelected={this.selectRow}
        getTdClass={(state, rowInfo) => (this.isRowSelected(rowInfo.original.dataArea) ? 'selectedRow' : '')}
      />
    );
  }
}

const mapStateToProps = (state: Store) => ({
  pageTitle: state.app.title,
  selectedRecord: state.app.selectedRecord,
  referenceData: state.referenceData,
});

export const mapDispatchToProps = {
  loadReferenceData,
  setPageTitle,
  setSelectedRecord,
  selectPanel,
  setPageHeaderControls,
  toggleModalVisible,
  setModalForm,
};

export { ReferenceDataScreen as PureReferenceDataScreen };

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceDataScreen);
