// @flow

import React from 'react';
import { Button } from '@nats/webclient-common';

import styles from './ConfirmRemoveUserFacadeLicence.module.scss';

type Props = {
  onConfirm: () => void,
  onCancel: () => void,
};

const ConfirmRemoveUserFacadeLicence = ({ onConfirm, onCancel }: Props) => {
  return (
    <div className={styles.confirmRemoveLicenceDialog} data-qa-id="confirm-remove-user-messages-facade-licence">
      <p>All messages facade settings will be removed from this user, click ok to continue, cancel to stop.</p>
      <div className={styles.buttonGroup}>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onConfirm}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default ConfirmRemoveUserFacadeLicence;
