// @flow
import axios from 'axios';
import moment from 'moment';
import noop from 'lodash/noop';
import { cookie } from '@nats/webclient-common';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { ApiError } from '../actions/errorActions';

import { getApiOptions } from './helpers/options';
import environment from '../environment';
import { handleApiError } from '../actions/errorActions';

const REFRESH_SESSION_URL = `${environment.identityServiceUrl}/api/v1/refresh`;

type Tokens = {
  accessTokenExpiry: string,
  idToken: string,
  refreshToken: string,
};

type RefreshResponseType = {
  data: {
    _embedded: Tokens,
  },
  status: number,
};

function saveSessionCreds(tokens: Tokens) {
  const cookieOptions = cookie.getBaseCookieOptions();

  cookie.saveCookie(cookie.COOKIE.ACCESS_TOKEN_EXPIRY, tokens.accessTokenExpiry, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.ID_TOKEN, tokens.idToken, cookieOptions);
  cookie.saveCookie(cookie.COOKIE.REFRESH_TOKEN, tokens.refreshToken, cookieOptions);
}

export function refreshSession(): ThunkAction {
  return function action(dispatch: Function) {
    const baseOptions = getApiOptions();

    return axios
      .get(REFRESH_SESSION_URL, {
        ...baseOptions,
        headers: {
          ...baseOptions.headers,
          refresh_token: cookie.getCookie(cookie.COOKIE.REFRESH_TOKEN),
        },
      })
      .then((response: RefreshResponseType) => {
        saveSessionCreds(response.data._embedded);
        return response.status;
      })
      .catch((error: ApiError) => {
        dispatch(handleApiError(error));
        return error.response.status;
      });
  };
}

export function refreshSessionInterceptor() {
  // deliberately swallowing the handleApiError dispatch - the interceptor handles this already
  // so this saves redundant error logs appearing in the console
  return refreshSession()(noop);
}

const EXPIRY_MARGIN = 10;
export function shouldRefreshSession(): boolean {
  const expiryTime = moment.unix(cookie.getCookie(cookie.COOKIE.ACCESS_TOKEN_EXPIRY));
  const currentTime = moment();

  return currentTime.add(EXPIRY_MARGIN, 'minutes').isAfter(expiryTime);
}
