// @flow
import { applicationTypes } from '@nats/nats-service-sdk/lib/types/Applications';

import type { OrganisationApplication } from '../../../types/Organisation';
import { formatFieldName, toTitleCase } from '../../../utilities/format';

export const getEditLicenceFields = (applications: Array<OrganisationApplication>) => {
  const secondColumnApps = [
    applicationTypes.LARA,
    applicationTypes.MESSAGES,
    applicationTypes.TRANSIT_ROUTES,
    applicationTypes.WINGBOARD,
  ];

  const applicationTypeSort: string[] = Object.keys(applicationTypes).sort((a: string, b: string) =>
    a.localeCompare(b)
  );

  return applicationTypeSort.reduce((acc, app) => {
    const getApp = appName => applications.find(a => a.name === appName);

    const appUsers = getApp(app);
    const numAllocatedUsers = appUsers ? appUsers.allocated_users : 0;
    const appFormPrefix = formatFieldName(app);

    const newColumnAttrs = secondColumnApps.includes(app) ? { shouldStartNewRow: false, offset: 2 } : {};

    const appLicencesUsedName = `${appFormPrefix}Used`;
    const appLicenceLimitLabel = `${appFormPrefix}Limit`;

    return [
      ...acc,
      {
        name: appLicencesUsedName,
        isHidden: true,
        initialValue: numAllocatedUsers,
      },
      {
        type: 'text',
        name: appLicenceLimitLabel,
        label: `${app === 'LARA' ? app : toTitleCase(app)} (${numAllocatedUsers} in use)`,
        initialValue: appUsers ? appUsers.max_users || 0 : 0,
        cols: 3,
        shouldWrapTooltip: true,
        shouldStartNewRow: true,
        ...newColumnAttrs,
      },
    ];
  }, []);
};

export const getCreateLicenceFields = () => [
  {
    isLabelOnly: true,
    name: 'adminLabel',
    label: 'Admin',
    cols: 3,
  },
  {
    type: 'text',
    name: 'adminLimit',
    initialValue: 0,
    label: null,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'laraLabel',
    label: 'LARA',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'laraLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'localAvoidsLabel',
    label: 'Local Avoids',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'localAvoidsLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'messagesFieldLabel',
    label: 'Messages',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'messagesLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'messagesFacadeFieldLabel',
    label: 'Messages Facade',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'messagesFacadeLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'transitRoutesLabel',
    label: 'Transit Routes',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'transitRoutesLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'visualLabel',
    label: 'Visual',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'visualLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
  {
    isLabelOnly: true,
    name: 'wingboardLabel',
    label: 'Wingboard',
    cols: 3,
  },
  {
    type: 'text',
    label: null,
    name: 'wingboardLimit',
    initialValue: 0,
    cols: 3,
    shouldWrapTooltip: true,
  },
];

export const getViewLicenceFields = (applications: Array<OrganisationApplication>) => {
  const appLicenceFieldValues = Object.keys(applicationTypes).reduce((acc, appType) => {
    const getApp = appName => applications.find(a => a.name === appName);

    const app = getApp(appType);
    const allocated = app && app.allocated_users ? app.allocated_users : 0;
    const max = app && app.max_users ? app.max_users : 0;

    return {
      ...acc,
      [appType]: `${allocated} / ${max}`,
    };
  }, {});

  return [
    {
      isLabelOnly: true,
      name: 'adminLabel',
      label: 'Admin',
      cols: 3,
    },
    {
      type: 'text',
      name: 'adminUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.ADMIN],
      label: null,
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'laraLabel',
      label: 'LARA',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'laraUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.LARA],
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'localAvoidsLabel',
      label: 'Local Avoids',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'localAvoidsUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.LOCAL_AVOIDS],
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'messagesFieldLabel',
      label: 'Messages',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'messagesUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.MESSAGES],
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'messagesFacadeLabel',
      label: 'Messages Facade',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'messagesFacadeUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.MESSAGES_FACADE],
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'transitRoutesLabel',
      label: 'Transit Routes',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'transitRoutesUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.TRANSIT_ROUTES],
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'visualLabel',
      label: 'Visual',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'visualUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.VISUAL],
      cols: 3,
    },
    {
      isLabelOnly: true,
      name: 'wingboardLabel',
      label: 'Wingboard',
      cols: 3,
    },
    {
      type: 'text',
      label: null,
      name: 'wingboardUsedTotalLicences',
      initialValue: appLicenceFieldValues[applicationTypes.WINGBOARD],
      cols: 3,
    },
  ];
};
