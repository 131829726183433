// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

import { Sidebar, Modal, Loading } from '@nats/webclient-common';
import userCacheMatchingInterceptor from '@nats/webclient-common/lib/interceptors/userCache/axiosResponseErrorInterceptor';
import licenceCheckingInterceptor from '@nats/webclient-common/lib/interceptors/licence/axiosResponseErrorInterceptor';
import unauthorizedInterceptor from '@nats/webclient-common/lib/interceptors/unauthorized/axiosResponseErrorInterceptor';
import type { AxiosError } from '@nats/webclient-common/lib/types/AxiosTypes';

import { hasLicence as checkLicence } from '@nats/webclient-common/lib/helpers/permissions';
import { applicationTypes } from '@nats/nats-service-sdk/lib/types/Applications';
import { redirectIncorrectPermission } from '@nats/webclient-common/lib/helpers/Auth';
import Header from '../../components/header/Header';
import styles from './App.module.scss';
import AuthorityScreen from '../authority/AuthorityScreen';
import OrganisationScreen from '../organisations/OrganisationScreen';
import Menu from '../Menu/Menu';
import UserScreen from '../users/UserScreen';
import MailboxScreen from '../../mailboxes/MailboxScreen';

import SoapUserScreen from '../../soap-users/SoapUserScreen';
import { toggleModalVisible, setModalForm } from '../../actions/modalActions';
import type { Modals } from '../../types/state';
import type { State } from '../../types/ReduxStateType';
import AdminPanel from '../AdminPanel/AdminPanel';
import EndpointsScreen from '../soap/EndpointsScreen';
import ReferenceDataScreen from '../../reference-data/ReferenceDataScreen';
import MessageProfilesScreen from '../profiles/MessageProfilesScreen';
import MessageParametersScreen from '../messageParameters/MessageParametersScreen';
import VisualParametersScreen from '../VisualParametres/VisualParameterScreen';
import TransitRouteParametersScreen from '../transitRouteParameters/TransitRouteParametersScreen';

import modalStyles from '../Modal/Modal.module.scss';
import menu from './menu';
import { FORM_MODE } from '../../components/forms/getFormFromType';
import { refreshSession, refreshSessionInterceptor, shouldRefreshSession } from '../../api/sessionApi';
import { setSelectedRecord } from '../../actions/appActions';
import { selectPanel } from '../../actions/panelActions';
import {
  canCreateOrganisation,
  canCreateUser,
  canCreateSoapUser,
  canCreateMessageProfile,
  canCreateMessageMailbox,
} from '../../utilities/permissions';
import environment from '../../environment';
import { clearCookiesAndRedirect } from '../../actions/authActions';
import FeatureTogglesScreen from '../FeatureToggles/FeatureTogglesScreen';
import LoginsByOrganisationScreen from '../../logins-by-organisation/LoginsByOrganisationScreen';

type Props = {
  toggleModalVisible: Function,
  setModalForm: Function,
  refreshSession: Function,
  selectPanel: (?string, {}) => mixed,
  setSelectedRecord: (?{}) => mixed,
  clearCookiesAndRedirect: () => void,
  modals: Modals,
};

type LocalState = {
  hasLicence: boolean,
  refreshInterval: *, // must be inferred, see https://github.com/facebook/flow/issues/5627
};

class App extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasLicence: false,
      refreshInterval: setInterval(async () => {
        if (shouldRefreshSession()) {
          await this.props.refreshSession();
        }
      }, 10000),
    };

    axios.interceptors.response.use(null, this.handleUserCacheMatchingError);
    axios.interceptors.response.use(this.handleLicenceCheckingSuccess, this.handleLicenceCheckingError);
    axios.interceptors.response.use(null, this.handleAxiosError);
  }

  componentDidMount() {
    if (!checkLicence(applicationTypes.ADMIN)) {
      redirectIncorrectPermission(environment.loginUrl, 'admin', 'licences');
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.refreshInterval);
  }

  handleUserCacheMatchingError = async <T>(error: T): Promise<T> =>
    userCacheMatchingInterceptor(error, refreshSessionInterceptor);

  // Flow generics cause an unnecessary linting error with combination of async + fat arrow + curly braces
  handleLicenceCheckingSuccess = async <T>(response: T): Promise<T> => {
    this.dismissInitialLoadingScreen();
    return response;
  };

  handleLicenceCheckingError = async <T>(error: T): Promise<T> =>
    licenceCheckingInterceptor(error, { url: environment.loginUrl, origin: 'admin' }, this.dismissInitialLoadingScreen);

  handleAxiosError = async (error: AxiosError) => unauthorizedInterceptor(error, this.props.clearCookiesAndRedirect);

  dismissInitialLoadingScreen = () => {
    if (!this.state.hasLicence) {
      this.setState({ hasLicence: true });
    }
  };

  displayModal = (nodeCode: string) => {
    this.props.toggleModalVisible();

    // TODO: pass in create/edit form mode based on context
    this.props.setModalForm(nodeCode, FORM_MODE.CREATE, {});

    // clear side panel
    this.props.setSelectedRecord(null);
    this.props.selectPanel(null, {});
  };

  renderRoutes = () => (
    <Switch>
      <Route exact path="/" component={OrganisationScreen} />
      <Route exact path="/authorities" component={AuthorityScreen} />
      <Route exact path="/organisations" component={OrganisationScreen} />
      <Route exact path="/users" component={UserScreen} />
      <Route exact path="/message-mailboxes" component={MailboxScreen} />
      <Route exact path="/soap-users" component={SoapUserScreen} />
      <Route exact path="/soap-endpoints" component={EndpointsScreen} />
      <Route exact path="/reference-data" component={ReferenceDataScreen} />
      <Route exact path="/message-profiles" component={MessageProfilesScreen} />
      <Route exact path="/message-parameters" component={MessageParametersScreen} />
      <Route exact path="/visual-parameters" component={VisualParametersScreen} />
      <Route exact path="/transit-route-parameters" component={TransitRouteParametersScreen} />
      <Route exact path="/feature-toggles" component={FeatureTogglesScreen} />
      <Route exact path="/logins-by-organisation" component={LoginsByOrganisationScreen} />
      <Route render={() => <h1>Page not found</h1>} />
    </Switch>
  );

  renderModals = (): Array<Node> => {
    const { modals } = this.props;

    return modals
      ? modals
          .filter(modal => modal.isVisible)
          .map((modal, idx) => (
            <Modal
              header={modal.modalHeader}
              onDismiss={() => this.props.toggleModalVisible(idx)}
              externalClassNames={{
                overlay: modalStyles.overlay,
                wrapper: modalStyles.wrapper,
                header: modalStyles.header,
                headerText: modalStyles.headerText,
                content: modalStyles.content,
              }}
            >
              {modal.modalContent}
            </Modal>
          ))
      : [];
  };

  render() {
    const { hasLicence } = this.state;
    const usableMenuItems = [];

    if (canCreateUser()) {
      usableMenuItems.push('Accounts_User');
    }

    if (canCreateSoapUser()) {
      usableMenuItems.push('Soap_User');
    }

    if (canCreateOrganisation()) {
      usableMenuItems.push('Organisation');
    }

    if (canCreateMessageProfile()) {
      usableMenuItems.push('Message_Profiles');
    }

    if (canCreateMessageMailbox()) {
      usableMenuItems.push('Message_Mailbox');
    }

    return (
      <div>
        {!hasLicence && <Loading isLoading externalClassNames={{ loadingContainer: styles.loadingContainer }} />}
        {this.renderModals()}
        <ToastContainer />
        <Header />
        <div className={styles.appBody}>
          <Sidebar
            sidebarText="Menu"
            ContentComponent={Menu}
            compose={{
              menu,
              menuPermissions: {
                canUse: usableMenuItems,
              },
              onItemClick: this.displayModal,
              expandedText: 'Create New',
            }}
          />
          <div className={styles.main}>{this.renderRoutes()}</div>
          <AdminPanel />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ modals }: State) => ({ modals });

const mapDispatchToProps = {
  toggleModalVisible,
  setModalForm,
  refreshSession,
  setSelectedRecord,
  selectPanel,
  clearCookiesAndRedirect,
};

export { App as PureApp };
export default connect(mapStateToProps, mapDispatchToProps)(App);
