// @flow
import environment from '../environment';
import { loadRolesStarted, loadRolesSuccessful, loadRolesFailed } from '../actions/roleActions';
import { handleApiError } from '../actions/errorActions';
import { apiGet, apiPost, apiPut, apiDelete } from './api';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { LoadRolesResponse } from '../actions/roleActions';
import type { CreateRoleInfo, EditRoleInfo } from '../types/RoleType';

const ROLES_URL = `${environment.messagesServiceUrl}/api/v1/roles`;

export function loadRoles(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve roles endpoint; config not provided');
    }

    dispatch(loadRolesStarted());

    return apiGet(ROLES_URL)
      .then((response: LoadRolesResponse) => {
        dispatch(loadRolesSuccessful(response));
      })
      .catch(error => {
        dispatch(loadRolesFailed(error));
        dispatch(handleApiError(error));

        throw error;
      });
  };
}

export function createRole(role: CreateRoleInfo): ThunkAction {
  return function action(dispatch: Function) {
    return apiPost(ROLES_URL, role).catch(error => {
      dispatch(handleApiError(error));
      throw error;
    });
  };
}

export function editRole(role: EditRoleInfo): ThunkAction {
  return async function action(dispatch: Function) {
    try {
      return apiPut(ROLES_URL, role);
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export const deleteRole = async (id: string) => {
  const uri = `${ROLES_URL}/${id}`;
  await apiDelete(uri);
  return { id };
};
