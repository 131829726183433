// @flow
import axios from 'axios';

import {
  loadUserDetailsStarted,
  loadUserDetailsSuccessful,
  loadUserDetailsFailed,
} from '../actions/userDetailsActions';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes';
import { getApiOptions } from './helpers/options';
import { handleApiError } from '../actions/errorActions';

export const USER_DETAILS_URL = `${environment.messagesServiceUrl}/api/v1/user-details`;

export function loadUserDetails(userId: string, organisationId: ?string): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve user details data; config not provided');
    }
    dispatch(loadUserDetailsStarted());

    try {
      const params = {
        userId,
        organisationId,
      };

      const response = await axios.get(USER_DETAILS_URL, {
        ...getApiOptions(),
        params,
      });

      const { userDetails } = response.data._embedded;

      dispatch(loadUserDetailsSuccessful(response));
      return userDetails;
    } catch (error) {
      const errorCode = error.response ? error.response.data.error : error;

      dispatch(loadUserDetailsFailed(errorCode));
      dispatch(handleApiError(error));

      return null;
    }
  };
}
