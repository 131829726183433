// @flow

import {
  USERS_DATA_FAILURE,
  USERS_DATA_LOADING,
  USERS_DATA_SUCCESS,
  UPDATE_USERS_FILTER_MODEL,
  UPDATE_USERS_PAGE_NUMBER,
  UPDATE_USERS_SORT_MODEL,
  USER_LOAD_FAILED,
  USER_LOAD_STARTED,
  USER_LOAD_SUCCESSFUL,
} from './actionTypes';

import type { Filter, Sort } from '../types/TableTypes';
import type { LoadUsersResponse } from '../api/userApi';

import { deleteUser as deleteUserApi } from '../api/userApi';

export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_CONFIRMED = 'USER_DELETE_CONFIRM';

type loadStartedAction = { type: typeof USER_LOAD_STARTED };
type loadSuccessAction = {
  type: typeof USER_LOAD_STARTED,
  payload: LoadUsersResponse,
};
type loadFailedAction = { type: typeof USER_LOAD_FAILED };

type userDeleteAction = {
  type: typeof USER_DELETE,
  payload: { id: string },
};

export type userDeleteConfirmedAction = {
  type: typeof USER_DELETE_CONFIRMED,
  payload: { id: string },
};

type fetchUsersLoadingAction = { type: typeof USERS_DATA_LOADING };
type fetchUsersSuccessAction = { type: typeof USERS_DATA_SUCCESS };
type fetchUsersFailureAction = { type: typeof USERS_DATA_FAILURE };

export type UserAction =
  | loadStartedAction
  | loadSuccessAction
  | loadFailedAction
  | userDeleteAction
  | userDeleteConfirmedAction
  | fetchUsersLoadingAction
  | fetchUsersSuccessAction
  | fetchUsersFailureAction;

export function loadUsersStarted(): loadStartedAction {
  return {
    type: USER_LOAD_STARTED,
  };
}

export function loadUsersSuccessful(response: LoadUsersResponse): loadSuccessAction {
  return {
    type: USER_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadUsersFailed(): loadFailedAction {
  return {
    type: USER_LOAD_FAILED,
  };
}

export type UsersUpdatePageAction = {
  type: typeof UPDATE_USERS_PAGE_NUMBER,
  payload: {
    newPage: number,
  },
};

export type UsersUpdateFilterModelAction = {
  type: typeof UPDATE_USERS_FILTER_MODEL,
  payload: {
    newFilterModel: Array<Filter>,
  },
};

export type UsersUpdateSortModelAction = {
  type: typeof UPDATE_USERS_SORT_MODEL,
  payload: {
    newSortModel: Array<Sort>,
  },
};

export function updateUsersPageNumber(newPage: number = 1): UsersUpdatePageAction {
  return {
    type: UPDATE_USERS_PAGE_NUMBER,
    payload: {
      newPage,
    },
  };
}

export function updateUsersFilterModel(newFilterModel: Array<Filter>): UsersUpdateFilterModelAction {
  return {
    type: UPDATE_USERS_FILTER_MODEL,
    payload: {
      newFilterModel,
    },
  };
}

export function updateUsersSortModel(newSortModel: Array<Sort>): UsersUpdateSortModelAction {
  return {
    type: UPDATE_USERS_SORT_MODEL,
    payload: {
      newSortModel,
    },
  };
}

export const deleteUser = (userId: string) => ({
  type: USER_DELETE,
  promise: Promise.resolve({ id: userId }),
});

export const deleteUserConfirm = (userId: string, username: string, isFacadeUser: boolean) => ({
  type: USER_DELETE_CONFIRMED,
  promise: deleteUserApi(userId, username, isFacadeUser),
});

export function fetchUsersLoading(): fetchUsersLoadingAction {
  return {
    type: USERS_DATA_LOADING,
  };
}

export function fetchUsersSuccess(): fetchUsersSuccessAction {
  return {
    type: USERS_DATA_SUCCESS,
  };
}

export function fetchUsersFailure(): fetchUsersFailureAction {
  return {
    type: USERS_DATA_FAILURE,
  };
}
