// @flow
import get from 'lodash/get';

import {
  loadVisualParametersStarted,
  loadVisualParametersSuccessful,
  editVisualParametersSuccessful,
  loadVisualParametersFailed,
  editVisualParametersFailed,
} from '../actions/visualParametersActions';
import { apiGet, apiPost } from './api';
import environment from '../environment';

import type { NewVisualParameters, LoadVisualParametersResponse } from '../types/VisualParameterType';

import type { ThunkAction } from '../types/ReduxActionTypes';

export const VISUAL_PARAMETERS_URL = `${environment.visualApiUrl}/api/v1/visual-parameters`;

export function loadVisualParameters(): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.visualApiUrl) {
      throw new Error('Unable to retrieve Visual Parameters; config not provided');
    }

    dispatch(loadVisualParametersStarted());
    return apiGet(VISUAL_PARAMETERS_URL)
      .then((response: LoadVisualParametersResponse) => dispatch(loadVisualParametersSuccessful(response)))
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(loadVisualParametersFailed(errorCode));
      });
  };
}

export function editVisualParameters(newVisualParameters: NewVisualParameters): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.visualApiUrl) {
      throw new Error('Unable to retrieve Visual Parameters; config not provided');
    }
    return apiPost(VISUAL_PARAMETERS_URL, newVisualParameters)
      .then(() => {
        editVisualParametersSuccessful();
      })
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(editVisualParametersFailed(errorCode));
      });
  };
}
