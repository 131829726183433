// @flow
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';

import type { SoapEndpoint } from '../../types/SoapType';

const mapSoapEndpoints = (soapEndpoints: Array<SoapEndpoint>): Array<SoapEndpoint> => {
  return soapEndpoints.map(soapEndpoint => ({
    name: soapEndpoint.name,
    lastEamsRequest: moment.utc(soapEndpoint.lastEamsRequest).format('YYYY.MM.DD HH:mm:ss'),
    status: upperFirst(soapEndpoint.status),
    currentState: soapEndpoint.currentState === 'DISABLED' ? 'False' : 'True',
  }));
};

const SoapEndpointsMapperModule = {
  mapSoapEndpoints,
};

export default SoapEndpointsMapperModule;
