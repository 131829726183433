// @flow
import { Notification } from '@nats/webclient-common';

import {
  MESSAGE_PARAMETERS_LOAD_STARTED,
  MESSAGE_PARAMETERS_LOAD_SUCCESSFUL,
  MESSAGE_PARAMETERS_LOAD_FAILED,
  MESSAGE_PARAMETERS_EDIT_FAILED,
} from './actionTypes';

import type {
  LoadStartedAction,
  LoadSuccessAction,
  LoadFailedAction,
  EditFailedAction,
  LoadMessageParametersResponse,
} from '../types/MessageParameterTypes';

import { getErrorMessage } from '../components/forms/getFormFromType';

const errorMap = {
  GENERIC_ERROR: 'An error occurred. Please reload the page.',
  FAILED_TO_RETRIEVE_MESSAGE_PARAMETERS: 'Unable to load Message Parameters. Please reload the page.',
};

const displayErrorNotification = (error: string) => {
  Notification.error(getErrorMessage(errorMap, error, errorMap.GENERIC_ERROR), {
    autoClose: false,
  });
};

export const loadMessageParametersStarted = (): LoadStartedAction => {
  return {
    type: MESSAGE_PARAMETERS_LOAD_STARTED,
  };
};

export const loadMessageParametersSuccessful = (response: LoadMessageParametersResponse): LoadSuccessAction => {
  return {
    type: MESSAGE_PARAMETERS_LOAD_SUCCESSFUL,
    payload: response,
  };
};

export const loadMessageParametersFailed = (error: string): LoadFailedAction => {
  displayErrorNotification(error);
  return {
    type: MESSAGE_PARAMETERS_LOAD_FAILED,
    error,
  };
};

export const editMessageParametersSuccessful = () => {
  Notification.success('Message Parameters updated successfully');
};

export const editMessageParametersFailed = (error: string): EditFailedAction => {
  displayErrorNotification(error);
  return {
    type: MESSAGE_PARAMETERS_EDIT_FAILED,
    error,
  };
};
