// @flow
/* eslint no-nested-ternary: 0 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import { Header as GlobalHeader, SubHeader, cookie } from '@nats/webclient-common';

import environment from '../../environment';
import { logout } from '../../actions/authActions';
import { handleApiError } from '../../actions/errorActions';
import getHelp from '../../actions/helpActions';

import type { State } from '../../types/ReduxStateType';
import type { AppState, PageHeaderControls } from '../../types/state';
import type { HeaderControl } from '../../types/state/HeaderControl';

type Props = {
  app: AppState,
  pageHeaderControls: PageHeaderControls,
  logout: Function,
  handleApiError: Function,
};

const applications = {
  ADMIN: { name: 'Admin', url: environment.adminUrl, current: true },
  MESSAGES: { name: 'MilEAMS Messages', url: environment.messagesUrl },
  VISUAL: { name: 'MilEAMS Visual', url: environment.visualUrl },
  LARA: { name: 'LARA', url: environment.laraUrl, openInNewTab: true },
  LOCAL_AVOIDS: { name: 'Local Avoids', url: environment.localAvoidsUrl },
  WINGBOARD: { name: 'Wingboard', url: environment.wingboardUrl },
  TRANSIT_ROUTES: { name: 'Transit Routes', url: environment.transitRoutesUrl },
};

class Header extends Component<Props> {
  getSubheaderControls = () => {
    // construct the subheader with any static controls, and any page-specific controls.
    // controls may be grouped - this affects their layout on the screen.
    // page-specific controls are provided as an array of arrays, where each
    // sub-array is one group of controls.

    let controls = [];
    let controlGroupNum = 1;
    const appendControlGroup = controlGroup => {
      if (controlGroup.length === 0) {
        return;
      }

      const numberedControlGroup = controlGroup.map((control: HeaderControl) => ({
        ...control,
        group: controlGroupNum,
      }));

      controls = [...controls, ...numberedControlGroup];
      controlGroupNum += 1;
    };

    this.props.pageHeaderControls.forEach(controlGroup => {
      appendControlGroup(controlGroup);
    });

    return controls;
  };

  onHelpClick = async () => {
    try {
      const helpUrl = await getHelp();
      window.open(helpUrl.data._embedded.helpUrl, '_blank');
    } catch (err) {
      this.props.handleApiError(err);
    }
  };

  render() {
    const availableApplications = cookie.getCookie(cookie.COOKIE.APPLICATIONS) || [];
    const changePasswordUrl = `${environment.loginUrl}/changePassword`;
    const homeUrl = `${environment.loginUrl}/home`;
    const apps = availableApplications.map(a => applications[a]).filter(a => a !== undefined);
    apps.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    const globalVersionElement = document.getElementById('globalVersion');
    const globalVersion = globalVersionElement ? globalVersionElement.textContent : 'unknown version';

    const headerText = <span title={globalVersion}>{this.props.app.title}</span>;

    return (
      <div data-qa-id="header">
        <GlobalHeader
          apps={apps}
          changePasswordUrl={changePasswordUrl}
          dashboardUrl={homeUrl}
          onLogout={this.props.logout}
          idleAction={this.props.logout}
          idleTimeout={environment.idleTimeout}
        />

        <SubHeader
          key={uuid()}
          heading={headerText}
          controls={this.getSubheaderControls()}
          onHelpClick={this.onHelpClick}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ app, pageHeaderControls }: State) => ({
  app,
  pageHeaderControls,
  handleApiError,
});

export const mapDispatchToProps = {
  logout,
};

export { Header as PureHeader };
export default connect(mapStateToProps, mapDispatchToProps)(Header);
