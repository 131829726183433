// @flow
import type { Authority, ApplicationLicencesExport } from '~/types/Authority';

const mapAuthorities = (authorities: Array<Authority>): Array<ApplicationLicencesExport> => {
  const authList = [];

  authorities.forEach(authority => {
    const authorityGroup = authority.name;

    authority.applications.forEach(application =>
      authList.push({
        ...application,
        authorityName: authorityGroup,
        remaining_licences: application.max_licences - application.allocated_licences,
      })
    );
  });

  return authList;
};

export default mapAuthorities;
