// @flow
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingButton } from '@nats/webclient-common';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './ParametersAudit.module.scss';

import type { State as Store } from '../../../types/ReduxStateType';

type Props = {
  fetchError: ?string,
  isLoading: boolean,
  onConfirm: (string, string) => void,
  type: string,
};

type State = {
  fromDate: moment$Moment,
  toDate: moment$Moment,
};

const renderDateRow = (label, value, changeFunc) => (
  <div className={styles.dateWrapper}>
    <div className={styles.dateLabel}>{`${label}:`}</div>
    <DatePicker
      calendarClassName={styles.calender}
      selected={value.toDate()}
      onChange={changeFunc}
      dateFormat="dd/MM/yyyy"
      disabledKeyboardNavigation
      strictParsing
    />
  </div>
);

class ParametersAudit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const fromDate = moment.utc().subtract(3, 'months');

    this.state = {
      fromDate,
      toDate: moment.utc(),
    };
  }

  renderSubmissionError = () => {
    const { fetchError } = this.props;

    if (fetchError) {
      return (
        <div
          data-qa-id="audit-fetch-error-message"
          className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
        >
          <ul>
            <li>Failed to retrieve audit logs from the server</li>
          </ul>
        </div>
      );
    }
    return null;
  };

  onFromDateTimeChange = (event: SyntheticInputEvent<EventTarget>) => {
    if (moment(event).isValid()) {
      this.setState({ fromDate: moment.utc(event) });
    }
  };

  onToDateTimeChange = (event: SyntheticInputEvent<EventTarget>) => {
    if (moment(event).isValid()) {
      this.setState({ toDate: moment.utc(event) });
    }
  };

  render() {
    const { fromDate, toDate } = this.state;

    return (
      <>
        {this.renderSubmissionError()}
        <div className={styles.auditModalContent} data-qa-id={`${this.props.type}-parameters-audit-modal`}>
          <p>Enter date boundaries:</p>
          {renderDateRow('FROM', fromDate, this.onFromDateTimeChange)}
          {renderDateRow('TO', toDate, this.onToDateTimeChange)}
          <div className={styles.downloadButton} data-qa-id="audit-loading-button">
            <LoadingButton
              isLoading={this.props.isLoading}
              buttonText="Download"
              onClick={() => this.props.onConfirm(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD'))}
            />
          </div>
        </div>
      </>
    );
  }
}

export const mapStateToProps = (state: Store, props: Props) => ({
  isLoading:
    props.type === 'transit-route' ? state.transitRouteParametersAudit.isBusy : state.messageParametersAudit.isBusy,
  fetchError:
    props.type === 'transit-route' ? state.transitRouteParametersAudit.error : state.messageParametersAudit.error,
});

export default connect(mapStateToProps, null)(ParametersAudit);

export { ParametersAudit as PureParametersAudit };
