// @flow
import { saveCSVFile } from '../utilities/exportToCSV';

import {
  TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_STARTED,
  TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL,
  TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_FAILED,
} from './actionTypes';

import type {
  LoadStartedAction,
  LoadSuccessAction,
  LoadFailedAction,
  LoadTransitRouteParametersAuditResponse,
} from '../types/TransitRouteParameterAuditTypes';

export const saveAuditToFile = (csvData: string): void => {
  saveCSVFile('Transit Route Parameters Audit', csvData);
};

export const loadTransitRouteParametersAuditStarted = (): LoadStartedAction => {
  return {
    type: TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_STARTED,
  };
};

export const loadTransitRouteParametersAuditSuccessful = (
  response: LoadTransitRouteParametersAuditResponse
): LoadSuccessAction => {
  saveAuditToFile(response.data.auditLog);
  return {
    type: TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL,
    payload: response,
  };
};

export const loadTransitRouteParametersAuditFailed = (error: string): LoadFailedAction => {
  return {
    type: TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_FAILED,
    error,
  };
};
