// @flow
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { TOGGLE_MODAL_VISIBLE, SET_MODAL_CONTENT } from '../actions/actionTypes';

import type { Modals } from '../types/state/Modal';
import type { ModalAction } from '../actions/modalActions';
import { UPDATE_FEATURE_TOGGLES_FAILED, UPDATE_FEATURE_TOGGLES_SUCCESSFUL } from '../actions/FeatureTogglesActions';

export const initialState: Modals = [
  {
    isVisible: false,
    modalHeader: '',
    modalContent: null,
  },
  {
    isVisible: false,
    modalHeader: '',
    modalContent: null,
  },
];

export default function modalReducer(state: Modals = initialState, action: ModalAction) {
  switch (action.type) {
    case UPDATE_FEATURE_TOGGLES_FAILED:
    case UPDATE_FEATURE_TOGGLES_SUCCESSFUL:
    case TOGGLE_MODAL_VISIBLE: {
      const layer = get(action, 'payload.layer', 0);
      const newState: Modals = cloneDeep(state);
      newState[layer].isVisible = !newState[layer].isVisible;
      return newState;
    }
    case SET_MODAL_CONTENT: {
      const { header, content, layer } = action.payload;
      const newState: Modals = cloneDeep(state);

      const modalLayer: number = layer || 0;

      newState[modalLayer] = { ...newState[modalLayer], modalHeader: header, modalContent: content };

      return newState;
    }
    default:
      return state;
  }
}
