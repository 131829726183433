// @flow
/* eslint-disable react/no-did-mount-set-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import soapUserTypes from '@nats/nats-service-sdk/lib/types/SoapUser';
import { FormBuilder, Loading, Notification } from '@nats/webclient-common';
import createSoapUserValidationSchema from '@nats/nats-service-sdk/lib/validation/soapUser/createSoapUserValidation';
import editValidationSchema from '@nats/nats-service-sdk/lib/validation/soapUser/editSoapUserValidation';
import type { CreateSoapUserObject, EditSoapUserObject, LoadSoapUsersResponse } from '~/api/soapUserApi';

import formModalStyles from '../components/forms/FormModal.module.scss';
import { FORM_MODE, getErrorMessage } from '../components/forms/getFormFromType';

import { createSoapUser, editSoapUser, loadSoapUsers } from '../api/soapUserApi';
import { toggleModalVisible } from '../actions/modalActions';
import { selectPanel } from '../actions/panelActions';
import { setSelectedRecord } from '../actions/appActions';

import type { State as Store } from '../types/ReduxStateType';
import type { FieldDefinitions } from '../types/FormBuilderTypes';
import type { RawSoapUserRecord } from './SoapUserScreen';
import type { FormMode } from '../components/forms/getFormFromType';

import styles from './SoapUser.module.scss';

type Props = {
  selectedRecord: ?RawSoapUserRecord,
  createSoapUser: CreateSoapUserObject => Promise<mixed>,
  editSoapUser: EditSoapUserObject => Promise<mixed>,
  mode: FormMode,
  toggleModalVisible: () => mixed,
  loadSoapUsers: () => Promise<LoadSoapUsersResponse>,
  selectPanel: (?string, {}) => mixed,
  setSelectedRecord: (?{}) => mixed,
};

type State = {
  fields: FieldDefinitions,
  submissionError: null | string,
  formWarning: string,
};

class SoapUser extends Component<Props, State> {
  errorMap: { [string]: string };

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: [],
      submissionError: null,
      formWarning: 'Caution: incorrect settings may cause serious issues.',
    };

    const actions: Function = () => {
      switch (this.props.mode) {
        case FORM_MODE.VIEW:
          return { past: 'viewed', present: 'view' };
        case FORM_MODE.EDIT:
          return { past: 'edited', present: 'edit' };
        default:
          return { past: 'created', present: 'create' };
      }
    };

    this.errorMap = {
      GENERIC_ERROR: `SOAP user could not be ${actions().past}. Please try again.`,
      PERMISSION_ERROR: `You do not have permission to ${actions().present} a SOAP user.`,
      SERVER_ERROR: 'An unexpected server error occured. Contact helpdesk if this persists.',
      UNKNOWN_ERROR: 'An unknown error occured. Contact helpdesk if this persists.',
      INVALID_REQUEST_BODY: 'The request body was invalid. Contact helpdesk if this persists.',
      SOAP_USERNAME_IN_USE_ERROR: 'Username is already in use.',
      SOAP_USER_LIMIT_REACHED: 'There can only be 200 SOAP users. Delete a SOAP user first.',
      SOAP_USER_NOT_FOUND: 'SOAP user not found. Refresh the page.',
      MAILBOXES_IN_USE_ERROR: 'MAILBOXES_IN_USE_ERROR', // This error message is being handled elsewhere.
      INVALID_ENDPOINT_SET_ERROR: 'The system endpoint configuration is invalid. Contact helpdesk.',
      INVALID_SYSTEM_CONFIG: 'The system configuration is invalid. Contact helpdesk.',
      CURRENT_VALUES_OUT_OF_DATE: 'The table is out of date. Refresh the page.',
      EDITING_PENDING_DELETE_ERROR: 'Cannot edit a user pending deletion. Refresh the page.',
    };
  }

  componentDidMount() {
    const enabledField = {
      type: 'checkbox',
      name: 'enabled',
      isLabelInline: true,
      checkboxText: 'Enabled',
      initialValue: true,
      initialValueFrom: 'enabled',
      cols: 12,
      shouldWrapTooltip: true,
      isDisabled: this.props.mode === FORM_MODE.VIEW,
    };

    const usernameField = {
      type: 'text',
      name: 'username',
      label: 'Username',
      initialValue: '',
      initialValueFrom: 'username',
      cols: 12,
      shouldWrapTooltip: true,
      isDisabled: this.props.mode !== FORM_MODE.CREATE,
    };

    const passwordField = {
      type: 'password',
      name: 'password',
      label: 'Password',
      initialValue: this.props.mode === FORM_MODE.VIEW ? '******************' : '',
      cols: 12,
      shouldWrapTooltip: true,
    };

    const passwordConfirmField = {
      type: 'password',
      name: 'confirmPassword',
      label: 'Re-enter Password',
      initialValue: this.props.mode === FORM_MODE.VIEW ? '******************' : '',
      cols: 12,
      shouldWrapTooltip: true,
      isHidden: this.props.mode === FORM_MODE.VIEW,
    };

    const typeField = {
      type: 'select',
      name: 'userType',
      label: 'User Type',
      initialValue: this.props.mode !== FORM_MODE.CREATE ? '' : soapUserTypes.NORMAL,
      initialValueFrom: this.props.mode !== FORM_MODE.CREATE ? 'userType' : undefined,
      cols: 12,
      shouldWrapTooltip: true,
      options: Object.keys(soapUserTypes).map(type => ({
        text: type,
        value: type,
      })),
      shouldShowWarning: {
        message: 'If a user of this system type already exists, it will be changed to a normal user.',
        when: async (value: mixed) => value !== 'NORMAL',
      },
    };

    const endpointField = {
      type: 'text',
      name: 'endpoint',
      label: 'Endpoint',
      initialValue: '',
      initialValueFrom: 'lastEndpoint',
      cols: 12,
      shouldWrapTooltip: true,
      isDisabled: true,
      isHidden: this.props.mode === FORM_MODE.CREATE,
    };

    const requestField = {
      type: 'text',
      name: 'request',
      label: 'Last EAMS request',
      initialValue: '',
      initialValueFrom: {
        path: 'lastEamsRequest',
        withTransformation: dateString =>
          dateString === 'NO_LAST_USAGE'
            ? 'No EAMS requests have been made'
            : moment.utc(dateString).format('YY.MM.DD HH:mm:ss'),
      },
      cols: 12,
      shouldWrapTooltip: true,
      isDisabled: true,
      isHidden: this.props.mode === FORM_MODE.CREATE,
    };

    const mailboxesField = {
      type: 'textarea',
      name: 'mailboxes',
      label: 'Mailboxes',
      initialValue: '',
      initialValueFrom: {
        path: 'mailboxes',
        withTransformation: mailboxes => mailboxes.join(', '),
      },
      cols: 12,
      shouldWrapTooltip: true,
      outputValueAt: {
        path: 'mailboxes',
        withTransformation: value => value.split(',').map(mailbox => mailbox.trim().toUpperCase()),
      },
    };

    const statusField = {
      type: 'text',
      name: 'status',
      label: 'Status',
      initialValue: '',
      initialValueFrom: 'status',
      cols: 12,
      shouldWrapTooltip: true,
      isDisabled: true,
      isHidden: this.props.mode !== FORM_MODE.VIEW,
    };

    const viewFields = [
      enabledField,
      usernameField,
      passwordField,
      passwordConfirmField,
      typeField,
      endpointField,
      requestField,
      mailboxesField,
      statusField,
    ];

    const createFields = [enabledField, usernameField, passwordField, passwordConfirmField, typeField, mailboxesField];

    this.setState({
      fields: this.props.mode === FORM_MODE.CREATE ? createFields : viewFields,
    });
  }

  getUpdateValues: Function = values => {
    const updateValues = {};
    const currentSoapUser = this.props.selectedRecord;
    if (!currentSoapUser) throw new Error('Empty selected record');
    if (values.enabled !== currentSoapUser.enabled) {
      updateValues.enabled = values.enabled;
    }
    if (values.password !== '') {
      updateValues.password = values.password;
    }
    if (!isEmpty(values.mailboxes) && !isEqual([...values.mailboxes].sort(), [...currentSoapUser.mailboxes].sort())) {
      updateValues.mailboxes = values.mailboxes;
    }

    if (values.userType !== currentSoapUser.userType) {
      updateValues.userType = values.userType;
    }
    return updateValues;
  };

  onSubmit: Function = async (values: CreateSoapUserObject) => {
    this.setState({ submissionError: null });

    const action = this.props.mode === FORM_MODE.CREATE ? 'created' : 'edited';
    const currentSoapUser = this.props.selectedRecord;

    let updateValues;

    const hidePanel = () => {
      this.props.setSelectedRecord(null);
      this.props.selectPanel(null, {});
    };
    try {
      switch (this.props.mode) {
        case FORM_MODE.VIEW:
          break;
        case FORM_MODE.CREATE:
          await this.props.createSoapUser(values);
          break;
        case FORM_MODE.EDIT:
          if (!currentSoapUser || !values) throw new Error(`Empty selected record`);
          updateValues = this.getUpdateValues(values);
          if (isEmpty(updateValues)) {
            this.setState({
              submissionError: 'You have to make changes before you submit.',
            });
            return;
          }
          await this.props.editSoapUser({
            id: currentSoapUser.username,
            currentValues: {
              enabled: currentSoapUser.enabled,
              mailboxes: currentSoapUser.mailboxes,
              userType: currentSoapUser.userType,
            },
            updateValues,
          });
          hidePanel();
          break;
        default:
          throw new Error(`Unknown form mode '${this.props.mode}`);
      }

      this.props.toggleModalVisible();
      this.props.loadSoapUsers();

      Notification.success(`Soap user successfully ${action}`);
    } catch (error) {
      this.setSubmissionError(error);
    }
  };

  renderUserChangeWarning = () => {
    const { formWarning } = this.state;

    if (!formWarning) return null;

    return (
      <div data-qa-id="formWarning" className={`${styles.validationErrors} ${styles.alertBox} ${styles.warningAlert}`}>
        <ul>
          <li>{formWarning}</li>
        </ul>
      </div>
    );
  };

  renderSubmissionError = () => {
    const { submissionError } = this.state;

    if (!submissionError) return null;

    return (
      <div
        data-qa-id="submissionError"
        className={`${styles.validationErrors} ${styles.alertBox} ${styles.errorAlert}`}
      >
        <ul>
          <li>{submissionError}</li>
        </ul>
      </div>
    );
  };

  handleMailboxInUseError = (errorCode: string) => {
    if (errorCode.includes(this.errorMap.MAILBOXES_IN_USE_ERROR)) {
      return errorCode.replace(this.errorMap.MAILBOXES_IN_USE_ERROR, '').trim();
    }

    return null;
  };

  setSubmissionError: Function = error => {
    if (!error.response || !error.response.data) {
      this.setState({
        submissionError: this.errorMap.GENERIC_ERROR,
      });
      return;
    }

    const mailboxInUseError = this.handleMailboxInUseError(error.response.data.error);
    if (mailboxInUseError != null) {
      this.setState({
        submissionError: mailboxInUseError,
      });
      return;
    }
    this.setState({
      submissionError: getErrorMessage(this.errorMap, error.response.data.error),
    });
  };

  render() {
    if (this.state.fields.length === 0) {
      return (
        <div>
          <Loading block isLoading hasData />
        </div>
      );
    }

    const style = this.props.mode === FORM_MODE.VIEW ? { padding: 0 } : null;

    const getSubmitText = () => {
      switch (this.props.mode) {
        case FORM_MODE.CREATE:
          return 'Create SOAP user';
        case FORM_MODE.EDIT:
          return 'Save';
        default:
          return null;
      }
    };

    return (
      <div className={formModalStyles.modalForm} style={style}>
        {this.renderSubmissionError()}
        {this.props.mode !== FORM_MODE.VIEW ? this.renderUserChangeWarning() : null}
        <FormBuilder
          name="soap-user"
          isComposing={this.props.mode !== FORM_MODE.VIEW}
          fields={this.state.fields}
          initialValues={this.props.selectedRecord}
          submitButtonText={getSubmitText()}
          validationSchema={
            this.props.mode === FORM_MODE.CREATE ? createSoapUserValidationSchema : editValidationSchema
          }
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

export const mapStateToProps = ({ app: { selectedRecord } }: Store) => ({
  selectedRecord,
});

export const mapDispatchToProps = {
  createSoapUser,
  toggleModalVisible,
  loadSoapUsers,
  editSoapUser,
  setSelectedRecord,
  selectPanel,
};

export { SoapUser as PureSoapUser };
export default connect(mapStateToProps, mapDispatchToProps)(SoapUser);
