// @flow

import { PANEL_SELECT } from './actionTypes';
import getFormFromType, { FORM_MODE } from '../components/forms/getFormFromType';
import type { FormCode } from '../components/forms/getFormFromType';

type panelSelectAction = {
  type: typeof PANEL_SELECT,
  panelTitle: string,
  PanelComponent: Function,
  panelProps: {},
};

export type PanelAction = panelSelectAction;

export function selectPanel(formCode: ?FormCode, props: {}): panelSelectAction {
  if (!formCode) {
    return {
      type: PANEL_SELECT,
      panelTitle: '',
      PanelComponent: null,
      panelProps: {},
    };
  }

  // right-side panel is only used for viewing read-only forms
  const [title, component] = getFormFromType(formCode, FORM_MODE.VIEW, props);

  return {
    type: PANEL_SELECT,
    panelTitle: title,
    PanelComponent: component,
    panelProps: props,
  };
}

export function selectPanelCustom(title: string, component: React$Node, props: {}): panelSelectAction {
  return {
    type: PANEL_SELECT,
    panelTitle: title,
    PanelComponent: component,
    panelProps: props,
  };
}

export function closePanel(): panelSelectAction {
  return {
    type: PANEL_SELECT,
    panelTitle: '',
    PanelComponent: null,
    panelProps: {},
  };
}
