// @flow

import { sortModelToUrl } from '@nats/webclient-common/lib/helpers/Urls';
import { helper } from '@nats/webclient-common';
import type { ThunkAction } from '../types/ReduxActionTypes';

import { apiGet, apiPost } from '../api/api';
import { handleApiError } from './errorActions';
import environment from '../environment';
import { exportToCSV } from '../utilities/exportToCSV';
import userMapperModule from '../utilities/csvMappers/usersScreen';
import type { ExportUserProfiles, ExportUsersResponse } from '../types/User';
import { fetchUsersFailure, fetchUsersLoading, fetchUsersSuccess } from './userActions';

const USERS_URL = `${environment.identityServiceUrl}/api/v1/users`;
const USER_PROFILES_URL = `${environment.messagesServiceUrl}/api/v1/user-profiles`;

export function exportUsers(): ThunkAction {
  return async function action(dispatch: Function, getState: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve message service data; config not provided');
    }

    if (!environment.identityServiceUrl) {
      throw new Error('Unable to retrieve identity service details data; config not provided');
    }

    try {
      dispatch(fetchUsersLoading());

      const {
        userState: { viewState },
      } = getState();

      const selectionParams = viewState.sortModel.length !== 0 ? { sort: sortModelToUrl(viewState.sortModel) } : {};
      const filterParams = helper.Urls.filterModelToUrlParams(viewState.filterModel);

      if (filterParams.type) {
        filterParams.type = filterParams.type.trim().replace(/ /g, '_');
      }

      const usersResponseObj: ExportUsersResponse = await apiGet(USERS_URL, {
        ...filterParams,
        ...selectionParams,
      });
      const usersData = usersResponseObj.data._embedded.users;

      const uniqueIds = usersData.map(user => user.id);

      dispatch(fetchUsersLoading());
      const usersProfileResponse: ExportUserProfiles = await apiPost(USER_PROFILES_URL, [...uniqueIds]);

      dispatch(fetchUsersSuccess());
      const profileDetails = usersProfileResponse.data._embedded.userProfiles;
      exportToCSV('Users', userMapperModule.userMapper(usersData, profileDetails));
    } catch (err) {
      dispatch(fetchUsersFailure());
      dispatch(handleApiError(err));
      throw err;
    }
  };
}
