// @flow

import React from 'react';
import { Button } from '@nats/webclient-common';

import styles from './ConfirmRemoveUserMessagesLicence.module.scss';

type Props = {
  onConfirm: () => void,
  onCancel: () => void,
};

const ConfirmRemoveUserMessagesLicence = ({ onConfirm, onCancel }: Props) => {
  return (
    <div className={styles.confirmRemoveLicenceDialog} data-qa-id="confirm-remove-user-messages-licence">
      <p>All message settings will be removed from this user, click ok to continue, cancel to stop.</p>
      <div className={styles.buttonGroup}>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="primary" onClick={onConfirm}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default ConfirmRemoveUserMessagesLicence;
