// @flow
import jwt from 'jsonwebtoken';
import { hasPermission, getPermissions } from '@nats/webclient-common/lib/helpers/permissions';
import { hasPermission as hasPermissionSdk } from '@nats/nats-service-sdk/lib/lambda/request';
import { userTypes } from '@nats/nats-service-sdk/lib/types/User';
import permissionsEnum from '@nats/nats-service-sdk/lib/types/Permissions';
import { cookie } from '@nats/webclient-common';

import type { Organisation } from '~/types/Organisation';
import type { RawUserRecord } from '~/containers/users/UserScreen';

export const hasFacadePermissions = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersAdd) &&
  hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersView) &&
  hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersEdit) &&
  hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersDelete);

export const canViewMessageFacadeUser = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersView);

export const canDeleteMessageFacadeUser = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersDelete);

export const canCreateUser = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.UserCreateDefault) ||
  hasPermission(hasPermissionSdk, permissionsEnum.UserCreateOrganisationAdmin) ||
  hasPermission(hasPermissionSdk, permissionsEnum.UserCreateAuthorityAdmin) ||
  hasPermission(hasPermissionSdk, permissionsEnum.UserCreateNATSHelpdesk);

export const canCreateSoapUser = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.SoapUsersAdd);

export const canEditSoapUser = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.SoapUsersEdit);

export const canEditSoapEndpoint = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.SoapEndpointsEdit);

export const canCreateUserOtherOrg = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.UserCreateAuthorityAdmin) ||
  hasPermission(hasPermissionSdk, permissionsEnum.UserCreateNATSHelpdesk);

export const hasFacadeRecord = (selectedUser: RawUserRecord): boolean =>
  selectedUser && selectedUser.applications.includes('MESSAGES_FACADE');

export const canEditUser = (selectedUser: RawUserRecord): boolean => {
  if (cookie.getCookie(cookie.COOKIE.COGNITO_SUB) !== selectedUser.id) {
    if (selectedUser.type === userTypes.DEFAULT && hasPermission(hasPermissionSdk, permissionsEnum.UserEditDefault)) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersEdit)
        : true;
    } else if (
      selectedUser.type === userTypes.ORGANISATION_ADMIN &&
      hasPermission(hasPermissionSdk, permissionsEnum.UserEditOrganisationAdmin)
    ) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersEdit)
        : true;
    } else if (
      selectedUser.type === userTypes.AUTHORITY_ADMIN &&
      hasPermission(hasPermissionSdk, permissionsEnum.UserEditAuthorityAdmin)
    ) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersEdit)
        : true;
    } else if (
      selectedUser.type === userTypes.NATS_HELP_DESK &&
      hasPermission(hasPermissionSdk, permissionsEnum.UserEditNATSHelpdesk)
    ) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersEdit)
        : true;
    }
  }
  return false;
};

export const canDeleteUser = (selectedUser: RawUserRecord): boolean => {
  if (selectedUser && cookie.getCookie(cookie.COOKIE.COGNITO_SUB) !== selectedUser.id) {
    if (selectedUser.type === userTypes.DEFAULT && hasPermission(hasPermissionSdk, permissionsEnum.UserDeleteDefault)) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersDelete)
        : true;
    } else if (
      selectedUser.type === userTypes.ORGANISATION_ADMIN &&
      hasPermission(hasPermissionSdk, permissionsEnum.UserDeleteOrganisationAdmin)
    ) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersDelete)
        : true;
    } else if (
      selectedUser.type === userTypes.AUTHORITY_ADMIN &&
      hasPermission(hasPermissionSdk, permissionsEnum.UserDeleteAuthorityAdmin)
    ) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersDelete)
        : true;
    } else if (
      selectedUser.type === userTypes.NATS_HELP_DESK &&
      hasPermission(hasPermissionSdk, permissionsEnum.UserDeleteNATSHelpdesk)
    ) {
      return hasFacadeRecord(selectedUser)
        ? hasPermission(hasPermissionSdk, permissionsEnum.MessagesFacadeUsersDelete)
        : true;
    }
  }
  return false;
};

export function getOwnOrganisation(): ?Organisation {
  const rawOrganisation = jwt.decode(cookie.getCookie(cookie.COOKIE.ID_TOKEN))['mos:organisation'];

  if (!rawOrganisation) {
    return null;
  }

  return JSON.parse(rawOrganisation);
}

export const canCreateOrganisation = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.OrganisationCreate);

export const canEditOrganisation = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.OrganisationEdit);

export const canEditAuthority = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.AuthorityEdit);

export const canUploadReferenceData = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.ReferenceDataEdit);

export const userTypeDisplayMapping = {
  [userTypes.DEFAULT]: 'Default',
  [userTypes.ORGANISATION_ADMIN]: 'Organisation Admin',
  [userTypes.AUTHORITY_ADMIN]: 'Authority Admin',
  [userTypes.NATS_HELP_DESK]: 'NATS Help Desk',
};

export const getUserTypeCreationOptions = (): Array<{
  value: string,
  text: string,
}> => {
  const userTypeList = [];
  if (hasPermission(hasPermissionSdk, permissionsEnum.UserCreateDefault)) {
    userTypeList.push({
      value: userTypes.DEFAULT,
      text: userTypeDisplayMapping[userTypes.DEFAULT],
    });
  }
  if (hasPermission(hasPermissionSdk, permissionsEnum.UserCreateOrganisationAdmin)) {
    userTypeList.push({
      value: userTypes.ORGANISATION_ADMIN,
      text: userTypeDisplayMapping[userTypes.ORGANISATION_ADMIN],
    });
  }
  if (hasPermission(hasPermissionSdk, permissionsEnum.UserCreateAuthorityAdmin)) {
    userTypeList.push({
      value: userTypes.AUTHORITY_ADMIN,
      text: userTypeDisplayMapping[userTypes.AUTHORITY_ADMIN],
    });
  }
  if (hasPermission(hasPermissionSdk, permissionsEnum.UserCreateNATSHelpdesk)) {
    userTypeList.push({
      value: userTypes.NATS_HELP_DESK,
      text: userTypeDisplayMapping[userTypes.NATS_HELP_DESK],
    });
  }
  return userTypeList;
};

export function getUserTypeEditOptions(selectedUser: RawUserRecord): Array<{ value: string, text: string }> {
  if (selectedUser.type === userTypes.NATS_HELP_DESK) {
    const permissions: Array<Number> = getPermissions();
    if (permissions.includes(permissionsEnum.UserEditNATSHelpdesk)) {
      return [
        {
          value: userTypes.NATS_HELP_DESK,
          text: userTypeDisplayMapping[userTypes.NATS_HELP_DESK],
        },
      ];
    }
    return [];
  }

  return getPermissions().reduce((acc, permission) => {
    switch (permission) {
      case permissionsEnum.UserEditDefault:
        return [
          ...acc,
          {
            value: userTypes.DEFAULT,
            text: userTypeDisplayMapping[userTypes.DEFAULT],
          },
        ];
      case permissionsEnum.UserEditOrganisationAdmin:
        return [
          ...acc,
          {
            value: userTypes.ORGANISATION_ADMIN,
            text: userTypeDisplayMapping[userTypes.ORGANISATION_ADMIN],
          },
        ];
      case permissionsEnum.UserEditAuthorityAdmin:
        return [
          ...acc,
          {
            value: userTypes.AUTHORITY_ADMIN,
            text: userTypeDisplayMapping[userTypes.AUTHORITY_ADMIN],
          },
        ];
      default:
        return acc;
    }
  }, []);
}

export const canCreateMessageProfile = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessageProfilesCreate);

export const canEditMessageProfile = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessageProfilesEdit);

export const canDeleteMessageProfile = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessageProfilesDelete);

export const canCreateMessageMailbox = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.MailboxCreate);

export const canEditMessageMailbox = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.MailboxEdit);

export const canDeleteMessageMailbox = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.MailboxDelete);

export const canDownloadMessageParametersAudit = (): boolean =>
  hasPermission(hasPermissionSdk, permissionsEnum.MessageParametersEdit);

export const canDeleteOrganisation = (): boolean => hasPermission(hasPermissionSdk, permissionsEnum.OrganisationDelete);
