const columnMappings = {
  Authorities: [
    { label: 'Authority Name', value: 'authorityName' },
    { label: 'Application', value: 'name' },
    { label: 'Allocated Licences', value: 'allocated_licences' },
    { label: 'Max Licences', value: 'max_licences' },
    { label: 'Remaining Licences', value: 'remaining_licences' },
  ],
  Mailboxes_By_User: [
    { label: 'Username', value: 'username' },
    { label: 'Mailbox', value: 'mailbox' },
  ],
  'Message Mailboxes': [
    { label: 'Address', value: 'address' },
    { label: 'Name', value: 'description' },
    { label: 'Organisation', value: 'organisationName' },
    { label: 'Monitored', value: 'isMonitored' },
  ],
  'Message Profiles': [
    { label: 'Profile Name', value: 'name' },
    { label: 'Description', value: 'description' },
    { label: 'Can Delete Message Templates', value: 'canDeleteMessageTemplate' },
    { label: 'Can Mark Messages As Read', value: 'canMarkMessageAsRead' },
    { label: 'Can Send ASP', value: 'canSendASP' },
    { label: 'Can Send ATFM', value: 'canSendATFM' },
    { label: 'Can Send FPL', value: 'canSendFPL' },
    { label: 'Can Send Free Text', value: 'canSendFreeText' },
    { label: 'Can Send NOTAM Proposal', value: 'canSendNOTAMProposal' },
    { label: 'Can Send RQL and RQN', value: 'canSendRQNL' },
    { label: 'Can Send SNOWTAM', value: 'canSendSNOWTAM' },
    { label: 'Can View ASP', value: 'canViewASP' },
    { label: 'FPL Supplementary Information Optional', value: 'fplSupplementaryInfoOptional' },
  ],
  Organisations: [
    { label: 'Organisation name', value: 'name' },
    { label: 'Admin allocated licences', value: 'admin_allocated_users' },
    { label: 'Admin max licences', value: 'admin_max_users' },
    { label: 'Local Avoids allocated licences', value: 'local_avoids_allocated_users' },
    { label: 'Local Avoids max licences', value: 'local_avoids_max_users' },
    { label: 'Messages allocated licences', value: 'messages_allocated_users' },
    { label: 'Messages max licences', value: 'messages_max_users' },
    { label: 'Messages Facade allocated licences', value: 'messages_facade_allocated_users' },
    { label: 'Messages Facade max licences', value: 'messages_facade_max_users' },
    { label: 'Transit Routes allocated licences', value: 'transit_routes_allocated_users' },
    { label: 'Transit Routes max licences', value: 'transit_routes_max_users' },
    { label: 'Visual allocated licences', value: 'visual_allocated_users' },
    { label: 'Visual max licences', value: 'visual_max_users' },
    { label: 'Wingboard allocated licences', value: 'wingboard_allocated_users' },
    { label: 'Wingboard max licences', value: 'wingboard_max_users' },
    { label: 'LARA allocated licences', value: 'lara_allocated_users' },
    { label: 'LARA max licences', value: 'lara_max_users' },
  ],
  Organisations_Mailboxes: [
    { label: 'Organisation name', value: 'organisation_name' },
    { label: 'Mailbox', value: 'mailbox_name' },
  ],
  Organisations_Profiles: [
    { label: 'Organisation name', value: 'organisation_name' },
    { label: 'Profile', value: 'profile_name' },
  ],
  'Reference Data': [
    { label: 'Data Area', value: 'dataArea' },
    { label: 'Current File Name', value: 'currentFileName' },
    { label: 'Current File Size', value: 'currentFileSize' },
    { label: 'File Extension', value: 'fileExtension' },
    { label: 'Last Uploaded (UTC)', value: 'lastUploadedTimestamp' },
    { label: 'By User', value: 'lastUploadedUsername' },
  ],
  Users: [
    { label: 'Username', value: 'username' },
    { label: 'Organisation', value: 'organisation' },
    { label: 'User Type', value: 'type' },
    { label: 'Licences', value: 'licences' },
    { label: 'Profile', value: 'profile' },
    { label: 'Email', value: 'email' },
  ],
  Logins_By_Org: [
    { label: 'Username', value: 'username' },
    { label: 'Organisation', value: 'organisation' },
    { label: 'Login Count', value: 'loginCount' },
    { label: 'Last Login', value: 'loginDateTime' },
  ],
  'SOAP Endpoints': [
    { label: 'Endpoint Name', value: 'name' },
    { label: 'Last EAMS Request (UTC)', value: 'lastEamsRequest' },
    { label: 'Status', value: 'status' },
    { label: 'Enabled', value: 'currentState' },
  ],
  'SOAP Users': [
    { label: 'Username', value: 'username' },
    { label: 'Enabled', value: 'enabled' },
    { label: 'Endpoint', value: 'lastEndpoint' },
    { label: 'Mailboxes', value: 'mailboxes' },
    { label: 'User Type', value: 'userType' },
    { label: 'Last EAMS Request (UTC)', value: 'lastEamsRequest' },
    { label: 'Status', value: 'status' },
  ],
};

export default columnMappings;
