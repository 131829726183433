// @flow
import React from 'react';
import { connect } from 'react-redux';
import { deleteUserConfirm } from '~/actions/userActions';
import type { State as Store } from '~/types/ReduxStateType';
import { ConfirmModal } from '~/components/confirmModal/confirmModal';

type ErrorType = { response?: { data?: { error: string } } };

const getErrorMessage = (error: ErrorType) => {
  const defaultMessage = 'There was an error deleting the user.';

  if (!error) {
    return defaultMessage;
  }

  const errorMap = {
    GENERIC_ERROR: defaultMessage,
    SERVER_ERROR: defaultMessage,
    PERMISSION_ERROR: 'You do not have permission to delete a user.',
    ILLEGAL_ACTION_ERROR: 'You cannot perform this action on the selected user.',
  };

  if (error.response && error.response.data) {
    if (error.response.data.error in errorMap) {
      return errorMap[error.response.data.error];
    }
  }

  return defaultMessage;
};

type ConfirmUserProps = {
  isLoading: boolean,
  error: ErrorType,
  onConfirm: () => void,
  onCancel: () => void,
};

const ConfirmUserDelete = (props: ConfirmUserProps) => (
  <ConfirmModal
    isLoading={props.isLoading}
    confirmationMessage="Are you sure you want to delete this user?"
    errorMessage={props.error ? getErrorMessage(props.error) : null}
    onCancel={() => props.onCancel()}
    onConfirm={() => {
      props.onConfirm();
    }}
  />
);

type ConfirmUserDeleteConnectedProps = {
  isLoading: boolean,
  error: ErrorType,
  userId: string,
  username: string,
  isFacadeUser: boolean,
  onCancel: () => void,
  deleteUserConfirm: (string, string, boolean) => void,
};

const ConfirmUserDeleteConnected = (props: ConfirmUserDeleteConnectedProps) => {
  return (
    <ConfirmUserDelete
      isLoading={props.isLoading}
      error={props.error}
      onConfirm={() => {
        props.deleteUserConfirm(props.userId, props.username, props.isFacadeUser);
      }}
      onCancel={() => props.onCancel()}
    />
  );
};

const mapStateToProps = (state: Store) => ({
  isLoading: state.userState.delete.isLoading,
  error: state.userState.delete.error,
});

const mapDispatchToProps = {
  deleteUserConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUserDeleteConnected);
