// @flow
import React from 'react';
import get from 'lodash/get';
import { DataTable, Portal } from '@nats/webclient-common';
import type { OrganisationProfileEdit } from '~/types/OrganisationProfilesTypes';
import orgStyles from './Organisation.module.scss';

type OrganisationProfilesEditProps = {
  tableData: Array<OrganisationProfileEdit>,
  isLoading: boolean,
  parentNodeId: ?string,
  assignmentChangeHandler: (id: string) => void,
};

const NO_DATA_TEXT = 'No message profiles defined.';

const OrganisationProfilesEditTable = ({
  tableData,
  isLoading,
  parentNodeId,
  assignmentChangeHandler,
}: OrganisationProfilesEditProps) => {
  const getAssignedCell = cellInfo => {
    const cellValue = tableData[cellInfo.index].isAssigned;
    return <input type="checkbox" checked={cellValue} onChange={() => assignmentChangeHandler(cellInfo.original.id)} />;
  };

  const renderComponent = () => {
    const columns = [
      {
        Header: 'Profile Name',
        id: 'name',
        accessor: (organisationProfile: OrganisationProfileEdit) => organisationProfile.name,
        resizable: false,
      },
      {
        accessor: (organisationProfile: OrganisationProfileEdit) => get(organisationProfile, 'isAssigned'),
        Cell: getAssignedCell,
        className: 'toggleInput',
        Header: 'Assigned',
        headerClassName: 'toggleInputHeader',
        id: 'isAssigned',
        maxWidth: 85,
        property: 'isAssigned',
        resizable: false,
      },
    ];

    const getClassNames = () => (tableData.length === 0 ? orgStyles.organisationTableError : '');

    return (
      <DataTable
        className={`organisationProfilesEditTable ${getClassNames()}`}
        isClientSide
        disableFilteringAndPagination
        filterable={false}
        sortable={false}
        noDataText={NO_DATA_TEXT}
        {...{
          tableData,
          columns,
          isLoading,
        }}
      />
    );
  };

  return parentNodeId ? <Portal to={parentNodeId}>{renderComponent()}</Portal> : renderComponent();
};

export default OrganisationProfilesEditTable;
