// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import merge from 'lodash/merge';
import { FormBuilder, Notification, Loading, Suggestions } from '@nats/webclient-common';
import { createUserValidationSchemaFrontEnd } from '@nats/nats-service-sdk/lib/validation/user/createUserValidation';
import { editUserValidationSchemaFrontEnd } from '@nats/nats-service-sdk/lib/validation/user/editUserValidation';
import { applicationTypes } from '@nats/nats-service-sdk/lib/types/Applications';
import { userTypes } from '@nats/nats-service-sdk/lib/types/User';
import formModalStyles from '~/components/forms/FormModal.module.scss';
import { FORM_MODE, getErrorMessage } from '~/components/forms/getFormFromType';
import type { UserServiceConfig, ConfigMailbox } from '~/types/MessageFacade';

import {
  hasFacadePermissions,
  canCreateUser,
  canCreateUserOtherOrg,
  getOwnOrganisation,
  getUserTypeCreationOptions,
  canEditUser,
  userTypeDisplayMapping,
  getUserTypeEditOptions,
  canViewMessageFacadeUser,
} from '~/utilities/permissions';

import {
  createUser as createUserAction,
  editUser as editUserAction,
  loadUsers as loadUsersAction,
} from '~/api/userApi';
import { loadUserDetails as loadUserDetailsAction } from '~/api/userDetailsApi';
import { loadOrganisationDetails as loadOrganisationDetailsAction } from '~/api/organisationDetailsApi';
import { loadMailboxes as loadMailboxesAction } from '~/api/mailboxApi';
import { loadMessageFacadeUsers as loadMessageFacadeUsersAction } from '~/api/messageFacadeApi';
import { resetUserFormState as resetUserFormStateAction } from '~/actions/userFormResetActions';
import { setModal as setModalAction, toggleModalVisible as toggleModalVisibleAction } from '~/actions/modalActions';
import { loadOrganisations as loadOrganisationsAction } from '~/api/organisationApi';
import { setSelectedRecord as setSelectedRecordAction } from '~/actions/appActions';
import { selectPanel as selectPanelAction } from '~/actions/panelActions';

import type { UserFormValues, LoadUsersResponse } from '~/api/userApi';
import type { LoadMessageFacadeResponse } from '~/api/messageFacadeApi';
import type { UserDetails } from '~/types/UserDetails';
import type { OrganisationDetails } from '~/types/OrganisationDetailsTypes';
import type { OrganisationMailbox, UserMailbox, UserOrganisationMailbox } from '~/types/Mailbox';
import type { LoadMailboxesResponse } from '~/api/mailboxApi';
import type { OrganisationState } from '~/types/state/Organisation';
import type { UserDetailsState } from '~/types/state/UserDetails';
import type { MailboxState } from '~/types/state/Mailbox';
import type { MessageFacadeUsersState } from '~/types/state/MessageFacadeUsers';
import type { OrganisationDetailsState } from '~/types/state/OrganisationDetails';
import type { State as Store } from '~/types/ReduxStateType';
import type { FieldDefinitions, FieldDefinition, Option } from '~/types/FormBuilderTypes';
import type { FormMode } from '~/components/forms/getFormFromType';
import type { RawUserRecord } from '~/containers/users/UserScreen';
import type { Organisation } from '~/types/Organisation';

import UserMessageFacadeFromMailboxTable from './UserMessageFacadeFromMailboxTable';
import UserOrganisationMailboxTable from './UserOrganisationMailboxTable';
import ConfirmRemoveUserMessagesLicence from './ConfirmRemoveUserMessagesLicence';
import ConfirmRemoveUserFacadeLicence from './ConfirmRemoveUserFacadeLicence';
import { AllowedSendFromAddressSchema, AllowedSendToAddressesSchema } from './messageFacadeConfigSchema';

import styles from './User.module.scss';

type Props = {
  selectedRecord: ?RawUserRecord,
  mode: FormMode,
  loadUsers: () => Promise<LoadUsersResponse>,
  loadOrganisations: () => Promise<Array<Organisation>>,
  loadMailboxes: () => Promise<LoadMailboxesResponse>,
  loadMessageFacadeUsers: () => Promise<LoadMessageFacadeResponse>,
  createUser: UserFormValues => Promise<mixed>,
  editUser: UserFormValues => Promise<mixed>,
  setModal: (string, React$Node, ?number) => void,
  toggleModalVisible: (?number) => mixed,
  setSelectedRecord: (?{}) => mixed,
  selectPanel: (?string, {}) => mixed,
  loadUserDetails: (string, ?string) => Promise<UserDetails>,
  loadOrganisationDetails: (?string) => Promise<OrganisationDetails>,
  resetUserFormState: () => void,
  organisations: OrganisationState,
  userDetails: UserDetailsState,
  organisationDetails: OrganisationDetailsState,
  mailboxState: MailboxState,
  messageFacadeUsersState: MessageFacadeUsersState,
};

type RestoreValues = {
  id: ?string,
  username: ?string,
  type: ?string,
  organisation: ?string,
  applications: Array<string>,
  userDetails: { profile: ?{ id: string } },
};

type State = {
  submissionError: null | string,
  setFormikSubmitting: ?Function,
  selectedOrg: ?string,
  selectedUserType: ?string,
  fields: FieldDefinitions,
  keepModalOpenToDisplayApiError: boolean,
  lastSelectedOrganisationId: ?mixed,
  formHeight: ?number,
  userDetailsLoaded: boolean,
  organisationDetailsLoaded: boolean,
  mailboxesLoaded: boolean,
  facadeConfigLoaded: boolean,
  formReady: boolean,
  restoreValues: ?RestoreValues,
  userOrganisationMailboxes: Array<UserOrganisationMailbox>,
  userMessageFacadeConfig: UserServiceConfig,
};

export type SuggestionsResponse = {
  nextPage: ?string,
  results: Suggestions,
};

// Form field definitions
const userIdField = {
  type: 'uuid',
  name: 'userId',
  label: 'UserId',
  initialValueFrom: 'id',
  cols: 12,
  isHidden: true,
};

const usernameField: FieldDefinition = {
  type: 'text',
  name: 'username',
  label: 'Username',
  initialValue: '',
  initialValueFrom: 'username',
  cols: 12,
  shouldWrapTooltip: true,
};

const emailField = {
  type: 'text',
  name: 'email',
  label: 'Email',
  initialValue: '',
  initialValueFrom: 'email',
  cols: 12,
  shouldWrapTooltip: true,
};

const organisationField = {
  type: 'select',
  name: 'organisationId',
  label: 'Organisation',
  initialValue: '',
  initialValueFrom: 'organisation',
  cols: 12,
  shouldWrapTooltip: true,
};

const userTypeField = {
  type: 'select',
  name: 'userType',
  label: 'User Type',
  initialValue: 'DEFAULT',
  initialValueFrom: 'type',
  cols: 12,
  shouldWrapTooltip: true,
};

const messagesFacadeLabel = {
  name: 'messagesFacadeLabel',
  label: 'Messages Facade',
  shouldStartNewRow: true,
  isLabelOnly: true,
  cols: 12,
  shouldWrapTooltip: true,
  labelPaddingAbove: 'standard',
  isHidden: {
    when: 'messagesFacadeLicence',
    is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
  },
};

const facadeFromMailboxLabel = {
  name: 'facadeFromMailboxLabel',
  label: 'From Mailbox',
  shouldStartNewRow: true,
  isLabelOnly: true,
  cols: 12,
  shouldWrapTooltip: true,
  labelPaddingAbove: 'standard',
  isHidden: {
    when: 'messagesFacadeLicence',
    is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
  },
};
const facadeToMailboxLabel = {
  name: 'facadeToMailboxLabel',
  label: 'To Mailboxes',
  shouldStartNewRow: true,
  isLabelOnly: true,
  cols: 12,
  shouldWrapTooltip: true,
  labelPaddingAbove: 'standard',
  isHidden: {
    when: 'messagesFacadeLicence',
    is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
  },
};

const messagesLabel = {
  name: 'messagesLabel',
  label: 'Messages',
  shouldStartNewRow: true,
  isLabelOnly: true,
  cols: 12,
  shouldWrapTooltip: true,
  labelPaddingAbove: 'standard',
  isHidden: {
    when: 'messagesLicence',
    is: (v: mixed): boolean => !v,
  },
};

const helpdeskUserDefaultMailbox = {
  type: 'text',
  name: 'defaultMailbox',
  label: 'Default Mailbox',
  initialValue: '',
  initialValueFrom: 'defaultMailbox',
  cols: 6,
  suggestionsMinChars: 2, // Must enter the country code prefix
  isHidden: {
    when: 'messagesLicence',
    is: (v: mixed): boolean => !v,
  },
  shouldWrapTooltip: true,
};

const messagesProfile = {
  type: 'select',
  name: 'profile',
  label: 'Profile',
  initialValueFrom: 'userDetails.profile.id',
  shouldWrapTooltip: true,
  isHidden: {
    when: 'messagesLicence',
    is: (v: mixed): boolean => !v,
  },
};

const userOrganisationMailboxLabel = {
  name: 'mailboxesLabel',
  label: 'Mailboxes',
  shouldStartNewRow: true,
  isLabelOnly: true,
  cols: 12,
};

const userOrganisationMailboxPortal = {
  name: 'mailboxesPortal',
  isLabelOnly: true,
};

const userFacadeFromPortal = {
  name: 'facadeFromMailboxPortal',
  isLabelOnly: true,
};

const { ADMIN, LARA, LOCAL_AVOIDS, MESSAGES, TRANSIT_ROUTES, VISUAL, WINGBOARD, MESSAGES_FACADE } = applicationTypes;
const BLANK_ORG = { name: '', id: '' };
const DEFAULT_ORG = 0;
const DEFAULT_PROFILE = 0;
const MAILBOX_PORTAL_ID_PREFIX = 'userOrganisationMailboxesTable';
const FACADE_FROM_MAILBOX_PORTAL_ID_PREFIX = 'userFacadeFromMailboxTable';

class User extends Component<Props, State> {
  errorMap: { [string]: string };

  constructor(props: Props) {
    super(props);

    this.state = {
      submissionError: null,
      setFormikSubmitting: undefined,
      selectedOrg: undefined,
      selectedUserType: undefined,
      fields: [],
      keepModalOpenToDisplayApiError: false,
      lastSelectedOrganisationId: undefined,
      restoreValues: null,
      formHeight: null,
      userDetailsLoaded: false,
      organisationDetailsLoaded: false,
      mailboxesLoaded: false,
      facadeConfigLoaded: false,
      formReady: false,
      userOrganisationMailboxes: [],
      userMessageFacadeConfig: {},
    };

    const actions: Function = () => {
      switch (this.props.mode) {
        case FORM_MODE.VIEW:
          return { past: 'viewed', present: 'view' };
        case FORM_MODE.EDIT:
          return { past: 'edited', present: 'edit' };
        default:
          return { past: 'created', present: 'create' };
      }
    };

    this.errorMap = {
      GENERIC_ERROR: `User could not be ${actions().past}. Please try again.`,
      USERNAME_IN_USE_ERROR: 'Username is already in use',
      EMAIL_IN_USE_ERROR: 'Email address is already in use',
      LICENCE_LIMIT_EXCEEDED_ERROR: 'The selected licence(s) are unavailable for the chosen organisation',
      ORG_LOAD_FAIL: 'Failed to load organisations',
      PERMISSION_ERROR: `You do not have permission to ${actions().present} a user`,
      SERVER_ERROR: 'Failed to retrieve requested data from the server',
      NO_ORGANISATION_DETAILS_FOUND_ERROR: 'Failed to fetch message profiles for this organisation',
      NO_PROFILE_FOUND_ERROR: 'User has no message profile assigned',
      INCONSISTENT_DATA_ERROR: 'This user details are in an unexpected state and cannot be edited',
      NO_FACADE_FROM_MAILBOX_SELECTED_ERROR: 'Please select a mailbox to use as the facade from mailbox',
      INVALID_FACADE_TO_MAILBOXES_SET_ERROR: 'Please enter a valid set of facade to mailboxes',
    };
  }
  componentDidMount() {
    const {
      mode,
      selectedRecord,
      loadUserDetails,
      loadOrganisations,
      loadMailboxes,
      loadMessageFacadeUsers,
      resetUserFormState,
    } = this.props;
    const { CREATE, EDIT, VIEW } = FORM_MODE;
    if (canViewMessageFacadeUser()) {
      loadMessageFacadeUsers();
    } else {
      this.handleFacadeConfigLoaded();
    }

    if (mode !== EDIT) {
      resetUserFormState();
    }

    // Fetch all the organisations details
    if (mode !== VIEW) {
      loadOrganisations();
      loadMailboxes();
    } else {
      this.handleOrganisationsLoaded();
      this.handleMailboxesLoaded();
    }

    // If required, load the user details (message profiles only)
    const applications = selectedRecord && selectedRecord.applications ? selectedRecord.applications : [];

    if (mode !== CREATE && applications.includes(MESSAGES)) {
      const userId = selectedRecord ? selectedRecord.id : '';
      const organisationId = selectedRecord ? selectedRecord.organisationId : null;

      loadUserDetails(userId, organisationId);
    } else {
      this.handleUserDetailsLoaded();
    }

    if (selectedRecord && selectedRecord.organisation) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ selectedOrg: selectedRecord.organisation });
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { organisations, userDetails, organisationDetails, mailboxState, messageFacadeUsersState } = this.props;
    const { userDetailsLoaded, organisationDetailsLoaded, mailboxesLoaded, facadeConfigLoaded, formReady } = this.state;

    if (prevProps.organisations.isBusy && !organisations.isBusy) {
      this.handleOrganisationsLoaded();
    }
    if (prevProps.organisationDetails.isBusy && !organisationDetails.isBusy) {
      this.handleOrganisationDetailsLoaded();
    }

    if (prevProps.userDetails.isBusy && !userDetails.isBusy) {
      this.handleUserDetailsLoaded();
    }

    if (prevProps.mailboxState.isBusy && !mailboxState.isBusy) {
      this.handleMailboxesLoaded();
    }

    if (prevProps.messageFacadeUsersState.isBusy && !messageFacadeUsersState.isBusy) {
      this.handleFacadeConfigLoaded();
    }

    if (userDetailsLoaded && organisationDetailsLoaded && mailboxesLoaded && facadeConfigLoaded && !formReady) {
      this.setUserOrganisationMailboxes();
      this.setUserFacadeConfig();
      this.createForm();
    }
  }

  handleOrganisationsLoaded = () => {
    const { mode, organisations, selectedRecord } = this.props;

    if (organisations.error) {
      this.setState({ formReady: true });
      return;
    }

    if (mode === FORM_MODE.CREATE) {
      const defaultOrgId = organisations.organisations[DEFAULT_ORG].id;
      this.updateOrganisationDetails(defaultOrgId);
      this.setState({ selectedOrg: defaultOrgId });
    } else {
      const selectedOrg = selectedRecord ? selectedRecord.organisationId : undefined;
      this.updateOrganisationDetails(selectedOrg);
    }
  };

  handleUserDetailsLoaded = () => {
    this.setState({ userDetailsLoaded: true });
  };

  handleFacadeConfigLoaded = () => {
    this.setState({ facadeConfigLoaded: true });
  };

  handleMailboxesLoaded = () => {
    this.setState({ mailboxesLoaded: true });
  };

  handleOrganisationDetailsLoaded = () => {
    this.setState({ organisationDetailsLoaded: true });
  };

  handleMessagesLicenceRemoval = async (formValues: UserFormValues) => {
    const modalLayer = 1;
    const { setModal, toggleModalVisible } = this.props;
    toggleModalVisible(modalLayer);
    return setModal(
      formValues.username,
      <ConfirmRemoveUserMessagesLicence
        onConfirm={() => {
          toggleModalVisible(modalLayer);
          this.updateRestoreValues({ ...formValues, messagesLicence: false, profile: undefined });
          this.setState({ formReady: false });
        }}
        onCancel={() => {
          toggleModalVisible(modalLayer);
        }}
      />,
      modalLayer
    );
  };

  handleFacadeMessagesLicenceRemoval = async (formValues: UserFormValues) => {
    const modalLayer = 1;
    const { setModal, toggleModalVisible } = this.props;
    toggleModalVisible(modalLayer);
    return setModal(
      formValues.username,
      <ConfirmRemoveUserFacadeLicence
        onConfirm={() => {
          toggleModalVisible(modalLayer);
          this.updateRestoreValues({ ...formValues, messagesFacadeLicence: false });
          this.setState({ formReady: false });
        }}
        onCancel={() => {
          toggleModalVisible(modalLayer);
        }}
      />,
      modalLayer
    );
  };

  getDefaultFacadeConfig = (username: string): UserServiceConfig => {
    return {
      Id: username,
      AllowedSendFromAddress: {
        Description: '',
        MailboxAddress: '',
      },
      AllowedSendToAddresses: [
        {
          Description: '',
          MailboxAddress: '',
        },
        {
          Description: '',
          MailboxAddress: '',
        },
        {
          Description: '',
          MailboxAddress: '',
        },
      ],
    };
  };

  stripFacadeConfigDisplayData = (facadeConfig: UserServiceConfig) => {
    const { AllowedSendToAddresses } = facadeConfig;
    const addresses: ConfigMailbox[] = AllowedSendToAddresses.filter(
      mailbox => mailbox.MailboxAddress.trim() !== '' || mailbox.Description.trim() !== ''
    );
    return {
      ...facadeConfig,
      AllowedSendToAddresses: addresses,
    };
  };

  handleFacadeMessagesLicenceAddition = async (formValues: UserFormValues) => {
    const { username } = formValues;
    this.setState(prevState => {
      if (prevState.userMessageFacadeConfig != null && prevState.userMessageFacadeConfig.Id === username) {
        return prevState;
      }

      return {
        ...prevState,
        userMessageFacadeConfig: this.getDefaultFacadeConfig(username),
      };
    });
  };

  shouldReloadOrganisationDetails = (values: UserFormValues) => {
    const { selectedUserType } = this.state;
    const { NATS_HELP_DESK } = userTypes;

    const isHelpDeskUser = values.userType === NATS_HELP_DESK;
    const wasHelpDeskUser = selectedUserType === NATS_HELP_DESK;

    return (isHelpDeskUser && !wasHelpDeskUser) || (!isHelpDeskUser && wasHelpDeskUser);
  };

  handleFormChange = (values: UserFormValues) => {
    const { mode } = this.props;
    const { VIEW, EDIT } = FORM_MODE;
    const { messagesLicence, organisationId, messagesFacadeLicence } = values;
    const { selectedOrg, restoreValues } = this.state;
    const orgChanged = mode !== EDIT && selectedOrg !== values.organisationId;
    const shouldReloadOrgDetails = this.shouldReloadOrganisationDetails(values);
    const messagesLicenceChanged = restoreValues
      ? restoreValues.applications.includes(MESSAGES) !== messagesLicence
      : false;
    const facadeMessagesLicenceChanged = restoreValues
      ? restoreValues.applications.includes(MESSAGES_FACADE) !== messagesFacadeLicence
      : false;

    if (mode !== VIEW) {
      if (selectedOrg && (orgChanged || shouldReloadOrgDetails)) {
        this.updateOrganisationsList(values);
      } else if (!selectedOrg && organisationId && shouldReloadOrgDetails) {
        this.updateOrganisationsList(values);
      } else if (messagesLicenceChanged) {
        if (!messagesLicence && mode === EDIT) {
          this.handleMessagesLicenceRemoval(values);

          const profileId =
            restoreValues && restoreValues.userDetails.profile ? restoreValues.userDetails.profile.id : undefined;

          // Set licence & profile to prevent their controls being removed from DOM while modal is open
          this.updateRestoreValues({ ...values, messagesLicence: true, profile: profileId });
        } else {
          this.updateRestoreValues(values);
        }

        this.setState({ formReady: false });
      } else if (facadeMessagesLicenceChanged) {
        if (!messagesFacadeLicence && mode === EDIT) {
          this.handleFacadeMessagesLicenceRemoval(values);

          const profileId =
            restoreValues && restoreValues.userDetails.profile ? restoreValues.userDetails.profile.id : undefined;

          this.updateRestoreValues({ ...values, messagesFacadeLicence: true, profile: profileId });
        } else {
          this.handleFacadeMessagesLicenceAddition(values);
          this.updateRestoreValues(values);
        }
      }
      this.setState((prevState: State) => {
        const prevUserFacadeConfig = prevState.userMessageFacadeConfig || this.getDefaultFacadeConfig('');
        return {
          userMessageFacadeConfig: {
            ...prevUserFacadeConfig,
            AllowedSendToAddresses: prevUserFacadeConfig.AllowedSendToAddresses.map((mailbox, index) => {
              if (index === 0) {
                return {
                  MailboxAddress: values.mailboxToAddress0,
                  Description: values.mailboxToDescription0,
                };
              }
              if (index === 1) {
                return {
                  MailboxAddress: values.mailboxToAddress1,
                  Description: values.mailboxToDescription1,
                };
              }
              if (index === 2) {
                return {
                  MailboxAddress: values.mailboxToAddress2,
                  Description: values.mailboxToDescription2,
                };
              }
              return mailbox;
            }),
          },
        };
      });
    }
  };

  createForm = () => {
    this.setState((prevState: State, props: Props) => {
      let fields = [];

      switch (this.props.mode) {
        case FORM_MODE.CREATE: {
          fields = this.getCreateFields(prevState, props);
          break;
        }
        case FORM_MODE.EDIT: {
          fields = this.getEditFields(prevState, props);
          break;
        }
        case FORM_MODE.VIEW: {
          fields = this.getViewFields(prevState, props);
          break;
        }
        default:
          fields = [];
          break;
      }
      return {
        fields,
        formReady: true,
      };
    });
  };

  updateRestoreValues = (formValues: UserFormValues) => {
    const {
      adminLicence,
      laraLicence,
      localAvoidsLicence,
      messagesLicence,
      transitRoutesLicence,
      visualLicence,
      wingboardLicence,
      messagesFacadeLicence,
      profile,
      email,
      organisationId,
      userType,
      username,
      userId,
    } = formValues;

    // Save form values to restore when a new form is rendered
    const apps = [];
    if (adminLicence) {
      apps.push(ADMIN);
    }
    if (laraLicence) {
      apps.push(LARA);
    }
    if (localAvoidsLicence) {
      apps.push(LOCAL_AVOIDS);
    }
    if (messagesLicence) {
      apps.push(MESSAGES);
    }
    if (messagesFacadeLicence) {
      apps.push(MESSAGES_FACADE);
    }
    if (transitRoutesLicence) {
      apps.push(TRANSIT_ROUTES);
    }
    if (visualLicence) {
      apps.push(VISUAL);
    }
    if (wingboardLicence) {
      apps.push(WINGBOARD);
    }

    const selectedProfile = messagesLicence ? { id: profile } : undefined;

    this.setState(prevState => {
      const { userOrganisationMailboxes, userMessageFacadeConfig } = prevState;

      const restoreValues = {
        id: userId,
        username,
        email,
        organisation: organisationId,
        type: userType,
        applications: apps,
        userDetails: { profile: selectedProfile, mailboxes: userOrganisationMailboxes },
      };

      let nextUserFacadeConfig = userMessageFacadeConfig;
      if (messagesFacadeLicence) {
        if (nextUserFacadeConfig != null) {
          nextUserFacadeConfig.Id = username;
        } else {
          nextUserFacadeConfig = this.getDefaultFacadeConfig(username);
        }
      } else {
        nextUserFacadeConfig = undefined;
      }

      return {
        ...prevState,
        userMessageFacadeConfig: nextUserFacadeConfig,
        restoreValues,
        selectedOrg: organisationId,
        selectedUserType: userType,
      };
    });
  };

  updateOrganisationsList = (formValues: UserFormValues) => {
    const { mode } = this.props;
    let { organisationId } = formValues;
    const { NATS_HELP_DESK } = userTypes;

    // In edit mode the org value provided is the NAME rather than the ID so we need to switch to the actual ID
    if (mode === FORM_MODE.EDIT) {
      const orgList = this.getOrgList();
      const selectedOrg = orgList.find(org => org.name === organisationId) || BLANK_ORG;
      organisationId = selectedOrg.id;
    }

    this.updateRestoreValues(formValues);

    // We save the form height so we can keep the loading form the same size (prevents nasty flicker)
    const formWrapper = document.getElementById('formWrapper');
    const formHeight = formWrapper ? formWrapper.clientHeight : undefined;

    const selectedOrg = formValues.userType === NATS_HELP_DESK ? undefined : organisationId;

    this.setState(
      {
        // Reset the form and profiles so a new API call is made
        formReady: false,
        organisationDetailsLoaded: false,
        formHeight,
        selectedUserType: formValues.userType,
        selectedOrg,
        userOrganisationMailboxes: [],
      },
      this.updateOrganisationDetails(selectedOrg)
    );
  };

  updateOrganisationDetails = (orgId: ?string) => {
    const { loadOrganisationDetails } = this.props;
    loadOrganisationDetails(orgId);
  };

  // if we are creating a new user and the user can create users for other organisations, load the full list.
  getOrgList = (): Array<any> => {
    const { mode, organisations } = this.props;
    const { CREATE } = FORM_MODE;

    const ownOrganisation = getOwnOrganisation();

    // Helpdesk users do not have an organisation
    if ((mode === CREATE && canCreateUserOtherOrg()) || !ownOrganisation) {
      return organisations.organisations;
    }

    // no need to load; the user's own organisation is the only valid choice
    return [ownOrganisation];
  };

  getToMailboxesToFields = (prevState: State) => {
    const { userMessageFacadeConfig } = prevState;

    const displayConfig =
      userMessageFacadeConfig != null && Object.keys(userMessageFacadeConfig).length > 0
        ? userMessageFacadeConfig
        : this.getDefaultFacadeConfig('');

    return [
      {
        type: 'text',
        name: 'mailboxToAddress0',
        label: 'Mailbox Address',
        initialValue: displayConfig.AllowedSendToAddresses[0].MailboxAddress,
        cols: 6,
        isHidden: {
          when: 'messagesFacadeLicence',
          is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
        },
      },
      {
        type: 'text',
        name: 'mailboxToDescription0',
        label: 'Mailbox Name',
        initialValue: displayConfig.AllowedSendToAddresses[0].Description,
        cols: 6,
        isHidden: {
          when: 'messagesFacadeLicence',
          is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
        },
      },
      {
        type: 'text',
        name: 'mailboxToAddress1',
        label: 'Mailbox Address',
        initialValue: displayConfig.AllowedSendToAddresses[1].MailboxAddress,
        cols: 6,
        isHidden: {
          when: 'messagesFacadeLicence',
          is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
        },
      },
      {
        type: 'text',
        name: 'mailboxToDescription1',
        label: 'Mailbox Name',
        initialValue: displayConfig.AllowedSendToAddresses[1].Description,
        cols: 6,
        isHidden: {
          when: 'messagesFacadeLicence',
          is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
        },
      },
      {
        type: 'text',
        name: 'mailboxToAddress2',
        label: 'Mailbox Address',
        initialValue: displayConfig.AllowedSendToAddresses[2].MailboxAddress,
        cols: 6,
        isHidden: {
          when: 'messagesFacadeLicence',
          is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
        },
      },
      {
        type: 'text',
        name: 'mailboxToDescription2',
        label: 'Mailbox Name',
        initialValue: displayConfig.AllowedSendToAddresses[2].Description,
        cols: 6,
        isHidden: {
          when: 'messagesFacadeLicence',
          is: (v: mixed): boolean => !(v && canViewMessageFacadeUser()),
        },
      },
    ];
  };
  getLicenceFields = (prevState: State, props: Props) => {
    const { organisationDetails } = props;

    const organisationDetailsNotFound = organisationDetails.profiles.length === 0;
    const sharedCheckboxAttributes = {
      type: 'checkbox',
      isLabelInline: true,
      initialValue: false,
      cols: 5,
      shouldWrapTooltip: true,
    };

    return [
      {
        type: 'text',
        name: 'licenceLabel',
        shouldStartNewRow: true,
        label: 'Licences',
        isLabelOnly: true,
        cols: 12,
        shouldWrapTooltip: true,
      },
      {
        name: 'adminLicence',
        checkboxText: 'Admin',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => Array.isArray(value) && value.indexOf(ADMIN) !== -1,
        },
        ...sharedCheckboxAttributes,
      },
      {
        name: 'laraLicence',
        checkboxText: 'LARA',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => Array.isArray(value) && value.indexOf(LARA) !== -1,
        },
        ...sharedCheckboxAttributes,
      },
      {
        name: 'localAvoidsLicence',
        checkboxText: 'Local Avoids',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => Array.isArray(value) && value.indexOf(LOCAL_AVOIDS) !== -1,
        },
        ...sharedCheckboxAttributes,
      },
      {
        name: 'messagesLicence',
        checkboxText: 'Messages',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => {
            if (organisationDetails.error || organisationDetailsNotFound) {
              // We cannot assign a messages license if there are no message profiles
              return false;
            }
            return Array.isArray(value) && value.indexOf(MESSAGES) !== -1;
          },
        },
        isDisabled: !!organisationDetails.error || organisationDetailsNotFound,
        ...sharedCheckboxAttributes,
      },
      {
        name: 'transitRoutesLicence',
        checkboxText: 'Transit Routes',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => Array.isArray(value) && value.indexOf(TRANSIT_ROUTES) !== -1,
        },
        ...sharedCheckboxAttributes,
      },
      {
        name: 'visualLicence',
        checkboxText: 'Visual',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => Array.isArray(value) && value.indexOf(VISUAL) !== -1,
        },
        ...sharedCheckboxAttributes,
      },
      {
        name: 'wingboardLicence',
        checkboxText: 'Wingboard',
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => Array.isArray(value) && value.indexOf(WINGBOARD) !== -1,
        },
        ...sharedCheckboxAttributes,
      },
      {
        name: 'messagesFacadeLicence',
        checkboxText: 'Messages Facade',
        isDisabled: !!organisationDetails.error || organisationDetailsNotFound || !hasFacadePermissions(),
        initialValueFrom: {
          path: 'applications',
          withTransformation: (value: string) => {
            if (organisationDetails.error || organisationDetailsNotFound) {
              // We cannot assign a messages facade license if there are no message profiles
              return false;
            }
            return Array.isArray(value) && value.indexOf(MESSAGES_FACADE) !== -1;
          },
        },
        ...sharedCheckboxAttributes,
        cols: 6,
      },
    ];
  };

  // Loads the list of mailboxes that match the value entered in the field
  getMailboxSuggestions = async (query: ?string): Promise<SuggestionsResponse> => {
    const {
      mailboxState: { mailboxes },
    } = this.props;

    const results = mailboxes
      .filter(mailbox => (query ? mailbox.address.startsWith(query.toUpperCase()) : false))
      .map(mailbox => {
        return {
          value: mailbox.address,
          label: mailbox.address,
        };
      });

    return {
      nextPage: null,
      results,
    };
  };

  getDefaultMailboxAddress = () => {
    const { userDetails } = this.props;

    let address;
    if (userDetails && userDetails.userDetails && userDetails.userDetails.mailboxes) {
      const defaultMailbox = userDetails.userDetails.mailboxes.find(mailbox => mailbox.isDefault);
      address = defaultMailbox ? defaultMailbox.address : undefined;
    }
    return address;
  };

  getCreateFields = (prevState: State, props: Props): FieldDefinitions => {
    const { organisationDetails, mode } = props;
    const { restoreValues, lastSelectedOrganisationId } = prevState;
    const { NATS_HELP_DESK } = userTypes;

    const isMailboxesTableHidden = this.isUserMailboxesHidden(restoreValues);
    const isFacadeTableHidden = this.isUserFacadeMailboxesHidden(restoreValues);

    const hideHelpdeskDefaultMailbox = this.isHelpdeskUserDefaultMailboxHidden(restoreValues);

    const organisations = this.getOrgList();
    const profileOptions = organisationDetails.profiles || [];

    let organisationInitialValue;

    if (!lastSelectedOrganisationId && (!restoreValues || (restoreValues && restoreValues.type !== NATS_HELP_DESK))) {
      organisationInitialValue = organisations[DEFAULT_ORG].id;
    }

    return [
      usernameField,
      emailField,
      {
        ...organisationField,
        options: organisations.map((org): Option => ({
          value: org.id,
          text: org.name,
        })),
        initialValue: organisationInitialValue,
        isDisabled: !canCreateUserOtherOrg(),
        isHidden: {
          when: 'userType',
          is: (value: mixed): boolean => value === NATS_HELP_DESK, // Helpdesk users should not have an organisation
        },
      },
      {
        ...userTypeField,
        options: getUserTypeCreationOptions(),
        setValuesOnChange: [
          {
            field: 'organisationId',
            value: (userTypeValue: mixed, orgIdValue: mixed) => {
              // This will make sure the last organisationId value is kept when it is changed to undefined,
              // so that if the user change the type from helpdesk to something else, the last selected organisation is used.
              if (userTypeValue === NATS_HELP_DESK) {
                this.setState({
                  lastSelectedOrganisationId: orgIdValue,
                });
                return undefined;
              }
              if (typeof orgIdValue === 'undefined') {
                return lastSelectedOrganisationId;
              }
              return orgIdValue;
            },
          },
        ],
      },
      ...this.getLicenceFields(prevState, props),
      messagesLabel,
      { ...userOrganisationMailboxLabel, isHidden: isMailboxesTableHidden },
      {
        ...userOrganisationMailboxPortal,
        isHidden: isMailboxesTableHidden,
        portalId: `${MAILBOX_PORTAL_ID_PREFIX}_${mode}`,
      },
      {
        ...helpdeskUserDefaultMailbox,
        getSuggestions: this.getMailboxSuggestions,
        isHidden: hideHelpdeskDefaultMailbox,
      },
      {
        ...messagesProfile,
        cols: 9,
        options: profileOptions.map(profile => ({
          value: profile.id,
          text: profile.name,
        })),
        initialValue: profileOptions.length ? profileOptions[DEFAULT_PROFILE].id : '',
      },
      messagesFacadeLabel,
      facadeFromMailboxLabel,
      {
        ...userFacadeFromPortal,
        isHidden: isFacadeTableHidden,
        portalId: `${FACADE_FROM_MAILBOX_PORTAL_ID_PREFIX}_${mode}`,
      },
      facadeToMailboxLabel,
      ...this.getToMailboxesToFields(prevState),
    ];
  };

  getEditFields = (prevState: State, props: Props): FieldDefinitions => {
    const { selectedRecord, organisationDetails, userDetails, mode } = props;
    const { restoreValues } = prevState;

    const isMailboxesTableHidden = this.isUserMailboxesHidden(restoreValues || selectedRecord);

    const isFacadeTableHidden = this.isUserFacadeMailboxesHidden(restoreValues || selectedRecord);

    const hideHelpdeskDefaultMailbox = this.isHelpdeskUserDefaultMailboxHidden(restoreValues || selectedRecord);

    const messageProfileOptions =
      !userDetails.error && !organisationDetails.error
        ? organisationDetails.profiles.map(profile => ({
            value: profile.id,
            text: profile.name,
          }))
        : [];

    const [
      licenceLabel,
      adminLicenceField,
      laraLicence,
      localAvoidsLicence,
      messagesLicenceField,
      messagesFacadeLicenceField,
      transitRoutesLicence,
      visualLicenceField,
      wingboardLicence,
    ] = this.getLicenceFields(prevState, props);

    return [
      {
        ...userIdField,
        isDisabled: true,
      },
      {
        ...usernameField,
        isDisabled: true,
      },
      {
        ...emailField,
        isDisabled: true,
      },
      {
        ...organisationField,
        options: [
          {
            value: selectedRecord && selectedRecord.organisationId ? selectedRecord.organisationId : '',
            text: selectedRecord && selectedRecord.organisation ? selectedRecord.organisation : '',
          },
        ],
        isDisabled: true,
        isHidden: {
          when: 'userType',
          is: (value: mixed) => value === userTypes.NATS_HELP_DESK,
        },
      },
      {
        ...userTypeField,
        options: selectedRecord ? getUserTypeEditOptions(selectedRecord) : [],
        isDisabled: !!selectedRecord && selectedRecord.type === userTypes.NATS_HELP_DESK,
      },
      licenceLabel,
      adminLicenceField,
      laraLicence,
      localAvoidsLicence,
      {
        ...messagesLicenceField,
        setValuesOnChange: [
          {
            field: 'profile',
            value: (messageLicenceValue: mixed) => {
              if (messageLicenceValue) {
                return selectedRecord && selectedRecord.userDetails.profile
                  ? selectedRecord.userDetails.profile.id
                  : organisationDetails.profiles[DEFAULT_PROFILE].id;
              }

              return undefined;
            },
          },
        ],
      },
      messagesFacadeLicenceField,
      transitRoutesLicence,
      visualLicenceField,
      wingboardLicence,
      messagesLabel,
      { ...userOrganisationMailboxLabel, isHidden: isMailboxesTableHidden },
      {
        ...userOrganisationMailboxPortal,
        isHidden: isMailboxesTableHidden,
        portalId: `${MAILBOX_PORTAL_ID_PREFIX}_${mode}`,
      },
      {
        ...helpdeskUserDefaultMailbox,
        getSuggestions: this.getMailboxSuggestions,
        isHidden: hideHelpdeskDefaultMailbox,
      },
      {
        ...messagesProfile,
        cols: 9,
        options: messageProfileOptions,
        isDisabled: {
          when: 'userDetails.profile.id',
          is: (val: mixed) => !val && (!!userDetails.error || !!organisationDetails.error),
        },
      },
      messagesFacadeLabel,
      facadeFromMailboxLabel,
      {
        ...userFacadeFromPortal,
        isHidden: isFacadeTableHidden,
        portalId: `${FACADE_FROM_MAILBOX_PORTAL_ID_PREFIX}_${mode}`,
      },
      facadeToMailboxLabel,
      ...this.getToMailboxesToFields(prevState),
    ];
  };

  getViewFields = (prevState: State, props: Props): FieldDefinitions => {
    const { selectedRecord, userDetails, mode } = props;

    const isMailboxesTableHidden = this.isUserMailboxesHidden(selectedRecord);

    const isFacadeTableHidden = this.isUserFacadeMailboxesHidden(selectedRecord);

    const hideHelpdeskDefaultMailbox = this.isHelpdeskUserDefaultMailboxHidden(selectedRecord);

    return [
      usernameField,
      emailField,
      {
        ...organisationField,
        options: [
          {
            value: selectedRecord && selectedRecord.organisationId ? selectedRecord.organisationId : '',
            text: selectedRecord && selectedRecord.organisation ? selectedRecord.organisation : '',
          },
        ],
        isHidden: {
          when: 'userType',
          is: (value: mixed) => value === userTypes.NATS_HELP_DESK, // Helpdesk users should not have an organisation
        },
      },
      {
        ...userTypeField,
        options: [
          {
            value: selectedRecord && selectedRecord.type ? selectedRecord.type : '',
            text: selectedRecord && selectedRecord.type ? userTypeDisplayMapping[selectedRecord.type] : '',
          },
        ],
      },
      ...this.getLicenceFields(prevState, props),
      messagesLabel,
      { ...userOrganisationMailboxLabel, isHidden: isMailboxesTableHidden },
      {
        ...userOrganisationMailboxPortal,
        isHidden: isMailboxesTableHidden,
        portalId: `${MAILBOX_PORTAL_ID_PREFIX}_${mode}`,
      },
      {
        ...helpdeskUserDefaultMailbox,
        getSuggestions: this.getMailboxSuggestions,
        isHidden: hideHelpdeskDefaultMailbox,
      },
      {
        ...messagesProfile,
        cols: 8,
        options: [
          {
            value: userDetails.userDetails && userDetails.userDetails.profile ? userDetails.userDetails.profile.id : '',
            text:
              userDetails.userDetails && userDetails.userDetails.profile ? userDetails.userDetails.profile.name : '',
          },
        ],
      },
      messagesFacadeLabel,
      facadeFromMailboxLabel,
      {
        ...userFacadeFromPortal,
        isHidden: isFacadeTableHidden,
        portalId: `${FACADE_FROM_MAILBOX_PORTAL_ID_PREFIX}_${mode}`,
      },
      facadeToMailboxLabel,
      ...this.getToMailboxesToFields(prevState),
    ];
  };

  setSubmissionError: Function = error => {
    this.setState({
      submissionError:
        error.response && error.response.data
          ? getErrorMessage(this.errorMap, error.response.data.error)
          : this.errorMap.GENERIC_ERROR,
    });
  };

  setSubmissionValidationError: Function = error => {
    this.setState({
      submissionError: error.message,
    });
  };

  validateFacadeConfig: Function = (values: UserFormValues, userMessageFacadeConfig: UserServiceConfig) => {
    const { messagesFacadeLicence } = values;
    if (messagesFacadeLicence) {
      try {
        AllowedSendFromAddressSchema.validateSync(userMessageFacadeConfig.AllowedSendFromAddress);
      } catch (error) {
        const errorMessage = getErrorMessage(this.errorMap, 'NO_FACADE_FROM_MAILBOX_SELECTED_ERROR');
        throw new Error(`${errorMessage}: ${error.message}`);
      }

      try {
        AllowedSendToAddressesSchema.validateSync(userMessageFacadeConfig.AllowedSendToAddresses);
      } catch (error) {
        const errorMessage = getErrorMessage(this.errorMap, 'INVALID_FACADE_TO_MAILBOXES_SET_ERROR');
        throw new Error(`${errorMessage}: ${error.message}`);
      }
    }
  };

  hidePanel = () => {
    const { setSelectedRecord, selectPanel } = this.props;

    setSelectedRecord(null);
    selectPanel(null, {});
  };

  onSubmit: Function = async (values: UserFormValues) => {
    const { mode, toggleModalVisible, loadUsers, loadMessageFacadeUsers, createUser, editUser } = this.props;
    const { CREATE, VIEW, EDIT } = FORM_MODE;
    const { userOrganisationMailboxes, userMessageFacadeConfig, setFormikSubmitting } = this.state;

    let mailboxes;
    if (values.userType === userTypes.NATS_HELP_DESK) {
      mailboxes = [
        {
          address: values.defaultMailbox,
          isDefault: true,
          isMonitored: true,
          description: '',
          organisationId: '',
          isAssigned: true,
        },
      ];
    } else {
      mailboxes = userOrganisationMailboxes.filter(m => m.isAssigned);
    }

    // This check makes ure we keep the modal open if a USER_CACHE_MISMATCH occurs and we lose
    // permission to this form, so we can display the error and not just close the form.
    if (!this.state.keepModalOpenToDisplayApiError) {
      this.setState({ keepModalOpenToDisplayApiError: true });
    }
    this.setState({ submissionError: null });

    let configRecordToSubmit;
    try {
      switch (mode) {
        case VIEW:
          break;
        case CREATE:
        case EDIT:
          if (values.messagesFacadeLicence) {
            const unknownConfig = this.stripFacadeConfigDisplayData(
              userMessageFacadeConfig || this.getDefaultFacadeConfig(values.username)
            );
            this.validateFacadeConfig(values, unknownConfig);
            configRecordToSubmit = {
              ...unknownConfig,
              Id: values.username,
            };
          } else {
            configRecordToSubmit = undefined;
          }
          break;
        default:
          throw new Error(`Unknown form mode '${mode}`);
      }
    } catch (error) {
      this.setSubmissionValidationError(error);
      if (setFormikSubmitting) setFormikSubmitting(false);
      return;
    }

    const fullUserValues = { ...values, mailboxes, userConfigRecord: configRecordToSubmit };

    try {
      switch (mode) {
        case VIEW:
          break;
        case CREATE:
          await createUser(fullUserValues);
          break;
        case EDIT:
          await editUser(fullUserValues);
          this.hidePanel();
          break;
        default:
          throw new Error(`Unknown form mode '${mode}`);
      }

      toggleModalVisible();
      loadUsers();

      if (canViewMessageFacadeUser()) {
        loadMessageFacadeUsers();
      } else {
        this.handleFacadeConfigLoaded();
      }

      const action = mode === CREATE ? 'created' : 'edited';
      Notification.success(`User successfully ${action}`);
    } catch (error) {
      this.setSubmissionError(error);
    } finally {
      if (setFormikSubmitting) setFormikSubmitting(false);
    }
  };

  getSubmitText = () => {
    switch (this.props.mode) {
      case FORM_MODE.VIEW:
        return null;
      case FORM_MODE.CREATE:
        return 'Create user';
      case FORM_MODE.EDIT:
        return 'Save';
      default:
        throw new Error(`Unknown form mode '${this.props.mode}'`);
    }
  };

  getFormName = () => {
    switch (this.props.mode) {
      case FORM_MODE.VIEW:
        return null;
      case FORM_MODE.CREATE:
        return 'createUser';
      case FORM_MODE.EDIT:
        return 'editUser';
      default:
        throw new Error(`Unknown form mode '${this.props.mode}'`);
    }
  };

  isUserMailboxesHidden = (selectedRecord: ?RawUserRecord | ?RestoreValues): boolean => {
    const { applications, type } = !selectedRecord ? {} : selectedRecord;
    return !(applications && applications.includes(MESSAGES)) || !(type && type !== userTypes.NATS_HELP_DESK);
  };

  isUserFacadeMailboxesHidden = (selectedRecord: ?RawUserRecord | ?RestoreValues): boolean => {
    const { applications } = !selectedRecord ? {} : selectedRecord;
    return !(applications && applications.includes(MESSAGES_FACADE) && canViewMessageFacadeUser());
  };

  isHelpdeskUserDefaultMailboxHidden = (selectedRecord: ?RawUserRecord | ?RestoreValues): boolean => {
    const { applications, type } = !selectedRecord ? {} : selectedRecord;

    return !(applications && applications.includes(MESSAGES)) || !(type && type === userTypes.NATS_HELP_DESK);
  };

  getUserOrganisationMailboxes = (
    userMailboxes: Array<UserMailbox>,
    organisationMailboxes: Array<OrganisationMailbox>
  ): Array<UserOrganisationMailbox> => {
    const getAssignedMailbox = (mailbox: OrganisationMailbox) => userMailboxes.find(m => m.address === mailbox.address);

    return organisationMailboxes.map(m => {
      const assignedMailbox = getAssignedMailbox(m);

      return {
        ...m,
        isDefault: assignedMailbox ? assignedMailbox.isDefault : false,
        isAssigned: assignedMailbox != null,
        isMonitored: assignedMailbox ? assignedMailbox.isMonitored : false,
      };
    });
  };

  setUserFacadeConfig = () => {
    this.setState((prevState, props) => {
      const { selectedRecord, messageFacadeUsersState, mode } = props;

      const userFacadeConfig = messageFacadeUsersState.messageFacadeUsers.find(x => x.Id === selectedRecord?.username);

      const emptyConfig = this.getDefaultFacadeConfig('');
      const mergedConfig = merge(emptyConfig, userFacadeConfig);
      switch (mode) {
        case FORM_MODE.VIEW:
          if (userFacadeConfig) {
            return {
              userMessageFacadeConfig: mergedConfig,
            };
          }
          return prevState;
        case FORM_MODE.CREATE:
          return {
            userMessageFacadeConfig: emptyConfig,
          };
        case FORM_MODE.EDIT:
          if (userFacadeConfig) {
            return {
              userMessageFacadeConfig: mergedConfig,
            };
          }
          return {
            userMessageFacadeConfig: emptyConfig,
          };
        default:
          throw new Error(`Unknown form mode '${this.props.mode}'`);
      }
    });
  };

  setUserOrganisationMailboxes = () => {
    const { selectedRecord, organisationDetails, mode } = this.props;
    const { userOrganisationMailboxes } = this.state;
    const { CREATE } = FORM_MODE;
    if (!userOrganisationMailboxes.length) {
      const userMailboxes = mode !== CREATE && selectedRecord ? selectedRecord.userDetails.mailboxes : [];
      const organisationMailboxes = organisationDetails ? organisationDetails.mailboxes : [];

      const userOrganisationMailboxRows = this.getUserOrganisationMailboxes(userMailboxes, organisationMailboxes);

      this.setState({ userOrganisationMailboxes: userOrganisationMailboxRows });
    }
  };

  shouldRenderUserFacadeMailboxesTable = () => {
    const { mode, selectedRecord } = this.props;
    const { restoreValues } = this.state;
    const { VIEW } = FORM_MODE;

    if (mode !== VIEW) {
      if (restoreValues) {
        return !this.isUserFacadeMailboxesHidden(restoreValues);
      }
    }

    return !this.isUserFacadeMailboxesHidden(selectedRecord);
  };

  shouldRenderUserMailboxesTable = () => {
    const { mode, selectedRecord } = this.props;
    const { restoreValues } = this.state;
    const { VIEW } = FORM_MODE;

    if (mode !== VIEW) {
      if (restoreValues) {
        return !this.isUserMailboxesHidden(restoreValues);
      }
    }

    return !this.isUserMailboxesHidden(selectedRecord);
  };

  onUserOrganisationMailboxTableIsAssignedChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    const {
      userDetails: { userDetails },
    } = this.props;
    const { userOrganisationMailboxes } = this.state;

    const changedRowIndex = userOrganisationMailboxes.findIndex(m => m.address === id);
    const currentDefaultMailboxIndex = userOrganisationMailboxes.findIndex(m => m.isDefault);

    if (checked) {
      // Mailbox is assigned
      const assignedCount = userOrganisationMailboxes.filter(m => m.isAssigned).length;

      const currentMailboxSetting = userDetails ? userDetails.mailboxes.find(m => m.address === id) : undefined;

      userOrganisationMailboxes[changedRowIndex].isAssigned = true;

      if (currentMailboxSetting) {
        // Restore user's current mailbox config
        userOrganisationMailboxes[changedRowIndex].isDefault = currentMailboxSetting.isDefault;
        userOrganisationMailboxes[changedRowIndex].isMonitored = currentMailboxSetting.isMonitored;

        if (currentDefaultMailboxIndex !== -1 && currentMailboxSetting.isDefault) {
          // Set current default as false if restored value was the previous default
          userOrganisationMailboxes[currentDefaultMailboxIndex].isDefault = false;
        }
      }

      if (!assignedCount) {
        // If assigned mailbox is first for user set as default & monitored
        userOrganisationMailboxes[changedRowIndex].isDefault = true;
        userOrganisationMailboxes[changedRowIndex].isMonitored = true;
      }
    } else {
      // Mailbox is unassigned

      // Unassign mailbox
      userOrganisationMailboxes[changedRowIndex].isAssigned = false;
      userOrganisationMailboxes[changedRowIndex].isMonitored = false;

      // If unassigned mailbox was the default
      if (userOrganisationMailboxes[changedRowIndex].isDefault) {
        const newDefaultMailboxIndex = userOrganisationMailboxes.findIndex(m => m.isAssigned);

        // If other assigned mailboxes exist
        if (newDefaultMailboxIndex !== -1) {
          // Set first assigned mailbox in list as default & monitored
          userOrganisationMailboxes[newDefaultMailboxIndex].isDefault = true;
          userOrganisationMailboxes[newDefaultMailboxIndex].isMonitored = true;
        }

        userOrganisationMailboxes[changedRowIndex].isDefault = false;
      }
    }

    this.setState({ userOrganisationMailboxes });
  };

  onUserOrganisationMailboxTableIsDefaultChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    const { userOrganisationMailboxes } = this.state;

    const changedRowIndex = userOrganisationMailboxes.findIndex(m => m.address === id);
    const currentDefaultMailboxIndex = userOrganisationMailboxes.findIndex(m => m.isDefault);

    // Unset previous default mailbox
    userOrganisationMailboxes[currentDefaultMailboxIndex].isDefault = !checked;

    // Set new default mailbox
    userOrganisationMailboxes[changedRowIndex].isDefault = checked;
    userOrganisationMailboxes[changedRowIndex].isMonitored = checked;

    this.setState({ userOrganisationMailboxes });
  };

  onUserOrganisationMailboxTableIsMonitoredChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    const { userOrganisationMailboxes } = this.state;

    const changedRowIndex = userOrganisationMailboxes.findIndex(m => m.address === id);

    userOrganisationMailboxes[changedRowIndex].isMonitored = checked;

    this.setState({ userOrganisationMailboxes });
  };

  onToMailboxDescriptionChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    this.setState((prevState: State) => {
      const prevUserMessageFacadeConfig = prevState.userMessageFacadeConfig;

      const targetMailbox = prevUserMessageFacadeConfig.AllowedSendToAddresses.find(m => m.MailboxAddress === id);

      if (targetMailbox) {
        return {
          ...prevState,
          userMessageFacadeConfig: {
            ...prevUserMessageFacadeConfig,
            AllowedSendToAddresses: prevUserMessageFacadeConfig.AllowedSendToAddresses.map(address => {
              if (address.MailboxAddress === id) {
                return {
                  ...address,
                  Description: value,
                };
              }

              return address;
            }),
          },
        };
      }

      return prevState;
    });
  };

  onUserFacadeMailboxTableIsFromMailboxChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { id } = e.target;

    this.setState((prevState: State) => {
      const selectedMailbox = prevState.userOrganisationMailboxes.find(m => m.address === id);
      if (selectedMailbox != null) {
        const prevUserMessageFacadeConfig = prevState.userMessageFacadeConfig || this.getDefaultFacadeConfig('');
        return {
          userMessageFacadeConfig: {
            ...prevUserMessageFacadeConfig,
            AllowedSendFromAddress: {
              Description: selectedMailbox.description,
              MailboxAddress: selectedMailbox.address,
            },
          },
        };
      }
      return prevState;
    });
  };

  renderUserFacadeFromMailboxPortal = () => {
    const { mode } = this.props;
    const { userOrganisationMailboxes, userMessageFacadeConfig } = this.state;

    if (!this.shouldRenderUserFacadeMailboxesTable()) return false;

    return (
      <UserMessageFacadeFromMailboxTable
        portalId={`${FACADE_FROM_MAILBOX_PORTAL_ID_PREFIX}_${mode}`}
        userConfig={userMessageFacadeConfig}
        mailboxes={userOrganisationMailboxes}
        mode={mode}
        onIsFromMailboxChange={this.onUserFacadeMailboxTableIsFromMailboxChange}
      />
    );
  };

  renderUserMailboxesPortal = () => {
    const { mode } = this.props;
    const { userOrganisationMailboxes } = this.state;

    if (!this.shouldRenderUserMailboxesTable()) return false;

    return (
      <UserOrganisationMailboxTable
        portalId={`${MAILBOX_PORTAL_ID_PREFIX}_${mode}`}
        mailboxes={userOrganisationMailboxes}
        mode={mode}
        onIsAssignedChange={this.onUserOrganisationMailboxTableIsAssignedChange}
        onIsDefaultChange={this.onUserOrganisationMailboxTableIsDefaultChange}
        onIsMonitoredChange={this.onUserOrganisationMailboxTableIsMonitoredChange}
      />
    );
  };

  isFormLoading = () => {
    const { formReady } = this.state;
    const { userDetails, organisationDetails, organisations } = this.props;
    return userDetails.isBusy || organisationDetails.isBusy || organisations.isBusy || !formReady;
  };

  renderFetchError = () => {
    const { userDetails, organisationDetails, organisations } = this.props;

    const organisationDetailsNotFound = organisationDetails.profiles.length === 0;

    const isLoading = this.isFormLoading();

    if (!userDetails.error && !organisationDetails.error && !organisationDetailsNotFound && !organisations.error) {
      return null;
    }

    const userDetailsError = userDetails.error
      ? getErrorMessage(this.errorMap, userDetails.error, 'Failed to fetch user details')
      : undefined;

    const organisationDetailsError = organisationDetails.error
      ? getErrorMessage(this.errorMap, organisationDetails.error, 'Failed to fetch organisation details')
      : undefined;

    let organisationDetailsWarning;
    if (!organisationDetailsError && organisationDetailsNotFound) {
      organisationDetailsWarning =
        'The selected organisation has no message profiles assigned so you will not be able to assign this user a message licence.';
    }

    const organisationsError = organisations.error
      ? getErrorMessage(this.errorMap, organisations.error, 'Failed to load organisations')
      : undefined;

    return !isLoading
      ? [
          organisationDetailsError ? this.renderErrorBox(organisationDetailsError) : null,
          userDetailsError ? this.renderErrorBox(userDetailsError) : null,
          organisationsError ? this.renderErrorBox(organisationsError) : null,
          organisationDetailsWarning ? this.renderWarningBox(organisationDetailsWarning) : null,
        ]
      : [];
  };

  renderSubmissionError = () => {
    const { submissionError } = this.state;
    if (!submissionError || this.isFormLoading()) return null;

    return this.renderErrorBox(submissionError);
  };

  renderWarningBox = (message: string) => (
    <div
      data-qa-id="submissionWarning"
      className={`${styles.validationErrors} ${styles.alertBox} ${styles.warningAlert}`}
    >
      <ul>
        <li>{message}</li>
      </ul>
    </div>
  );

  renderErrorBox = (message: string) => (
    <div data-qa-id="submissionError" className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}>
      <ul>
        <li>{message}</li>
      </ul>
    </div>
  );

  renderLoadingSpinner = () => {
    const { formHeight } = this.state;

    const wrapperStyles = formHeight
      ? {
          height: formHeight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      : null;

    return (
      <div style={wrapperStyles}>
        {this.renderFetchError()}
        {this.renderSubmissionError()}
        <Loading block isLoading hasData />
      </div>
    );
  };

  render() {
    const { mode, selectedRecord, toggleModalVisible } = this.props;
    const { fields, keepModalOpenToDisplayApiError, restoreValues, formReady } = this.state;
    const { CREATE, EDIT, VIEW } = FORM_MODE;

    if ((mode === CREATE && !canCreateUser()) || (mode === EDIT && selectedRecord && !canEditUser(selectedRecord))) {
      if (!keepModalOpenToDisplayApiError) {
        toggleModalVisible();
        return null;
      }
    }

    if (!formReady || !fields.length) {
      return this.renderLoadingSpinner();
    }

    const formData = {
      ...(restoreValues || selectedRecord),
      defaultMailbox: this.getDefaultMailboxAddress(),
    };

    const style = mode === VIEW ? { padding: 0, overflowY: 'scroll' } : { overflowY: 'scroll' };
    return (
      <div
        key={selectedRecord ? selectedRecord.id : 'COMPOSING'}
        className={formModalStyles.modalForm}
        style={style}
        id="formWrapper"
      >
        {this.renderFetchError()}
        {this.renderSubmissionError()}
        <FormBuilder
          isComposing={mode !== VIEW}
          name={this.getFormName()}
          fields={fields}
          initialValues={formData}
          validationSchema={mode === CREATE ? createUserValidationSchemaFrontEnd : editUserValidationSchemaFrontEnd}
          submitButtonText={this.getSubmitText()}
          onSubmit={this.onSubmit}
          updateFormikInstance={formik => {
            this.setState({ setFormikSubmitting: formik.setSubmitting });
            this.handleFormChange(formik.values);
          }}
          onInit={formik => {
            if (!restoreValues) {
              this.setState({ setFormikSubmitting: formik.setSubmitting });
              this.updateRestoreValues(formik.values);
            }
          }}
        />
        {this.renderUserMailboxesPortal()}
        {this.renderUserFacadeFromMailboxPortal()}
      </div>
    );
  }
}

export const mapStateToProps = ({
  app: { selectedRecord },
  organisationState,
  userDetailsState,
  organisationDetailsState,
  mailboxState,
  messageFacadeUsersState,
}: Store) => ({
  selectedRecord: { ...selectedRecord, userDetails: userDetailsState.userDetails },
  organisations: organisationState,
  userDetails: userDetailsState,
  organisationDetails: organisationDetailsState,
  mailboxState,
  messageFacadeUsersState,
});

export const mapDispatchToProps = {
  loadUsers: loadUsersAction,
  loadOrganisations: loadOrganisationsAction,
  createUser: createUserAction,
  editUser: editUserAction,
  setModal: setModalAction,
  toggleModalVisible: toggleModalVisibleAction,
  setSelectedRecord: setSelectedRecordAction,
  selectPanel: selectPanelAction,
  loadUserDetails: loadUserDetailsAction,
  loadOrganisationDetails: loadOrganisationDetailsAction,
  resetUserFormState: resetUserFormStateAction,
  loadMailboxes: loadMailboxesAction,
  loadMessageFacadeUsers: loadMessageFacadeUsersAction,
};

export { User as PureUser };
export default connect(mapStateToProps, mapDispatchToProps)(User);
