// @flow
import React from 'react';
import { DataTable, Portal } from '@nats/webclient-common';
import styles from './Organisation.module.scss';

import type { OrganisationSerialCode } from '../../../types/OrganisationSerialCodeTypes';

const NO_DATA_TEXT = 'No serial codes are assigned to this organisation.';

type Props = {
  tableData: Array<OrganisationSerialCode>,
  isLoading: boolean,
  parentNodeId: string,
};

const OrganisationSerialCodesTable = ({ tableData, isLoading, parentNodeId }: Props) => {
  const columns: Array<{
    Header: string,
    id: string,
    accessor: OrganisationSerialCode => string,
  }> = [
    {
      Header: 'Trigraph',
      id: 'code',
      accessor: (serialCode: OrganisationSerialCode) => `${serialCode.code} ${serialCode.name}`,
      resizable: false,
    },
  ];

  const getClassNames = () => (tableData.length === 0 ? styles.organisationTableError : '');

  const renderComponent = () => (
    <DataTable
      className={getClassNames()}
      isClientSide
      disableFilteringAndPagination
      filterable={false}
      sortable={false}
      noDataText={NO_DATA_TEXT}
      {...{
        tableData,
        columns,
        isLoading,
      }}
    />
  );

  return parentNodeId ? <Portal to={parentNodeId}>{renderComponent()}</Portal> : renderComponent();
};

export default OrganisationSerialCodesTable;
