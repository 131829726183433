// @flow
import { getAuthorities as getAuthoritiesApi } from '../api/authorityApi';
import type { Authority } from '../types/Authority';

export const GET_AUTHORITIES = 'GET_AUTHORITIES';

type Authorities = {
  data: {
    _embedded: {
      authorities: Array<Authority>,
    },
  },
};

export type GetAuthoritiesActionSuccess = {
  type: typeof GET_AUTHORITIES,
  payload: Authorities,
};

export type GetAuthoritiesActionFailure = {
  type: typeof GET_AUTHORITIES,
  payload: Error,
};

export const getAuthorities = () => ({
  type: GET_AUTHORITIES,
  promise: getAuthoritiesApi(),
});

export type AuthorityAction = GetAuthoritiesActionSuccess | GetAuthoritiesActionFailure;
