// @flow

import {
  MESSAGE_PARAMETERS_AUDIT_LOAD_STARTED,
  MESSAGE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL,
  MESSAGE_PARAMETERS_AUDIT_LOAD_FAILED,
} from '../actions/actionTypes';

import type { MessageParametersAuditAction } from '../types/MessageParameterAuditTypes';
import type { MessageParametersAuditState } from '../types/state';

export const initialState: MessageParametersAuditState = {
  auditLog: '',
  isBusy: false,
  error: null,
};

export default function messageParametersAuditReducer(
  state: MessageParametersAuditState = initialState,
  action: MessageParametersAuditAction
): MessageParametersAuditState {
  switch (action.type) {
    case MESSAGE_PARAMETERS_AUDIT_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
      };
    case MESSAGE_PARAMETERS_AUDIT_LOAD_FAILED: {
      const { error } = action;
      return { ...state, isBusy: false, auditLog: '', error };
    }
    case MESSAGE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL: {
      const { auditLog } = action.payload.data._embedded;
      return { ...state, isBusy: false, error: null, auditLog };
    }
    default:
      return state;
  }
}
