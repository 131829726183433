// @flow
import get from 'lodash/get';
import { toggleModalVisible } from '~/actions/modalActions';

import environment from '../environment';
import { apiGet } from './api';
import {
  loadTransitRouteParametersAuditStarted,
  loadTransitRouteParametersAuditSuccessful,
  loadTransitRouteParametersAuditFailed,
} from '../actions/transitRouteParametersAuditActions';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { LoadTransitRouteParametersAuditResponse } from '../types/TransitRouteParameterAuditTypes';

export const TRANSIT_ROUTE_PARAMETERS_AUDIT_URL = `${environment.transitRoutesUrl}/api/admin/transit-route-parameters/audit`;

export function loadTransitRouteParametersAudit(fromDate: string, toDate: string): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.transitRoutesUrl) {
      throw new Error('Unable to retrieve Transit Route Parameters audit; config not provided');
    }

    dispatch(loadTransitRouteParametersAuditStarted());

    return apiGet(`${TRANSIT_ROUTE_PARAMETERS_AUDIT_URL}/${fromDate}/${toDate}`)
      .then((response: LoadTransitRouteParametersAuditResponse) => {
        dispatch(loadTransitRouteParametersAuditSuccessful(response));
        dispatch(toggleModalVisible());
      })
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(loadTransitRouteParametersAuditFailed(errorCode));
      });
  };
}
