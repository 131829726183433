// @flow
import {
  SOAP_ENDPOINTS_LOAD_FAILED,
  SOAP_ENDPOINTS_LOAD_STARTED,
  SOAP_ENDPOINTS_LOAD_SUCCESSFUL,
} from '../actions/actionTypes';

import type { SoapState } from '../types/state';
import type { SoapAction } from '../actions/soapActions';

export const initialState: SoapState = {
  isBusy: false,
  error: null,
  endpoints: [],
  endpointsEnabledStatus: true,
};

export default function soapReducer(state: SoapState = initialState, action: SoapAction): SoapState {
  switch (action.type) {
    case SOAP_ENDPOINTS_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case SOAP_ENDPOINTS_LOAD_SUCCESSFUL: {
      const { endpoints } = action.payload.data._embedded;
      return {
        ...state,
        isBusy: false,
        error: null,
        endpoints,
        endpointsEnabledStatus: endpoints.every(e => e.currentState === 'ACTIVE'),
      };
    }
    case SOAP_ENDPOINTS_LOAD_FAILED:
      return {
        ...state,
        endpoints: initialState.endpoints,
        isBusy: false,
        error: action.error,
      };
    default:
      return state;
  }
}
