// @flow
import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import { Notification, FilterInput, DataTable } from '@nats/webclient-common';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { loadSoapUsers } from '~/api/soapUserApi';

import type { State as Store } from '~/types/ReduxStateType';
import type { SoapUser } from '~/types/SoapUser';
import type { FormCode, FormMode } from '~/components/forms/getFormFromType';
import type { PageHeaderControls } from '~/types/state';

import { setPageTitle, setSelectedRecord } from '~/actions/appActions';
import { selectPanelCustom } from '~/actions/panelActions';
import { setPageHeaderControls } from '~/actions/headerControlActions';
import { setModalForm, toggleModalVisible, setModal } from '~/actions/modalActions';

import getErrorMessageForErrorCode from '~/utilities/errorCodeMapping';
import { FORM_CODE, FORM_MODE } from '~/components/forms/getFormFromType';
import { canEditSoapUser } from '~/utilities/permissions';
import soapUsersMapperModule from '~/utilities/csvMappers/soapUsersMapper';
import { exportToCSV } from '~/utilities/exportToCSV';
import SoapUserView from './SoapUserView';

import ConfirmSoapUserDelete from './ConfirmSoapUserDelete';

export type RawSoapUserRecord = {
  username: string,
  password: string,
  mailboxes: Array<string>,
  enabled: boolean,
  lastEndpoint: string,
  userType: string,
  lastEamsRequest: string,
  status: string,
};

type SoapUserRecord = {
  username: string,
  _original: RawSoapUserRecord,
};

type Props = {
  soapUsers: Array<SoapUser>,
  selectedRecord: ?RawSoapUserRecord,
  setPageTitle: string => mixed,
  loadSoapUsers: () => mixed,
  isLoading: boolean,
  isValidConfig: boolean,
  location: Object,
  setSelectedRecord: (RawSoapUserRecord | null) => mixed,
  selectPanelCustom: (string, React$Node, {}) => mixed,
  setModalForm: (FormCode, FormMode, {}) => void,
  toggleModalVisible: () => void,
  setPageHeaderControls: Function,
  setModal: (string, React$Node) => void,
  pageTitle: string,
};

type State = {
  permissionErrorNotificationId: string | null,
  configErrorNotificationId: string | null,
  tableColumns: Array<{
    Header: Node,
    id: string,
    accessor: SoapUser => string,
  }>,
  clearFilterInputs: Array<Function>,
  getTableData: ?Function,
};

class SoapUserScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      permissionErrorNotificationId: null,
      configErrorNotificationId: null,
      getTableData: null,
      tableColumns: [
        {
          Header: 'Username',
          id: 'username',
          accessor: (u: SoapUser) => u.username,
          Filter: (filter: Object) => this.getFilterInput(filter, 'username', this.props.location.search),
          filterMethod: (filter, row) => row.username.toUpperCase().includes(filter.value.toUpperCase()),
        },
        {
          Header: 'Enabled',
          id: 'enabled',
          accessor: (u: SoapUser) => String(get(u, 'enabled', '')),
          Filter: (filter: Object) => this.getFilterInput(filter, 'enabled', this.props.location.search),
          filterMethod: (filter, row) => row.enabled.toUpperCase().includes(filter.value.toUpperCase()),
          minWidth: 25,
        },
        {
          Header: 'Endpoint',
          id: 'endpoint',
          accessor: (u: SoapUser) => get(u, 'lastEndpoint', ''),
          Filter: (filter: Object) => this.getFilterInput(filter, 'endpoint', this.props.location.search),
          filterMethod: (filter, row) => row.endpoint.toUpperCase().includes(filter.value.toUpperCase()),
        },
        {
          Header: 'Mailboxes',
          id: 'mailbox',
          accessor: (u: SoapUser) => (u.mailboxes && u.mailboxes.join(', ')) || '',
          Filter: (filter: Object) => this.getFilterInput(filter, 'mailbox', this.props.location.search),
          sortable: false,
          filterMethod: (filter, row) => row.mailbox.toUpperCase().includes(filter.value.toUpperCase()),
          minWidth: 50,
        },
        {
          Header: 'User Type',
          id: 'type',
          accessor: (u: SoapUser) => get(u, 'userType', ''),
          Filter: (filter: Object) => this.getFilterInput(filter, 'type', this.props.location.search),
          filterMethod: (filter, row) => row.type.toUpperCase().includes(filter.value.toUpperCase()),
          minWidth: 50,
        },
        {
          Header: 'Last EAMS request',
          id: 'request',
          accessor: (u: SoapUser) =>
            u.lastEamsRequest === 'NO_LAST_USAGE'
              ? 'No EAMS requests have been made'
              : moment.utc(u.lastEamsRequest).format('YY.MM.DD HH:mm:ss'),
          sortable: false,
          filterable: false,
          minWidth: 75,
        },
        {
          Header: 'Status',
          id: 'status',
          accessor: (u: SoapUser) => u.status,
          Filter: (filter: Object) => this.getFilterInput(filter, 'status', this.props.location.search),
          filterMethod: (filter, row) => row.status.toUpperCase().startsWith(filter.value.toUpperCase()),
          minWidth: 50,
        },
      ],
      clearFilterInputs: [],
    };

    this.props.setPageHeaderControls(this.getSubheaderControls());
  }

  getFilterInput = (filter: Object, name: string, search: string) => {
    const attrs = {
      name,
      search,
      maxLength: 100,
    };

    return (
      <FilterInput
        onChange={value => filter.onChange(value)}
        setClearFilterFunction={this.setClearFilterFunctions}
        name={name}
        attrs={attrs}
      />
    );
  };

  setClearFilterFunctions = (clearFilter: Function) => {
    const { clearFilterInputs } = this.state;
    clearFilterInputs.push(clearFilter);
    this.setState({ clearFilterInputs });
  };

  clearFilters = () => {
    const { clearFilterInputs } = this.state;
    clearFilterInputs.forEach(fn => fn());
  };

  getSubheaderControls = (): PageHeaderControls => {
    const { selectedRecord, soapUsers, isLoading } = this.props;
    const { permissionErrorNotificationId } = this.state;
    const pendingDeletion = !!selectedRecord && selectedRecord.status === 'delete pending';

    const editControls = {
      name: 'Edit',
      icon: ['fab', 'wpforms'],
      onClick: this.handleClickEdit,
      isDisabled: !canEditSoapUser() || pendingDeletion,
    };

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled: isLoading || !!permissionErrorNotificationId || soapUsers.length === 0,
    };

    return selectedRecord ? [[exportControls, editControls]] : [[exportControls]];
  };

  componentDidMount() {
    this.props.setPageTitle('SOAP Users');
    this.getSoapUsers();
  }

  componentWillUnmount() {
    this.clearErrorNotificationsIfSet();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedRecord !== prevProps.selectedRecord ||
      this.props.isLoading !== prevProps.isLoading ||
      !!this.state.permissionErrorNotificationId
    ) {
      this.props.setPageHeaderControls(this.getSubheaderControls());
    }

    if (!this.state.configErrorNotificationId && prevProps.isValidConfig && !this.props.isValidConfig) {
      const notificationId = Notification.warning(
        'Please ensure exactly one SOAP user of each of the following types: ASP, NOTAM, METAR, TAF are enabled and configured.',
        { autoClose: false }
      );

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ configErrorNotificationId: notificationId });
    }
  }

  clearErrorNotificationsIfSet = () => {
    if (this.state.permissionErrorNotificationId) {
      Notification.dismiss(this.state.permissionErrorNotificationId);
      this.setState({ permissionErrorNotificationId: null });
    }

    if (this.state.configErrorNotificationId) {
      Notification.dismiss(this.state.configErrorNotificationId);
      this.setState({ configErrorNotificationId: null });
    }
  };

  getSoapUsers = async (): Promise<void> => {
    try {
      await this.props.loadSoapUsers();
    } catch (error) {
      // This is only needed here because in order to reload users you need to refresh the page
      this.clearErrorNotificationsIfSet();

      const notificationId = Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });

      this.setState({ permissionErrorNotificationId: notificationId });
    }
  };

  setDataExporterFunction = (getTableData: Function) => {
    if (!this.state.getTableData) {
      this.setState({ getTableData });
    }
  };

  selectRow = (soapUserRecord: SoapUserRecord) => {
    if (!soapUserRecord) {
      return;
    }

    const { username, _original } = soapUserRecord;

    this.props.setSelectedRecord(_original);

    const panelProps = {
      isComposing: false,
      username,
    };

    this.props.selectPanelCustom(
      username,
      <SoapUserView username={username} onDelete={this.handleSoapUserDelete} />,
      panelProps
    );
  };

  handleSoapUserDelete = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.toggleModalVisible();
    this.props.setModal(
      selectedRecord.username,
      <ConfirmSoapUserDelete
        username={selectedRecord.username}
        unselectRow={() => this.props.setSelectedRecord(null)}
      />
    );
  };

  handleClickEdit = () => {
    const { selectedRecord } = this.props;

    if (!selectedRecord) {
      return;
    }

    this.props.setModalForm(FORM_CODE.SOAP_USER, FORM_MODE.EDIT, {
      username: selectedRecord.username,
    });
    this.props.toggleModalVisible();
  };

  exportViewClickHandler = () => {
    const { pageTitle } = this.props;

    const { getTableData } = this.state;

    if (getTableData) {
      const exportData = getTableData();
      const dataToExport = soapUsersMapperModule.mapSoapUsers(exportData);
      exportToCSV(pageTitle, dataToExport);
    }
  };

  isRowSelected = (username: string): boolean =>
    !!this.props.selectedRecord && this.props.selectedRecord.username === username;

  render() {
    return (
      <div>
        <DataTable
          noDataText={this.props.soapUsers.length > 0 ? undefined : 'No SOAP users currently configured'}
          isClientSide
          sortable
          tableData={this.props.soapUsers}
          columns={this.state.tableColumns}
          isLoading={this.props.isLoading}
          defaultPageSize={40}
          defaultSorted={[{ id: 'username', desc: false }]}
          onRowSelected={this.selectRow}
          getTdClass={(state, rowInfo) => (this.isRowSelected(rowInfo.original.username) ? 'selectedRow' : '')}
          clearClientSideFilters={this.clearFilters}
          setDataExporter={this.setDataExporterFunction}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  soapUsers: state.soapUserState.soapUsers,
  delete: state.userState.delete,
  isLoading: state.soapUserState.isBusy,
  selectedRecord: state.app.selectedRecord,
  isValidConfig: state.soapUserState.isValidConfig,
  pageTitle: state.app.title,
});

export const mapDispatchToProps = {
  setPageTitle,
  loadSoapUsers,
  setSelectedRecord,
  selectPanelCustom,
  setPageHeaderControls,
  setModalForm,
  toggleModalVisible,
  setModal,
};

export { SoapUserScreen as PureSoapUserScreen };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SoapUserScreen));
