import axios from 'axios';
import deepmerge from 'deepmerge';
import { getApiOptions } from './helpers/options';

const merge = (obj1, obj2) => {
  if (!obj1) {
    throw new Error('No first object for merge.');
  }

  return obj2 ? deepmerge(obj1, obj2) : obj1;
};

const apiRequest = async options => {
  return axios(merge(options, getApiOptions()));
};

export default apiRequest;
