// @flow
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import messageParametersReducer from '~/reducers/messageParametersReducer';
import messageParametersAuditReducer from '~/reducers/messageParametersAuditReducer';
import transitRouteParametersReducer from '~/reducers/transitRouteParametersReducer';
import visualParametersReducer from '~/reducers/visualParametersReducer';
import transitRouteParametersAuditReducer from '~/reducers/transitRouteParametersAuditReducer';
import appReducer from './appReducer';
import headerControlReducer from './headerControlReducer';
import authorityReducer from './authorityReducer';
import organisationReducer from './organisationReducer';
import organisationDetailsReducer from './organisationDetailsReducer';
import userReducer from './userReducer';
import mailboxReducer from './mailboxReducer';
import userDetailsReducer from './userDetailsReducer';
import referenceDataReducer from './referenceDataReducer';
import panelReducer from './panelReducer';
import modalReducer from './modalReducer';
import soapReducer from './soapReducer';
import soapUserReducer from './soapUserReducer';
import messageFacadeReducer from './messageFacadeReducer';
import roleReducer from './roleReducer';
import featureTogglesReducer from './featureTogglesReducer';
import loginsByOrganisationReducer from './loginsByOrganisationReducer';
import serialCodesReducer from './serialCodesReducer';

export default combineReducers({
  app: appReducer,
  featureTogglesState: featureTogglesReducer,
  router: routerReducer,
  pageHeaderControls: headerControlReducer,
  authorityState: authorityReducer,
  organisationState: organisationReducer,
  organisationDetailsState: organisationDetailsReducer,
  userState: userReducer,
  mailboxState: mailboxReducer,
  userDetailsState: userDetailsReducer,
  referenceData: referenceDataReducer,
  panel: panelReducer,
  modals: modalReducer,
  soap: soapReducer,
  soapUserState: soapUserReducer,
  messageFacadeUsersState: messageFacadeReducer,
  roleState: roleReducer,
  visualParameters: visualParametersReducer,
  messageParameters: messageParametersReducer,
  messageParametersAudit: messageParametersAuditReducer,
  loginsByOrganisationState: loginsByOrganisationReducer,
  serialCodeState: serialCodesReducer,
  transitRouteParameters: transitRouteParametersReducer,
  transitRouteParametersAudit: transitRouteParametersAuditReducer,
});
