// @flow
import React from 'react';
import { connect } from 'react-redux';
import { deleteRoleConfirm } from '~/actions/roleActions';
import type { State as Store } from '~/types/ReduxStateType';
import { ConfirmModal } from '~/components/confirmModal/confirmModal';

type ErrorType = { response?: { data?: { error: string } } };

const getErrorMessage = (error: ErrorType): ?string => {
  if (!error) {
    return null;
  }

  const defaultMessage = 'There was an error deleting the message profile.';

  const errorMap = {
    GENERIC_ERROR: defaultMessage,
    SERVER_ERROR: defaultMessage,
    PERMISSION_ERROR: 'You do not have permission to delete a message profile.',
    ILLEGAL_ACTION_ERROR: 'You cannot perform this action on the selected message profile.',
    PROFILE_ASSIGNED_TO_USERS_ERROR: 'Message profile could not be deleted while users are assigned to it',
  };

  if (error.response && error.response.data && error.response.data.error) {
    if (error.response.data.error in errorMap) {
      return errorMap[error.response.data.error];
    }
    return error.response.data.error;
  }

  return defaultMessage;
};

type ConfirmMessageProfileDeleteConnectedProps = {
  isLoading: boolean,
  error: ErrorType,
  roleId: string,
  onCancel: () => void,
  deleteRoleConfirm: string => void,
};

export const ConfirmMessageProfileDelete = (props: ConfirmMessageProfileDeleteConnectedProps) => (
  <ConfirmModal
    isLoading={props.isLoading}
    confirmationMessage="Are you sure you want to delete this message profile?"
    errorMessage={getErrorMessage(props.error)}
    onCancel={() => props.onCancel()}
    onConfirm={() => {
      props.deleteRoleConfirm(props.roleId);
    }}
  />
);

const mapStateToProps = (state: Store, ownProps) => ({
  isLoading: state.roleState.delete.isLoading,
  error: state.roleState.delete.error,
  roleId: ownProps.roleId,
  onCancel: ownProps.onCancel,
});

const mapDispatchToProps = {
  deleteRoleConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMessageProfileDelete);
