// @flow
import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import styles from './exportUsersView.module.scss';
import { exportUsers } from '../../actions/exportUsers';
import { exportMailboxesByUsers } from '../../actions/exportMailboxesByUser';

import type { State } from '../../types/ReduxStateType';

type props = {
  exportMailboxesByUsersDispatch: Function,
  exportUsersToCSV: Function,
};

const validKeyEntry = e => {
  return e.keyCode === 13;
};

const listMenuItems = (exportUsersToCSV, exportMailboxesByUsersDispatch) => {
  const menuItems = [
    {
      name: 'Users',
      qaId: 'usersExport',
      onActionHandler: () => exportUsersToCSV(),
    },
    {
      name: 'Mailboxes by User',
      qaId: 'mailboxesByUserExport',
      onActionHandler: () => exportMailboxesByUsersDispatch(),
    },
  ];

  return menuItems.map(menuItem => (
    <li className={styles.printOption} key={uuid()}>
      <span
        data-qa-id={menuItem.qaId}
        tabIndex={0}
        role="button"
        onClick={() => menuItem.onActionHandler()}
        onKeyDown={e => (validKeyEntry(e) ? menuItem.onActionHandler() : null)}
      >
        {menuItem.name}
      </span>
    </li>
  ));
};

export const ExportUsersView = ({ exportUsersToCSV, exportMailboxesByUsersDispatch }: props) => (
  <div className={styles.printMenuWrapper}>
    <ul className={styles.printMenuList}>{listMenuItems(exportUsersToCSV, exportMailboxesByUsersDispatch)}</ul>
  </div>
);

const mapStateToProps = (state: State) => ({
  pageTitle: state.app.title,
  users: state.userState.users,
});

const mapDispatchToProps = {
  exportUsersToCSV: exportUsers,
  exportMailboxesByUsersDispatch: exportMailboxesByUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportUsersView);
