// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormBuilder, Notification } from '@nats/webclient-common';
import getEditAuthorityApplicationValidationSchema from '@nats/nats-service-sdk/lib/validation/authority/editAuthorityValidation';

import formModalStyles from './FormModal.module.scss';
import { FORM_MODE, getErrorMessage } from './getFormFromType';
import { getAuthorities } from '../../actions/authorityActions';
import { editAuthority } from '../../api/authorityApi';
import { toggleModalVisible } from '../../actions/modalActions';
import { setSelectedRecord } from '../../actions/appActions';
import { selectPanel } from '../../actions/panelActions';
import { canEditAuthority } from '../../utilities/permissions';

import type { Authority, AuthorityApplication } from '../../types/Authority';
import type { FieldDefinitions } from '../../types/FormBuilderTypes';
import type { State as Store } from '../../types/ReduxStateType';
import type { FormMode } from './getFormFromType';
import type { EditAuthorityObj } from '../../api/authorityApi';

import styles from './AuthorityApplication.module.scss';

type Props = {
  mode: FormMode,
  selectedRecord: AuthorityApplication,
  editAuthority: EditAuthorityObj => Promise<mixed>,
  getAuthorities: () => Promise<Array<Authority>>,
  toggleModalVisible: () => void,
  setSelectedRecord: (?{}) => mixed,
  selectPanel: (?string, {}) => mixed,
};

type State = {
  submissionError: null | string,
  fields: FieldDefinitions,
  keepModalOpenToDisplayApiError: boolean,
};

class AuthorityApplicationForm extends Component<Props, State> {
  errorMap: { [string]: string };

  constructor(props: Props) {
    super(props);
    this.state = {
      submissionError: null,
      fields: this.getFields(),
      keepModalOpenToDisplayApiError: false,
    };

    this.errorMap = {
      GENERIC_ERROR: 'Failed to update the authority. Please try again.',
      PERMISSION_ERROR: 'You do not have permission to edit an authority.',
      SCHEMA_ERROR: 'Licences total must be a positive integer.',
      NO_AUTHORITY_FOUND_ERROR: 'No authority with this name exists.',
      LICENCE_LIMIT_EXCEEDED_ERROR:
        'The total number of licences must be greater than the number of licences being used.',
    };
  }

  getFields = () => {
    const isEditing = this.props.mode === FORM_MODE.EDIT;

    const idField = {
      type: 'text',
      name: 'authority_id',
      initialValue: '',
      initialValueFrom: 'id',
      isHidden: true,
      isDisabled: true,
      cols: 0,
    };

    const nameField = {
      type: 'text',
      name: 'authority_name',
      label: 'Authority Name',
      initialValue: '',
      initialValueFrom: 'authorityName',
      isDisabled: true,
      cols: 12,
      shouldWrapTooltip: true,
    };

    const applicationField = {
      type: 'text',
      name: 'application',
      label: 'Application',
      initialValue: '',
      initialValueFrom: 'application',
      isDisabled: true,
      cols: 12,
      shouldWrapTooltip: true,
    };

    const licencesAllocatedField = {
      type: 'text',
      name: 'allocated_licences',
      label: 'Licences Allocated',
      initialValue: '',
      initialValueFrom: 'allocatedLicences',
      isDisabled: true,
      cols: isEditing ? 6 : 4,
    };

    // the edit modal is smaller than the view form. hiding this field when editing allows
    // extra space for the other (more useful) fields and saves having to implement logic
    // to update the value when editing the max licences field
    const licencesUnallocatedField = {
      type: 'text',
      name: 'unallocated_licences',
      label: 'Licences Unallocated',
      initialValue: '',
      initialValueFrom: 'unallocatedLicences',
      isDisabled: true,
      cols: isEditing ? 0 : 4,
      isHidden: isEditing,
    };

    const licencesMaxField = {
      type: 'text',
      name: 'max_licences',
      label: 'Licences Total',
      initialValue: '',
      initialValueFrom: 'maxLicences',
      cols: isEditing ? 6 : 4,
      shouldWrapTooltip: true,
    };

    return [idField, nameField, applicationField, licencesAllocatedField, licencesUnallocatedField, licencesMaxField];
  };

  setSubmissionError: Function = error => {
    this.setState({
      submissionError:
        error.response && error.response.data
          ? getErrorMessage(this.errorMap, error.response.data.error)
          : this.errorMap.GENERIC_ERROR,
    });
  };

  onSubmit = async (values: EditAuthorityObj) => {
    if (!this.state.keepModalOpenToDisplayApiError) {
      this.setState({ keepModalOpenToDisplayApiError: true });
    }

    this.setState({ submissionError: null });

    try {
      await this.props.editAuthority(values);

      this.props.setSelectedRecord(null);
      this.props.selectPanel(null, {});

      this.props.toggleModalVisible();
      this.props.getAuthorities();

      Notification.success('Authority successfully updated');
    } catch (err) {
      this.setSubmissionError(err);
    }
  };

  renderSubmissionError = () => {
    const { submissionError } = this.state;

    if (!submissionError) return null;

    return (
      <div
        data-qa-id="submissionError"
        className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
      >
        <ul>
          <li>{submissionError}</li>
        </ul>
      </div>
    );
  };

  hasAccessToForm = () =>
    this.props.mode === FORM_MODE.VIEW || (this.props.mode === FORM_MODE.EDIT && canEditAuthority());

  render() {
    if (!this.hasAccessToForm()) {
      if (!this.state.keepModalOpenToDisplayApiError) {
        this.props.toggleModalVisible();
        return null;
      }
    }

    return (
      <div className={formModalStyles.modalForm}>
        {this.renderSubmissionError()}
        <FormBuilder
          isComposing={this.props.mode !== FORM_MODE.VIEW}
          name={this.props.mode === FORM_MODE.VIEW ? 'viewAuthorityApplication' : 'editAuthorityApplication'}
          fields={this.state.fields}
          initialValues={this.props.selectedRecord}
          validationSchema={getEditAuthorityApplicationValidationSchema(true)}
          submitButtonText="Save"
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

export const mapStateToProps = ({ app }: Store) => ({
  selectedRecord: app.selectedRecord,
});

export const mapDispatchToProps = {
  editAuthority,
  getAuthorities,
  setSelectedRecord,
  selectPanel,
  toggleModalVisible,
};

export { AuthorityApplicationForm as PureAuthorityApplicationForm };
export default connect(mapStateToProps, mapDispatchToProps)(AuthorityApplicationForm);
