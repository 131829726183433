// @flow
import React, { useState } from 'react';
import get from 'lodash/get';
import { deleteOrganisation } from '~/api/organisationApi';
import { ConfirmModal } from '~/components/confirmModal/confirmModal';

type ErrorType = { response?: { data?: { error: string } } };

export const defaultMessage = 'There was an error deleting the organisation.';
export const errorMap = {
  SERVER_ERROR: defaultMessage,
  PERMISSION_ERROR: 'You do not have permission to delete organisations.',
  ORGANISATION_IN_USE_ERROR: 'There are users currently assigned to this organisation.',
};

const getErrorMessage = (error: ?ErrorType): string | null => {
  if (!error) {
    return null;
  }

  const errorCode = get(error, 'response.data.error');

  return errorMap[errorCode] || defaultMessage;
};

type Props = {
  organisationId: string,
  onConfirm: () => void,
  onCancel: () => void,
};

export const OrganisationConfirmDelete = ({ onConfirm, onCancel, organisationId }: Props) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const confirmDelete = async () => {
    setLoading(true);

    try {
      await deleteOrganisation(organisationId);
      onConfirm();
    } catch (err) {
      setError(getErrorMessage(err));
      setLoading(false);
    }
  };

  return (
    <ConfirmModal
      data-qa-id="organisation-confirm-delete"
      isLoading={isLoading}
      confirmationMessage="Are you sure you want to delete this organisation?"
      errorMessage={error}
      onCancel={() => onCancel()}
      onConfirm={confirmDelete}
    />
  );
};

export default OrganisationConfirmDelete;
