// @flow
import React from 'react';
import { DataTable, Portal } from '@nats/webclient-common';
import type { OrganisationProfilesProps } from '~/types/OrganisationProfilesTypes';
import styles from './Organisation.module.scss';

import type { Role } from '../../../types/RoleType';

const NO_DATA_TEXT = 'No profiles are assigned to this organisation.';

const OrganisationProfilesTable = ({ tableData, isLoading, parentNodeId }: OrganisationProfilesProps) => {
  const columns: Array<{
    Header: string,
    id: string,
    accessor: Role => string,
  }> = [
    {
      Header: 'Profile',
      id: 'name',
      accessor: (profile: Role) => profile.name,
      resizable: false,
    },
  ];

  const getClassNames = () => (tableData.length === 0 ? styles.organisationTableError : '');

  const renderComponent = () => (
    <DataTable
      className={getClassNames()}
      isClientSide
      disableFilteringAndPagination
      filterable={false}
      sortable={false}
      noDataText={NO_DATA_TEXT}
      {...{
        tableData,
        columns,
        isLoading,
      }}
    />
  );

  return parentNodeId ? <Portal to={parentNodeId}>{renderComponent()}</Portal> : renderComponent();
};

export default OrganisationProfilesTable;
