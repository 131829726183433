// @flow

import { resetRoles } from './roleActions';
import { resetOrganisationDetailsAction } from './organisationDetailsActions';

import type { ThunkAction } from '../types/ReduxActionTypes';

export function resetOrganisationFormState(): ThunkAction {
  return async function action(dispatch: Function) {
    dispatch(resetRoles());
    dispatch(resetOrganisationDetailsAction());
  };
}
