// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormBuilder, Notification, Loading } from '@nats/webclient-common';
import getCreateOrganisationValidationSchema from '@nats/nats-service-sdk/lib/validation/organisation/createOrganisationValidation';
import type { SerialCodeState } from '~/types/state/SerialCodes';
import {
  loadSerialCodes as loadSerialCodesAction,
  loadOrganisationSerialCodes as loadOrgSerialCodesAction,
  upsertOrganisationSerialCodes as upsertSerialCodesAction,
} from '~/api/serialCodesApi';
import { loadRoles } from '~/api/roleApi';
import type { OrganisationProfileEdit, OrganisationProfilesUpdateParams } from '~/types/OrganisationProfilesTypes';

import type { OrganisationSerialCode } from '~/types/OrganisationSerialCodeTypes';
import { FORM_MODE, getErrorMessage } from '../getFormFromType';
import {
  createOrganisation as createOrganisationAction,
  editOrganisation as editOrganisationAction,
  loadOrganisations as loadOrganisationsAction,
} from '../../../api/organisationApi';
import OrganisationProfilesTable from './OrganisationProfilesTable';
import OrganisationProfilesEditTable from './OrganisationProfilesEditTable';
import OrganisationMailboxesTable from './OrganisationMailboxesTable';
import OrganisationSerialCodesTable from './OrganisationSerialCodesTable';
import OrganisationSerialCodesEditTable from './OrganisationSerialCodesEditTable';
import ConfirmOrganisationDelete from './OrganisationConfirmDelete';
import { loadOrganisationDetails } from '../../../api/organisationDetailsApi';
import { updateOrganisationProfiles as updateOrganisationProfilesAction } from '../../../api/organisationProfilesApi';

import type { FieldDefinitions } from '../../../types/FormBuilderTypes';
import type { State as Store } from '../../../types/ReduxStateType';
import type { OrganisationDetailsState } from '../../../types/state/OrganisationDetails';
import type { RoleState } from '../../../types/state/Role';
import type { Organisation as OrganisationType } from '../../../types/Organisation';
import type { FormMode } from '../getFormFromType';
import type { OrganisationDetails } from '../../../types/OrganisationDetailsTypes';
import type { CreateOrgObj, EditOrgObj } from '../../../api/organisationApi';

import { getEditLicenceFields, getCreateLicenceFields, getViewLicenceFields } from './getOrganisationLicenceFields';
import formModalStyles from '../FormModal.module.scss';
import { canCreateOrganisation, canEditOrganisation, canDeleteOrganisation } from '../../../utilities/permissions';
import styles from './Organisation.module.scss';
import { toggleModalVisible as toggleModalVisibleAction, setModal } from '../../../actions/modalActions';
import { setSelectedRecord } from '../../../actions/appActions';
import { selectPanel, closePanel } from '../../../actions/panelActions';
import { editOrganisationValidationSchema } from './organisation-schema';
import { resetOrganisationFormState } from '../../../actions/organisationFormResetActions';

type Props = {
  mode: FormMode,
  selectedRecord: OrganisationType,
  createOrganisation: CreateOrgObj => Promise<string>,
  editOrganisation: EditOrgObj => Promise<mixed>,
  toggleModalVisible: () => void,
  loadOrganisations: () => Promise<Array<OrganisationType>>,
  loadSerialCodes: () => Promise<Array<OrganisationSerialCode>>,
  loadOrganisationSerialCodes: string => Promise<Array<OrganisationSerialCode>>,
  upsertSerialCodes: (string, Array<string>) => Promise<void>,
  setSelectedRecord: (?{}) => mixed,
  setModal: (string, React$Node) => void,
  closePanel: () => void,
  selectPanel: (?string, {}) => mixed,
  loadOrganisationDetails: string => Promise<OrganisationDetails>,
  updateOrganisationProfiles: OrganisationProfilesUpdateParams => Promise<mixed>,
  loadRoles: () => void,
  organisationDetailsState: OrganisationDetailsState,
  roleState: RoleState,
  serialCodeState: SerialCodeState,
};

type State = {
  submissionError: null | string,
  fields: FieldDefinitions,
  keepModalOpenToDisplayApiError: boolean,
  editProfileTable: Array<OrganisationProfileEdit>,
  editSerialCodeTable: Array<OrganisationSerialCode>,
  isLoading: boolean,
};

const nameField = {
  type: 'text',
  name: 'name',
  label: 'Organisation Name',
  initialValue: '',
  initialValueFrom: 'name',
  cols: 12,
  shouldWrapTooltip: true,
};

const licenceHeader = {
  name: 'licencesLabel',
  label: 'Licences',
  isLabelOnly: true,
  cols: 12,
  shouldStartNewRow: true,
  labelPaddingAbove: 'standard',
};

const messagesHeader = {
  name: 'messagesLabel',
  label: 'Messages',
  isLabelOnly: true,
  cols: 12,
  shouldStartNewRow: true,
  labelPaddingAbove: 'standard',
};

const serialCodesHeader = {
  name: 'serialCodesLabel',
  label: 'Serial Codes',
  isLabelOnly: true,
  cols: 12,
  shouldStartNewRow: true,
  labelPaddingAbove: 'standard',
};

const organisationMailboxesField = {
  isHidden: true,
  portalId: 'organisationMailboxesTable',
};

const organisationProfilesField = {
  isLabelOnly: true,
  portalId: 'organisationProfilesTable',
};

const organisationProfilesEditField = {
  isHidden: true,
  portalId: 'organisationProfilesEditTable',
};

const organisationSerialCodesField = {
  isHidden: true,
  portalId: 'organisationSerialCodesTable',
};

const organisationSerialCodesEditField = {
  isHidden: true,
  portalId: 'organisationSerialCodesEditTable',
};

const errorCodes = {
  ORGANISATION_DETAILS_LOAD_ERROR: 'ORGANISATION_DETAILS_LOAD_ERROR',
  PROFILES_LOAD_ERROR: 'PROFILES_LOAD_ERROR',
  SERIAL_CODES_LOAD_ERROR: 'SERIAL_CODES_LOAD_ERROR',
};

class Organisation extends Component<Props, State> {
  errorMap: { [string]: string };

  constructor(props: Props) {
    super(props);

    this.state = {
      submissionError: null,
      fields: [],
      keepModalOpenToDisplayApiError: false,
      editProfileTable: [],
      editSerialCodeTable: [],
      isLoading: true,
    };

    this.errorMap = {
      GENERIC_ERROR: 'Failed to process organisation data. Please try again.',
      ORGANISATION_IN_USE_ERROR: 'Organisation is already in use.',
      PERMISSION_ERROR: 'You do not have permission to create or edit an organisation',
      LICENCE_LIMIT_EXCEEDED_ERROR: 'The selected licence(s) are unavailable for the authority',
      SERVER_ERROR: 'Failed to retrieve requested data from the server',
      ORGANISATION_DETAILS_LOAD_ERROR: 'Failed to retrieve organisation details data from the server',
      PROFILES_LOAD_ERROR: 'Failed to retrieve message profile data from the server',
      ILLEGAL_ACTION_ERROR: 'An illegal action was performed.',
      CANNOT_DELETE_ASSIGNED_ORG_ROLE_ERROR: 'Cannot remove a message profile that is currently assigned to a user.',
      FAILED_TO_RETRIEVE_ORGANISATION_USERS: 'Failed to retrieve the users for the organisation',
      SERIAL_CODES_LOAD_ERROR: 'Failed to retrieve serial codes for the selected organisation',
    };
  }

  getFields() {
    let fields;
    switch (this.props.mode) {
      case FORM_MODE.CREATE: {
        fields = [
          nameField,
          licenceHeader,
          ...getCreateLicenceFields(),
          messagesHeader,
          organisationProfilesEditField,
          serialCodesHeader,
          organisationSerialCodesEditField,
        ];
        break;
      }
      case FORM_MODE.VIEW: {
        fields = [
          nameField,
          { ...licenceHeader, label: 'Licences (allocated/total)' },
          ...getViewLicenceFields(this.props.selectedRecord ? this.props.selectedRecord.applications : []),
          messagesHeader,
          organisationMailboxesField,
          organisationProfilesField,
          serialCodesHeader,
          organisationSerialCodesField,
        ];

        break;
      }
      case FORM_MODE.EDIT: {
        fields = [
          {
            name: 'id',
            isHidden: true,
            initialValue: this.props.selectedRecord ? this.props.selectedRecord.id : '',
          },
          nameField,
          licenceHeader,
          ...getEditLicenceFields(this.props.selectedRecord ? this.props.selectedRecord.applications : []),
          messagesHeader,
          organisationProfilesEditField,
          serialCodesHeader,
          organisationSerialCodesEditField,
        ];
        break;
      }
      default:
        fields = [];
        break;
    }
    return fields;
  }

  loadData() {
    resetOrganisationFormState();

    const getOrganisationDetails = () => {
      return this.props.loadOrganisationDetails(this.props.selectedRecord ? this.props.selectedRecord.id : '');
    };

    switch (this.props.mode) {
      case FORM_MODE.CREATE:
        this.props.loadRoles();
        this.props.loadSerialCodes();
        break;
      case FORM_MODE.VIEW:
        getOrganisationDetails();
        this.props.loadOrganisationSerialCodes(this.props.selectedRecord.id);
        break;
      case FORM_MODE.EDIT:
        getOrganisationDetails();
        this.props.loadRoles();
        this.props.loadOrganisationSerialCodes(this.props.selectedRecord.id);
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    const { isLoading } = this.state;
    if (isLoading) {
      if (!this.isStillLoading()) {
        this.dataLoadedHandler();
      }
    }
  }

  dataLoadedHandler() {
    const editProfileTable = this.getInitialEditProfileTable();
    const fields = this.getFields();

    this.setState({
      isLoading: false,
      fields,
      editProfileTable,
      editSerialCodeTable: this.props.serialCodeState.serialCodes,
    });
  }

  organisationDetailsLoaded() {
    const { organisationDetailsState } = this.props;
    return organisationDetailsState && !organisationDetailsState.isBusy && !organisationDetailsState.error;
  }

  rolesLoaded() {
    const { roleState } = this.props;
    return roleState && !roleState.isLoading && !roleState.error && roleState.roles.length !== 0;
  }

  serialCodesLoaded() {
    const { serialCodeState } = this.props;
    return serialCodeState && !serialCodeState.isBusy && !serialCodeState.error;
  }

  isStillLoading() {
    let isLoading = true;

    // Dertermine if data is still loading
    switch (this.props.mode) {
      case FORM_MODE.VIEW:
        if (this.organisationDetailsLoaded() && this.serialCodesLoaded()) {
          isLoading = false;
        }
        break;
      case FORM_MODE.CREATE:
        if (this.rolesLoaded() && this.serialCodesLoaded()) {
          isLoading = false;
        }
        break;
      case FORM_MODE.EDIT:
        if (this.rolesLoaded() && this.organisationDetailsLoaded() && this.serialCodesLoaded()) {
          isLoading = false;
        }
        break;
      default:
        break;
    }

    return isLoading;
  }

  getInitialEditProfileTable(): Array<OrganisationProfileEdit> {
    const { roleState, organisationDetailsState } = this.props;
    return roleState.roles.map(role => {
      let isAssigned = false;
      if (this.props.mode === FORM_MODE.EDIT) {
        const orgRole = organisationDetailsState.profiles.find(profile => profile.id === role.id);
        isAssigned = !!orgRole;
      }

      return {
        id: role.id,
        name: role.name,
        isAssigned,
      };
    });
  }

  setSubmissionError: Function = error => {
    this.setState({
      submissionError:
        error.response && error.response.data
          ? getErrorMessage(this.errorMap, error.response.data.error)
          : this.errorMap.GENERIC_ERROR,
    });
  };

  createOrg = async (values: CreateOrgObj, profileIds: Array<string>, serialCodes: Array<string>) => {
    const { createOrganisation, loadOrganisations, toggleModalVisible, updateOrganisationProfiles, upsertSerialCodes } =
      this.props;

    let organisationId;
    try {
      organisationId = await createOrganisation(values);
    } catch (errorResponse) {
      this.setSubmissionError(errorResponse);
      return;
    }

    try {
      await upsertSerialCodes(organisationId, serialCodes);
    } catch (error) {
      Notification.warning('The organisation was created successfully but the serial codes have NOT been assigned');
    }

    try {
      await updateOrganisationProfiles({
        organisationId,
        profileIds,
      });
      Notification.success('Organisation successfully created');
    } catch (errorResponse) {
      Notification.warning('The organisation was created successfully but the message profiles have NOT been assigned');
    }

    toggleModalVisible();
    loadOrganisations();
  };

  updateOrg = async (values: EditOrgObj, profileIds: Array<string>, serialCodes: Array<string>) => {
    const {
      editOrganisation,
      updateOrganisationProfiles,
      loadOrganisations,
      toggleModalVisible,
      selectedRecord,
      upsertSerialCodes,
    } = this.props;
    const organisationId = selectedRecord.id;

    try {
      await upsertSerialCodes(organisationId, serialCodes);
      await updateOrganisationProfiles({
        organisationId,
        profileIds,
      });
      await editOrganisation({
        ...values,
        id: organisationId,
      });
    } catch (errorResponse) {
      this.setSubmissionError(errorResponse);
      return;
    }

    Notification.success('Organisation successfully edited');

    this.props.setSelectedRecord(null);
    this.props.selectPanel(null, {});

    toggleModalVisible();
    loadOrganisations();
  };

  onSubmit: Function = async values => {
    const { mode } = this.props;
    const { editProfileTable, editSerialCodeTable } = this.state;

    if (!this.state.keepModalOpenToDisplayApiError) {
      this.setState({ keepModalOpenToDisplayApiError: true });
    }

    this.setState({ submissionError: null });

    const profileIds = editProfileTable.filter(({ isAssigned }) => isAssigned).map(({ id }) => id);
    const serialCodes = editSerialCodeTable.filter(({ isAssigned }) => isAssigned).map(({ code }) => code);

    if (mode === FORM_MODE.CREATE) {
      await this.createOrg(values, profileIds, serialCodes);
    } else if (this.props.mode === FORM_MODE.EDIT) {
      await this.updateOrg(values, profileIds, serialCodes);
    }
  };

  toggleTableAssignment = (rowToChange: string, tableName: string, accessor: string) => {
    const editableTable = this.state[tableName];

    const tableRow = editableTable.find(editProfile => editProfile[accessor] === rowToChange);

    if (tableRow) {
      tableRow.isAssigned = !tableRow.isAssigned;
    }

    this.setState({ [tableName]: editableTable });
  };

  renderPortals = () => {
    switch (this.props.mode) {
      case FORM_MODE.VIEW: {
        const { profiles, mailboxes } = this.props.organisationDetailsState;
        const { serialCodes } = this.props.serialCodeState;

        return [
          <OrganisationProfilesTable parentNodeId="organisationProfilesTable" tableData={profiles} isLoading={false} />,
          <OrganisationMailboxesTable
            parentNodeId="organisationMailboxesTable"
            tableData={mailboxes}
            isLoading={false}
          />,
          <OrganisationSerialCodesTable
            parentNodeId="organisationSerialCodesTable"
            tableData={serialCodes.filter(({ isAssigned }) => isAssigned)}
            isLoading={false}
          />,
        ];
      }
      case FORM_MODE.CREATE:
      case FORM_MODE.EDIT: {
        const { isLoading, editProfileTable, editSerialCodeTable } = this.state;
        return [
          <OrganisationProfilesEditTable
            parentNodeId="organisationProfilesEditTable"
            tableData={editProfileTable}
            isLoading={isLoading}
            assignmentChangeHandler={id => this.toggleTableAssignment(id, 'editProfileTable', 'id')}
          />,
          <OrganisationSerialCodesEditTable
            parentNodeId="organisationSerialCodesEditTable"
            isLoading={isLoading}
            assignmentChangeHandler={code => this.toggleTableAssignment(code, 'editSerialCodeTable', 'code')}
            tableData={editSerialCodeTable}
          />,
        ];
      }
      default:
        return null;
    }
  };

  renderLoaderError = () => {
    let errorCode;

    const { organisationDetailsState, roleState, serialCodeState } = this.props;

    if (organisationDetailsState && organisationDetailsState.error) {
      errorCode = errorCodes.ORGANISATION_DETAILS_LOAD_ERROR;
    } else if (roleState && roleState.error) {
      errorCode = errorCodes.PROFILES_LOAD_ERROR;
    } else if (serialCodeState && serialCodeState.error) {
      errorCode = errorCodes.SERIAL_CODES_LOAD_ERROR;
    }

    if (errorCode) {
      const errorText = getErrorMessage(this.errorMap, errorCode);
      return (
        <div
          data-qa-id="loadingError"
          className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
        >
          <ul>
            <li>{errorText}</li>
          </ul>
        </div>
      );
    }
    return null;
  };

  renderSubmissionError = () => {
    const { submissionError } = this.state;

    if (submissionError) {
      return (
        <div
          data-qa-id="submissionError"
          className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
        >
          <ul>
            <li>{submissionError}</li>
          </ul>
        </div>
      );
    }
    return null;
  };

  showDeleteConfirmationModal = () => {
    const { id, name } = this.props.selectedRecord;

    this.props.toggleModalVisible();
    this.props.setModal(
      name,
      <ConfirmOrganisationDelete
        organisationId={id}
        onDismiss={() => this.props.toggleModalVisible()}
        onCancel={() => this.props.toggleModalVisible()}
        onConfirm={() => {
          this.props.toggleModalVisible();
          this.props.closePanel();
          this.props.setSelectedRecord(null);
          this.props.loadOrganisations();
          Notification.success('Organisation successfully deleted');
        }}
      />
    );
  };

  renderDeleteButton = () => {
    if (this.props.mode !== FORM_MODE.VIEW || !canDeleteOrganisation()) {
      return null;
    }

    return (
      <div className={styles.organisationDeleteButton}>
        <Button data-qa-id="organisation-delete-btn" onClick={() => this.showDeleteConfirmationModal()}>
          Delete
        </Button>
      </div>
    );
  };

  hasAccessToForm = () =>
    this.props.mode === FORM_MODE.VIEW ||
    (this.props.mode === FORM_MODE.CREATE && canCreateOrganisation()) ||
    (this.props.mode === FORM_MODE.EDIT && canEditOrganisation());

  render() {
    if (!this.hasAccessToForm()) {
      if (!this.state.keepModalOpenToDisplayApiError) {
        this.props.toggleModalVisible();
      }
      return null;
    }

    const { fields, isLoading } = this.state;
    if (isLoading) {
      return (
        <div>
          {this.renderLoaderError()}
          <Loading block isLoading hasData />
        </div>
      );
    }

    return (
      <div
        key={this.props.selectedRecord ? this.props.selectedRecord.id : 'COMPOSING'}
        className={`${formModalStyles.modalForm} organisationModal`}
      >
        {this.renderSubmissionError()}
        <FormBuilder
          isComposing={this.props.mode !== FORM_MODE.VIEW}
          name={this.props.mode === FORM_MODE.CREATE ? 'createOrganisation' : 'editOrganisation'}
          fields={fields}
          initialValues={this.props.selectedRecord}
          validationSchema={
            this.props.mode === FORM_MODE.CREATE
              ? getCreateOrganisationValidationSchema(true)
              : editOrganisationValidationSchema
          }
          submitButtonText={this.props.mode === FORM_MODE.CREATE ? 'Create Organisation' : 'Save'}
          onSubmit={this.onSubmit}
        />
        {this.renderPortals()}
        {this.renderDeleteButton()}
      </div>
    );
  }
}

export const mapStateToProps = ({ app, organisationDetailsState, roleState, serialCodeState }: Store) => ({
  selectedRecord: app.selectedRecord,
  organisationDetailsState,
  roleState,
  serialCodeState,
});

export const mapDispatchToProps = {
  createOrganisation: createOrganisationAction,
  toggleModalVisible: toggleModalVisibleAction,
  loadOrganisations: loadOrganisationsAction,
  editOrganisation: editOrganisationAction,
  loadSerialCodes: loadSerialCodesAction,
  loadOrganisationSerialCodes: loadOrgSerialCodesAction,
  upsertSerialCodes: upsertSerialCodesAction,
  setSelectedRecord,
  setModal,
  closePanel,
  selectPanel,
  loadOrganisationDetails,
  updateOrganisationProfiles: updateOrganisationProfilesAction,
  loadRoles,
};

export { Organisation as PureOrganisation };
export default connect(mapStateToProps, mapDispatchToProps)(Organisation);
