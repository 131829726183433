// @flow
import moment from 'moment';
import { startCase, camelCase } from 'lodash';

export const formatDisplayDate = (date: string | null): string => {
  const newMoment = moment.utc(date);

  return newMoment.isValid() ? newMoment.format('YYYY-MM-DD HH:mm') : '';
};

export const formatFileSize = (fileSizeInBytes: number | null): string => {
  if (fileSizeInBytes === null) return '';

  const fileSizeInMegabytes = (fileSizeInBytes / 1024 / 1024).toFixed(2);

  return fileSizeInBytes > 0 && fileSizeInMegabytes === '0.00' ? '< 0.01 MB' : `${fileSizeInMegabytes} MB`;
};

export const toTitleCase = (input: string) => startCase(camelCase(input));

export const formatFieldName = (input: string) => camelCase(input);
