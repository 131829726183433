// @flow
import { handle } from 'redux-pack';
import {
  ROLES_LOAD_STARTED,
  ROLES_LOAD_SUCCESSFUL,
  ROLES_LOAD_FAILED,
  ROLES_DELETE,
  ROLES_DELETE_CONFIRMED,
  RESET_ROLES,
} from '../actions/actionTypes';
import type { RoleState } from '../types/state/index';
import type { RoleAction, RoleDeleteConfirmedAction } from '../actions/roleActions';

const initialState: RoleState = {
  isLoading: false,
  roles: [],
  error: null,
  delete: {
    deleted: false,
    isLoading: false,
    error: null,
  },
};

export default function roleReducer(state: RoleState = initialState, action: RoleAction): RoleState {
  switch (action.type) {
    case ROLES_LOAD_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case ROLES_LOAD_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        roles: action.payload.data._embedded.roles,
      };
    }
    case ROLES_LOAD_FAILED:
      return {
        ...initialState,
        error: action.error,
      };
    case ROLES_DELETE: {
      return handle(state, action, {
        always: prevState => ({
          ...prevState,
          delete: initialState.delete,
        }),
      });
    }
    case ROLES_DELETE_CONFIRMED: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          delete: {
            ...initialState.delete,
            isLoading: true,
          },
        }),
        finish: prevState => ({
          ...prevState,
          delete: { ...prevState.delete, isLoading: false },
        }),
        failure: (prevState, response: RoleDeleteConfirmedAction) => ({
          ...prevState,
          delete: { ...prevState.delete, error: response.payload },
        }),
        success: (prevState, response: RoleDeleteConfirmedAction) => ({
          ...prevState,
          roles: prevState.roles.filter(u => u.id !== response.payload.id),
          delete: { ...prevState.delete, deleted: true },
        }),
      });
    }
    case RESET_ROLES:
      return {
        ...state,
        isLoading: true,
        roles: [],
        error: null,
      };
    default:
      return state;
  }
}
