// @flow
import React, { Component, type Node } from 'react';
import { connect } from 'react-redux';
import { DataTable, FilterInput, Notification } from '@nats/webclient-common';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { applicationTypeDisplay } from '@nats/nats-service-sdk/lib/types/Applications';

import type { State as Store } from '../../types/ReduxStateType';

import { loadUsers } from '../../api/userApi';
import type { BasicUser } from '../../types/User';
import type { PageHeaderControls } from '../../types/state';

import styles from './UserScreen.module.scss';
import {
  updateUsersFilterModel,
  updateUsersPageNumber,
  updateUsersSortModel,
  deleteUser,
  deleteUserConfirm,
} from '../../actions/userActions';
import { selectPanelCustom, closePanel } from '../../actions/panelActions';
import { setPageTitle, setSelectedRecord } from '../../actions/appActions';
import type { ViewState, DeleteUserState } from '../../types/state/User';

import getErrorMessageForErrorCode from '../../utilities/errorCodeMapping';

import { FORM_CODE, FORM_MODE } from '../../components/forms/getFormFromType';
import type { FormCode, FormMode } from '../../components/forms/getFormFromType';
import type { Sort } from '../../types/TableTypes';
import type { UsersUpdateSortModelAction } from '../../actions/userActions';
import type { UserDetails } from '../../types/UserDetails';
import { setPageHeaderControls } from '../../actions/headerControlActions';
import { setModal, setModalForm, toggleModalVisible } from '../../actions/modalActions';
import { canEditUser, canDeleteUser } from '../../utilities/permissions';
import ConfirmUserDelete from '../../components/forms/user/ConfirmUserDelete';
import UserView from '../../components/forms/user/UserView';
import ExportUsersView from './ExportUsersView';

export type RawUserRecord = {
  id: string,
  username: string,
  type: string,
  organisation?: string,
  organisationId?: string,
  applications: Array<string>,
  userDetails: UserDetails,
};

type Props = {
  users: Array<BasicUser>,
  selectedRecord: ?RawUserRecord,
  viewState: ViewState,
  updateUsersSortModel: (Array<Sort>) => UsersUpdateSortModelAction,
  setPageTitle: string => mixed,
  loadUsers: () => mixed,
  updateUsersFilterModel: () => mixed,
  updateUsersPageNumber: () => mixed,
  selectPanelCustom: (string, React$Node, {}) => mixed,
  closePanel: () => void,
  setSelectedRecord: RawUserRecord => mixed,
  isLoading: boolean,
  isLoadingSelectedRecord: boolean,
  location: Object,
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModal: (string, React$Node) => void,
  setModalForm: (FormCode, FormMode, {}) => void,
  deleteUser: string => mixed,
  delete: DeleteUserState,
};

type State = {
  permissionErrorNotificationId: string | null,
  tableColumns: Array<{
    Header: Node,
    id: string,
    accessor: BasicUser => string,
    Filter: ({}) => Node,
  }>,
};

const userTypeDisplay = {
  DEFAULT: 'Default',
  NATS_HELP_DESK: 'NATS Help Desk',
  ORGANISATION_ADMIN: 'Organisation Admin',
  AUTHORITY_ADMIN: 'Authority Admin',
  NATS_ACCOUNT_ADMIN: 'NATS Account Admin',
};

type UserRecord = {
  username: string,
  _original: RawUserRecord,
};

class UserScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      permissionErrorNotificationId: null,
      tableColumns: [
        {
          Header: 'Username',
          id: 'username',
          accessor: (u: BasicUser) => u.username,
          Filter: (filter: Object) => this.getFilterInput(filter, 'username', this.props.location.search),
        },
        {
          Header: 'Organisation',
          id: 'organisation',
          accessor: (u: BasicUser) => u.organisation || '',
          Filter: (filter: Object) => this.getFilterInput(filter, 'organisation', this.props.location.search),
        },
        {
          Header: 'User Type',
          id: 'type',
          accessor: (u: BasicUser) => userTypeDisplay[u.type],
          Filter: (filter: Object) => this.getFilterInput(filter, 'type', this.props.location.search),
        },
        {
          Header: 'Licences',
          id: 'applications',
          accessor: this.renderLicenceCellString,
          Filter: (filter: Object) => this.getFilterInput(filter, 'applications', this.props.location.search),
        },
      ],
    };
  }

  componentWillMount() {
    this.props.setPageTitle('Users');
    this.props.setPageHeaderControls(this.setSubheaderControls());
  }

  componentWillUnmount() {
    this.clearPermissionErrorNotificationIfSet();
  }

  selectRow = (userRecord: UserRecord) => {
    if (!userRecord) {
      return;
    }

    const { username, _original } = userRecord;

    this.props.setSelectedRecord(_original);

    const panelProps = {
      isComposing: false,
      username,
    };

    this.props.selectPanelCustom(
      username,
      <UserView username={username} disabled={!canDeleteUser(_original)} onClick={this.handleUserDelete} />,
      panelProps
    );
  };

  isRowSelected = (id: string): boolean => !!this.props.selectedRecord && this.props.selectedRecord.id === id;

  getFilterInput = (filter: Object, name: string, search: string) => {
    const attrs = {
      name,
      search,
      maxLength: 100,
    };

    return <FilterInput onChange={value => filter.onChange(value)} name={name} attrs={attrs} />;
  };

  clearPermissionErrorNotificationIfSet = () => {
    if (this.state.permissionErrorNotificationId) {
      Notification.dismiss(this.state.permissionErrorNotificationId);
      this.setState({ permissionErrorNotificationId: null });
    }
  };

  getUsers = async (): Promise<void> => {
    try {
      await this.props.loadUsers();
    } catch (error) {
      // This is only needed here because in order to reload users you need to refresh the page
      this.clearPermissionErrorNotificationIfSet();

      const errorCode = get(error, 'response.data.error');
      if (errorCode !== 'INVALID_CHARACTER_ERROR') {
        const notificationId = Notification.error(getErrorMessageForErrorCode(errorCode), {
          autoClose: false,
        });

        this.setState({ permissionErrorNotificationId: notificationId });
      }
    }
  };

  handleClickEdit = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.toggleModalVisible();
    this.props.setModalForm(FORM_CODE.USER, FORM_MODE.EDIT, {
      username: selectedRecord.username,
    });
  };

  handleUserDelete = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.deleteUser(selectedRecord.id);
    this.props.toggleModalVisible();
    this.props.setModal(
      selectedRecord.username,
      <ConfirmUserDelete
        userId={selectedRecord.id}
        username={selectedRecord.username}
        isFacadeUser={selectedRecord.applications.includes('MESSAGES_FACADE')}
        onDismiss={() => this.props.toggleModalVisible()}
        onCancel={() => this.props.toggleModalVisible()}
      />
    );
  };

  setSubheaderControls = (): PageHeaderControls => {
    const { isLoading, users, selectedRecord, isLoadingSelectedRecord } = this.props;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      component: ExportUsersView,
      isDisabled: isLoading || users.length === 0,
    };

    const editControls = {
      name: 'Edit',
      icon: ['fab', 'wpforms'],
      onClick: this.handleClickEdit,
      isDisabled: isLoadingSelectedRecord || !selectedRecord || !canEditUser(selectedRecord),
    };

    return selectedRecord ? [[exportControls, editControls]] : [[exportControls]];
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedRecord !== prevProps.selectedRecord ||
      this.props.users !== prevProps.users ||
      this.props.isLoading !== prevProps.isLoading ||
      this.props.isLoadingSelectedRecord !== prevProps.isLoadingSelectedRecord
    ) {
      this.props.setPageHeaderControls(this.setSubheaderControls());
    }

    if (this.props.delete.deleted && this.props.delete.deleted !== prevProps.delete.deleted) {
      this.props.closePanel();

      Notification.success('User successfully deleted');
      this.props.toggleModalVisible();
    }
  }

  renderLicenceCellString = (u: BasicUser): string => {
    const licences = u.applications.map(a => applicationTypeDisplay[a]);
    if (licences.length === 0) {
      return '';
    }
    return licences.join(', ');
  };

  render() {
    return (
      <div className={styles.usersContainer}>
        <DataTable
          sortable
          filterable
          updatePageNumber={this.props.updateUsersPageNumber}
          updateFilterModel={this.props.updateUsersFilterModel}
          updateSortModel={this.props.updateUsersSortModel}
          tableData={this.props.users}
          columns={this.state.tableColumns}
          viewState={this.props.viewState}
          getData={this.getUsers}
          isLoading={this.props.isLoading}
          onRowSelected={this.selectRow}
          getTdClass={(state, rowInfo) => `
            ${this.isRowSelected(rowInfo.original.id) ? 'selectedRow' : ''}
          `}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  delete: state.userState.delete,
  isLoading: state.userState.isBusy,
  pageTitle: state.app.title,
  selectedRecord: state.app.selectedRecord,
  users: state.userState.users,
  viewState: state.userState.viewState,
  isLoadingSelectedRecord: state.organisationDetailsState.isBusy,
});

export const mapDispatchToProps = {
  setPageTitle,
  loadUsers,
  updateUsersPageNumber,
  updateUsersFilterModel,
  updateUsersSortModel,
  selectPanelCustom,
  closePanel,
  setSelectedRecord,
  setPageHeaderControls,
  toggleModalVisible,
  setModalForm,
  setModal,
  deleteUser,
  deleteUserConfirm,
};

export { UserScreen as PureUserScreen };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserScreen));
