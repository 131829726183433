// @flow
import { SOAP_ENDPOINTS_LOAD_FAILED, SOAP_ENDPOINTS_LOAD_STARTED, SOAP_ENDPOINTS_LOAD_SUCCESSFUL } from './actionTypes';

import type { LoadEndpointsResponse } from '../types/SoapType';

type LoadEndpointsStartedAction = {
  type: typeof SOAP_ENDPOINTS_LOAD_STARTED,
};

type LoadEndpointsSuccessAction = {
  type: typeof SOAP_ENDPOINTS_LOAD_STARTED,
  payload: LoadEndpointsResponse,
};

type LoadEndpointsFailedAction = {
  type: typeof SOAP_ENDPOINTS_LOAD_FAILED,
  error: string,
};

export type SoapAction = LoadEndpointsStartedAction | LoadEndpointsSuccessAction | LoadEndpointsFailedAction;

export function loadEndpointsStarted(): LoadEndpointsStartedAction {
  return {
    type: SOAP_ENDPOINTS_LOAD_STARTED,
  };
}

export function loadEndpointsSuccessful(response: LoadEndpointsResponse): LoadEndpointsSuccessAction {
  return {
    type: SOAP_ENDPOINTS_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadEndpointsFailed(error: string): LoadEndpointsFailedAction {
  return {
    type: SOAP_ENDPOINTS_LOAD_FAILED,
    error,
  };
}
