// @flow

import { PANEL_SELECT } from '../actions/actionTypes';
import type { PanelState } from '../types/state';
import type { PanelAction } from '../actions/panelActions';

const initialState: PanelState = {
  panelTitle: '',
  PanelComponent: undefined,
  panelProps: {},
};

export default function panelReducer(state: PanelState = initialState, action: PanelAction): PanelState {
  switch (action.type) {
    case PANEL_SELECT:
      return {
        ...state,
        panelTitle: action.panelTitle,
        PanelComponent: action.PanelComponent,
        panelProps: action.panelProps,
      };
    default:
      return state;
  }
}
