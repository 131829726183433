// @flow

import type { AppState } from '../types/state';

import { SET_PAGE_TITLE, SET_SELECTED_RECORD } from '../actions/actionTypes';
import type { AppAction } from '../actions/appActions';

const initialState: AppState = {
  title: '',
  contextSearchText: '',
  selectedRecord: null,
};

export default function appReducer(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return {
        ...state,
        title: action.newTitle,
      };
    case SET_SELECTED_RECORD:
      return {
        ...state,
        selectedRecord: action.selectedRecord,
      };
    default:
      return state;
  }
}
