// @flow
import { makeSafeError } from '@nats/webclient-common/lib/error/api';

import type { ThunkAction } from '../types/ReduxActionTypes';
import { clearCookiesAndRedirect } from './authActions';

export type ApiError = {
  response: {
    data: {
      error?: string,
      message?: string,
      authorizerErrorCode?: string,
    },
    status: number,
  },
};

export function handleApiError(error: ApiError): ThunkAction {
  return () => {
    const safeError = makeSafeError(error);
    const statusCode = safeError.response.status;

    if (statusCode === 401) {
      clearCookiesAndRedirect();
    }
  };
}
