// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '@nats/webclient-common/lib/helpers/permissions';
import { hasPermission as hasPermissionFn } from '@nats/nats-service-sdk/lib/lambda/request';
import { Loading, Notification } from '@nats/webclient-common';
import permissionsEnum from '@nats/nats-service-sdk/lib/types/Permissions';
import { loadFeatureToggles as loadFeatureTogglesAction } from '../../api/FeatureTogglesApi';
import { setPageTitle as setPageTitleAction } from '../../actions/appActions';
import FeatureTogglesForm from '../../components/forms/featureToggle/FeatureTogglesForm';
import type { State as ReduxState } from '../../types/ReduxStateType';
import formModalStyles from '../../components/forms/FormModal.module.scss';
import ErrorScreen from './ErrorScreen';
import type { FeatureToggle } from '../../types/FeatureTogglesTypes';

type Props = {
  loadError: boolean,
  loadFeatureToggles: Function,
  loading: boolean,
  featureToggles: Array<FeatureToggle>,
  setPageTitle: Function,
};

type State = {
  hasPermission: Function,
};

export class FeatureTogglesScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { setPageTitle } = props;
    setPageTitle('Feature Toggles');
    this.state = { hasPermission: hasPermission(hasPermissionFn, permissionsEnum.FeatureTogglesUpdate) };
  }

  componentDidMount() {
    this.props.loadFeatureToggles();
    if (!this.state.hasPermission) {
      Notification.error(
        'You do not have permission to view this page. To try again please refresh your browser. ' +
          'If this persists, please contact your organisation admin.'
      );
    }
  }

  render() {
    const { loadError, featureToggles, loading } = this.props;

    if (!this.state.hasPermission) {
      return <ErrorScreen message="You do not have permission to view this page." />;
    }

    if (loadError) {
      return <ErrorScreen message="An error occurred" />;
    }

    if (loading) {
      return <Loading isLoading />;
    }

    if (featureToggles.length === 0) {
      return <ErrorScreen message="No feature toggles available" />;
    }

    return (
      <div className={formModalStyles.modalForm}>
        <FeatureTogglesForm featureToggles={featureToggles} />
      </div>
    );
  }
}

function mapStateToProps({ featureTogglesState }: ReduxState) {
  return {
    featureToggles: featureTogglesState.featureToggles,
    loadError: featureTogglesState.loadError,
    loading: featureTogglesState.loading,
  };
}

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  loadFeatureToggles: loadFeatureTogglesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureTogglesScreen);
