// @flow
import {
  ROLES_DELETE,
  ROLES_DELETE_CONFIRMED,
  ROLES_LOAD_FAILED,
  ROLES_LOAD_STARTED,
  ROLES_LOAD_SUCCESSFUL,
  RESET_ROLES,
} from '~/actions/actionTypes';
import type { RoleInfo } from '../types/RoleType';
import { deleteRole as deleteRoleApi } from '../api/roleApi';

export const GET_ROLES = 'GET_ROLES';

export type LoadRolesResponse = {
  data: {
    _embedded: {
      roles: Array<RoleInfo>,
    },
  },
};

export type LoadRolesStartedAction = {
  type: typeof ROLES_LOAD_STARTED,
};

export type LoadRolesSuccessAction = {
  type: typeof ROLES_LOAD_SUCCESSFUL,
  payload: LoadRolesResponse,
};

export type LoadRolesFailedAction = {
  type: typeof ROLES_LOAD_FAILED,
  error: Error,
};

export type RoleDeleteAction = {
  type: typeof ROLES_DELETE,
  payload: { id: string },
};

export type RoleDeleteConfirmedAction = {
  type: typeof ROLES_DELETE_CONFIRMED,
  payload: any,
};

export type ResetRolesAction = {
  type: typeof RESET_ROLES,
};

export type RoleAction = LoadRolesStartedAction | LoadRolesSuccessAction | LoadRolesFailedAction | ResetRolesAction;

export function loadRolesStarted(): LoadRolesStartedAction {
  return {
    type: ROLES_LOAD_STARTED,
  };
}

export function loadRolesSuccessful(response: LoadRolesResponse): LoadRolesSuccessAction {
  return {
    type: ROLES_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadRolesFailed(error: Error): LoadRolesFailedAction {
  return {
    type: ROLES_LOAD_FAILED,
    error,
  };
}

export const deleteRole = (id: string) => ({
  type: ROLES_DELETE,
  promise: Promise.resolve({ id }),
});

export const deleteRoleConfirm = (id: string) => ({
  type: ROLES_DELETE_CONFIRMED,
  promise: deleteRoleApi(id),
});

export function resetRoles(): ResetRolesAction {
  return {
    type: RESET_ROLES,
  };
}
