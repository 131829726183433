// @flow
import { SET_PAGE_HEADER_CONTROLS } from './actionTypes';
import type { PageHeaderControls } from '../types/state';

type setPageHeaderControlAction = { type: typeof SET_PAGE_HEADER_CONTROLS, controls: PageHeaderControls };

export type HeaderControlAction = setPageHeaderControlAction;

export function setPageHeaderControls(controls: PageHeaderControls): setPageHeaderControlAction {
  return {
    type: SET_PAGE_HEADER_CONTROLS,
    controls,
  };
}
