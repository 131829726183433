// @flow
import { sortModelToUrl } from '@nats/webclient-common/lib/helpers/Urls';
import { Notification } from '@nats/webclient-common';
import get from 'lodash/get';
import environment from '../environment';

import type { ThunkAction } from '../types/ReduxActionTypes';
import {
  loadLoginsByOrganisationFailed,
  loadLoginsByOrganisationStarted,
  loadLoginsByOrganisationSuccessful,
} from '../actions/loginsByOrganisationActions';
import { apiGet } from './api';
import { handleApiError } from '../actions/errorActions';
import getErrorMessageForErrorCode from '../utilities/errorCodeMapping';

export const GET_LOGINS_BY_ORGANISATION_URL = `${environment.identityServiceUrl}/api/v1/logins-by-organisation`;

export function loadLoginsByOrganisation(): ThunkAction {
  return async function action(dispatch: Function, getState: Function) {
    if (!environment.identityServiceUrl) {
      throw new Error('Unable to retrieve Logins By Organisation; config not provided');
    }

    const { loginsByOrganisationState } = getState();
    const { viewState } = loginsByOrganisationState;

    const selectionParams = viewState.sortModel.length !== 0 ? { sort: sortModelToUrl(viewState.sortModel) } : {};

    dispatch(loadLoginsByOrganisationStarted());

    try {
      const response = await apiGet(GET_LOGINS_BY_ORGANISATION_URL, { ...selectionParams });
      dispatch(loadLoginsByOrganisationSuccessful(response));
      return response;
    } catch (error) {
      Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });
      dispatch(handleApiError(error));
      dispatch(loadLoginsByOrganisationFailed(error));
      throw error;
    }
  };
}
