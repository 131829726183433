// @flow
import axios from 'axios';
import FormData from 'form-data';
import environment from '../environment';
import {
  loadReferenceDataStarted,
  loadReferenceDataSuccessful,
  loadReferenceDataFailed,
  uploadReferenceDataStarted,
  uploadReferenceDataFailed,
  uploadReferenceDataSuccessful,
} from '../actions/referenceDataActions';
import { handleApiError } from '../actions/errorActions';
import { getApiOptions } from './helpers/options';

import type { Dispatch, ThunkAction } from '../types/ReduxActionTypes';

const GET_REFERENCE_DATA_URL = `${environment.identityServiceUrl}/api/v1/raw-reference-data`;
const REFERENCE_DATA_UPLOAD_DETAILS_URL = `${environment.identityServiceUrl}/api/v1/reference-data-upload-url`;
export const VISUAL_EXTRACT_REFERENCE_DATA_URL = `${environment.visualApiUrl}/api/v1/extracted-reference-data`;
export const MESSAGES_EXTRACT_REFERENCE_DATA_URL = `${environment.messagesServiceUrl}/api/v1/extracted-reference-data`;

export function loadReferenceData(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.visualApiUrl) {
      throw new Error('Unable to retrieve reference data; config not provided');
    }

    dispatch(loadReferenceDataStarted());

    try {
      const response = await axios.get(GET_REFERENCE_DATA_URL, getApiOptions());

      dispatch(loadReferenceDataSuccessful(response));
    } catch (error) {
      dispatch(loadReferenceDataFailed(error));
      dispatch(handleApiError(error));

      throw error;
    }
  };
}

export function uploadReferenceDataFile(dataArea: string, file: File): ThunkAction {
  return async function action(dispatch: Dispatch) {
    if (!environment.visualApiUrl || !environment.messagesServiceUrl) {
      throw new Error('Unable to upload reference data; config not provided');
    }

    let extractUrl;
    switch (dataArea) {
      case 'Visual_-_AIP_Data':
      case 'Visual_-_AIP_Dataset_2':
      case 'Visual_-_Mil_Aerodromes':
      case 'Visual_-_Minor_Civil_Airfields':
      case 'Visual_-_Area_Filters':
      case 'Visual_-_Known_Areas':
      case 'Visual_-_Helicopter_Landing_Fields':
      case 'Visual_-_Helicopter_Landing_Training_Areas':
        extractUrl = VISUAL_EXTRACT_REFERENCE_DATA_URL;
        break;
      case 'Visual_-_Helicopter_Landing_Sites':
        extractUrl = VISUAL_EXTRACT_REFERENCE_DATA_URL;
        break;
      case 'Visual_-_Helicopter_Landing_Hospitals':
        extractUrl = VISUAL_EXTRACT_REFERENCE_DATA_URL;
        break;
      case 'Messages_-_Aerodrome_Addresses':
      case 'Messages_-_Aerodromes':
      case 'Messages_-_Aircraft':
      case 'Messages_-_FIRs':
      case 'Messages_-_FPL_Addresses':
      case 'Messages_-_Preferential_Routes':
      case 'Messages_-_VFR_Addresses':
        extractUrl = MESSAGES_EXTRACT_REFERENCE_DATA_URL;
        break;
      case 'Admin_-_Online_Help':
      case 'Messages_-_Online_Help':
      case 'Visual_-_Online_Help':
      case 'Wingboard_-_Online_Help':
      case 'Local_Avoids_-_Online_Help':
      case 'Transit_Routes_-_Online_Help':
        break;
      default:
        throw new Error('Unable to upload reference data');
    }

    dispatch(uploadReferenceDataStarted());

    // uploadDetails returns a signed URL from the s3 bucket
    try {
      const uploadDetails = await axios.get(REFERENCE_DATA_UPLOAD_DETAILS_URL, {
        ...getApiOptions(),
        params: {
          dataArea,
          filename: file.name,
          fileSize: file.size,
          fileMimetype: file.type,
        },
      });

      const { referenceDataUpload } = uploadDetails.data._embedded;

      const formData = new FormData();
      Object.entries(referenceDataUpload.fields).forEach(entry => formData.append(entry[0], entry[1]));
      formData.append('file', file);

      // uploads the file using the signed URL from s3
      await axios.post(referenceDataUpload.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // If no extractUrl file doesn't require processing (help files)
      if (extractUrl) {
        // send command to read the file from s3 and perform the required processing
        await axios.post(extractUrl, null, {
          ...getApiOptions(),
          params: {
            dataArea,
            filename: file.name,
          },
        });
      }

      dispatch(uploadReferenceDataSuccessful());
    } catch (error) {
      dispatch(uploadReferenceDataFailed(error));

      throw error;
    }
  };
}
