// @flow
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';

import type { SoapUser, SoapUserExport } from '../../types/SoapUser';

const mapSoapUsers = (soapUsers: Array<SoapUser>): Array<SoapUserExport> => {
  return soapUsers.map(soapUser => ({
    username: soapUser.username,
    mailboxes: soapUser.mailboxes.join(', '),
    enabled: upperFirst(String(soapUser.enabled)),
    lastEndpoint: soapUser.lastEndpoint,
    userType: soapUser.userType,
    lastEamsRequest:
      soapUser.lastEamsRequest === 'NO_LAST_USAGE'
        ? 'No EAMS requests have been made'
        : moment.utc(soapUser.lastEamsRequest).format('YYYY.MM.DD HH:mm:ss'),
    status: upperFirst(soapUser.status),
  }));
};

const SoapUsersMapperModule = {
  mapSoapUsers,
};

export default SoapUsersMapperModule;
