// @flow

import environment from '~/environment';
import { apiGet } from '~/api/api';

const USERS_HELPDESK_URL = `${environment.identityServiceUrl}/api/v1/users-helpdesk`;

export type LoadHelpdeskUserResponse = {
  data: {
    _embedded: {
      users: Array<string>,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

export async function loadHelpDeskUsers(): Promise<Array<string>> {
  if (!environment.identityServiceUrl) {
    throw new Error('Unable to retrieve helpdesk user details data; config not provided');
  }

  const response: LoadHelpdeskUserResponse = await apiGet(USERS_HELPDESK_URL);

  const {
    data: {
      _embedded: { users },
    },
  } = response;

  return users;
}
