// @flow
import { cookie } from '@nats/webclient-common';
import environment from '../environment';
import { apiGet } from '../api/api';

const { identityServiceUrl, loginUrl, adminUrl } = environment;

const LOGOUT_IDENTITY_API_URL = `${identityServiceUrl}/api/v1/logout`;

export const clearCookiesAndRedirect = () => {
  cookie.clearCookies();
  window.location.replace(`${loginUrl}?ref=${adminUrl}`);
};

export function logout(): () => Promise<void> {
  return () =>
    apiGet(LOGOUT_IDENTITY_API_URL)
      .then(() => clearCookiesAndRedirect())
      .catch(() => clearCookiesAndRedirect());
}
