// @flow
import sortBy from 'lodash/sortBy';
import type {
  Organisation,
  NameIdMap,
  MailboxesResponse,
  OrganisationMailboxesResponseMapped,
  ProfilesResponse,
  OrganisationProfilesResponseMapped,
  OrganisationExport,
} from '~/types/Organisation';

const mapOrganisations = (organisations: Array<Organisation>): Array<OrganisationExport> => {
  const mappedOrganisations = [];

  organisations.forEach(org => {
    const mappingOrg = {};
    mappingOrg.name = org.name;

    org.applications.forEach(app => {
      const name = `${app.name.toLowerCase()}_allocated_users`;
      const users = `${app.name.toLowerCase()}_max_users`;

      mappingOrg[name] = app.allocated_users;
      mappingOrg[users] = app.max_users;
    });

    mappedOrganisations.push(mappingOrg);
  });

  return mappedOrganisations;
};

const mapMailboxesByOrganisation = (
  namedIdList: Array<NameIdMap>,
  mailboxes: Array<MailboxesResponse>
): Array<OrganisationMailboxesResponseMapped> => {
  const mappedMailboxesByOrganisation = [];

  mailboxes.forEach(mailbox => {
    namedIdList.forEach(pairing => {
      if (pairing.id === mailbox.organisation) {
        mappedMailboxesByOrganisation.push({
          mailbox_name: mailbox.address,
          organisation_name: pairing.name,
        });
      }
    });
  });

  return sortBy(mappedMailboxesByOrganisation, 'organisation_name');
};

const mapProfilesByOrganisations = (
  namedIdList: Array<NameIdMap>,
  profiles: Array<ProfilesResponse>
): Array<OrganisationProfilesResponseMapped> => {
  const mappedProfilesByOrganisations = [];

  profiles.forEach(profile => {
    namedIdList.forEach(pairing => {
      if (profile.organisation_id === pairing.id) {
        mappedProfilesByOrganisations.push({
          organisation_name: pairing.name,
          profile_name: profile.name,
        });
      }
    });
  });

  return sortBy(mappedProfilesByOrganisations, 'organisation_name');
};

const organisationsMapperModule = {
  mapOrganisations,
  mapProfilesByOrganisations,
  mapMailboxesByOrganisation,
};

export default organisationsMapperModule;
