// @flow
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, FormBuilder, Loading, Notification } from '@nats/webclient-common';
import getCreateMailboxValidationSchema from '@nats/nats-service-sdk/lib/validation/mailbox/createMailboxValidation';
import formModalStyles from '~/components/forms/FormModal.module.scss';

import type { State as Store } from '~/types/ReduxStateType';
import type { Mailbox as RawMailboxRecord } from '~/types/Mailbox';
import { FORM_MODE, getErrorMessage } from '~/components/forms/getFormFromType';
import {
  createMailbox as createMailboxAction,
  loadMailboxes as loadMailboxesAction,
  editMailbox as editMailboxAction,
} from '~/api/mailboxApi';
import { loadHelpDeskUsers } from '~/api/userHelpdeskApi';
import { loadOrganisations as loadOrganisationsAction } from '~/api/organisationApi';
import { loadSoapMailbox as checkIfMailboxExists } from '~/api/soapMailboxApi';
import { toggleModalVisible as toggleModalVisibleAction, setModal as setModalAction } from '~/actions/modalActions';
import { setSelectedRecord as setSelectedRecordAction } from '~/actions/appActions';
import { selectPanel as selectPanelAction, closePanel as closePanelAction } from '~/actions/panelActions';
import {
  setMailboxToBeDeleted as setMailboxToBeDeletedAction,
  deleteMailboxReset as deleteMailboxResetAction,
} from '~/actions/mailboxActions';
import type { Organisation } from '~/types/Organisation';
import type { FormMode } from '~/components/forms/getFormFromType';
import { canCreateMessageMailbox, canDeleteMessageMailbox, canEditMessageMailbox } from '~/utilities/permissions';
import type { CreateMailboxObj, EditMailboxObj } from '~/api/mailboxApi';

import ConfirmDeleteMailbox from '~/mailboxes/MailboxConfirmDelete';
import type { DeleteMailboxState } from '~/types/state/Mailbox';
import styles from './Mailbox.module.scss';

type Props = {
  selectedRecord: RawMailboxRecord,
  setModal: (string, React$Node) => void,
  mode: FormMode,
  createMailbox: CreateMailboxObj => Promise<void>,
  editMailbox: EditMailboxObj => Promise<void>,
  setMailboxToBeDeleted: string => Promise<mixed>,
  setSelectedRecord: (?{}) => mixed,
  selectPanel: (?string, {}) => mixed,
  toggleModalVisible: () => mixed,
  loadMailboxes: () => Promise<Array<RawMailboxRecord>>,
  loadOrganisations: () => Promise<Array<Organisation>>,
  closePanel: () => void,
  deleteState: DeleteMailboxState,
  deleteMailboxReset: () => Promise<mixed>,
};

const getSubmitText = mode => {
  switch (mode) {
    case FORM_MODE.VIEW:
      return null;
    case FORM_MODE.EDIT:
    case FORM_MODE.CREATE:
      return 'Save';
    default:
      throw new Error(`Unknown form mode '${mode}'`);
  }
};

export const errorMap = {
  GENERIC_ERROR: 'Failed to create a new message mailbox. Please try again.',
  MAILBOX_NOT_FOUND_ERROR: 'Unable to find a SOAP mailbox with that address.',
  MAILBOX_ADDRESS_UNIQUE_CONSTRAINT_ERROR: 'The mailbox address must be unique.',
  MAILBOX_DOES_NOT_EXIST_ERROR: 'The mailbox you are trying to edit no longer exists.',
  MAILBOX_STILL_ASSIGNED_ERROR:
    'Existing users must be removed from the mailbox before the organisation can be amended.',
};

const getFormName = mode => {
  switch (mode) {
    case FORM_MODE.VIEW:
      return 'view-mailbox';
    case FORM_MODE.EDIT:
      return 'edit-mailbox';
    case FORM_MODE.CREATE:
      return 'create-mailbox';
    default:
      throw new Error(`Unknown form mode '${mode}'`);
  }
};

function usePrevious(value: mixed): Object {
  const ref = useRef({});
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Mailbox = ({
  mode,
  loadOrganisations,
  toggleModalVisible,
  selectedRecord,
  setModal,
  createMailbox,
  editMailbox,
  setMailboxToBeDeleted,
  setSelectedRecord,
  selectPanel,
  loadMailboxes,
  closePanel,
  deleteState,
  deleteMailboxReset,
}: Props) => {
  const [submissionErrorMessage: null | Error, setSubmissionErrorMessage: Error => void] = useState(null);
  const [isLoadingOrganisations: boolean, setIsLoadingOrganisations: boolean => void] = useState(false);
  const [organisations: Array<Organisation>, setOrganisations: (Array<Organisation>) => void] = useState([]);

  useEffect(() => {
    (async () => {
      switch (mode) {
        case FORM_MODE.CREATE:
          if (!canCreateMessageMailbox()) {
            toggleModalVisible();
            return;
          }
          break;
        case FORM_MODE.EDIT:
          if (!canEditMessageMailbox()) {
            toggleModalVisible();
            return;
          }
          break;
        case FORM_MODE.VIEW:
        default:
          return;
      }

      setIsLoadingOrganisations(true);

      try {
        const organisationData = await loadOrganisations();

        if (organisationData.length === 0) {
          Notification.error(`Failed to load organisations`);
          toggleModalVisible();
          return;
        }

        setOrganisations(organisationData);
      } catch {
        Notification.error(`Failed to load organisations`);
        toggleModalVisible();
      }

      setIsLoadingOrganisations(false);
    })();
  }, []);

  const prevDeletedState = usePrevious(deleteState);
  useEffect(() => {
    if (deleteState.deleted && deleteState.deleted !== prevDeletedState.deleted) {
      closePanel();
      Notification.success('Message mailbox successfully deleted');
      toggleModalVisible();
      deleteMailboxReset();
    }
  }, [deleteState]);

  const onSubmit = async ({ address, description, organisationId }) => {
    setSubmissionErrorMessage(null);

    try {
      if (mode === FORM_MODE.CREATE) {
        await checkIfMailboxExists(address);
        await createMailbox({ address, description, organisationId });
      } else if (mode === FORM_MODE.EDIT) {
        const helpdeskUsers = await loadHelpDeskUsers();
        await editMailbox({
          id: selectedRecord.id,
          description,
          organisationId,
          helpdeskUsers,
        });

        setSelectedRecord(null);
        selectPanel(null, {});
      }
      Notification.success(`Mailbox successfully ${mode === FORM_MODE.CREATE ? 'created' : 'edited'}`);

      await toggleModalVisible();
      await loadMailboxes();
    } catch (error) {
      setSubmissionErrorMessage(
        error.response && error.response.data
          ? getErrorMessage(errorMap, error.response.data.error)
          : errorMap.GENERIC_ERROR
      );
    }
  };

  const confirmDeleteMailbox = () => {
    const { id, address } = selectedRecord;

    setMailboxToBeDeleted(id);
    toggleModalVisible();
    setModal(
      address,
      <ConfirmDeleteMailbox
        mailboxId={id}
        onDismiss={() => toggleModalVisible()}
        onCancel={() => toggleModalVisible()}
      />
    );
  };

  const showDeleteButton = () => {
    if (mode !== FORM_MODE.VIEW) {
      return null;
    }

    return (
      <div>
        <Button
          data-qa-id="mailbox-delete-btn"
          disabled={!canDeleteMessageMailbox()}
          onClick={() => confirmDeleteMailbox()}
        >
          Delete
        </Button>
      </div>
    );
  };

  return (
    <div className={formModalStyles.modalForm} data-qa-id="mailbox-form">
      {submissionErrorMessage && (
        <div
          data-qa-id="submissionError"
          className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
        >
          <ul>
            <li>{submissionErrorMessage}</li>
          </ul>
        </div>
      )}
      {isLoadingOrganisations === true ? (
        <Loading block isLoading hasData />
      ) : (
        <Fragment>
          <FormBuilder
            name={getFormName(mode)}
            isComposing={mode !== FORM_MODE.VIEW}
            fields={[
              {
                label: 'Address',
                initialValue: '',
                initialValueFrom: 'address',
                name: 'address',
                cols: 12,
                type: 'text',
                shouldWrapTooltip: true,
                isDisabled: mode === FORM_MODE.EDIT,
              },
              {
                label: 'Name',
                initialValue: '',
                initialValueFrom: 'description',
                name: 'description',
                cols: 12,
                type: 'text',
                shouldWrapTooltip: true,
              },
              {
                label: 'Organisation',
                initialValue: '',
                initialValueFrom: mode === FORM_MODE.EDIT ? 'organisationId' : 'organisationName',
                name: 'organisationId',
                cols: 12,
                type: mode === FORM_MODE.VIEW ? 'text' : 'select',
                options: [
                  { value: null, text: '' },
                  ...organisations.map(org => ({
                    value: org.id,
                    text: org.name,
                  })),
                ],
                shouldWrapTooltip: true,
              },
              {
                label: 'Monitored',
                initialValue: 'Unknown',
                initialValueFrom: 'isMonitored',
                name: 'monitored',
                cols: 12,
                type: 'text',
                shouldWrapTooltip: true,
                isDisabled: true,
              },
            ]}
            initialValues={selectedRecord}
            submitButtonText={getSubmitText(mode)}
            validationSchema={getCreateMailboxValidationSchema()}
            onSubmit={onSubmit}
          />
          {showDeleteButton()}
        </Fragment>
      )}
    </div>
  );
};

export { Mailbox as PureMailbox };
export default connect(
  (state: Store) => ({
    selectedRecord: state.app.selectedRecord,
    deleteState: state.mailboxState.delete,
  }),
  {
    createMailbox: createMailboxAction,
    editMailbox: editMailboxAction,
    setMailboxToBeDeleted: setMailboxToBeDeletedAction,
    loadOrganisations: loadOrganisationsAction,
    loadMailboxes: loadMailboxesAction,
    setSelectedRecord: setSelectedRecordAction,
    selectPanel: selectPanelAction,
    setModal: setModalAction,
    toggleModalVisible: toggleModalVisibleAction,
    closePanel: closePanelAction,
    deleteMailboxReset: deleteMailboxResetAction,
  }
)(Mailbox);
