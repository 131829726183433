// @flow
import type { LoginsByOrganisationType, LoginsByOrganisationExport } from '../../types/LoginsByOrganisationType';
import { formatDisplayDate } from '../format';

const mapLoginsByOrganisation = (
  loginsByOrganisation: Array<LoginsByOrganisationType>
): Array<LoginsByOrganisationExport> => {
  return loginsByOrganisation.map(loginByOrganisation => ({
    username: loginByOrganisation.username,
    organisation: loginByOrganisation.organisation ?? '',
    loginCount: loginByOrganisation.loginCount ?? '0',
    loginDateTime: loginByOrganisation.loginTime === null ? '' : formatDisplayDate(loginByOrganisation.loginTime),
  }));
};

export default mapLoginsByOrganisation;
