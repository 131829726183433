// @flow
import type { Node } from 'react';

import { TOGGLE_MODAL_VISIBLE, SET_MODAL_CONTENT } from './actionTypes';
import getFormFromType from '../components/forms/getFormFromType';
import type { FormCode, FormMode } from '../components/forms/getFormFromType';

type toggleModalAction = {
  type: typeof TOGGLE_MODAL_VISIBLE,
  payload: {
    layer?: number,
  },
};

type setModalFormAction = {
  type: typeof SET_MODAL_CONTENT,
  payload: {
    header: string,
    content: Node,
    layer?: number,
  },
};

export type ModalAction = toggleModalAction | setModalFormAction;

export function toggleModalVisible(layer?: number): toggleModalAction {
  return { type: TOGGLE_MODAL_VISIBLE, payload: { layer } };
}

export function setModalForm(formCode: FormCode, formMode: FormMode, props: {}): setModalFormAction {
  const [title, component] = getFormFromType(formCode, formMode, props);
  return {
    type: SET_MODAL_CONTENT,
    payload: {
      header: title,
      content: component,
    },
  };
}

export function setModal(title: string, component: React$Node, layer?: number): setModalFormAction {
  return {
    type: SET_MODAL_CONTENT,
    payload: {
      header: title,
      content: component,
      layer,
    },
  };
}
