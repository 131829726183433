// @flow
import soapUserTypes from '@nats/nats-service-sdk/lib/types/SoapUser';
import {
  SOAP_USER_CONFIG_VALIDATE,
  SOAP_USER_LOAD_FAILED,
  SOAP_USER_LOAD_STARTED,
  SOAP_USER_LOAD_SUCCESSFUL,
} from './actionTypes';

import type { LoadSoapUsersResponse } from '../api/soapUserApi';
import type { ThunkAction } from '../types/ReduxActionTypes';

type loadStartedAction = { type: typeof SOAP_USER_LOAD_STARTED };
type loadSuccessAction = {
  type: typeof SOAP_USER_LOAD_SUCCESSFUL,
  payload: LoadSoapUsersResponse,
};
type loadFailedAction = { type: typeof SOAP_USER_LOAD_FAILED };
type validateSoapConfigAction = {
  type: typeof SOAP_USER_CONFIG_VALIDATE,
  isValid: boolean,
};

export type SoapUserAction = loadStartedAction | loadSuccessAction | loadFailedAction | validateSoapConfigAction;

export function loadSoapUsersStarted(): loadStartedAction {
  return {
    type: SOAP_USER_LOAD_STARTED,
  };
}

export function loadSoapUsersSuccessful(response: LoadSoapUsersResponse): loadSuccessAction {
  return {
    type: SOAP_USER_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadSoapUsersFailed(): loadFailedAction {
  return {
    type: SOAP_USER_LOAD_FAILED,
  };
}

function setSoapConfigValidFlag(isValid: boolean): validateSoapConfigAction {
  return {
    type: SOAP_USER_CONFIG_VALIDATE,
    isValid,
  };
}

export function checkSoapUserConfig(response: LoadSoapUsersResponse): ThunkAction {
  // This function assumes that all SOAP users were returned from the API (as no pagination is used for this endpoint)
  return dispatch => {
    const { soapUsers } = response.data._embedded;

    const hasAspUser = soapUsers.some(user => user.userType === soapUserTypes.ASP);
    const hasMetarUser = soapUsers.some(user => user.userType === soapUserTypes.METAR);
    const hasNotamUser = soapUsers.some(user => user.userType === soapUserTypes.NOTAM);
    const hasTafUser = soapUsers.some(user => user.userType === soapUserTypes.TAF);

    const hasValidConfig = hasAspUser && hasMetarUser && hasNotamUser && hasTafUser;

    dispatch(setSoapConfigValidFlag(hasValidConfig));
  };
}
