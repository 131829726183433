// @flow

// App Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_SELECTED_RECORD = 'SET_SELECTED_RECORD';

// Header Actions
export const SET_PAGE_HEADER_CONTROLS = 'SET_PAGE_HEADER_CONTROLS';

// Organisation Actions
export const ORGANISATION_LOAD_STARTED = 'ORGANISATION_LOAD_STARTED';
export const ORGANISATION_LOAD_SUCCESSFUL = 'ORGANISATION_LOAD_SUCCESSFUL';
export const ORGANISATION_LOAD_FAILED = 'ORGANISATION_LOAD_FAILED';

// User Actions
export const USER_LOAD_STARTED = 'USER_LOAD_STARTED';
export const USER_LOAD_SUCCESSFUL = 'USER_LOAD_SUCCESSFUL';
export const USER_LOAD_FAILED = 'USER_LOAD_FAILED';

export const UPDATE_USERS_PAGE_NUMBER = 'UPDATE_USERS_PAGE_NUMBER';
export const UPDATE_USERS_FILTER_MODEL = 'UPDATE_USERS_FILTER_MODEL';
export const UPDATE_USERS_SORT_MODEL = 'UPDATE_USERS_SORT_MODEL';

// Reference Data Actions
export const REFERENCE_DATA_LOAD_STARTED = 'REFERENCE_DATA_LOAD_STARTED';
export const REFERENCE_DATA_LOAD_SUCCESSFUL = 'REFERENCE_DATA_LOAD_SUCCESSFUL';
export const REFERENCE_DATA_LOAD_FAILED = 'REFERENCE_DATA_LOAD_FAILED';
export const REFERENCE_DATA_UPLOAD_STARTED = 'REFERENCE_DATA_UPLOAD_STARTED';
export const REFERENCE_DATA_UPLOAD_SUCCESSFUL = 'REFERENCE_DATA_UPLOAD_SUCCESSFUL';
export const REFERENCE_DATA_UPLOAD_FAILED = 'REFERENCE_DATA_UPLOAD_FAILED';

// Modal actions
export const TOGGLE_MODAL_VISIBLE = 'TOGGLE_MODAL_VISIBLE';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';

// Panel Actions
export const PANEL_SELECT = 'PANEL_SELECT';

// Licence Actions
export const LICENCE_ORG_LOAD_STARTED = 'LICENCE_ORG_LOAD_STARTED';
export const LICENCE_ORG_LOAD_SUCCESSFUL = 'LICENCE_ORG_LOAD_SUCCESSFUL';
export const LICENCE_ORG_LOAD_FAILED = 'LICENCE_ORG_LOAD_FAILED';

export const LICENCE_AUTHORITY_LOAD_STARTED = 'LICENCE_AUTHORITY_LOAD_STARTED';
export const LICENCE_AUTHORITY_LOAD_SUCCESSFUL = 'LICENCE_AUTHORITY_LOAD_SUCCESSFUL';
export const LICENCE_AUTHORITY_LOAD_FAILED = 'LICENCE_AUTHORITY_LOAD_FAILED';

export const LICENCE_USER_LOAD_STARTED = 'LICENCE_USER_LOAD_STARTED';
export const LICENCE_USER_LOAD_SUCCESSFUL = 'LICENCE_USER_LOAD_SUCCESSFUL';
export const LICENCE_USER_LOAD_FAILED = 'LICENCE_USER_LOAD_FAILED';

// Soap Actions
export const SOAP_ENDPOINTS_LOAD_STARTED = 'SOAP_ENDPOINTS_LOAD_STARTED';
export const SOAP_ENDPOINTS_LOAD_SUCCESSFUL = 'SOAP_ENDPOINTS_LOAD_SUCCESSFUL';
export const SOAP_ENDPOINTS_LOAD_FAILED = 'SOAP_ENDPOINTS_LOAD_FAILED';

// Soap User Actions
export const SOAP_USER_LOAD_STARTED = 'SOAP_USER_LOAD_STARTED';
export const SOAP_USER_LOAD_SUCCESSFUL = 'SOAP_USER_LOAD_SUCCESSFUL';
export const SOAP_USER_LOAD_FAILED = 'SOAP_USER_LOAD_FAILED';
export const SOAP_USER_CONFIG_VALIDATE = 'SOAP_USER_CONFIG_VALIDATE';

// Message Facade Actions
export const MESSAGES_FACADE_LOAD_STARTED = 'MESSAGES_FACADE_LOAD_STARTED';
export const MESSAGES_FACADE_LOAD_SUCCESSFUL = 'MESSAGES_FACADE_LOAD_SUCCESSFUL';
export const MESSAGES_FACADE_LOAD_FAILED = 'MESSAGES_FACADE_LOAD_FAILED';

// Role Actions
export const ROLES_LOAD_STARTED = 'ROLES_LOAD_STARTED';
export const ROLES_LOAD_SUCCESSFUL = 'ROLES_LOAD_SUCCESSFUL';
export const ROLES_LOAD_FAILED = 'ROLE_LOAD_FAILED';
export const ROLES_DELETE = 'ROLES_DELETE';
export const ROLES_DELETE_CONFIRMED = 'ROLES_DELETE_CONFIRMED';
export const RESET_ROLES = 'RESET_ROLES';

// Mailbox Actions
export const MAILBOX_LOAD_FAILED = 'MAILBOX_LOAD_FAILED';
export const MAILBOX_LOAD_STARTED = 'MAILBOX_LOAD_STARTED';
export const MAILBOX_LOAD_SUCCESSFUL = 'MAILBOX_LOAD_SUCCESSFUL';
export const MAILBOX_CREATE_FAILED = 'MAILBOX_CREATE_FAILED';
export const MAILBOX_CREATE_STARTED = 'MAILBOX_CREATE_STARTED';
export const MAILBOX_CREATE_SUCCESSFUL = 'MAILBOX_CREATE_SUCCESSFUL';
export const MAILBOX_EDIT_FAILED = 'MAILBOX_EDIT_FAILED';
export const MAILBOX_EDIT_SUCCESSFUL = 'MAILBOX_EDIT_SUCCESSFUL';
export const MAILBOX_EDIT_STARTED = 'MAILBOX_EDIT_STARTED';
export const MAILBOX_DELETE_PENDING = 'MAILBOX_DELETE_PENDING';
export const MAILBOX_DELETE_CONFIRMED = 'MAILBOX_DELETE_CONFIRMED';
export const MAILBOX_DELETE_RESET = 'MAILBOX_DELETE_RESET';

// User Details Actions
export const USER_DETAILS_LOAD_STARTED = 'USER_DETAILS_LOAD_STARTED';
export const USER_DETAILS_LOAD_SUCCESSFUL = 'USER_DETAILS_LOAD_SUCCESSFUL';
export const USER_DETAILS_LOAD_FAILED = 'USER_DETAILS_LOAD_FAILED';
export const USER_DETAILS_PROFILE_NOT_FOUND = 'USER_DETAILS_PROFILE_NOT_FOUND';
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';

export const USERS_DATA_LOADING = 'USERS_DATA_LOADING';
export const USERS_DATA_SUCCESS = 'USERS_DATA_SUCCESS';
export const USERS_DATA_FAILURE = 'USERS_DATA_FAILURE';

// Organisation Profiles Actions
export const ORGANISATION_DETAILS_LOAD_STARTED = 'ORGANISATION_DETAILS_LOAD_STARTED';
export const ORGANISATION_DETAILS_LOAD_SUCCESSFUL = 'ORGANISATION_DETAILS_LOAD_SUCCESSFUL';
export const ORGANISATION_DETAILS_LOAD_FAILED = 'ORGANISATION_DETAILS_LOAD_FAILED';
export const RESET_ORGANISATION_DETAILS = 'RESET_ORGANISATION_DETAILS';
export const EXPORT_DATA_LOADING = 'EXPORT_DATA_LOADING';
export const EXPORT_DATA_SUCCESS = 'EXPORT_DATA_SUCCESS';
export const EXPORT_DATA_FAILURE = 'EXPORT_DATA_FAILURE';

// Message Parameters Actions
export const MESSAGE_PARAMETERS_LOAD_STARTED = 'MESSAGE_PARAMETERS_LOAD_STARTED';
export const MESSAGE_PARAMETERS_LOAD_SUCCESSFUL = 'MESSAGE_PARAMETERS_LOAD_SUCCESSFUL';
export const MESSAGE_PARAMETERS_LOAD_FAILED = 'MESSAGE_PARAMETERS_LOAD_FAILED';
export const MESSAGE_PARAMETERS_EDIT_FAILED = 'MESSAGE_PARAMETERS_EDIT_FAILED';

// Message Parameters Audit Actions
export const MESSAGE_PARAMETERS_AUDIT_LOAD_STARTED = 'MESSAGE_PARAMETERS_AUDIT_LOAD_STARTED';
export const MESSAGE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL = 'MESSAGE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL';
export const MESSAGE_PARAMETERS_AUDIT_LOAD_FAILED = 'MESSAGE_PARAMETERS_AUDIT_LOAD_FAILED';

// Logins By Organisation Actions
export const LOGINS_BY_ORGANISATION_LOAD_STARTED = 'LOGINS_BY_ORGANISATION_LOAD_STARTED';
export const LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL = 'LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL';
export const LOGINS_BY_ORGANISATION_LOAD_FAILED = 'LOGINS_BY_ORGANISATION_LOAD_FAILED';
export const UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL = 'UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL';

// Serial Code Actions
export const SERIAL_CODES_LOAD_STARTED = 'SERIAL_CODES_LOAD_STARTED';
export const SERIAL_CODES_LOAD_SUCCESSFUL = 'SERIAL_CODES_LOAD_SUCCESSFUL';
export const SERIAL_CODES_LOAD_FAILED = 'SERIAL_CODES_LOAD_FAILED';

// Transit Route Parameters Actions
export const TRANSIT_ROUTE_PARAMETERS_LOAD_STARTED = 'TRANSIT_ROUTE_PARAMETERS_LOAD_STARTED';
export const TRANSIT_ROUTE_PARAMETERS_LOAD_SUCCESSFUL = 'TRANSIT_ROUTE_PARAMETERS_LOAD_SUCCESSFUL';
export const TRANSIT_ROUTE_PARAMETERS_LOAD_FAILED = 'TRANSIT_ROUTE_PARAMETERS_LOAD_FAILED';
export const TRANSIT_ROUTE_PARAMETERS_EDIT_FAILED = 'TRANSIT_ROUTE_PARAMETERS_EDIT_FAILED';

// Visual Parameters Actions
export const VISUAL_PARAMETERS_LOAD_STARTED = 'VISUAL_PARAMETERS_LOAD_STARTED';
export const VISUAL_PARAMETERS_LOAD_SUCCESSFUL = 'VISUAL_PARAMETERS_LOAD_SUCCESSFUL';
export const VISUAL_PARAMETERS_LOAD_FAILED = 'VISUAL_PARAMETERS_LOAD_FAILED';
export const VISUAL_PARAMETERS_EDIT_FAILED = 'VISUAL_PARAMETERS_EDIT_FAILED';

// Transit Route Parameters Audit Actions
export const TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_STARTED = 'TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_STARTED';
export const TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL = 'TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL';
export const TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_FAILED = 'TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_FAILED';
