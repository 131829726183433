// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import formModalStyles from '../../components/forms/FormModal.module.scss';
import VisualParametersForm from '../../components/forms/VisualParameters/VisualParameters';

type Props = {};
class VisualParametersScreen extends Component<Props> {
  componentDidMount() {}
  render() {
    return (
      <div className={formModalStyles.modalForm}>
        <VisualParametersForm />
      </div>
    );
  }
}
export const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(VisualParametersScreen);

export { VisualParametersScreen as PureVisualParametersScreen };
