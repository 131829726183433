// @flow

import {
  ORGANISATION_DETAILS_LOAD_STARTED,
  ORGANISATION_DETAILS_LOAD_SUCCESSFUL,
  ORGANISATION_DETAILS_LOAD_FAILED,
  RESET_ORGANISATION_DETAILS,
  EXPORT_DATA_LOADING,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_FAILURE,
} from './actionTypes';

import type { Role } from '../types/RoleType';
import type { OrganisationMailbox } from '../types/Mailbox';

export type LoadOrganisationDetailsResponse = {
  data: {
    _embedded: {
      profiles: Array<Role>,
      mailboxes: Array<OrganisationMailbox>,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

type LoadStartedAction = { type: typeof ORGANISATION_DETAILS_LOAD_STARTED };
type LoadSuccessAction = {
  type: typeof ORGANISATION_DETAILS_LOAD_SUCCESSFUL,
  payload: LoadOrganisationDetailsResponse,
};
type LoadFailedAction = { type: typeof ORGANISATION_DETAILS_LOAD_FAILED, error: string };
type ResetAction = { type: typeof RESET_ORGANISATION_DETAILS };

export type OrganisationDetailsAction = LoadStartedAction | LoadSuccessAction | LoadFailedAction | ResetAction;

export function loadOrganisationDetailsStarted(): LoadStartedAction {
  return {
    type: ORGANISATION_DETAILS_LOAD_STARTED,
  };
}

export function exportDataLoading() {
  return {
    type: EXPORT_DATA_LOADING,
  };
}

export function exportDataSuccess() {
  return {
    type: EXPORT_DATA_SUCCESS,
  };
}
export function exportDataFailure() {
  return {
    type: EXPORT_DATA_FAILURE,
  };
}

export function loadOrganisationDetailsSuccessful(response: LoadOrganisationDetailsResponse): LoadSuccessAction {
  return {
    type: ORGANISATION_DETAILS_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadOrganisationDetailsFailed(error: string): LoadFailedAction {
  return {
    type: ORGANISATION_DETAILS_LOAD_FAILED,
    error,
  };
}

export function resetOrganisationDetailsAction(): ResetAction {
  return {
    type: RESET_ORGANISATION_DETAILS,
  };
}
