// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import noop from 'lodash/noop';
import { FormBuilder } from '@nats/webclient-common';

import { FORM_MODE } from './getFormFromType';
import formModalStyles from './FormModal.module.scss';

import type { State as Store } from '../../types/ReduxStateType';
import type { FieldDefinitions } from '../../types/FormBuilderTypes';
import type { SoapEndpoint as SoapEndpointType } from '../../types/SoapType';
import type { FormMode } from './getFormFromType';

type Props = {
  selectedRecord: ?SoapEndpointType,
  mode: FormMode,
};

class SoapEndpoint extends Component<Props> {
  fields: FieldDefinitions;

  constructor(props: Props) {
    super(props);

    this.fields = [
      {
        type: 'checkbox',
        name: 'enabled',
        checkboxText: 'Enabled',
        shouldWrapTooltip: true,
        isLabelInline: true,
        initialValue: true,
        initialValueFrom: {
          path: 'currentState',
          withTransformation: (value: string) => value !== 'DISABLED',
        },
        cols: 12,
        isDisabled: true,
      },
      {
        name: 'endpoint',
        type: 'text',
        label: 'Endpoint',
        initialValueFrom: 'name',
        cols: 12,
      },
      {
        name: 'request',
        type: 'text',
        label: 'Last EAMS Request',
        initialValueFrom: {
          path: 'lastEamsRequest',
          withTransformation: dateString => moment.utc(dateString).format('YY.MM.DD HH:mm:ss'),
        },
        cols: 12,
      },
      {
        name: 'status',
        type: 'text',
        label: 'Status',
        initialValueFrom: 'status',
        cols: 12,
      },
    ];
  }

  render() {
    return (
      <div
        key={this.props.selectedRecord ? this.props.selectedRecord.name : null}
        className={formModalStyles.modalForm}
      >
        <FormBuilder
          isComposing={this.props.mode !== FORM_MODE.VIEW}
          name="soapEndpoint"
          fields={this.fields}
          initialValues={this.props.selectedRecord}
          onSubmit={noop}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  selectedRecord: state.app.selectedRecord,
});

export { SoapEndpoint as PureSoapEndpoint };

export default connect(mapStateToProps, null)(SoapEndpoint);
