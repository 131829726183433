// @flow

import type { LoadSerialCodesResponse } from '~/types/OrganisationSerialCodeTypes';
import { SERIAL_CODES_LOAD_FAILED, SERIAL_CODES_LOAD_STARTED, SERIAL_CODES_LOAD_SUCCESSFUL } from './actionTypes';

export type LoadSerialCodesStartedAction = {
  type: typeof SERIAL_CODES_LOAD_STARTED,
};

export type LoadSerialCodesSuccessAction = {
  type: typeof SERIAL_CODES_LOAD_SUCCESSFUL,
  payload: LoadSerialCodesResponse,
};

export type LoadSerialCodesFailedAction = {
  type: typeof SERIAL_CODES_LOAD_FAILED,
  error: Error,
};

export type SerialCodeAction =
  | LoadSerialCodesStartedAction
  | LoadSerialCodesSuccessAction
  | LoadSerialCodesFailedAction;

export function loadSerialCodesStarted(): LoadSerialCodesStartedAction {
  return {
    type: SERIAL_CODES_LOAD_STARTED,
  };
}

export function loadSerialCodesSuccessful(response: LoadSerialCodesResponse): LoadSerialCodesSuccessAction {
  return {
    type: SERIAL_CODES_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadSerialCodesFailed(error: Error): LoadSerialCodesFailedAction {
  return {
    type: SERIAL_CODES_LOAD_FAILED,
    error,
  };
}
