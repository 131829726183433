// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable, Notification } from '@nats/webclient-common';
import { applicationTypes, applicationTypeDisplay } from '@nats/nats-service-sdk/lib/types/Applications';
import noop from 'lodash/noop';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { loadOrganisations } from '../../api/organisationApi';

import { selectPanel } from '../../actions/panelActions';
import { setPageTitle, setSelectedRecord } from '../../actions/appActions';
import getErrorMessageForErrorCode from '../../utilities/errorCodeMapping';

import type { State } from '../../types/ReduxStateType';
import type { Organisation, OrganisationDataTableRecord } from '../../types/Organisation';

import type { FormCode, FormMode } from '../../components/forms/getFormFromType';
import { FORM_CODE, FORM_MODE } from '../../components/forms/getFormFromType';
import type { OrganisationResponseType } from '../../api/organisationApi';
import { setPageHeaderControls } from '../../actions/headerControlActions';
import { setModalForm, toggleModalVisible, setModal } from '../../actions/modalActions';
import { canEditOrganisation } from '../../utilities/permissions';
import type { PageHeaderControls } from '../../types/state';
import ExportOrganisationsView from './ExportOrganisationsView';

type TableColumn = {
  Header: string | Function,
  id: string,
  accessor: Organisation => string,
};

type Props = {
  isFetchingDetails: boolean,
  organisations: {
    organisations: Array<Organisation>,
    error: string | null,
    isBusy: boolean,
  },
  setPageTitle: Function,
  loadOrganisations: void => Promise<OrganisationResponseType>,
  selectedRecord: Organisation,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: Organisation => mixed,
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModalForm: (FormCode, FormMode, {}) => void,
};

type LocalState = {
  permissionErrorNotificationId: string | null,
};

const getOrganisationApplicationStats = (organisation: Organisation, appKey: string): string => {
  const { applications: orgApps } = organisation;

  const application = orgApps.find(app => app.name === appKey);
  const allocated = application && application.allocated_users ? application.allocated_users : 0;
  const max = application && application.max_users ? application.max_users : 0;

  return `${allocated} / ${max}`;
};

const getApplicationColumn = (appKey: string): TableColumn => ({
  Header: () => (
    <>
      <span>{applicationTypeDisplay[appKey]} licences</span>
      <span style={{ display: 'block' }} />
      <span>(used/total)</span>
    </>
  ),
  id: `${applicationTypes[appKey].toLowerCase()}Licences`,
  accessor: (o: Organisation) => getOrganisationApplicationStats(o, appKey),
});

const getApplicationTableColumns = (): Array<TableColumn> =>
  sortBy(Object.keys(applicationTypes)).map(getApplicationColumn);

class OrganisationScreen extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      permissionErrorNotificationId: null,
    };
  }

  async componentDidMount() {
    this.props.setPageTitle('Organisations');
    this.setSubheaderControls();

    try {
      await this.props.loadOrganisations();
    } catch (error) {
      const notificationId = Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ permissionErrorNotificationId: notificationId });
    }
  }

  clearPermissionErrorNotificationIfSet = () => {
    if (this.state.permissionErrorNotificationId) {
      Notification.dismiss(this.state.permissionErrorNotificationId);
      this.setState({ permissionErrorNotificationId: null });
    }
  };

  componentWillUnmount() {
    this.clearPermissionErrorNotificationIfSet();
  }

  handleClickEdit = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.toggleModalVisible();
    this.props.setModalForm(FORM_CODE.ORGANISATION, FORM_MODE.EDIT, {
      name: selectedRecord.name,
    });
  };

  setSubheaderControls = () => {
    const { selectedRecord, organisations } = this.props;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      isDisabled: organisations.isBusy || organisations.error !== null || organisations.organisations.length === 0,
      component: ExportOrganisationsView,
    };

    const editControls = {
      name: 'Edit',
      icon: ['fab', 'wpforms'],
      onClick: this.handleClickEdit,
      isDisabled: !canEditOrganisation(),
    };

    this.props.setPageHeaderControls(selectedRecord ? [[exportControls, editControls]] : [[exportControls]]);
  };

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.selectedRecord !== prevProps.selectedRecord ||
      this.props.organisations.organisations !== prevProps.organisations.organisations
    ) {
      this.setSubheaderControls();
    }
  }

  selectRow = (organisationDataRecord: OrganisationDataTableRecord) => {
    if (!organisationDataRecord) return;

    const { _original: organisation } = organisationDataRecord;

    this.props.setSelectedRecord(organisation);

    const { name } = organisation;
    this.props.selectPanel(FORM_CODE.ORGANISATION, {
      isComposing: false,
      name,
    });
  };

  isRowSelected = (id: string): boolean => !!(this.props.selectedRecord && this.props.selectedRecord.id === id);

  render() {
    const tableColumns = [
      {
        Header: 'Organisation',
        id: 'organisationName',
        accessor: (o: Organisation) => o.name,
      },
      ...getApplicationTableColumns(),
    ];

    return (
      <DataTable
        columns={tableColumns}
        tableData={this.props.organisations.organisations}
        isLoading={this.props.organisations.isBusy || this.props.isFetchingDetails}
        sortable={false}
        filterable={false}
        viewState={{ pageNumber: 1, pageCount: 1 }}
        updatePageNumber={noop}
        updateFilterModel={noop}
        onRowSelected={this.selectRow}
        getTdClass={(state, rowInfo) => `${this.isRowSelected(rowInfo.original.id) ? 'selectedRow' : ''}`}
      />
    );
  }
}

export const mapStateToProps = (state: State) => ({
  organisations: state.organisationState,
  pageTitle: state.app.title,
  selectedRecord: state.app.selectedRecord,
  isFetchingDetails: state.organisationDetailsState.isBusy,
});

export const mapDispatchToProps = {
  setPageTitle,
  loadOrganisations,
  selectPanel,
  setSelectedRecord,
  setPageHeaderControls,
  toggleModalVisible,
  setModalForm,
  setModal,
};

export { OrganisationScreen as PureOrganisationScreen };
export default connect(mapStateToProps, mapDispatchToProps)(OrganisationScreen);
