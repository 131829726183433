// @flow
import React, { Component, type Node } from 'react';
import get from 'lodash/get';
import moment from 'moment';
import { connect } from 'react-redux';
import { DataTable, FilterInput, Notification } from '@nats/webclient-common';
import { withRouter } from 'react-router-dom';

import { loadMailboxes as loadMailboxesApi } from '~/api/mailboxApi';

import { setPageTitle as setPageTitleAction, setSelectedRecord as setSelectedRecordAction } from '~/actions/appActions';
import { selectPanelCustom as selectPanelCustomAction, closePanel } from '~/actions/panelActions';
import { setPageHeaderControls } from '~/actions/headerControlActions';
import { setModal, setModalForm, toggleModalVisible } from '~/actions/modalActions';

import getErrorMessageForErrorCode from '~/utilities/errorCodeMapping';
import { exportToCSV } from '~/utilities/exportToCSV';
import type { Mailbox, MailboxScreenProps, MailboxScreenState, MailboxRecord } from '~/types/Mailbox';
import type { State as Store } from '~/types/ReduxStateType';
import type { PageHeaderControls } from '~/types/state';
import { canEditMessageMailbox } from '~/utilities/permissions';
import { FORM_CODE, FORM_MODE } from '~/components/forms/getFormFromType';

import MailboxView from './MailboxView';

class MailboxScreen extends Component<MailboxScreenProps, MailboxScreenState> {
  columns: Array<{
    Header: Node,
    id: string,
    accessor: Mailbox => string,
    Filter: ({}) => Node,
    headerStyle?: {},
    filterMethod?: Function,
  }>;

  constructor(props: MailboxScreenProps) {
    super(props);

    const columnMap: {
      Header: string,
      property: string,
      headerStyle?: {},
    }[] = [
      { Header: 'Address', property: 'address' },
      { Header: 'Name', property: 'description' },
      { Header: 'Organisation', property: 'organisationName' },
      { Header: 'Monitored', property: 'isMonitored', headerStyle: { whiteSpace: 'pre-wrap' } },
    ];

    this.columns = columnMap.map(({ Header, property, headerStyle }) => ({
      Header,
      id: Header.toLowerCase(),
      accessor: (mailbox: Mailbox) => get(mailbox, property, 'Unknown'),
      Filter: (filter: Object) => this.getFilterInput(filter, Header.toLowerCase(), this.props.location.search),
      filterMethod: (filter, row) => row[Header.toLowerCase()].toUpperCase().includes(filter.value.toUpperCase()),
      headerStyle: headerStyle || undefined,
    }));

    const isMonitoredColumn = this.columns.find(column => column.Header === 'Monitored');
    if (isMonitoredColumn != null) {
      isMonitoredColumn.filterMethod = (filter, row) =>
        row.monitored.toUpperCase().startsWith(filter.value.toUpperCase());
    }

    this.state = {
      permissionErrorNotificationId: null,
      getTableData: null,
      clearFilterInputs: [],
    };
  }

  componentDidMount() {
    this.props.setPageTitle('Message Mailboxes');

    this.setPageHeaderControls();

    this.getData();
  }

  componentWillUnmount() {
    this.clearErrorNotification();
  }

  handleClickEdit = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.toggleModalVisible();
    this.props.setModalForm(FORM_CODE.MESSAGE_MAILBOX, FORM_MODE.EDIT, {
      name: selectedRecord.address,
    });
  };

  exportViewClickHandler = () => {
    const { pageTitle } = this.props;
    const { getTableData } = this.state;

    if (getTableData) {
      const exportData = getTableData();

      exportToCSV(pageTitle, exportData);
    }
  };

  getSubheaderControls = (): PageHeaderControls => {
    const {
      mailboxState: { isBusy: isLoadingMailboxes },
      selectedRecord,
    } = this.props;
    const { getTableData, permissionErrorNotificationId } = this.state;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled: isLoadingMailboxes || !!permissionErrorNotificationId || !getTableData || !getTableData().length,
    };

    const editControls = {
      name: 'Edit',
      icon: ['fab', 'wpforms'],
      onClick: this.handleClickEdit,
      isDisabled: !canEditMessageMailbox(),
    };

    return selectedRecord ? [[exportControls, editControls]] : [[exportControls]];
  };

  componentDidUpdate() {
    this.setPageHeaderControls();
  }

  setPageHeaderControls = () => {
    const controls = this.getSubheaderControls();
    this.props.setPageHeaderControls(controls);
  };

  getFilterInput = (filter: Object, name: string, search: string) => {
    const attrs = {
      name,
      search,
      maxLength: 100,
    };

    return (
      <FilterInput
        onChange={value => filter.onChange(value)}
        setClearFilterFunction={this.setClearFilterFunctions}
        {...{ name, attrs }}
      />
    );
  };

  onRowSelected = (mailbox: MailboxRecord) => {
    if (!mailbox) {
      return;
    }

    const { address, _original } = mailbox;

    this.props.setSelectedRecord(_original);

    this.props.selectPanelCustom(address, <MailboxView address={address} />, {
      isComposing: false,
      address,
    });
  };

  isRowSelected = (id: string): boolean => !!this.props.selectedRecord && this.props.selectedRecord.id === id;

  clearErrorNotification = () => {
    const { permissionErrorNotificationId } = this.state;

    if (permissionErrorNotificationId) {
      Notification.dismiss(permissionErrorNotificationId);

      this.setState({
        permissionErrorNotificationId: null,
      });
    }
  };

  getData = async (): Promise<void> => {
    try {
      await this.props.loadMailboxes();
    } catch (error) {
      // This is only needed here because in order to reload mailboxes you need to refresh the page
      this.clearErrorNotification();

      const notificationId = Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });

      this.setState({ permissionErrorNotificationId: notificationId });
    }
    this.setPageHeaderControls();
  };

  setDataExporterFunction = (getTableData: Function) => {
    if (!this.state.getTableData) {
      this.setState({ getTableData });
    }
  };

  setClearFilterFunctions = (clearFilter: Function) => {
    const { clearFilterInputs } = this.state;
    clearFilterInputs.push(clearFilter);
    this.setState({ clearFilterInputs });
  };

  clearFilters = () => {
    const { clearFilterInputs } = this.state;
    clearFilterInputs.forEach(fn => fn());
  };

  render() {
    const {
      mailboxState: { mailboxes, isBusy: isLoadingMailboxes },
      timeCreated,
    } = this.props;
    const { permissionErrorNotificationId } = this.state;
    const columns = [...this.columns];

    if (timeCreated) {
      const monitorColumn = columns.find(column => column.Header === 'Monitored');
      if (monitorColumn) {
        const monitorColumnIndex = columns.indexOf(monitorColumn);
        const newMonitorColumn = {
          ...monitorColumn,
          Header: `Monitored\r\nas of ${moment.utc(timeCreated).format('YY.MM.DD HH:mm')}`,
        };
        columns[monitorColumnIndex] = newMonitorColumn;
      }
    }

    return (
      <div>
        <DataTable
          setDataExporter={this.setDataExporterFunction}
          isClientSide
          sortable={!permissionErrorNotificationId}
          filterable={!permissionErrorNotificationId}
          defaultSorted={[{ id: 'address', desc: false }]}
          defaultPageSize={40}
          getTdClass={(state, rowInfo) => (this.isRowSelected(rowInfo.original.id) ? 'selectedRow' : '')}
          onRowSelected={this.onRowSelected}
          columns={columns}
          isLoading={isLoadingMailboxes}
          tableData={mailboxes}
          onClientSideFilterChange={this.setPageHeaderControls}
          clearClientSideFilters={this.clearFilters}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => {
  return {
    pageTitle: state.app.title,
    mailboxState: state.mailboxState,
    selectedRecord: state.app.selectedRecord,
    timeCreated: state.mailboxState.timeCreated,
  };
};

export const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  selectPanelCustom: selectPanelCustomAction,
  closePanel,
  loadMailboxes: loadMailboxesApi,
  setSelectedRecord: setSelectedRecordAction,
  setPageHeaderControls,
  toggleModalVisible,
  setModalForm,
  setModal,
};

export { MailboxScreen as PureMailboxScreen };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MailboxScreen));
