// @flow

import {
  USER_DETAILS_LOAD_STARTED,
  USER_DETAILS_LOAD_SUCCESSFUL,
  USER_DETAILS_LOAD_FAILED,
  RESET_USER_DETAILS,
} from '../actions/actionTypes';

import type { UserDetailsState } from '../types/state/index';
import type { UserDetailsAction } from '../actions/userDetailsActions';

const initialState: UserDetailsState = {
  isBusy: false,
  userDetails: {
    profile: null,
    mailboxes: [],
  },
  error: null,
};

export default function userDetailsReducer(
  state: UserDetailsState = initialState,
  action: UserDetailsAction
): UserDetailsState {
  switch (action.type) {
    case USER_DETAILS_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
      };
    case USER_DETAILS_LOAD_SUCCESSFUL:
      return {
        ...state,
        isBusy: false,
        userDetails: action.payload.data._embedded.userDetails,
        error: null,
      };
    case USER_DETAILS_LOAD_FAILED:
      return {
        ...initialState,
        error: action.error,
      };
    case RESET_USER_DETAILS:
      return initialState;
    default:
      return state;
  }
}
