// @flow

import { resetUserDetailsAction } from './userDetailsActions';
import { resetOrganisationDetailsAction } from './organisationDetailsActions';

import type { ThunkAction } from '../types/ReduxActionTypes';

export function resetUserFormState(): ThunkAction {
  return async function action(dispatch: Function) {
    dispatch(resetUserDetailsAction());
    dispatch(resetOrganisationDetailsAction());
  };
}
