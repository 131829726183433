// @flow
import {
  REFERENCE_DATA_LOAD_FAILED,
  REFERENCE_DATA_LOAD_STARTED,
  REFERENCE_DATA_LOAD_SUCCESSFUL,
} from '../actions/actionTypes';

import type { ReferenceDataState } from '../types/state';
import type { ReferenceDataAction } from '../actions/referenceDataActions';

export const initialState: ReferenceDataState = {
  isBusy: false,
  error: null,
  referenceData: [],
};

export default function referenceDataReducer(
  state: ReferenceDataState = initialState,
  action: ReferenceDataAction
): ReferenceDataState {
  switch (action.type) {
    case REFERENCE_DATA_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case REFERENCE_DATA_LOAD_SUCCESSFUL: {
      return {
        ...state,
        isBusy: false,
        error: null,
        referenceData: action.payload.data._embedded.referenceData,
      };
    }
    case REFERENCE_DATA_LOAD_FAILED:
      return {
        ...state,
        isBusy: false,
        error: action.error,
      };
    default:
      return state;
  }
}
