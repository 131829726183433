// @flow

import {
  VISUAL_PARAMETERS_LOAD_STARTED,
  VISUAL_PARAMETERS_LOAD_SUCCESSFUL,
  VISUAL_PARAMETERS_LOAD_FAILED,
  VISUAL_PARAMETERS_EDIT_FAILED,
} from '../actions/actionTypes';

import type { VisualParametersAction } from '../types/VisualParameterType';

import type { VisualParametersState } from '../types/state';

const initialState: VisualParametersState = {
  isBusy: false,
  error: null,
};

export default function visualParametersReducer(
  state: VisualParametersState = initialState,
  action: VisualParametersAction
): VisualParametersState {
  switch (action.type) {
    case VISUAL_PARAMETERS_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
        isFetchError: null,
      };
    case VISUAL_PARAMETERS_LOAD_FAILED: {
      const { error } = action;
      return { ...state, isBusy: false, error, isFetchError: true };
    }
    case VISUAL_PARAMETERS_EDIT_FAILED: {
      const { error } = action;
      return { ...state, isBusy: false, error, isFetchError: false };
    }
    case VISUAL_PARAMETERS_LOAD_SUCCESSFUL: {
      const { visualParameters } = action.payload.data._embedded;
      return {
        ...state,
        isBusy: false,
        error: null,
        isFetchError: null,
        ...visualParameters,
      };
    }
    default:
      return state;
  }
}
