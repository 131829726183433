// @flow
import get from 'lodash/get';

import { toggleModalVisible } from '~/actions/modalActions';
import environment from '../environment';
import { apiGet } from './api';
import {
  loadMessageParametersAuditStarted,
  loadMessageParametersAuditSuccessful,
  loadMessageParametersAuditFailed,
} from '../actions/messageParametersAuditActions';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { LoadMessageParametersAuditResponse } from '../types/MessageParameterAuditTypes';

export const MESSAGE_PARAMETERS_AUDIT_URL = `${environment.messagesServiceUrl}/api/v1/message-parameters-audit`;

export function loadMessageParametersAudit(fromDate: string, toDate: string): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve Message Parameters audit; config not provided');
    }

    dispatch(loadMessageParametersAuditStarted());

    return apiGet(MESSAGE_PARAMETERS_AUDIT_URL, { fromDate, toDate })
      .then((response: LoadMessageParametersAuditResponse) => {
        dispatch(loadMessageParametersAuditSuccessful(response));
        dispatch(toggleModalVisible());
      })
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(loadMessageParametersAuditFailed(errorCode));
      });
  };
}
