// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import { DataTable, Notification } from '@nats/webclient-common';

import { setPageTitle, setSelectedRecord } from '../actions/appActions';
import { selectPanel } from '../actions/panelActions';
import { loadLoginsByOrganisation } from '../api/loginsByOrganisationApi';
import { FORM_CODE } from '../components/forms/getFormFromType';

import type { State as Store } from '../types/ReduxStateType';
import type { FormCode } from '../components/forms/getFormFromType';
import type { LoginsByOrganisationState } from '../types/state/LoginsByOrganisation';
import type { LoginsByOrganisationTableRecord, LoginsByOrganisationType } from '../types/LoginsByOrganisationType';
import type { PageHeaderControls } from '../types/state';
import { setPageHeaderControls } from '../actions/headerControlActions';
import { formatDisplayDate } from '../utilities/format';
import type { LoginsByOrganisationUpdateSortModelAction } from '../actions/loginsByOrganisationActions';
import type { Sort } from '../types/TableTypes';
import { updateLoginsByOrganisationSortModel } from '../actions/loginsByOrganisationActions';
import mapLoginsByOrganisation from '../utilities/csvMappers/loginsByOrganisationMapper';
import { exportToCSV } from '../utilities/exportToCSV';

type Props = {
  loginsByOrganisation: LoginsByOrganisationState,
  selectedRecord: ?LoginsByOrganisationType,
  setPageTitle: string => void,
  error: boolean,
  loadLoginsByOrganisation: () => mixed,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: LoginsByOrganisationType => mixed,
  setPageHeaderControls: PageHeaderControls => void,
  updateLoginsByOrganisationSortModel: (Array<Sort>) => LoginsByOrganisationUpdateSortModelAction,
};

class LoginsByOrganisationScreen extends Component<Props> {
  tableColumns = [
    {
      id: 'username',
      Header: 'Username',
      accessor: ({ username }: LoginsByOrganisationType): string => username,
    },
    {
      id: 'organisation',
      Header: 'Organisation',
      accessor: ({ organisation }: LoginsByOrganisationType): string | null => organisation,
    },
    {
      id: 'loginCount',
      Header: 'Login Count (last 30 days)',
      accessor: ({ loginCount }: LoginsByOrganisationType): string => loginCount,
    },
    {
      id: 'loginTime',
      Header: 'Last Login',
      accessor: ({ loginTime }: LoginsByOrganisationType): string | null => formatDisplayDate(loginTime),
    },
  ];

  componentDidMount() {
    this.props.setPageTitle('Logins By Organisation');
    this.setSubheaderControls();
  }

  componentWillUnmount() {
    this.props.setPageHeaderControls([]);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.error && this.props.error !== prevProps.error) {
      Notification.error('Logins By Organisation data could not be loaded.');
    }
    if (this.props.loginsByOrganisation !== prevProps.loginsByOrganisation) {
      this.setSubheaderControls();
    }
  }

  selectRow = (loginsByOrganisationRecord: LoginsByOrganisationTableRecord) => {
    if (!loginsByOrganisationRecord) {
      return;
    }

    const { _original: loginsByOrganisation } = loginsByOrganisationRecord;
    const { username } = loginsByOrganisation;

    this.props.setSelectedRecord(loginsByOrganisation);

    this.props.selectPanel(FORM_CODE.LOGINS_BY_ORGANISATION, { username });
  };

  isRowSelected = (username: string): boolean =>
    !!this.props.selectedRecord && this.props.selectedRecord.username === username;

  getLogins = () => {
    this.props.loadLoginsByOrganisation();
  };

  exportViewClickHandler = () => {
    const { loginsByOrganisation } = this.props;
    const csvFileName = 'Logins_By_Org';
    exportToCSV(csvFileName, mapLoginsByOrganisation(loginsByOrganisation.loginsByOrganisation));
  };

  setSubheaderControls() {
    const { loginsByOrganisation, error } = this.props;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled: loginsByOrganisation.loginsByOrganisation.length === 0 || error,
    };

    this.props.setPageHeaderControls([[exportControls]]);
  }

  render() {
    const { loginsByOrganisation } = this.props;

    return (
      <DataTable
        columns={this.tableColumns}
        tableData={loginsByOrganisation.loginsByOrganisation}
        isLoading={loginsByOrganisation.isBusy}
        sortable
        filterable={false}
        updateSortModel={this.props.updateLoginsByOrganisationSortModel}
        getData={this.getLogins}
        viewState={loginsByOrganisation.viewState}
        updateFilterModel={noop}
        updatePageNumber={noop}
        onRowSelected={this.selectRow}
        getTdClass={(state, rowInfo) => (this.isRowSelected(rowInfo.original.username) ? 'selectedRow' : '')}
      />
    );
  }
}

const mapStateToProps = (state: Store) => ({
  selectedRecord: state.app.selectedRecord,
  loginsByOrganisation: state.loginsByOrganisationState,
  error: state.loginsByOrganisationState.error,
});

export const mapDispatchToProps = {
  loadLoginsByOrganisation,
  setPageTitle,
  setSelectedRecord,
  selectPanel,
  setPageHeaderControls,
  updateLoginsByOrganisationSortModel,
};

export { LoginsByOrganisationScreen as PureLoginsByOrganisationScreen };

export default connect(mapStateToProps, mapDispatchToProps)(LoginsByOrganisationScreen);
