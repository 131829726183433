// @flow
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import { ConfirmModal } from '../../confirmModal/confirmModal';
import type { FeatureToggle } from '../../../types/FeatureTogglesTypes';
import { updateFeatureToggles as updateFeatureTogglesAction } from '../../../api/FeatureTogglesApi';
import { toggleModalVisible as toggleModalVisibleAction } from '../../../actions/modalActions';
import type { State as ReduxState } from '../../../types/ReduxStateType';

type Props = {
  featureToggle: FeatureToggle,
  toggleModalVisibility: Function,
  updateFeatureToggles: Function,
  updating: boolean,
};

export function ConfirmationModal({ featureToggle, updating, toggleModalVisibility, updateFeatureToggles }: Props) {
  const onOrOff = featureToggle.enabled ? 'OFF' : 'ON';

  function onConfirm() {
    updateFeatureToggles({
      featureToggles: [{ id: featureToggle.id, enabled: !featureToggle.enabled }],
    });
  }

  return (
    <ConfirmModal
      isLoading={updating}
      errorMessage={null} // errors handled by parent component
      confirmationMessage={`Do you want to toggle this feature ${onOrOff}?`}
      onConfirm={onConfirm}
      onCancel={toggleModalVisibility}
    />
  );
}

function mapStateToProps({ featureTogglesState }: ReduxState) {
  return { updating: featureTogglesState.formLoading };
}

const mapDispatchToProps = {
  updateFeatureToggles: updateFeatureTogglesAction,
  toggleModalVisibility: toggleModalVisibleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
