// @flow
import {
  LOGINS_BY_ORGANISATION_LOAD_FAILED,
  LOGINS_BY_ORGANISATION_LOAD_STARTED,
  LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL,
  UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL,
} from './actionTypes';

import type { LoadLoginsByOrganisationResponse } from '../types/LoginsByOrganisationType';
import type { Sort } from '../types/TableTypes';

type LoadStartedAction = {
  type: typeof LOGINS_BY_ORGANISATION_LOAD_STARTED,
};

type LoadSuccessAction = {
  type: typeof LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL,
  payload: LoadLoginsByOrganisationResponse,
};

type LoadFailedAction = {
  type: typeof LOGINS_BY_ORGANISATION_LOAD_FAILED,
  error: string,
};

export type LoginsByOrganisationAction = LoadStartedAction | LoadSuccessAction | LoadFailedAction;

export function loadLoginsByOrganisationStarted(): LoadStartedAction {
  return {
    type: LOGINS_BY_ORGANISATION_LOAD_STARTED,
  };
}

export function loadLoginsByOrganisationSuccessful(response: LoadLoginsByOrganisationResponse): LoadSuccessAction {
  return {
    type: LOGINS_BY_ORGANISATION_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadLoginsByOrganisationFailed(error: string): LoadFailedAction {
  return {
    type: LOGINS_BY_ORGANISATION_LOAD_FAILED,
    error,
  };
}

export type LoginsByOrganisationUpdateSortModelAction = {
  type: typeof UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL,
  payload: {
    newSortModel: Array<Sort>,
  },
};

export function updateLoginsByOrganisationSortModel(
  newSortModel: Array<Sort>
): LoginsByOrganisationUpdateSortModelAction {
  return {
    type: UPDATE_LOGINS_BY_ORGANISATION_SORT_MODEL,
    payload: {
      newSortModel,
    },
  };
}
