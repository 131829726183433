// @flow
import sortBy from 'lodash/sortBy';
import type { Users, MappedUsers, MailboxByUserResponse, MappedMailboxesByUser, UserProfiles } from '../../types/User';

const userMapper = (userData: Array<Users>, usersProfileData: Array<UserProfiles>): Array<MappedUsers> => {
  const expandedUserData = userData.map(user => ({
    ...user,
    ...usersProfileData.find(userProfiles => userProfiles.userId === user.id),
  }));

  return expandedUserData.map(user => ({
    username: user.username,
    organisation: user.organisation,
    type: user.type,
    licences: user.applications.join('/'),
    profile: user.profile ? user.profile : '',
    email: user.email,
  }));
};

const mailboxesByUserMapper = (
  mailboxesByUserData: Array<MailboxByUserResponse>,
  userList: Array<Users>,
  sortDescending: boolean
): Array<MappedMailboxesByUser> => {
  const mappedMailboxByUser = [];
  mailboxesByUserData.forEach(mailboxArrayByUser => {
    userList.forEach(user => {
      if (mailboxArrayByUser.userId === user.id && mailboxArrayByUser.mailboxAddressList) {
        const mailboxArrayForUser = mailboxArrayByUser.mailboxAddressList.map(mailboxName => ({
          username: user.username,
          mailbox: mailboxName,
        }));
        mappedMailboxByUser.push(...mailboxArrayForUser);
      }
    });
  });

  return sortDescending
    ? sortBy(mappedMailboxByUser, [userMailbox => userMailbox.username.toLowerCase()]).reverse()
    : sortBy(mappedMailboxByUser, [userMailbox => userMailbox.username.toLowerCase()]);
};

const userMapperModule = {
  userMapper,
  mailboxesByUserMapper,
};

export default userMapperModule;
