// @flow

import {
  MESSAGES_FACADE_LOAD_STARTED,
  MESSAGES_FACADE_LOAD_SUCCESSFUL,
  MESSAGES_FACADE_LOAD_FAILED,
} from './actionTypes';
import type { LoadMessageFacadeResponse } from '../api/messageFacadeApi';

type loadStartedAction = { type: typeof MESSAGES_FACADE_LOAD_STARTED };
type loadSuccessAction = {
  type: typeof MESSAGES_FACADE_LOAD_SUCCESSFUL,
  payload: LoadMessageFacadeResponse,
};

type LoadMessageFacadeFailed = { type: typeof MESSAGES_FACADE_LOAD_FAILED };

export type MessageFacadeUserAction = loadStartedAction | loadSuccessAction | LoadMessageFacadeFailed;

export function loadMessageFacadeStarted(): loadStartedAction {
  return {
    type: MESSAGES_FACADE_LOAD_STARTED,
  };
}
export function loadMessageFacadeSuccessful(response: LoadMessageFacadeResponse): loadSuccessAction {
  return {
    type: MESSAGES_FACADE_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadMessageFacadeFailed(error: string): LoadMessageFacadeFailed {
  return {
    type: MESSAGES_FACADE_LOAD_FAILED,
    error,
  };
}
