// @flow
import get from 'lodash/get';

import {
  loadTransitRouteParametersStarted,
  loadTransitRouteParametersSuccessful,
  loadTransitRouteParametersFailed,
  editTransitRouteParametersSuccessful,
  editTransitRouteParametersFailed,
} from '../actions/transitRouteParametersActions';
import environment from '../environment';
import { apiGet, apiPost } from './api';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { LoadTransitRouteParametersResponse, TransitRouteParameters } from '../types/TransitRouteParameterTypes';

export const TRANSIT_ROUTE_PARAMETERS_URL = `${environment.transitRoutesUrl}/api/admin/transit-route-parameters`;

export function loadTransitRouteParameters(): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.transitRoutesUrl) {
      throw new Error('Unable to retrieve Transit Route Parameters; config not provided');
    }

    dispatch(loadTransitRouteParametersStarted());

    return apiGet(TRANSIT_ROUTE_PARAMETERS_URL)
      .then((response: LoadTransitRouteParametersResponse) => dispatch(loadTransitRouteParametersSuccessful(response)))
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(loadTransitRouteParametersFailed(errorCode));
      });
  };
}

export function editTransitRouteParameters(newTransitRouteParameters: TransitRouteParameters): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.transitRoutesUrl) {
      throw new Error('Unable to edit Transit Route Parameters; config not provided');
    }

    return apiPost(TRANSIT_ROUTE_PARAMETERS_URL, newTransitRouteParameters)
      .then(() => {
        editTransitRouteParametersSuccessful();
      })
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(editTransitRouteParametersFailed(errorCode));
      });
  };
}
