// @flow
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import styles from './FilePicker.module.scss';

type Props = {
  onFileReceive: (Array<File>) => mixed,
  text?: string,
};

export default class FilePicker extends Component<Props> {
  static defaultProps = {
    text: 'Drag a file here, or click to open file dialog',
  };

  onDrop = (acceptedFiles: Array<File>) => {
    this.props.onFileReceive(acceptedFiles);
  };

  render() {
    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div data-qa-id="dropzone" className={styles.dropzone} {...getRootProps()}>
            <input {...getInputProps({ multiple: false })} />
            <p data-qa-id="dropzoneText">{this.props.text}</p>
          </div>
        )}
      </Dropzone>
    );
  }
}
