// @flow

import {
  MESSAGES_FACADE_LOAD_STARTED,
  MESSAGES_FACADE_LOAD_SUCCESSFUL,
  MESSAGES_FACADE_LOAD_FAILED,
} from '../actions/actionTypes';
import type { MessageFacadeUsersState } from '../types/state/index';
import type { MessageFacadeUserAction } from '../actions/messageFacadeAction';

const initialState: MessageFacadeUsersState = {
  isBusy: false,
  messageFacadeUsers: [],
};

export default function messageFacadeReducer(
  state: MessageFacadeUsersState = initialState,
  action: MessageFacadeUserAction
): MessageFacadeUsersState {
  switch (action.type) {
    case MESSAGES_FACADE_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case MESSAGES_FACADE_LOAD_SUCCESSFUL: {
      return {
        ...state,
        isBusy: false,
        messageFacadeUsers: action.payload.data.records,
      };
    }
    case MESSAGES_FACADE_LOAD_FAILED:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
