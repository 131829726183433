// @flow

import { ORGANISATION_LOAD_FAILED, ORGANISATION_LOAD_STARTED, ORGANISATION_LOAD_SUCCESSFUL } from './actionTypes';
import type { Organisation } from '../types/Organisation';

type loadStartedAction = { type: typeof ORGANISATION_LOAD_STARTED };
type loadSuccessAction = { type: typeof ORGANISATION_LOAD_STARTED, organisationList: Array<Organisation> };
type loadFailedAction = { type: typeof ORGANISATION_LOAD_FAILED, error: string };

export type OrganisationAction = loadStartedAction | loadSuccessAction | loadFailedAction;

export function loadOrganisationsStarted(): loadStartedAction {
  return {
    type: ORGANISATION_LOAD_STARTED,
  };
}

export function loadOrganisationsSuccessful(organisationList: Array<Organisation>): loadSuccessAction {
  return {
    type: ORGANISATION_LOAD_SUCCESSFUL,
    organisationList,
  };
}

export function loadOrganisationsFailed(error: string): loadFailedAction {
  return {
    type: ORGANISATION_LOAD_FAILED,
    error,
  };
}
