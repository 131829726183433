// @flow

import React, { Component } from 'react';

import styles from './Menu.module.scss';
import menuStructure from './menu-data';
import type { MenuNodeType } from './MenuNode';
import MenuNode from './MenuNode';

type Props = {};

type LocalState = {
  menu: Array<MenuNodeType>,
};

export default class Menu extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menu: menuStructure.root,
    };
  }

  render() {
    return (
      <div className={styles.menu}>
        {this.state.menu.map((node: MenuNodeType) => (
          <MenuNode key={node.name} node={node} depth={0} />
        ))}
      </div>
    );
  }
}
