// @flow

import type { FeatureToggle } from '../types/FeatureTogglesTypes';

export const LOAD_FEATURE_TOGGLES_STARTED = 'LOAD_FEATURE_TOGGLES_STARTED';
export const LOAD_FEATURE_TOGGLES_SUCCESSFUL = 'LOAD_FEATURE_TOGGLES_SUCCESSFUL';
export const LOAD_FEATURE_TOGGLES_FAILED = 'LOAD_FEATURE_TOGGLES_FAILED';

export const UPDATE_FEATURE_TOGGLES_STARTED = 'UPDATE_FEATURE_TOGGLES_STARTED';
export const UPDATE_FEATURE_TOGGLES_SUCCESSFUL = 'UPDATE_FEATURE_TOGGLES_SUCCESSFUL';
export const UPDATE_FEATURE_TOGGLES_FAILED = 'UPDATE_FEATURE_TOGGLES_FAILED';
export const UPDATE_FEATURE_TOGGLES_ERROR_HANDLED = 'UPDATE_FEATURE_TOGGLES_ERROR_HANDLED';

type ErrorType = { error: string };
type StartedAction = { type: string };
type SuccessAction = { type: string, payload: Array<FeatureToggle> };
type FailedAction = { type: string, error: ErrorType };

export function loadFeatureTogglesStarted(): StartedAction {
  return { type: LOAD_FEATURE_TOGGLES_STARTED };
}

export function loadFeatureTogglesSuccessful(payload: Array<FeatureToggle>): SuccessAction {
  return { type: LOAD_FEATURE_TOGGLES_SUCCESSFUL, payload };
}

export function loadFeatureTogglesFailed(error: ErrorType): FailedAction {
  return { type: LOAD_FEATURE_TOGGLES_FAILED, error };
}

export function updateFeatureTogglesStarted(): StartedAction {
  return { type: UPDATE_FEATURE_TOGGLES_STARTED };
}

export function updateFeatureTogglesSuccessful(payload: Array<FeatureToggle>): SuccessAction {
  return { type: UPDATE_FEATURE_TOGGLES_SUCCESSFUL, payload };
}

export function updateFeatureTogglesFailed(error: ErrorType): FailedAction {
  return { type: UPDATE_FEATURE_TOGGLES_FAILED, error };
}
