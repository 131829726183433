// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Location } from 'react-router-dom';
import { ButtonLink } from '@nats/webclient-common';
import { hasPermission } from '@nats/webclient-common/lib/helpers/permissions';
import { hasPermission as hasPermissionSdk } from '@nats/nats-service-sdk/lib/lambda/request';

import styles from './MenuNode.module.scss';
import { selectPanel } from '../../actions/panelActions';
import { setSelectedRecord } from '../../actions/appActions';
import { setPageHeaderControls } from '../../actions/headerControlActions';

import type { State } from '../../types/ReduxStateType';
import type { FormCode } from '../../components/forms/getFormFromType';
import type { PageHeaderControls } from '../../types/state';

export type MenuNodeType = {
  name: string,
  path?: string,
  requiredPermission?: number,
  children?: Array<MenuNodeType>,
};

// Note: MenuNode is used recursively; ensure you pass any new props to the recursive call!
type Props = {
  node: MenuNodeType,
  pagePath: string,
  depth: number,
  location: { action: string, location: Location } | null,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: (?{}) => mixed,
  setPageHeaderControls: PageHeaderControls => mixed,
};

const clearScreen = (props: Props) => {
  props.selectPanel(null, {});
  props.setSelectedRecord(null);
  props.setPageHeaderControls([]);
};

const MenuNode = (props: Props) => {
  const {
    depth,
    pagePath,
    location,
    node: { name, path, requiredPermission, children },
  } = props;

  const isTopLevel = depth === 0;
  const isLink = !!path;
  const pushPath: string | null =
    location && location.action === 'PUSH' && location.location ? location.location.pathname : null;
  const isCurrentPageLink = isLink && (path === pagePath || path === pushPath);
  const isDisabled = requiredPermission === undefined ? false : !hasPermission(hasPermissionSdk, requiredPermission);
  const qaId = `sidebar-link-${name.replace(/ /g, '-').toLowerCase()}`;

  const nodeStyle = isTopLevel ? styles.topNode : styles.node;
  const text = <span className={styles.nodeText}>{name}</span>;

  let nodeText;

  if (isDisabled) {
    nodeText = (
      <span data-qa-id="disabledPageLink" className={styles.disabledPageLink}>
        {text}
      </span>
    );
  } else if (isCurrentPageLink) {
    nodeText = (
      <span id="activePageLink" className={styles.activePageLink}>
        {text}
      </span>
    );
  } else if (isLink) {
    nodeText = (
      <ButtonLink className={styles.link} to={path} onClick={() => clearScreen(props)} data-qa-id={qaId}>
        {text}
      </ButtonLink>
    );
  } else {
    nodeText = text;
  }

  return (
    <div className={nodeStyle}>
      {nodeText}
      {children &&
        children.map((child: MenuNodeType) => (
          <MenuNode
            key={child.path}
            node={child}
            depth={props.depth + 1}
            pagePath={props.pagePath}
            location={props.location}
            selectPanel={props.selectPanel}
            setSelectedRecord={props.setSelectedRecord}
            setPageHeaderControls={props.setPageHeaderControls}
          />
        ))}
    </div>
  );
};

export const mapStateToProps = (state: State) => ({
  pagePath: state.router.location.pathname,
  location: state.router.location,
});

export { MenuNode as PureMenuNode };
export default connect(mapStateToProps, {
  selectPanel,
  setSelectedRecord,
  setPageHeaderControls,
})(MenuNode);
