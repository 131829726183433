// @flow
import React from 'react';
import { Button } from '@nats/webclient-common';
import getFormFromType, { FORM_CODE, FORM_MODE } from '../getFormFromType';
import styles from './UserView.module.scss';

type UserViewProps = {
  disabled?: boolean,
  username: string,
  onClick: () => void,
};

const UserView = (props: UserViewProps) => {
  const panelProps = {
    isComposing: false,
    username: props.username,
  };

  const [, userViewForm] = getFormFromType(FORM_CODE.USER, FORM_MODE.VIEW, panelProps);

  return (
    <div data-qa-id="user-view" className={styles.userView}>
      {userViewForm}
      <Button disabled={props.disabled} onClick={() => props.onClick()} data-qa-id="user-view-delete-button">
        Delete
      </Button>
    </div>
  );
};

UserView.defaultProps = {
  disabled: false,
};

export default UserView;
