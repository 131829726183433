// @flow
import axios from 'axios';

import environment from '../environment';
import { loadEndpointsFailed, loadEndpointsStarted, loadEndpointsSuccessful } from '../actions/soapActions';
import { handleApiError } from '../actions/errorActions';
import { getApiOptions } from './helpers/options';
import { apiPatch } from './api';

import type { ThunkAction } from '../types/ReduxActionTypes';

const SOAP_ENDPOINTS_URL = `${environment.soapApiUrl}/api/v1/endpoints`;

export function loadEndpoints(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!SOAP_ENDPOINTS_URL) {
      throw new Error('Unable to retrieve soap endpoints; config not provided');
    }

    dispatch(loadEndpointsStarted());

    try {
      const response = await axios.get(SOAP_ENDPOINTS_URL, getApiOptions());

      dispatch(loadEndpointsSuccessful(response));
    } catch (error) {
      dispatch(loadEndpointsFailed(error));
      dispatch(handleApiError(error));

      throw error;
    }
  };
}

export function pollLoadEndpoints(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!SOAP_ENDPOINTS_URL) {
      throw new Error('Unable to retrieve soap endpoints; config not provided');
    }

    try {
      const response = await axios.get(SOAP_ENDPOINTS_URL, getApiOptions());

      dispatch(loadEndpointsSuccessful(response));
    } catch (error) {
      dispatch(loadEndpointsFailed(error));
      dispatch(handleApiError(error));

      throw error;
    }
  };
}

export type EditSoapEndpointsObject = {
  updateValues: {
    disableEndpoints: boolean,
  },
};

export function editEndpoints(formValues: EditSoapEndpointsObject): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.soapApiUrl) {
      throw new Error('Unable to send SOAP Endpoints data; config not provided');
    }
    return apiPatch(SOAP_ENDPOINTS_URL, formValues).catch(error => {
      dispatch(handleApiError(error));
      throw error;
    });
  };
}
