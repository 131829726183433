// @flow

import type { PageHeaderControls } from '../types/state';

import { SET_PAGE_HEADER_CONTROLS } from '../actions/actionTypes';
import type { HeaderControlAction } from '../actions/headerControlActions';

const initialState: PageHeaderControls = [];

export default function headerControlReducer(
  state: PageHeaderControls = initialState,
  action: HeaderControlAction
): PageHeaderControls {
  switch (action.type) {
    case SET_PAGE_HEADER_CONTROLS:
      return action.controls;
    default:
      return state;
  }
}
