// @flow
import type { SoapUserType } from '@nats/nats-service-sdk/lib/types/SoapUser';
import {
  loadSoapUsersStarted,
  loadSoapUsersFailed,
  loadSoapUsersSuccessful,
  checkSoapUserConfig,
} from '../actions/soapUserActions';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes';
import type { SoapUser } from '../types/SoapUser';
import { handleApiError } from '../actions/errorActions';
import { apiGet, apiPatch, apiPost, apiDelete } from './api';

const SOAP_USERS_URL = `${environment.soapApiUrl}/api/v1/soapusers`;

export type LoadSoapUsersResponse = {
  data: {
    _embedded: {
      soapUsers: Array<SoapUser>,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

export function loadSoapUsers(): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.soapApiUrl) {
      throw new Error('Unable to retrieve SOAP user data; config not provided');
    }

    dispatch(loadSoapUsersStarted());

    return apiGet(SOAP_USERS_URL)
      .then((response: LoadSoapUsersResponse) => {
        dispatch(loadSoapUsersSuccessful(response));
        dispatch(checkSoapUserConfig(response));
      })
      .catch(error => {
        dispatch(loadSoapUsersFailed());
        dispatch(handleApiError(error));

        throw error;
      });
  };
}

export type CreateSoapUserObject = {
  enabled: Boolean,
  username: string,
  password: string,
  userType: string,
  mailboxes: Array<string>,
};

export type EditSoapUserObject = {
  id: string,
  currentValues: {
    enabled: boolean,
    mailboxes: Array<string>,
    userType: SoapUserType,
  },
  updateValues: {
    enabled?: boolean,
    mailboxes?: string[],
    password?: string,
    userType?: SoapUserType,
  },
};

export function createSoapUser(formValues: CreateSoapUserObject): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.soapApiUrl) {
      throw new Error('Unable to send SOAP user data; config not provided');
    }

    const postBody = {
      enabled: formValues.enabled,
      username: formValues.username,
      password: formValues.password,
      userType: formValues.userType,
      mailboxes: formValues.mailboxes,
    };

    return apiPost(SOAP_USERS_URL, postBody).catch(error => {
      dispatch(handleApiError(error));
      throw error;
    });
  };
}

export function editSoapUser(formValues: EditSoapUserObject): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.soapApiUrl) {
      throw new Error('Unable to send SOAP user data; config not provided');
    }
    return apiPatch(SOAP_USERS_URL, formValues).catch(error => {
      dispatch(handleApiError(error));
      throw error;
    });
  };
}

export function deleteSoapUser(soapUser: SoapUser) {
  return apiDelete(SOAP_USERS_URL, { username: soapUser.username });
}
