// @flow
import React from 'react';
import { Button, LoadingButton } from '@nats/webclient-common';
import styles from './confirmModal.module.scss';

export type ConfirmModalProps = {
  isLoading: boolean,
  errorMessage: ?string,
  confirmationMessage: string,
  onConfirm: () => void | Promise<void>,
  onCancel: () => void,
};

export const ConfirmModal = ({
  isLoading,
  errorMessage,
  confirmationMessage,
  onCancel,
  onConfirm,
}: ConfirmModalProps) => (
  <div className={styles.confirmDialog} data-qa-id="confirm-delete">
    {errorMessage ? (
      <div
        data-qa-id="confirm-delete-error"
        className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
      >
        <ul>
          <li>{errorMessage}</li>
        </ul>
      </div>
    ) : null}
    <p data-qa-id="confirm-delete-prompt">{confirmationMessage}</p>
    <div className={styles.buttonGroup}>
      <Button type="secondary" onClick={() => onCancel()} data-qa-id="confirm-delete-cancel">
        Cancel
      </Button>
      <LoadingButton isLoading={isLoading} buttonText="OK" onClick={() => onConfirm()} />
    </div>
  </div>
);
