// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal, toggleModalVisible } from '~/actions/modalActions';
import { loadMessageParametersAudit } from '~/api/messageParametersAuditApi';

import ParametersAudit from '../../components/forms/parametersAudit/ParametersAudit';
import MessageParametersForm from '../../components/forms/messageParameters/MessageParameters';
import formModalStyles from '../../components/forms/FormModal.module.scss';
import { canDownloadMessageParametersAudit } from '../../utilities/permissions';
import { setPageHeaderControls } from '../../actions/headerControlActions';

import type { PageHeaderControls } from '../../types/state';

type Props = {
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModal: (string, React$Node) => void,
  loadMessageParametersAudit: (string, string) => void,
};
class MessageParametersScreen extends Component<Props> {
  componentDidMount() {
    const controls = this.getSubheaderControls();
    this.props.setPageHeaderControls(controls);
  }

  handleClickAudit = () => {
    this.props.toggleModalVisible();
    this.props.setModal(
      'AUDIT LOGS',
      <ParametersAudit onConfirm={this.props.loadMessageParametersAudit} type="message" />
    );
  };

  getSubheaderControls = (): PageHeaderControls => {
    return [
      [
        {
          name: 'audit',
          icon: ['fas', 'download'],
          onClick: this.handleClickAudit,
          isDisabled: !canDownloadMessageParametersAudit(),
        },
      ],
    ];
  };

  render() {
    return (
      <div className={`${formModalStyles.modalForm} ${formModalStyles.scrollableModalForm}`}>
        <MessageParametersForm />
      </div>
    );
  }
}

export const mapDispatchToProps = {
  setPageHeaderControls,
  setModal,
  toggleModalVisible,
  loadMessageParametersAudit,
};

export default connect(null, mapDispatchToProps)(MessageParametersScreen);

export { MessageParametersScreen as PureMessageParametersScreen };
