// @flow
import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';

import fontawesome from '@fortawesome/fontawesome';
import faUpload from '@fortawesome/fontawesome-free-solid/faUpload';
import faToggleOn from '@fortawesome/fontawesome-free-solid/faToggleOn';
import faToggleOff from '@fortawesome/fontawesome-free-solid/faToggleOff';
import Global from '~/Global';

import './index.scss';
import ReduxApp from './containers/App/App';
import configureStore from './store/store';
import reportWebVitals from './reportWebVitals';

fontawesome.library.add(faUpload, faToggleOn, faToggleOff);

const rootElement: ?Element = document.getElementById('root');
const store = configureStore();

if (!rootElement) {
  throw new Error(`#root element not found`);
}

const render = component => {
  ReactDOM.render(<Global store={store}>{React.createElement(withRouter(component))}</Global>, rootElement);
};

render(ReduxApp);

// Learn more about reporting Web Vitals: https://bit.ly/CRA-vitals
reportWebVitals(/* console.table */);
