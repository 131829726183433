// @flow

import {
  TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_STARTED,
  TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL,
  TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_FAILED,
} from '../actions/actionTypes';

import type { TransitRouteParametersAuditAction } from '../types/TransitRouteParameterAuditTypes';
import type { TransitRouteParametersAuditState } from '../types/state';

export const initialState: TransitRouteParametersAuditState = {
  auditLog: '',
  isBusy: false,
  error: null,
};

export default function transitRouteParametersAuditReducer(
  state: TransitRouteParametersAuditState = initialState,
  action: TransitRouteParametersAuditAction
): TransitRouteParametersAuditState {
  switch (action.type) {
    case TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
      };
    case TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_FAILED: {
      const { error } = action;
      return { ...state, isBusy: false, auditLog: '', error };
    }
    case TRANSIT_ROUTE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL: {
      const { auditLog } = action.payload.data;
      return { ...state, isBusy: false, error: null, auditLog };
    }
    default:
      return state;
  }
}
