// @flow
import React from 'react';
import { DataTable, Portal } from '@nats/webclient-common';
import styles from './Organisation.module.scss';

import type { OrganisationMailboxProps } from '../../../types/OrganisationDetailsTypes';
import type { OrganisationMailbox } from '../../../types/Mailbox';

const NO_DATA_TEXT = 'No mailboxes are assigned to this organisation.';

const OrganisationMailboxesTable = ({ tableData, isLoading, parentNodeId }: OrganisationMailboxProps) => {
  const columns = [
    {
      id: 'address',
      Header: 'Mailbox Address',
      property: 'address',
      accessor: (mailbox: OrganisationMailbox) => mailbox.address,
      resizable: false,
      width: 130,
    },
    {
      id: 'name',
      Header: 'Name',
      property: 'description',
      accessor: (mailbox: OrganisationMailbox) => mailbox.description,
      resizable: false,
    },
  ];

  const getClassName = () => (tableData.length === 0 ? styles.organisationTableError : '');

  const renderComponent = () => (
    <DataTable
      style={styles}
      className={`organisationMailboxesTable ${getClassName()}`}
      isClientSide
      disableFilteringAndPagination
      filterable={false}
      sortable={false}
      noDataText={NO_DATA_TEXT}
      {...{
        tableData,
        columns,
        isLoading,
      }}
    />
  );

  return parentNodeId ? <Portal to={parentNodeId}>{renderComponent()}</Portal> : renderComponent();
};

export default OrganisationMailboxesTable;
