import apiRequest from './request';

export const apiGet = async (uri, params) => {
  return apiRequest({
    method: 'get',
    url: uri,
    params,
  });
};

export const apiPatch = async (uri, data) => {
  return apiRequest({
    method: 'patch',
    url: uri,
    data,
  });
};

export const apiPost = async (uri, data) => {
  return apiRequest({
    method: 'post',
    url: uri,
    data,
  });
};

export const apiPut = async (uri, data) => {
  return apiRequest({
    method: 'put',
    url: uri,
    data,
  });
};

export const apiDelete = async (uri, data) => {
  return apiRequest({
    method: 'delete',
    url: uri,
    data,
  });
};
