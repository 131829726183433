// @flow
import React from 'react';
import get from 'lodash/get';
import { DataTable, Portal } from '@nats/webclient-common';
import type { OrganisationSerialCode } from '~/types/OrganisationSerialCodeTypes';
import orgStyles from './Organisation.module.scss';

type OrganisationSerialCodesEditProps = {
  tableData: Array<OrganisationSerialCode>,
  isLoading: boolean,
  parentNodeId: ?string,
  assignmentChangeHandler: (id: string) => void,
};

const NO_DATA_TEXT = 'No serial codes available.';

const OrganisationSerialCodesEditTable = ({
  tableData,
  isLoading,
  parentNodeId,
  assignmentChangeHandler,
}: OrganisationSerialCodesEditProps) => {
  const getAssignedCell = cellInfo => {
    const cellValue = tableData[cellInfo.index].isAssigned;
    return (
      <input type="checkbox" checked={cellValue} onChange={() => assignmentChangeHandler(cellInfo.original.code)} />
    );
  };

  const renderComponent = () => {
    const columns = [
      {
        Header: 'Trigraph',
        id: 'trigraph',
        accessor: (serialCode: OrganisationSerialCode) => `${serialCode.code} ${serialCode.name}`,
        resizable: false,
      },
      {
        accessor: (serialCode: OrganisationSerialCode) => get(serialCode, 'isAssigned'),
        Cell: getAssignedCell,
        className: 'toggleInput',
        Header: 'Assigned',
        headerClassName: 'toggleInputHeader',
        id: 'isAssigned',
        maxWidth: 85,
        property: 'isAssigned',
        resizable: false,
      },
    ];

    const getClassNames = () => (tableData.length === 0 ? orgStyles.organisationTableError : '');

    return (
      <DataTable
        className={getClassNames()}
        isClientSide
        disableFilteringAndPagination
        filterable={false}
        sortable={false}
        noDataText={NO_DATA_TEXT}
        {...{
          tableData,
          columns,
          isLoading,
        }}
      />
    );
  };

  return parentNodeId ? <Portal to={parentNodeId}>{renderComponent()}</Portal> : renderComponent();
};

export default OrganisationSerialCodesEditTable;
