// @flow

import React from 'react';
import { connect } from 'react-redux';

import { Panel } from '@nats/webclient-common';

import type { State } from '../../types/ReduxStateType';

import styles from './AdminPanel.module.scss';
import type { PanelState } from '../../types/state';
import { selectPanel } from '../../actions/panelActions';
import { setSelectedRecord } from '../../actions/appActions';
import type { FormCode } from '../../components/forms/getFormFromType';

type Props = {
  panel: PanelState,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: (?{}) => mixed,
};

const AdminPanel = (props: Props) => {
  const { panelTitle, PanelComponent } = props.panel;

  const clearPanel = () => {
    props.selectPanel(null, {});
    props.setSelectedRecord(null);
  };

  return (
    <Panel isVisible={!!panelTitle} title={panelTitle} onClose={clearPanel}>
      <div data-qa-id="panelWrapper" className={styles.panelWrapper}>
        {PanelComponent}
      </div>
    </Panel>
  );
};

const mapStateToProps = ({ panel }: State) => ({
  panel,
});

const mapDispatchToProps = {
  selectPanel,
  setSelectedRecord,
};

export { AdminPanel as PureAdminPanel };
export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
