// @flow
import { apiDelete, apiGet, apiPost, apiPut } from './api';
import {
  loadMailboxesStarted,
  loadMailboxesFailed,
  loadMailboxesSuccessful,
  createMailboxesStarted,
  createMailboxesFailed,
  createMailboxesSuccessful,
  editMailboxesStarted,
  editMailboxesFailed,
  editMailboxesSuccessful,
} from '../actions/mailboxActions';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes';
import type { Mailbox } from '../types/Mailbox';
import { handleApiError } from '../actions/errorActions';

const ORGANISATION_URL = `${environment.identityServiceUrl}/api/v1/organisations`;
const MAILBOXES_ALL_URL = `${environment.messagesServiceUrl}/api/v1/mailboxes-all`;
const MAILBOXES_URL = `${environment.messagesServiceUrl}/api/v1/mailboxes`;

export type CreateMailboxObj = {
  address: string,
  description: string,
  organisationId: string,
};

export function createMailbox({ address, description, organisationId }: CreateMailboxObj): ThunkAction {
  return async function action(dispatch: Function) {
    let mailbox = { address: address.toUpperCase(), description };

    if (organisationId && typeof organisationId === 'string' && organisationId.trim() !== '') {
      mailbox = {
        ...mailbox,
        organisationId,
      };
    }

    dispatch(createMailboxesStarted());

    try {
      const response = await apiPost(MAILBOXES_URL, mailbox);
      dispatch(createMailboxesSuccessful());
      return response;
    } catch (error) {
      dispatch(handleApiError(error));
      dispatch(createMailboxesFailed(error));
      throw error;
    }
  };
}

export type EditMailboxObj = {
  id: string,
  description: string,
  organisationId: string,
  helpdeskUsers: Array<string>,
};

export function editMailbox({ id, description, organisationId, helpdeskUsers }: EditMailboxObj): ThunkAction {
  return async function action(dispatch: Function) {
    let mailbox = { id, description, helpdeskUsers };

    if (organisationId && typeof organisationId === 'string' && organisationId.trim() !== '') {
      mailbox = {
        ...mailbox,
        organisationId,
      };
    }

    dispatch(editMailboxesStarted());

    try {
      const response = await apiPut(MAILBOXES_URL, mailbox);
      dispatch(editMailboxesSuccessful());
      return response;
    } catch (error) {
      dispatch(handleApiError(error));
      dispatch(editMailboxesFailed(error));
      throw error;
    }
  };
}

export type LoadMailboxesResponse = {
  data: {
    _embedded: {
      mailboxes: Array<Mailbox>,
      timeCreated: string,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

export type LoadMailboxesPayload = {
  mailboxes: Array<Mailbox>,
  timeCreated: string,
};

export function loadMailboxes(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.identityServiceUrl && !environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve mailbox data; config not provided');
    }

    dispatch(loadMailboxesStarted());

    return Promise.all(
      [MAILBOXES_ALL_URL, ORGANISATION_URL].map(url => {
        return apiGet(url);
      })
    )
      .then(data => {
        const [mailboxesResponse, organisationsResponse] = data;
        const { organisations } = organisationsResponse.data._embedded;
        const { mailboxes, timeCreated } = mailboxesResponse.data._embedded;

        const orgDictionary = organisations.reduce((acc, org) => {
          acc[org.id] = org.name;
          return acc;
        }, {});

        const mergedData = mailboxes.map(m => ({
          ...m,
          organisationName: orgDictionary[m.organisationId],
        }));

        dispatch(loadMailboxesSuccessful({ mailboxes: mergedData, timeCreated }));
      })
      .catch(error => {
        dispatch(loadMailboxesFailed());
        dispatch(handleApiError(error));
        throw error;
      });
  };
}

export const deleteMailbox = async (id: string) => {
  const uri = `${MAILBOXES_URL}/${id}`;
  await apiDelete(uri);
  return { id };
};
