// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { DataTable, Notification } from '@nats/webclient-common';

import { setPageTitle, setSelectedRecord } from '../../actions/appActions';
import { selectPanel } from '../../actions/panelActions';
import { setPageHeaderControls } from '../../actions/headerControlActions';
import { loadEndpoints, editEndpoints, pollLoadEndpoints } from '../../api/soapApi';
import { FORM_CODE } from '../../components/forms/getFormFromType';
import getErrorMessageForErrorCode from '../../utilities/errorCodeMapping';
import { exportToCSV } from '../../utilities/exportToCSV';
import soapEndpointsMapperModule from '../../utilities/csvMappers/soapEndpointsMapper';

import type { State as Store } from '../../types/ReduxStateType';
import type { LoadEndpointsResponse, SoapEndpointDataTableRecord, SoapEndpoint } from '../../types/SoapType';
import type { EditSoapEndpointsObject } from '../../api/soapApi';
import type { SoapState, PageHeaderControls } from '../../types/state';
import type { FormCode } from '../../components/forms/getFormFromType';
import { canEditSoapEndpoint } from '../../utilities/permissions';

type State = {
  errorNotificationId: string | null,
};

type Props = {
  soapState: SoapState,
  selectedRecord: SoapEndpoint,
  setPageTitle: string => void,
  loadEndpoints: void => Promise<LoadEndpointsResponse>,
  pollLoadEndpoints: void => Promise<LoadEndpointsResponse>,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: SoapEndpoint => mixed,
  setPageHeaderControls: PageHeaderControls => void,
  editEndpoints: EditSoapEndpointsObject => Promise<mixed>,
  pageTitle: string,
};

const POLLING_INTERVAL = 10000;

class EndpointsScreen extends Component<Props, State> {
  timer = null;

  tableColumns = [
    {
      id: 'endpoint',
      Header: 'Endpoint',
      accessor: (endpoint: Object): string => endpoint.name,
    },
    {
      id: 'request',
      Header: 'Last EAMS Request',
      accessor: (endpoint: Object): string => moment.utc(endpoint.lastEamsRequest).format('YY.MM.DD HH:mm:ss'),
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: (endpoint: Object): string => endpoint.status,
    },
    {
      id: 'currentState',
      Header: 'Enabled',
      accessor: (endpoint: Object): string => (endpoint.currentState === 'DISABLED' ? 'false' : 'true'),
    },
  ];

  constructor() {
    super();

    this.state = {
      errorNotificationId: null,
    };
  }

  clearErrorNotificationIfSet = () => {
    if (this.state.errorNotificationId) {
      Notification.dismiss(this.state.errorNotificationId);
      this.setState({ errorNotificationId: null });
    }
  };

  async componentDidMount() {
    this.props.setPageTitle('SOAP Endpoints');

    try {
      await this.props.loadEndpoints();
    } catch (error) {
      this.clearErrorNotificationIfSet();

      const notificationId = Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ errorNotificationId: notificationId });
    }

    this.pollEndpointsAPI();
  }

  componentDidUpdate() {
    const controls = this.getSubheaderControls();
    this.props.setPageHeaderControls(controls);
  }

  getEndpointsEnabledStatus = (endpoints: Array<SoapEndpoint>): string => {
    if (endpoints.every(e => e.status.includes('deactivation'))) {
      return 'deactivation';
    }

    return 'activation';
  };

  getSubheaderControls = (): PageHeaderControls => {
    const { endpoints, error, isBusy, endpointsEnabledStatus } = this.props.soapState;
    const endpointsPending = endpoints.every(e => e.status.includes('Pending'));
    const pendingStatus = endpointsPending ? this.getEndpointsEnabledStatus(endpoints) : '';

    const endpointsControl = {
      name: endpointsEnabledStatus ? 'Disable All' : 'Enable All',
      title: 'SOAP',
      onClick: this.handleClickKillswitch,
      isDisabled: !canEditSoapEndpoint() || endpointsPending || isBusy,
      tooltip: endpointsPending && !error ? `SOAP Endpoints pending ${pendingStatus}.` : null,
    };

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled: isBusy || !!error || endpoints.length === 0,
    };

    return [[exportControls, endpointsControl]];
  };

  componentWillUnmount() {
    this.clearErrorNotificationIfSet();
    this.stopPollingEndpointsAPI();
  }

  selectRow = (endpointRecord: SoapEndpointDataTableRecord) => {
    if (!endpointRecord) {
      return;
    }

    const { _original: endpoint } = endpointRecord;

    this.props.setSelectedRecord(endpoint);
    this.props.selectPanel(FORM_CODE.SOAP_ENDPOINT, { endpoint: endpoint.name });
  };

  isRowSelected = (endpoint: SoapEndpoint): boolean =>
    !!this.props.selectedRecord && this.props.selectedRecord.name === endpoint.name;

  pollEndpointsAPI = () => {
    this.timer = setInterval(() => this.props.pollLoadEndpoints(), POLLING_INTERVAL);
  };

  stopPollingEndpointsAPI = () => {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  handleClickKillswitch = async () => {
    const { endpointsEnabledStatus } = this.props.soapState;
    const action = endpointsEnabledStatus ? 'de' : '';

    try {
      await this.props.editEndpoints({
        updateValues: {
          disableEndpoints: endpointsEnabledStatus,
        },
      });

      // Call once to get the updated status = pending (de)activation
      this.props.loadEndpoints();

      Notification.success(`SOAP Endpoints have been marked for ${action}activation`);
    } catch (error) {
      this.clearErrorNotificationIfSet();

      const notificationId = Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });
      this.setState({ errorNotificationId: notificationId });
    }
  };

  exportViewClickHandler = () => {
    const { pageTitle, soapState } = this.props;
    const dataToExport = soapEndpointsMapperModule.mapSoapEndpoints(soapState.endpoints);

    exportToCSV(pageTitle, dataToExport);
  };

  render() {
    const { soapState } = this.props;

    return (
      <DataTable
        columns={this.tableColumns}
        tableData={soapState.endpoints}
        isLoading={soapState.isBusy}
        sortable={false}
        filterable={false}
        viewState={{ pageNumber: 1, pageCount: 1 }}
        updateFilterModel={noop}
        updatePageNumber={noop}
        onRowSelected={this.selectRow}
        getTdClass={(state, rowInfo) => (this.isRowSelected(rowInfo.original) ? 'selectedRow' : '')}
      />
    );
  }
}

const mapStateToProps = (state: Store) => ({
  pageTitle: state.app.title,
  selectedRecord: state.app.selectedRecord,
  soapState: state.soap,
});

export const mapDispatchToProps = {
  loadEndpoints,
  pollLoadEndpoints,
  setPageTitle,
  setSelectedRecord,
  selectPanel,
  setPageHeaderControls,
  editEndpoints,
};

export { EndpointsScreen as PureEndpointsScreen };

export default connect(mapStateToProps, mapDispatchToProps)(EndpointsScreen);
