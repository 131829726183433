// @flow
const getErrorMessageForErrorCode = (errorCode: string): string => {
  switch (errorCode) {
    case 'PERMISSION_ERROR':
      return (
        'You do not have permission to view this page. To try again please refresh your browser. If this persists, ' +
        'please contact your organisation admin.'
      );
    default:
      return 'Data could not be loaded.';
  }
};

export default getErrorMessageForErrorCode;
