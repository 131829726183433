// @flow

import type { ThunkAction } from '../types/ReduxActionTypes';
import { exportDataFailure, exportDataLoading, exportDataSuccess } from './organisationDetailsActions';
import { apiPost } from '../api/api';
import { handleApiError } from './errorActions';
import environment from '../environment';
import { exportToCSV } from '../utilities/exportToCSV';
import organisationsMapperModule from '../utilities/csvMappers/organisationScreen';

const ORGANISATIONS_PROFILES_URL = `${environment.messagesServiceUrl}/api/v1/organisations-profiles`;

type idName = {
  id: string,
  name: string,
};

export function exportOrganisationsProfiles(organisationIds: Array<string>, namedIdList: Array<idName>): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve organisation details data; config not provided');
    }

    try {
      dispatch(exportDataLoading());

      const profileDetailsResponseObj = await apiPost(ORGANISATIONS_PROFILES_URL, organisationIds);

      dispatch(exportDataSuccess());
      const profilesData = profileDetailsResponseObj.data._embedded.organisationProfiles;

      exportToCSV(
        'Organisations_Profiles',
        organisationsMapperModule.mapProfilesByOrganisations(namedIdList, profilesData)
      );
    } catch (err) {
      dispatch(exportDataFailure());
      dispatch(handleApiError(err));
      throw err;
    }
  };
}
