// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormBuilder, Notification } from '@nats/webclient-common';
import getReferenceDataSchemaByDataArea from '@nats/nats-service-sdk/lib/validation/referenceData/referenceDataValidation';

import { FORM_MODE, getErrorMessage } from './getFormFromType';
import { formatDisplayDate, formatFileSize } from '../../utilities/format';
import formModalStyles from './FormModal.module.scss';
import FilePicker from './FilePicker/FilePicker';

import type { State as Store } from '../../types/ReduxStateType';
import type { FieldDefinitions, YupSchemaType } from '../../types/FormBuilderTypes';
import type { FormMode } from './getFormFromType';
import type { ReferenceDataType } from '../../types/ReferenceDataType';
import { uploadReferenceDataFile, loadReferenceData } from '../../api/referenceDataApi';
import styles from './AuthorityApplication.module.scss';
import { canUploadReferenceData } from '../../utilities/permissions';
import { toggleModalVisible } from '../../actions/modalActions';
import { selectPanel } from '../../actions/panelActions';

type Props = {
  uploadReferenceDataFile: (string, File) => void,
  selectedRecord: ReferenceDataType,
  selectPanel: (?string, {}) => mixed,
  mode: FormMode,
  toggleModalVisible: () => void,
  loadReferenceData: () => Promise<Array<ReferenceDataType>>,
};

type State = {
  storedFile: File | null,
  submissionError: null | string,
};

class ReferenceData extends Component<Props, State> {
  errorMap: { [string]: string };

  fields: FieldDefinitions;

  editFields: FieldDefinitions;

  validationSchema: YupSchemaType;

  constructor(props: Props) {
    super(props);
    this.state = {
      storedFile: null,
      submissionError: null,
    };

    this.errorMap = {
      GENERIC_ERROR: 'Failed to update the reference data. Please try again.',
      PERMISSION_ERROR: 'You do not have permission to upload reference data.',
      IOT_PUBLISH_ERROR: `Reference data updated successfully, but failed to automatically notify Visual users.
      You can try to repeat the upload, or contact Visual users and ask them to manually refresh
      the page to see the latest data.`,
    };

    this.fields = [
      {
        name: 'dataArea',
        type: 'text',
        label: 'Data Area',
        initialValueFrom: {
          path: 'dataArea',
          withTransformation: (dataArea: string): string => dataArea.replace(/_/g, ' '),
        },
        cols: 12,
      },
      {
        name: 'currentFileName',
        type: 'text',
        label: 'Current File Name',
        initialValueFrom: 'currentFileName',
        cols: 12,
      },
      {
        name: 'currentFileSize',
        type: 'text',
        label: 'Current File Size',
        initialValueFrom: {
          path: 'currentFileSize',
          withTransformation: (currentFileSize: number | null): string => formatFileSize(currentFileSize),
        },
        cols: 12,
      },
      {
        name: 'fileExtension',
        type: 'text',
        label: 'File Extension',
        initialValueFrom: 'fileExtension',
        cols: 12,
      },
      {
        name: 'lastUploadedTimestamp',
        type: 'text',
        label: 'Last Uploaded (UTC)',
        initialValueFrom: {
          path: 'lastUploadedTimestamp',
          withTransformation: (lastUploadedTimestamp: string | null): string =>
            formatDisplayDate(lastUploadedTimestamp),
        },
        cols: 12,
      },
      {
        name: 'lastUploadedUsername',
        type: 'text',
        label: 'By User',
        initialValueFrom: 'lastUploadedUsername',
        cols: 12,
      },
    ];

    // when editing, we disable all fields as the file picker populates them automatically
    this.editFields = this.fields
      .filter(field => ['lastUploadedTimestamp', 'lastUploadedUsername'].indexOf(field.name) === -1)
      .map(field => ({ ...field, isDisabled: true }));

    const { dataArea } = this.props.selectedRecord;
    this.validationSchema = getReferenceDataSchemaByDataArea(dataArea);
  }

  isComposing = () => this.props.mode !== FORM_MODE.VIEW;

  getFields = () => (this.isComposing() ? this.editFields : this.fields);

  getFieldValues = () => {
    const { selectedRecord } = this.props;

    if (!selectedRecord) {
      return {};
    }
    if (!this.isComposing()) {
      return selectedRecord;
    }

    if (this.state.storedFile) {
      const file = this.state.storedFile;

      const extensionIdx = file.name.lastIndexOf('.');

      return {
        dataArea: selectedRecord.dataArea,
        currentFileName: extensionIdx === -1 ? file.name : file.name.substring(0, extensionIdx),
        currentFileSize: file.size,
        fileExtension: extensionIdx === -1 ? null : file.name.substring(extensionIdx + 1),
      };
    }

    return {
      dataArea: selectedRecord.dataArea,
      currentFileName: '',
      currentFileSize: 0,
      fileExtension: '',
    };
  };

  onFileReceive = (acceptedFiles: Array<File>) => {
    this.setState({ submissionError: null });
    this.setState({
      storedFile: acceptedFiles[0],
    });
  };

  setSubmissionError: Function = error => {
    this.setState({
      submissionError:
        error.response && error.response.data
          ? getErrorMessage(this.errorMap, error.response.data.error)
          : this.errorMap.GENERIC_ERROR,
    });
  };

  onSubmit = async () => {
    const file = this.state.storedFile;
    if (!file) {
      return null;
    }

    this.setState({ submissionError: null });

    try {
      await this.props.uploadReferenceDataFile(this.props.selectedRecord.dataArea, file);

      this.props.selectPanel(null, {});
      this.props.toggleModalVisible();
      this.props.loadReferenceData();

      Notification.success('Reference Data successfully uploaded');
    } catch (error) {
      this.setSubmissionError(error);
    }
    return null;
  };

  renderIotFailureWarning = () => {
    return (
      <div data-qa-id="formWarning" className={`${styles.validationErrors} ${styles.alertBox} ${styles.warningAlert}`}>
        <ul>
          <li>{this.errorMap.IOT_PUBLISH_ERROR}</li>
        </ul>
      </div>
    );
  };

  renderSubmissionError = (submissionError: string) => {
    return (
      <div
        data-qa-id="submissionError"
        className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
      >
        <ul>
          <li>{submissionError}</li>
        </ul>
      </div>
    );
  };

  renderError = () => {
    const { submissionError } = this.state;
    if (submissionError && submissionError === this.errorMap.IOT_PUBLISH_ERROR) {
      return this.renderIotFailureWarning();
    }
    if (submissionError) {
      return this.renderSubmissionError(submissionError);
    }
    return null;
  };

  hasAccessToForm = () =>
    this.props.mode === FORM_MODE.VIEW || (this.props.mode === FORM_MODE.EDIT && canUploadReferenceData());

  // Formbuilder does not update when initial values change, so we are forcing it to be destroyed and recreated
  // by creating a unique key every time a new file is uploaded.
  uniqueKey = () => {
    return `${this.props.selectedRecord.dataArea}${this.state.storedFile ? this.state.storedFile.name : ''}${
      this.state.storedFile ? this.state.storedFile.size : ''
    }${this.state.storedFile ? this.state.storedFile.type : ''}`;
  };

  render() {
    if (!this.hasAccessToForm()) {
      this.props.toggleModalVisible();
      return null;
    }

    return (
      <div className={formModalStyles.modalForm}>
        {this.renderError()}

        {this.isComposing() ? (
          <FilePicker
            onFileReceive={this.onFileReceive}
            text={this.state.storedFile ? this.state.storedFile.name : undefined}
          />
        ) : null}
        <div key={this.uniqueKey()}>
          <FormBuilder
            isComposing={this.isComposing()}
            name="referenceData"
            fields={this.getFields()}
            initialValues={this.getFieldValues()}
            validationSchema={this.validationSchema}
            onSubmit={this.onSubmit}
            submitButtonText="Submit"
            submittedButtonText="Submitting..."
            useLoadingSpinner
          />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  selectedRecord: state.app.selectedRecord,
});

export const mapDispatchToProps = {
  uploadReferenceDataFile,
  toggleModalVisible,
  loadReferenceData,
  selectPanel,
};

export { ReferenceData as PureReferenceData };

export default connect(mapStateToProps, mapDispatchToProps)(ReferenceData);
