import { object, string, array } from 'yup';
import { mailboxAddressTest } from '@nats/nats-service-sdk/lib/validation/common';

const label = 'Facade Mailbox Address';
const mailboxAddressSchema = string()
  .label(label)
  .required()
  .max(8)
  .test('mailbox-is-valid', `${label} address must be 8 letters`, mailboxAddressTest);

const descriptionLabel = 'Facade Mailbox Name';
const descriptionSchema = string()
  .label(descriptionLabel)
  .strict()
  .typeError(`${descriptionLabel} must be a string`)
  .required()
  .max(25, `${descriptionLabel} must be a maximum of 25 characters`)
  .matches(/^[a-z0-9 ]+$/i, `${descriptionLabel} must contain only alphanumeric characters and spaces`);

const configMailboxSchema = object({
  MailboxAddress: mailboxAddressSchema,
  Description: descriptionSchema,
});

export const AllowedSendFromAddressSchema = configMailboxSchema
  .label('Facade From Mailbox')
  .required('A Facade From Mailbox must be selected');

export const AllowedSendToAddressesSchema = array().required().of(configMailboxSchema.required()).min(1);
export const facadeUserConfigSchema = object({
  AllowedSendFromAddress: AllowedSendFromAddressSchema,
  AllowedSendToAddresses: AllowedSendToAddressesSchema,
});
