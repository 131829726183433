// @flow
import { Notification } from '@nats/webclient-common';

import {
  VISUAL_PARAMETERS_LOAD_STARTED,
  VISUAL_PARAMETERS_LOAD_SUCCESSFUL,
  VISUAL_PARAMETERS_LOAD_FAILED,
  VISUAL_PARAMETERS_EDIT_FAILED,
} from './actionTypes';

import type {
  LoadStartedAction,
  LoadSuccessAction,
  LoadFailedAction,
  EditFailedAction,
  LoadVisualParametersResponse,
} from '../types/VisualParameterType';

import { getErrorMessage } from '../components/forms/getFormFromType';

const errorMap = {
  GENERIC_ERROR: 'An error occurred. Please reload the page.',
  FAILED_TO_RETRIEVE_VISUAL_PARAMETERS: 'Unable to load Visual Parameters. Please reload the page.',
};

const displayErrorNotification = (error: string) => {
  Notification.error(getErrorMessage(errorMap, error, errorMap.GENERIC_ERROR), {
    autoClose: false,
  });
};

export const loadVisualParametersStarted = (): LoadStartedAction => {
  return {
    type: VISUAL_PARAMETERS_LOAD_STARTED,
  };
};

export const loadVisualParametersSuccessful = (response: LoadVisualParametersResponse): LoadSuccessAction => {
  return {
    type: VISUAL_PARAMETERS_LOAD_SUCCESSFUL,
    payload: response,
  };
};

export const loadVisualParametersFailed = (error: string): LoadFailedAction => {
  displayErrorNotification(error);
  return {
    type: VISUAL_PARAMETERS_LOAD_FAILED,
    error,
  };
};

export const editVisualParametersSuccessful = () => {
  Notification.success('Visual Parameters updated successfully');
};

export const editVisualParametersFailed = (error: string): EditFailedAction => {
  displayErrorNotification(error);
  return {
    type: VISUAL_PARAMETERS_EDIT_FAILED,
    error,
  };
};
