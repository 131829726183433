// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import { FormBuilder } from '@nats/webclient-common';

import { setPageTitle as setPageTitleAction } from '~/actions/appActions';
import {
  loadTransitRouteParameters as loadTransitRouteParametersAction,
  editTransitRouteParameters as editTransitRouteParametersAction,
} from '~/api/transitRouteParametersApi';

import type { State as Store } from '~/types/ReduxStateType';
import type { TransitRouteParametersState } from '~/types/state/TransitRouteParameters';
import type { TransitRouteParameters } from '~/types/TransitRouteParameterTypes';

import formStyles from '../SectionStyleForm.module.scss';

type Props = {
  setPageTitle: string => void,
  loadTransitRouteParameters: () => void,
  editTransitRouteParameters: (newTransitRouteParameters: TransitRouteParameters) => void,
  transitRouteParameters: TransitRouteParametersState,
};

class TransitRouteParametersForm extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const { setPageTitle, loadTransitRouteParameters } = props;

    setPageTitle('Transit Route Parameters');
    loadTransitRouteParameters();
  }

  getValidationSchema = () => {
    const transitRouteMailboxesSchema = (fieldName: string) =>
      string()
        .required(`${fieldName} is a required field`)
        .trim()
        .max(188)
        .matches(/^[a-z\s]*$/i, `${fieldName} must be a space separated list up to 21 mailboxes, each 8 letters`)
        .test(
          'is-valid-address',
          `${fieldName} must be a space separated list up to 21 mailboxes, each 8 letters`,
          addrs => !addrs || addrs.split(' ').every(a => a.length === 8)
        )
        .test('is-unique', `${fieldName} must be unique`, addrs => {
          if (!addrs) return true;
          const addresses = addrs.split(' ').map(a => a.toUpperCase());
          if (addresses.length === 0) return true;
          return new Set(addresses).size === addresses.length;
        });

    return object()
      .noUnknown()
      .shape({
        rfratAddressees: transitRouteMailboxesSchema('RFRA(T) Addressees'),
        rfratOriginators: transitRouteMailboxesSchema('RFRA(T) Originators'),
        royalFlightAddressees: transitRouteMailboxesSchema('RF Addressees'),
        royalFlightOriginators: transitRouteMailboxesSchema('RF Originators'),
        transitAddressees: transitRouteMailboxesSchema('Transit Addressees'),
        transitOriginators: transitRouteMailboxesSchema('Transit Originators'),
      });
  };

  getFields = () => {
    const outputValueAt = (path: string) => ({
      path,
      withTransformation: (value: mixed) => typeof value === 'string' && value.toUpperCase().trim(),
    });

    const rfratFields = [
      {
        name: 'rfratAddresseesSectionLabel',
        label: 'RFRA(T) Mailboxes',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: true,
      },
      {
        type: 'textarea',
        name: 'rfratAddressees',
        label: 'Addressees',
        initialValueFrom: 'rfratAddressees',
        initialValue: '',
        shouldStartNewRow: true,
        cols: 8,
        outputValueAt: outputValueAt('rfratAddressees'),
      },
      {
        type: 'textarea',
        name: 'rfratOriginators',
        label: 'Originators',
        initialValueFrom: 'rfratOriginators',
        initialValue: '',
        shouldStartNewRow: true,
        cols: 8,
        outputValueAt: outputValueAt('rfratOriginators'),
      },
    ];

    const royalFlightFields = [
      {
        name: 'royalFlightAddresseesSectionLabel',
        label: 'RF Mailboxes',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: true,
        labelPaddingAbove: 'double',
      },
      {
        type: 'textarea',
        name: 'royalFlightAddressees',
        label: 'Addressees',
        initialValueFrom: 'royalFlightAddressees',
        initialValue: '',
        shouldStartNewRow: true,
        cols: 8,
        outputValueAt: outputValueAt('royalFlightAddressees'),
      },
      {
        type: 'textarea',
        name: 'royalFlightOriginators',
        label: 'Originators',
        initialValueFrom: 'royalFlightOriginators',
        initialValue: '',
        shouldStartNewRow: true,
        cols: 8,
        outputValueAt: outputValueAt('royalFlightOriginators'),
      },
    ];

    const transitFields = [
      {
        name: 'transitAddresseesSectionLabel',
        label: 'Transit Mailboxes',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: true,
        labelPaddingAbove: 'double',
      },
      {
        type: 'textarea',
        name: 'transitAddressees',
        label: 'Addressees',
        initialValueFrom: 'transitAddressees',
        initialValue: '',
        shouldStartNewRow: true,
        cols: 8,
        outputValueAt: outputValueAt('transitAddressees'),
      },
      {
        type: 'textarea',
        name: 'transitOriginators',
        label: 'Originators',
        initialValueFrom: 'transitOriginators',
        initialValue: '',
        shouldStartNewRow: true,
        cols: 8,
        outputValueAt: outputValueAt('transitOriginators'),
      },
    ];

    return [...rfratFields, ...royalFlightFields, ...transitFields];
  };

  fields = this.getFields();

  validationSchema = this.getValidationSchema();

  render() {
    const { editTransitRouteParameters, transitRouteParameters } = this.props;

    const isComposing = transitRouteParameters.error != null ? !transitRouteParameters.isFetchError : true;

    if (!transitRouteParameters.isBusy) {
      return (
        <div className={formStyles.sectionStyleForm}>
          <FormBuilder
            isComposing={isComposing}
            name="transitRouteParameters"
            fields={this.fields}
            initialValues={transitRouteParameters}
            submitButtonText="Save"
            onSubmit={values => editTransitRouteParameters(values)}
            validationSchema={this.validationSchema}
          />
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ transitRouteParameters }: Store) => ({ transitRouteParameters });

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  loadTransitRouteParameters: loadTransitRouteParametersAction,
  editTransitRouteParameters: editTransitRouteParametersAction,
};

export { TransitRouteParametersForm as PureTransitRouteParametersForm };

export default connect(mapStateToProps, mapDispatchToProps)(TransitRouteParametersForm);
