// @flow
import {
  REFERENCE_DATA_LOAD_FAILED,
  REFERENCE_DATA_LOAD_STARTED,
  REFERENCE_DATA_LOAD_SUCCESSFUL,
  REFERENCE_DATA_UPLOAD_STARTED,
  REFERENCE_DATA_UPLOAD_SUCCESSFUL,
  REFERENCE_DATA_UPLOAD_FAILED,
} from './actionTypes';

import type { LoadReferenceDataResponse } from '../types/ReferenceDataType';

type LoadStartedAction = {
  type: typeof REFERENCE_DATA_LOAD_STARTED,
};

type LoadSuccessAction = {
  type: typeof REFERENCE_DATA_LOAD_STARTED,
  payload: LoadReferenceDataResponse,
};

type LoadFailedAction = {
  type: typeof REFERENCE_DATA_LOAD_FAILED,
  error: string,
};

type UploadStartedAction = {
  type: typeof REFERENCE_DATA_UPLOAD_STARTED,
};

type UploadSuccessAction = {
  type: typeof REFERENCE_DATA_UPLOAD_STARTED,
};

type UploadFailedAction = {
  type: typeof REFERENCE_DATA_UPLOAD_FAILED,
  error: string,
};

export type ReferenceDataAction =
  | LoadStartedAction
  | LoadSuccessAction
  | LoadFailedAction
  | UploadStartedAction
  | UploadSuccessAction
  | UploadFailedAction;

export function loadReferenceDataStarted(): LoadStartedAction {
  return {
    type: REFERENCE_DATA_LOAD_STARTED,
  };
}

export function loadReferenceDataSuccessful(response: LoadReferenceDataResponse): LoadSuccessAction {
  return {
    type: REFERENCE_DATA_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadReferenceDataFailed(error: string): LoadFailedAction {
  return {
    type: REFERENCE_DATA_LOAD_FAILED,
    error,
  };
}

export function uploadReferenceDataStarted(): UploadStartedAction {
  return {
    type: REFERENCE_DATA_UPLOAD_STARTED,
  };
}

export function uploadReferenceDataSuccessful(): UploadSuccessAction {
  return {
    type: REFERENCE_DATA_UPLOAD_SUCCESSFUL,
  };
}

export function uploadReferenceDataFailed(error: string): UploadFailedAction {
  return {
    type: REFERENCE_DATA_UPLOAD_FAILED,
    error,
  };
}
