// @flow
import React from 'react';
import getFormFromType, { FORM_CODE, FORM_MODE } from '~/components/forms/getFormFromType';

type MailboxViewProps = {
  address: string,
};

const MailboxView = ({ address }: MailboxViewProps) => {
  const [, mailboxViewForm] = getFormFromType(FORM_CODE.MESSAGE_MAILBOX, FORM_MODE.VIEW, {
    isComposing: false,
    address,
  });

  return <div data-qa-id="mailbox-view">{mailboxViewForm}</div>;
};

export default MailboxView;
