import '@babel/polyfill';
import getConfig from './api/helpers/getConfig';

const environment = {
  development: {
    isProduction: false,
  },
  production: {
    isProduction: true,
  },
}[process.env.NODE_ENV || 'development'];

export default Object.assign(
  {
    loginUrl: getConfig('LOGIN_URL') || '',
    messagesUrl: getConfig('MESSAGES_URL') || '',
    visualUrl: getConfig('VISUAL_URL') || '',
    adminUrl: getConfig('ADMIN_URL') || '',
    wingboardUrl: getConfig('WINGBOARD_URL') || '',
    laraUrl: getConfig('LARA_URL') || '',
    localAvoidsUrl: getConfig('LOCAL_AVOIDS_URL') || '',
    identityServiceUrl: getConfig('IDENTITY_SERVICE_API') || '',
    transitRoutesUrl: getConfig('TRANSIT_ROUTES_URL') || '',
    visualApiUrl: getConfig('VISUAL_API_URL') || '',
    idleTimeout: parseInt(getConfig('USER_IDLE_TIMEOUT') || '720', 10) * 60 * 1000,
    soapApiUrl: getConfig('SOAP_API') || '',
    messagesFacadeApiUrl: getConfig('MESSAGES_FACADE_API') || '',
    messagesServiceUrl: getConfig('MESSAGES_SERVICE_API') || '',
  },
  environment
);
