// @flow
import permissionsEnum from '@nats/nats-service-sdk/lib/types/Permissions';

export default {
  root: [
    {
      name: 'Authorities',
      path: '/authorities',
      requiredPermission: permissionsEnum.AuthorityView,
    },
    {
      name: 'Organisations',
      path: '/organisations',
      requiredPermission: permissionsEnum.OrganisationView,
    },
    {
      name: 'Users',
      path: '/users',
      requiredPermission: permissionsEnum.UserView,
    },
    {
      name: 'SOAP Endpoints',
      path: '/soap-endpoints',
      requiredPermission: permissionsEnum.SoapEndpointsView,
    },
    {
      name: 'SOAP Users',
      path: '/soap-users',
      requiredPermission: permissionsEnum.SoapUsersView,
    },
    {
      name: 'Message Mailboxes',
      path: '/message-mailboxes',
      requiredPermission: permissionsEnum.MailboxView,
    },
    {
      name: 'Message Profiles',
      path: '/message-profiles',
      requiredPermission: permissionsEnum.MessageProfilesView,
    },
    {
      name: 'Message Parameters',
      path: '/message-parameters',
      requiredPermission: permissionsEnum.MessageParametersEdit,
    },
    {
      name: 'Visual Parameters',
      path: '/visual-parameters',
      requiredPermission: permissionsEnum.MessageParametersEdit,
    },
    {
      name: 'TR Parameters',
      path: '/transit-route-parameters',
      requiredPermission: permissionsEnum.MessageParametersEdit,
    },
    {
      name: 'Reference Data',
      path: '/reference-data',
      requiredPermission: permissionsEnum.ReferenceDataView,
    },
    {
      name: 'Feature Toggles',
      path: '/feature-toggles',
      requiredPermission: permissionsEnum.FeatureTogglesUpdate,
    },
    {
      name: 'Logins By Org',
      path: '/logins-by-organisation',
      requiredPermission: permissionsEnum.LoginsByOrganisationView,
    },
  ],
};
