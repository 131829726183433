// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import { FormBuilder } from '@nats/webclient-common';

import { FORM_MODE } from './getFormFromType';
import formModalStyles from './FormModal.module.scss';

import type { State as Store } from '../../types/ReduxStateType';
import type { FieldDefinitions } from '../../types/FormBuilderTypes';
import type { FormMode } from './getFormFromType';
import type { LoginsByOrganisationType } from '../../types/LoginsByOrganisationType';
import { formatDisplayDate } from '../../utilities/format';

type Props = {
  selectedRecord: ?LoginsByOrganisationType,
  mode: FormMode,
};

class LoginsByOrganisation extends Component<Props> {
  fields: FieldDefinitions;

  constructor(props: Props) {
    super(props);

    this.fields = [
      {
        name: 'username',
        type: 'text',
        label: 'Username',
        initialValueFrom: 'username',
        cols: 12,
      },
      {
        name: 'organisation',
        type: 'text',
        label: 'Organisation',
        initialValueFrom: 'organisation',
        cols: 12,
      },
      {
        name: 'loginCount',
        type: 'text',
        label: 'Login Count',
        initialValueFrom: 'loginCount',
        cols: 12,
      },
      {
        name: 'loginTime',
        type: 'text',
        label: 'Last Login',
        initialValueFrom: {
          path: 'loginTime',
          withTransformation: dateString => formatDisplayDate(dateString),
        },
        cols: 12,
      },
    ];
  }

  render() {
    return (
      <div
        key={this.props.selectedRecord ? this.props.selectedRecord.username : null}
        className={formModalStyles.modalForm}
      >
        <FormBuilder
          isComposing={this.props.mode !== FORM_MODE.VIEW}
          name="loginsByOrganisation"
          fields={this.fields}
          initialValues={this.props.selectedRecord}
          onSubmit={noop}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  selectedRecord: state.app.selectedRecord,
});

export { LoginsByOrganisation as PureLoginsByOrganisation };

export default connect(mapStateToProps, null)(LoginsByOrganisation);
