// @flow

import axios from 'axios';
import type { ThunkAction } from '~/types/ReduxActionTypes';
import { getApiOptions } from '~/api/helpers/options';
import { handleApiError } from '~/actions/errorActions';
import environment from '~/environment';
import type { OrganisationProfilesUpdateParams } from '~/types/OrganisationProfilesTypes';

const ORGANISATION_PROFILES_URL = `${environment.messagesServiceUrl}/api/v1/organisation-profiles`;

export function updateOrganisationProfiles(organisationProfiles: OrganisationProfilesUpdateParams): ThunkAction {
  return async function action(dispatch: Function) {
    try {
      const response = await axios.put(ORGANISATION_PROFILES_URL, organisationProfiles, getApiOptions());
      return response;
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}
