// @flow
import React from 'react';
import get from 'lodash/get';
import { DataTable, Portal } from '@nats/webclient-common';
import { FORM_MODE } from '~/components/forms/getFormFromType';

import type { UserOrganisationMailbox, UserFacadeMailbox } from '~/types/Mailbox';
import type { UserServiceConfig } from '~/types/MessageFacade';
import type { FormMode } from '~/components/forms/getFormFromType';

import './UserMessageFacadeFromMailboxTable.module.scss';

const NO_DATA_TEXT = "No mailboxes assigned to user's organisation";

type Props = {
  portalId: ?string,
  userConfig: ?UserServiceConfig,
  mailboxes: Array<UserOrganisationMailbox>,
  mode: FormMode,
  onIsFromMailboxChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
};

const getInputCell = (
  mailboxAddress: string,
  columnProperty: string,
  inputType: string,
  value: boolean,
  isDisabled: boolean = true,
  onChangeHandler: Function
) => (
  <input
    id={mailboxAddress}
    name={columnProperty}
    type={inputType}
    checked={value}
    disabled={isDisabled}
    onChange={onChangeHandler}
  />
);

const textColumns: Array<any> = [
  {
    id: 'address',
    Header: 'Address',
    property: 'address',
    className: 'addressColumn',
    width: 80,
  },
  {
    id: 'name',
    Header: 'Name',
    property: 'description',
    className: 'ellipsesText',
    width: undefined,
  },
].map(({ id, Header, property, className, width }) => ({
  accessor: (mailbox: UserFacadeMailbox) => get(mailbox, property),
  className,
  Header,
  id,
  property,
  width,
  resizable: false,
}));

const getInputColumns = (
  isDisabled: boolean = true,
  mailboxes: Array<UserFacadeMailbox>,
  onIsFromMailboxChange: Function
): Array<any> => {
  return [
    {
      Header: 'From Mailbox',
      property: 'isFromMailbox',
      Cell: (props: any) => {
        const { column, original, value } = props;
        return getInputCell(original.address, column.id, 'radio', value, isDisabled, onIsFromMailboxChange);
      },
    },
  ].map(({ Header, property, Cell }) => ({
    accessor: (mailbox: UserFacadeMailbox) => get(mailbox, property),
    Cell,
    className: 'toggleInput',
    Header,
    headerClassName: 'toggleInputHeader',
    id: property,
    maxWidth: 100,
    property,
    resizable: false,
  }));
};

const getClassName = (rowCount: number) =>
  [
    ...(rowCount === 0 ? ['userFacadeFromMailboxTableNoData'] : []),
    ...(rowCount > 5 ? ['userFacadeFromMailboxTableScroll'] : []),
  ]
    .reduce((acc, val) => acc.concat(val), ['userFacadeFromMailboxTable'])
    .join(' ');

const UserMessageFacadeFromMailboxTable = (props: Props) => {
  const { portalId, userConfig, mailboxes, mode, onIsFromMailboxChange } = props;

  const selectedMailbox = get(userConfig, 'AllowedSendFromAddress.MailboxAddress', null);

  const isDisabled = mode === FORM_MODE.VIEW;

  const facadeMailboxes: UserFacadeMailbox[] = mailboxes
    .filter(x => x.isAssigned)
    .map(x => {
      return {
        ...x,
        isFromMailbox: selectedMailbox === x.address,
      };
    });

  const inputColumns = getInputColumns(isDisabled, facadeMailboxes, onIsFromMailboxChange);

  const mailboxTable = (
    <div>
      <DataTable
        isClientSide
        className={getClassName(facadeMailboxes.length)}
        sortable={false}
        filterable={false}
        resizable={false}
        disableFilteringAndPagination
        noDataText={NO_DATA_TEXT}
        tableData={facadeMailboxes}
        columns={[...textColumns, ...inputColumns]}
      />
    </div>
  );

  return portalId ? <Portal to={portalId}>{mailboxTable}</Portal> : mailboxTable;
};

export default UserMessageFacadeFromMailboxTable;
