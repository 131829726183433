// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal, toggleModalVisible } from '~/actions/modalActions';
import { loadTransitRouteParametersAudit } from '~/api/transitRouteParametersAuditApi';

import ParametersAudit from '../../components/forms/parametersAudit/ParametersAudit';
import TransitRouteParametersForm from '../../components/forms/transitRouteParameters/TransitRouteParameters';
import formModalStyles from '../../components/forms/FormModal.module.scss';
import { canDownloadMessageParametersAudit } from '../../utilities/permissions';
import { setPageHeaderControls } from '../../actions/headerControlActions';

import type { PageHeaderControls } from '../../types/state';

type Props = {
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModal: (string, React$Node) => void,
  loadTransitRouteParametersAudit: (string, string) => void,
};
class TransitRouteParametersScreen extends Component<Props> {
  componentDidMount() {
    const controls = this.getSubheaderControls();
    this.props.setPageHeaderControls(controls);
  }

  handleClickAudit = () => {
    this.props.toggleModalVisible();
    this.props.setModal(
      'AUDIT LOGS',
      <ParametersAudit onConfirm={this.props.loadTransitRouteParametersAudit} type="transit-route" />
    );
  };

  getSubheaderControls = (): PageHeaderControls => {
    return [
      [
        {
          name: 'audit',
          icon: ['fas', 'download'],
          onClick: this.handleClickAudit,
          isDisabled: !canDownloadMessageParametersAudit(),
        },
      ],
    ];
  };

  render() {
    return (
      <div className={formModalStyles.modalForm}>
        <TransitRouteParametersForm />
      </div>
    );
  }
}

export const mapDispatchToProps = {
  setPageHeaderControls,
  setModal,
  toggleModalVisible,
  loadTransitRouteParametersAudit,
};

export default connect(null, mapDispatchToProps)(TransitRouteParametersScreen);

export { TransitRouteParametersScreen as PureTransitRouteParametersScreen };
