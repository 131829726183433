// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import { FormBuilder, Loading, Notification, Button } from '@nats/webclient-common';
import getCreateMessageProfileValidationSchema from '@nats/nats-service-sdk/lib/validation/messageProfile/createMessageProfileValidation';
import getEditMessageProfileValidationSchema from '@nats/nats-service-sdk/lib/validation/messageProfile/editMessageProfileValidation';

import { setModal, toggleModalVisible } from '~/actions/modalActions';
import { closePanel, selectPanel } from '~/actions/panelActions';
import { setSelectedRecord } from '~/actions/appActions';
import type { DeleteRoleState } from '~/types/state/Role';

import { FORM_MODE } from '../getFormFromType';
import { getPermissions } from '../../../api/permissionsApi';
import { loadRoles, createRole, editRole } from '../../../api/roleApi';
import { deleteRole } from '../../../actions/roleActions';
import {
  canCreateMessageProfile,
  canDeleteMessageProfile,
  canEditMessageProfile,
} from '../../../utilities/permissions';
import ConfirmProfileDelete from './MessageProfileConfirmDelete';

import formModalStyles from '../FormModal.module.scss';
import styles from './MessageProfile.module.scss';

import type { State as Store } from '../../../types/ReduxStateType';
import type { FieldDefinitions } from '../../../types/FormBuilderTypes';
import type { FormMode } from '../getFormFromType';
import type { RoleInfo, CreateRoleInfo, EditRoleInfo, RolePermissions } from '../../../types/RoleType';
import type { Permission } from '../../../types/PermissionTypes';

type State = {
  submissionError: null | string,
  fields: FieldDefinitions,
  keepModalOpenToDisplayApiError: boolean,
  permissions: Array<Permission>,
};

type Props = {
  selectedRecord: RoleInfo,
  mode: FormMode,
  setSelectedRecord: (?{}) => mixed,
  selectPanel: (?string, {}) => mixed,
  getPermissions: () => Promise<Array<Permission>>,
  loadRoles: () => Promise<mixed>,
  createRole: CreateRoleInfo => Promise<mixed>,
  editRole: EditRoleInfo => Promise<mixed>,
  toggleModalVisible: () => void,
  setModal: (string, React$Node) => void,
  deleteRole: string => Promise<mixed>,
  closePanel: () => void,
  delete: DeleteRoleState,
};

const getPermissionTag = (displayName: string) => displayName.toLowerCase().replace(/ /g, '-');

const hasAccessToForm = mode => {
  switch (true) {
    case mode === FORM_MODE.VIEW:
    case mode === FORM_MODE.CREATE && canCreateMessageProfile():
    case mode === FORM_MODE.EDIT && canEditMessageProfile():
      return true;
    default:
      return false;
  }
};

class MessageProfile extends Component<Props, State> {
  actions: {
    past: string,
    future: string,
  };

  errorMap: { [string]: string };

  constructor(props: Props) {
    super(props);

    this.state = {
      submissionError: null,
      fields: [],
      keepModalOpenToDisplayApiError: false,
      permissions: [],
    };

    this.actions =
      this.props.mode === FORM_MODE.CREATE
        ? { past: 'created', future: 'create', present: 'creating' }
        : { past: 'edited', future: 'edit', present: 'editing' };

    this.errorMap = {
      GENERIC_ERROR: `Message profile could not be ${this.actions.past}. Please try again.`,
      PERMISSION_EXCESS_MISMATCH_ERROR: `Invalid permission mismatch. Permission must exist to link to a role`,
      PERMISSION_MISMATCH_ERROR: `Permission mismatch. All permissions are required when ${this.actions.present} a profile.`,
      ROLE_DOES_NOT_EXIST_ERROR: `Message profile no longer exists.`,
      PERMISSIONS_NOT_FOUND_ERROR: `No permissions found.`,
      USER_NOTIFICATION_ERROR: 'Failed to update existing user sessions with the new permissions',
      ROLE_NAME_UNIQUE_CONSTRAINT_ERROR: 'The message profile name must be unique',
    };
  }

  async componentDidMount() {
    const commonFields = [
      {
        name: 'name',
        type: 'text',
        label: 'Profile Name',
        initialValue: '',
        initialValueFrom: 'name',
        cols: 12,
        shouldWrapTooltip: true,
      },
      {
        name: 'description',
        type: 'textarea',
        label: 'Profile Description',
        initialValue: '',
        initialValueFrom: 'description',
        cols: 12,
        rows: 4,
        shouldWrapTooltip: true,
      },
      {
        type: 'text',
        name: 'permissionsLabel',
        shouldStartNewRow: true,
        label: 'Permissions',
        isLabelOnly: true,
        cols: 12,
      },
    ];

    let permissionCheckboxes = [];
    let permissions: Array<Permission> = [];

    const defaultPermissionCheckboxOptions = {
      type: 'checkbox',
      isLabelInline: true,
      cols: 12,
      shouldWrapTooltip: true,
    };

    switch (this.props.mode) {
      case FORM_MODE.EDIT:
      case FORM_MODE.VIEW:
        if (this.props.selectedRecord && this.props.selectedRecord.permissions) {
          permissions = this.props.selectedRecord.permissions.map(({ id, displayName, value }: RolePermissions) => ({
            id,
            displayName,
            defaultValue: value,
          }));
        }
        break;
      case FORM_MODE.CREATE:
        try {
          permissions = await this.props.getPermissions();

          if (permissions.length === 0) {
            Notification.error(`No permissions found`);
            this.props.toggleModalVisible();
          }
        } catch (error) {
          Notification.error(`Failed to load permissions`);
          this.props.toggleModalVisible();
        }
        break;
      default:
        break;
    }

    permissionCheckboxes = permissions.map(permission => ({
      ...defaultPermissionCheckboxOptions,
      name: getPermissionTag(permission.displayName),
      checkboxText: permission.displayName,
      initialValue: permission.defaultValue,
    }));

    const fields = [...commonFields, ...permissionCheckboxes];
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ fields, permissions });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.delete.deleted && this.props.delete.deleted !== prevProps.delete.deleted) {
      this.props.closePanel();
      Notification.success('Message profile successfully deleted');
      this.props.toggleModalVisible();
    }
  }

  setSubmissionError: Function = error => {
    let errortext = this.errorMap.GENERIC_ERROR;

    if (error.response && error.response.data && error.response.data.error) {
      if (this.errorMap[error.response.data.error]) {
        errortext = this.errorMap[error.response.data.error];
      } else {
        errortext = error.response.data.error;
      }
    }

    this.setState({
      submissionError: errortext,
    });
  };

  onSubmit: Function = async uiValues => {
    if (!this.state.keepModalOpenToDisplayApiError) {
      this.setState({ keepModalOpenToDisplayApiError: true });
    }

    this.setState({ submissionError: null });

    const { permissions } = this.state;
    const rolePermissions = permissions.map(permission => {
      const value = !!uiValues[getPermissionTag(permission.displayName)];
      return {
        id: permission.id,
        value,
      };
    });

    const role = {
      name: uiValues.name,
      description: uiValues.description,
      permissions: rolePermissions,
    };

    try {
      if (this.props.mode === FORM_MODE.CREATE) {
        await this.props.createRole(role);
      } else if (this.props.mode === FORM_MODE.EDIT) {
        await this.props.editRole({
          ...role,
          id: this.props.selectedRecord.id,
        });

        this.props.setSelectedRecord(null);
        this.props.selectPanel(null, {});
      }

      this.props.toggleModalVisible();
      Notification.success(`Message Profile successfully ${this.actions.past}`);
      await this.props.loadRoles();
    } catch (error) {
      this.setSubmissionError(error);
    }
  };

  renderSubmissionError = () => {
    const { submissionError } = this.state;
    if (!submissionError) return null;

    return (
      <div
        data-qa-id="submissionError"
        className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
      >
        <ul>
          <li>{submissionError}</li>
        </ul>
      </div>
    );
  };

  render() {
    if (!hasAccessToForm(this.props.mode)) {
      if (!this.state.keepModalOpenToDisplayApiError) {
        this.props.toggleModalVisible();
        return null;
      }
    }

    const { fields, submissionError } = this.state;

    if (fields.length === 0) {
      return (
        <div>
          {submissionError ? this.renderSubmissionError() : null}
          <Loading block isLoading hasData />
        </div>
      );
    }

    const getSubmitButtonText = () => {
      switch (this.props.mode) {
        case FORM_MODE.VIEW:
          return null;
        case FORM_MODE.EDIT:
        case FORM_MODE.CREATE:
          return 'Save';
        default:
          throw new Error(`Unknown form mode '${this.props.mode}'`);
      }
    };

    const confirmDeleteProfile = () => {
      const { id, name } = this.props.selectedRecord;

      this.props.deleteRole(id);
      this.props.toggleModalVisible();
      this.props.setModal(
        name,
        <ConfirmProfileDelete
          roleId={id}
          onDismiss={() => this.props.toggleModalVisible()}
          onCancel={() => this.props.toggleModalVisible()}
        />
      );
    };

    const showDeleteButton = () => {
      if (this.props.mode !== FORM_MODE.VIEW) {
        return null;
      }

      return (
        <div>
          <Button
            data-qa-id="messageprofiles-delete-btn"
            disabled={!canDeleteMessageProfile()}
            onClick={() => confirmDeleteProfile()}
          >
            Delete
          </Button>
        </div>
      );
    };

    return (
      <div
        key={this.props.selectedRecord ? this.props.selectedRecord.id : 'COMPOSING'}
        className={formModalStyles.modalForm}
      >
        <div>{this.renderSubmissionError()}</div>
        <FormBuilder
          isComposing={this.props.mode !== FORM_MODE.VIEW}
          name="messageProfiles"
          fields={fields}
          initialValues={this.props.selectedRecord}
          submitButtonText={getSubmitButtonText()}
          onSubmit={this.props.mode === FORM_MODE.VIEW ? noop : this.onSubmit}
          validationSchema={
            this.props.mode === FORM_MODE.CREATE
              ? getCreateMessageProfileValidationSchema(true)
              : getEditMessageProfileValidationSchema(true)
          }
        />
        {showDeleteButton()}
      </div>
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  selectedRecord: state.app.selectedRecord,
  delete: state.roleState.delete,
});

export const mapDispatchToProps = {
  getPermissions,
  loadRoles,
  createRole,
  editRole,
  setSelectedRecord,
  selectPanel,
  toggleModalVisible,
  setModal,
  deleteRole,
  closePanel,
};

export { MessageProfile as PureMessageProfile };

export default connect(mapStateToProps, mapDispatchToProps)(MessageProfile);
