// @flow

export default function getConfig(key: string) {
  if (window.globalConfig === undefined) {
    throw new Error('Global config is not defined.');
  }

  if (!(key in window.globalConfig)) {
    throw new Error(`Environment variable ${key} not defined`);
  }

  return window.globalConfig[key];
}
