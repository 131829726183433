// @flow

import {
  ORGANISATION_LOAD_FAILED,
  ORGANISATION_LOAD_STARTED,
  ORGANISATION_LOAD_SUCCESSFUL,
} from '../actions/actionTypes';
import type { OrganisationState } from '../types/state/index';
import type { OrganisationAction } from '../actions/organisationActions';

const initialState: OrganisationState = {
  isBusy: false,
  isEnabled: false,
  organisations: [],
  error: null,
};

export default function organisationReducer(
  state: OrganisationState = initialState,
  action: OrganisationAction
): OrganisationState {
  switch (action.type) {
    case ORGANISATION_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        isEnabled: false,
        organisations: [],
        error: null,
      };
    case ORGANISATION_LOAD_SUCCESSFUL:
      return {
        ...state,
        isBusy: false,
        isEnabled: true,
        organisations: action.organisationList,
      };
    case ORGANISATION_LOAD_FAILED:
      return {
        ...initialState,
        error: action.error,
      };
    default:
      return state;
  }
}
