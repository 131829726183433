// @flow
import React from 'react';
import styles from './ErrorScreen.module.scss';

type Props = {
  message: string,
};
export default function ErrorScreen({ message }: Props) {
  return <p className={styles.message}>{message}</p>;
}
