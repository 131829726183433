// @flow
import React from 'react';
import { connect } from 'react-redux';
import { deleteMailboxConfirm as deleteMailboxConfirmAction } from '~/actions/mailboxActions';
import type { State as Store } from '~/types/ReduxStateType';
import { ConfirmModal } from '~/components/confirmModal/confirmModal';

type ErrorType = { response?: { data?: { error: string } } };

export const defaultMessage = 'There was an error deleting the message mailbox.';

export const errorMap = {
  GENERIC_ERROR: defaultMessage,
  SERVER_ERROR: defaultMessage,
  PERMISSION_ERROR: 'You do not have permission to delete a message mailbox.',
  MAILBOX_STILL_ASSIGNED_ERROR: 'You cannot delete a mailbox assigned to a non help desk user.',
  MAILBOX_DEFAULT_FOR_HELPDESK_USER_ERROR:
    'You cannot delete a message mailbox currently set as the default mailbox for a help desk user.',
};

const getErrorMessage = (error: ErrorType): ?string => {
  if (!error) {
    return null;
  }

  if (error.response && error.response.data && error.response.data.error) {
    if (error.response.data.error in errorMap) {
      return errorMap[error.response.data.error];
    }
    return error.response.data.error;
  }

  return defaultMessage;
};

type ConfirmMailboxDeleteConnectedProps = {
  isLoading: boolean,
  error: ErrorType,
  mailboxId: string,
  onCancel: () => void,
  deleteMailboxConfirm: string => void,
};

export const MailboxConfirmDelete = ({
  isLoading,
  error,
  onCancel,
  deleteMailboxConfirm,
  mailboxId,
}: ConfirmMailboxDeleteConnectedProps) => (
  <ConfirmModal
    data-qa-id="mailbox-confirm-delete"
    isLoading={isLoading}
    confirmationMessage="Are you sure you want to delete this message mailbox?"
    errorMessage={getErrorMessage(error)}
    onCancel={() => onCancel()}
    onConfirm={() => {
      deleteMailboxConfirm(mailboxId);
    }}
  />
);

const mapStateToProps = (state: Store, ownProps) => ({
  isLoading: state.mailboxState.delete.isLoading,
  error: state.mailboxState.delete.error,
  mailboxId: ownProps.mailboxId,
  onCancel: ownProps.onCancel,
});

const mapDispatchToProps = {
  deleteMailboxConfirm: deleteMailboxConfirmAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailboxConfirmDelete);
