// @flow

import {
  TRANSIT_ROUTE_PARAMETERS_LOAD_STARTED,
  TRANSIT_ROUTE_PARAMETERS_LOAD_SUCCESSFUL,
  TRANSIT_ROUTE_PARAMETERS_LOAD_FAILED,
  TRANSIT_ROUTE_PARAMETERS_EDIT_FAILED,
} from '../actions/actionTypes';

import type { TransitRouteParametersAction } from '../types/TransitRouteParameterTypes';

import type { TransitRouteParametersState } from '../types/state';

export const initialState: TransitRouteParametersState = {
  isBusy: false,
  error: null,
  isFetchError: null,
  royalFlightAddressees: '',
  royalFlightOriginators: '',
  rfratAddressees: '',
  rfratOriginators: '',
  transitAddressees: '',
  transitOriginators: '',
};

export default function transitRouteParametersReducer(
  state: TransitRouteParametersState = initialState,
  action: TransitRouteParametersAction
): TransitRouteParametersState {
  switch (action.type) {
    case TRANSIT_ROUTE_PARAMETERS_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
        isFetchError: null,
      };
    case TRANSIT_ROUTE_PARAMETERS_LOAD_FAILED: {
      const { error } = action;
      return { ...state, isBusy: false, error, isFetchError: true };
    }
    case TRANSIT_ROUTE_PARAMETERS_EDIT_FAILED: {
      const { error } = action;
      return { ...state, isBusy: false, error, isFetchError: false };
    }
    case TRANSIT_ROUTE_PARAMETERS_LOAD_SUCCESSFUL: {
      const { data } = action.payload;
      return {
        ...state,
        isBusy: false,
        error: null,
        isFetchError: null,
        ...data,
      };
    }
    default:
      return state;
  }
}
