// @flow

import {
  ORGANISATION_DETAILS_LOAD_FAILED,
  ORGANISATION_DETAILS_LOAD_STARTED,
  ORGANISATION_DETAILS_LOAD_SUCCESSFUL,
  RESET_ORGANISATION_DETAILS,
  EXPORT_DATA_LOADING,
  EXPORT_DATA_SUCCESS,
  EXPORT_DATA_FAILURE,
} from '../actions/actionTypes';

import type { OrganisationDetailsState } from '../types/state/index';
import type { OrganisationDetailsAction } from '../actions/organisationDetailsActions';

const initialState: OrganisationDetailsState = {
  isBusy: false,
  profiles: [],
  mailboxes: [],
  error: null,
};

export default function organisationDetailsReducer(
  state: OrganisationDetailsState = initialState,
  action: OrganisationDetailsAction
): OrganisationDetailsState {
  switch (action.type) {
    case ORGANISATION_DETAILS_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
      };
    case ORGANISATION_DETAILS_LOAD_SUCCESSFUL: {
      const { profiles, mailboxes } = action.payload.data._embedded;

      return {
        ...state,
        isBusy: false,
        profiles,
        mailboxes,
        error: null,
      };
    }
    case ORGANISATION_DETAILS_LOAD_FAILED:
      return {
        ...initialState,
        error: action.error,
      };
    case RESET_ORGANISATION_DETAILS:
      return {
        ...initialState,
        isBusy: true,
      };
    case EXPORT_DATA_LOADING:
      return {
        ...state,
        isBusy: true,
      };
    case EXPORT_DATA_SUCCESS:
      return {
        ...state,
        isBusy: false,
      };
    case EXPORT_DATA_FAILURE:
      return {
        ...state,
        isBusy: false,
      };
    default:
      return state;
  }
}
