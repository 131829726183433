// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';
import noop from 'lodash/noop';
import flatMap from 'lodash/fp/flatMap';
import { DataTable, Notification } from '@nats/webclient-common';
import { exportToCSV } from '~/utilities/exportToCSV';

import { getAuthorities } from '../../actions/authorityActions';
import { selectPanel } from '../../actions/panelActions';
import { setPageTitle, setSelectedRecord } from '../../actions/appActions';
import { setPageHeaderControls } from '../../actions/headerControlActions';
import { setModalForm, toggleModalVisible } from '../../actions/modalActions';
import { canEditAuthority } from '../../utilities/permissions';
import { FORM_CODE, FORM_MODE } from '../../components/forms/getFormFromType';
import type { FormCode, FormMode } from '../../components/forms/getFormFromType';
import type { State } from '../../types/ReduxStateType';
import type { Authority, AuthorityApplication } from '../../types/Authority';
import type { PageHeaderControls } from '../../types/state';
import mapAuthorities from '../../utilities/csvMappers/authScreen';

type Props = {
  isLoading: boolean,
  authorities: Array<Authority>,
  error: boolean,
  getAuthorities: () => void,
  pageTitle: string,
  setPageTitle: string => void,
  selectedRecord: AuthorityApplication,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: AuthorityApplication => mixed,
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModalForm: (FormCode, FormMode, {}) => void,
};

export class AuthorityScreen extends Component<Props> {
  componentDidMount() {
    this.props.setPageTitle('Authorities');
    this.props.getAuthorities();
    this.setSubheaderControls();
  }

  handleClickEdit = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.toggleModalVisible();
    this.props.setModalForm(FORM_CODE.AUTHORITY_APPLICATION, FORM_MODE.EDIT, selectedRecord);
  };

  exportViewClickHandler = () => {
    const { pageTitle, authorities } = this.props;

    exportToCSV(pageTitle, mapAuthorities(authorities));
  };

  setSubheaderControls() {
    const { isLoading, selectedRecord, authorities, error } = this.props;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled: isLoading || authorities.length === 0 || error,
    };

    const editControls = {
      name: 'Edit',
      icon: ['fab', 'wpforms'],
      onClick: this.handleClickEdit,
      isDisabled: !canEditAuthority(),
    };

    this.props.setPageHeaderControls(selectedRecord ? [[exportControls, editControls]] : [[exportControls]]);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.error && this.props.error !== prevProps.error) {
      Notification.error('Authority data could not be loaded.');
    }

    if (this.props.selectedRecord !== prevProps.selectedRecord || this.props.authorities !== prevProps.authorities) {
      this.setSubheaderControls();
    }
  }

  selectRow = (authorityApplication: { _original: AuthorityApplication }) => {
    if (!authorityApplication) {
      return;
    }

    const { _original } = authorityApplication;

    this.props.setSelectedRecord(_original);

    const { authorityName, application } = _original;
    this.props.selectPanel(FORM_CODE.AUTHORITY_APPLICATION, {
      isComposing: false,
      authorityName,
      application,
    });
  };

  isRowSelected = (id: string): boolean => !!(this.props.selectedRecord && this.props.selectedRecord.rowId === id);

  render() {
    const authorityApplications: Array<AuthorityApplication> = flatMap(
      authority =>
        authority.applications.map(application => ({
          id: authority.id,
          rowId: `${authority.name}${application.name}`,
          authorityName: authority.name,
          application: application.name,
          allocatedLicences: application.allocated_licences,
          unallocatedLicences: application.max_licences - application.allocated_licences,
          maxLicences: application.max_licences,
        })),
      this.props.authorities
    );

    const tableColumns = [
      {
        Header: 'Authority Name',
        id: 'authorityName',
        accessor: (a: AuthorityApplication) => a.authorityName,
      },
      {
        Header: 'Application',
        id: 'applicationName',
        accessor: (a: AuthorityApplication) =>
          a.application === 'LARA' ? a.application : startCase(a.application.toLowerCase()),
      },
      {
        Header: 'Licences allocated to Orgs/Total Licences',
        id: 'licences',
        accessor: (a: AuthorityApplication) => `${a.allocatedLicences} / ${a.maxLicences}`,
      },
    ];

    return (
      <DataTable
        columns={tableColumns}
        tableData={authorityApplications}
        isLoading={this.props.isLoading}
        sortable={false}
        filterable={false}
        viewState={{ pageNumber: 1, pageCount: 1 }}
        updatePageNumber={noop}
        updateFilterModel={noop}
        onRowSelected={this.selectRow}
        getTdClass={(state, rowInfo) => `${this.isRowSelected(rowInfo.original.rowId) ? 'selectedRow' : ''}`}
      />
    );
  }
}

export const mapStateToProps = (state: State) => ({
  isLoading: state.authorityState.isLoading,
  authorities: state.authorityState.authorities,
  error: state.authorityState.error,
  selectedRecord: state.app.selectedRecord,
  pageTitle: state.app.title,
});

export const mapDispatchToProps = {
  getAuthorities,
  setPageTitle,
  selectPanel,
  setSelectedRecord,
  setPageHeaderControls,
  toggleModalVisible,
  setModalForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorityScreen);
