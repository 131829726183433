// @flow
import React from 'react';
import { orderBy } from 'lodash';
import type { FeatureToggle } from '../../../types/FeatureTogglesTypes';
import FeatureToggleSection from './FeatureToggleSection';

type Props = {
  featureToggles: Array<FeatureToggle>,
};

export default function FeatureTogglesForm({ featureToggles }: Props) {
  const orderedFeatureToggles = orderBy(featureToggles, 'id', 'asc');
  const sections = orderedFeatureToggles.map(feature => (
    <FeatureToggleSection key={feature.id} featureToggle={feature} />
  ));
  return <div>{sections}</div>;
}
