// @flow
import React from 'react';
import { Button, LoadingButton, Notification } from '@nats/webclient-common';
import { connect } from 'react-redux';
import { toggleModalVisible } from '~/actions/modalActions';
import { closePanel } from '~/actions/panelActions';
import { loadSoapUsers, deleteSoapUser } from '~/api/soapUserApi';
import type { SoapUser } from '~/types/SoapUser';
import type { LoadSoapUsersResponse } from '~/api/soapUserApi';
import styles from './ConfirmSoapUserDelete.module.scss';

type Props = {
  toggleModalVisible: () => void,
  closePanel: () => void,
  soapUser: SoapUser,
  loadSoapUsers: () => Promise<LoadSoapUsersResponse>,
  unselectRow: () => void,
};

type State = {
  isLoading: boolean,
  error: string | null,
};

class ConfirmSoapUserDelete extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      error: null,
    };
  }

  async confirmDelete() {
    this.setState({ isLoading: true });
    try {
      await deleteSoapUser(this.props.soapUser);
      this.props.toggleModalVisible();
      this.props.closePanel();
      this.props.unselectRow();
      await this.props.loadSoapUsers();
      Notification.success('User has been marked for deletion');
    } catch (err) {
      this.setState({ error: 'Unable to delete SOAP User' });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  renderErrorBox = (error: string) => (
    <div
      data-qa-id="confirm-soap-user-delete-error"
      className={`${styles.validationErrors} ${styles.alertBox} ${styles.dangerAlert}`}
    >
      <ul>
        <li>{error}</li>
      </ul>
    </div>
  );

  render() {
    return (
      <div className={styles.confirmUserDialog} data-qa-id="confirm-soap-user-delete">
        {this.state.error ? this.renderErrorBox(this.state.error) : null}
        <p>Deleting a SOAP user may cause issues in the system that cannot be automatically resolved.</p>
        {this.props.soapUser.userType !== 'NORMAL' && this.props.soapUser.enabled === true ? (
          <p data-qa-id="confirm-system-soap-user-delete">The system requires one SOAP user of each System User Type</p>
        ) : null}
        <div className={styles.buttonGroup}>
          <Button type="secondary" onClick={() => this.props.toggleModalVisible()}>
            Cancel
          </Button>
          <LoadingButton isLoading={this.state.isLoading} buttonText="OK" onClick={() => this.confirmDelete()} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  toggleModalVisible,
  closePanel,
  loadSoapUsers,
};

const mapStateToProps = state => ({
  soapUser: state.app.selectedRecord,
});

export { ConfirmSoapUserDelete as PureConfirmSoapUserDelete };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSoapUserDelete);
