// @flow

import { handle } from 'redux-pack';
import {
  UPDATE_USERS_FILTER_MODEL,
  UPDATE_USERS_PAGE_NUMBER,
  UPDATE_USERS_SORT_MODEL,
  USER_LOAD_FAILED,
  USER_LOAD_STARTED,
  USER_LOAD_SUCCESSFUL,
  USERS_DATA_FAILURE,
  USERS_DATA_LOADING,
  USERS_DATA_SUCCESS,
} from '../actions/actionTypes';

import type { UserState } from '../types/state/index';
import type { UserAction, userDeleteConfirmedAction } from '../actions/userActions';
import { USER_DELETE, USER_DELETE_CONFIRMED } from '../actions/userActions';

const initialState: UserState = {
  isBusy: false,
  isEnabled: false,
  users: [],
  viewState: {
    pageNumber: 1,
    pageCount: 1,
    filterModel: [],
    sortModel: [],
  },
  delete: {
    deleted: false,
    isLoading: false,
    error: null,
  },
};

export default function userReducer(state: UserState = initialState, action: UserAction): UserState {
  switch (action.type) {
    case USER_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        isEnabled: false,
      };
    case USER_LOAD_SUCCESSFUL: {
      return {
        ...state,
        isBusy: false,
        isEnabled: true,
        users: action.payload.data._embedded.users,
        viewState: {
          ...state.viewState,
          pageNumber: action.payload.data._links.self.currentPage,
          pageCount: action.payload.data._links.self.pageCount,
        },
      };
    }
    case USER_LOAD_FAILED:
      return {
        ...initialState,
      };
    case UPDATE_USERS_PAGE_NUMBER: {
      return {
        ...state,
        viewState: {
          ...state.viewState,
          pageNumber: action.payload.newPage,
        },
      };
    }
    case UPDATE_USERS_FILTER_MODEL: {
      const { newFilterModel } = action.payload;
      const ignoredParams = ['page', 'sort'];

      return {
        ...state,
        viewState: {
          ...state.viewState,
          filterModel: newFilterModel.filter(filter => ignoredParams.indexOf(filter.id) === -1),
        },
      };
    }
    case UPDATE_USERS_SORT_MODEL: {
      const { newSortModel } = action.payload;
      const ignoredParams = ['page', 'filter'];

      return {
        ...state,
        viewState: {
          ...state.viewState,
          sortModel: newSortModel.filter(sort => ignoredParams.indexOf(sort.id) === -1),
        },
      };
    }
    case USER_DELETE: {
      return handle(state, action, {
        always: prevState => ({
          ...prevState,
          delete: initialState.delete,
        }),
      });
    }
    case USER_DELETE_CONFIRMED: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          delete: {
            ...initialState.delete,
            isLoading: true,
          },
        }),
        finish: prevState => ({
          ...prevState,
          delete: { ...prevState.delete, isLoading: false },
        }),
        failure: (prevState, response: userDeleteConfirmedAction) => ({
          ...prevState,
          delete: { ...prevState.delete, error: response.payload },
        }),
        success: (prevState, response: userDeleteConfirmedAction) => ({
          ...prevState,
          users: prevState.users.filter(u => u.id !== response.payload.id),
          delete: { ...prevState.delete, deleted: true },
        }),
      });
    }
    case USERS_DATA_LOADING:
      return {
        ...state,
        isBusy: true,
      };
    case USERS_DATA_SUCCESS:
      return {
        ...state,
        isBusy: false,
      };
    case USERS_DATA_FAILURE:
      return {
        ...state,
        isBusy: false,
      };
    default:
      return state;
  }
}
