// @flow
import axios from 'axios';
import environment from '../environment';
import {
  loadOrganisationDetailsStarted,
  loadOrganisationDetailsSuccessful,
  loadOrganisationDetailsFailed,
} from '../actions/organisationDetailsActions';
import { handleApiError } from '../actions/errorActions';
import { apiGet } from './api';
import { getApiOptions } from './helpers/options';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { OrganisationDetailsEdit } from '../types/OrganisationDetailsTypes';

const ORGANISATION_DETAILS_URL = `${environment.messagesServiceUrl}/api/v1/organisation-details`;

export function loadOrganisationDetails(organisationId: string): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve organisation details data; config not provided');
    }

    dispatch(loadOrganisationDetailsStarted());

    try {
      const response = await apiGet(ORGANISATION_DETAILS_URL, { organisationId });
      const { profiles, mailboxes } = response.data._embedded;

      dispatch(loadOrganisationDetailsSuccessful(response));
      return { profiles, mailboxes };
    } catch (error) {
      dispatch(loadOrganisationDetailsFailed(error));
      dispatch(handleApiError(error));

      throw error;
    }
  };
}

export function updateOrganisationDetails(organisationDetails: OrganisationDetailsEdit): ThunkAction {
  return async function action(dispatch: Function) {
    try {
      const response = await axios.post(ORGANISATION_DETAILS_URL, organisationDetails, getApiOptions());
      return response;
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}
