// @flow

import axios from 'axios';
import { applicationTypes } from '@nats/nats-service-sdk/lib/types/Applications';
import {
  loadOrganisationsStarted,
  loadOrganisationsSuccessful,
  loadOrganisationsFailed,
} from '../actions/organisationActions';
import type { ThunkAction } from '../types/ReduxActionTypes';
import type { Organisation } from '../types/Organisation';
import { getApiOptions } from './helpers/options';
import environment from '../environment';
import { handleApiError } from '../actions/errorActions';
import { formatFieldName } from '../utilities/format';

const ORGANISATION_URL = `${environment.identityServiceUrl}/api/v1/organisations`;
const ORGANISATION_DETAILS_URL = `${environment.messagesServiceUrl}/api/v1/organisation-details`;
const ORGANISATION_SERIAL_CODES_URL = `${environment.localAvoidsUrl}/api/admin/organisation-serial-codes`;

export type OrganisationResponseType = {
  data: {
    _embedded: {
      organisations: Array<Organisation>,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

export type OrganisationCreateResponseType = {
  data: {
    _embedded: {
      id: string,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

export function loadOrganisations(): ThunkAction {
  return async function action(dispatch: Function) {
    if (!ORGANISATION_URL) {
      throw new Error('Unable to retrieve organisation data; config not provided');
    }

    dispatch(loadOrganisationsStarted());

    try {
      const response = await axios.get(ORGANISATION_URL, getApiOptions());
      const { organisations } = response.data._embedded;

      dispatch(loadOrganisationsSuccessful(organisations));
      return organisations;
    } catch (error) {
      dispatch(loadOrganisationsFailed(error));
      dispatch(handleApiError(error));

      throw error;
    }
  };
}

const licencesMapping = Object.keys(applicationTypes).reduce(
  (acc, app) => ({ ...acc, [`${formatFieldName(app)}Limit`]: app }),
  {}
);

export type CreateOrgObj = {
  name: string,
  visualLimit: string,
  adminLimit: string,
  laraLimit: string,
  messagesLimit: string,
  localAvoidsLimit: string,
  transitRoutesLimit: string,
  wingboardLimit: string,
  messagesFacadeLimit: string,
};

const getLicencesArray = (formValues: CreateOrgObj): Array<Object> =>
  Object.keys(licencesMapping)
    .filter(key => Object.keys(formValues).includes(key) && parseInt(formValues[key], 10) > 0)
    .map(limitStr => ({
      application: licencesMapping[limitStr],
      limit: parseInt(formValues[limitStr], 10),
    }));

export function createOrganisation(formValues: CreateOrgObj): ThunkAction {
  return async function action(dispatch: Function) {
    const organisation = {
      name: formValues.name,
      licences: getLicencesArray(formValues),
    };

    try {
      const response = await axios.post(ORGANISATION_URL, organisation, getApiOptions());
      return response.data._embedded.organisationId;
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export type EditOrgObj = {
  id: string,
  name: string,
  visualLimit: string,
  adminLimit: string,
  laraLimit: string,
  messagesLimit: string,
  localAvoidsLimit: string,
  transitRoutesLimit: string,
  wingboardLimit: string,
  messagesFacadeLimit: string,
};

export function editOrganisation(formValues: EditOrgObj): ThunkAction {
  return async function action(dispatch: Function) {
    const organisation = {
      id: formValues.id,
      name: formValues.name,
      licences: getLicencesArray(formValues),
    };

    try {
      const response = await axios.put(ORGANISATION_URL, organisation, getApiOptions());
      return response;
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export async function deleteOrganisation(organisationId: string) {
  if (!environment.identityServiceUrl || !environment.messagesServiceUrl || !environment.localAvoidsUrl) {
    throw new Error('Unable to delete organisation; config not provided');
  }

  // Delete messages data first so if it fails, the primary organisation record still exists and we're not left with zombie message records
  await axios.delete(`${ORGANISATION_DETAILS_URL}/${organisationId}`, { ...getApiOptions() });

  await axios.delete(`${ORGANISATION_SERIAL_CODES_URL}/${organisationId}`, { ...getApiOptions() });

  await axios.delete(`${ORGANISATION_URL}/${organisationId}`, { ...getApiOptions() });
}
