// @flow

type MenuItem = {
  code: string,
  text: string,
  children?: Array<MenuItem>,
};

type Menu = {
  root: Array<MenuItem>,
};
const menu: Menu = {
  root: [
    {
      code: 'Accounts_User',
      text: 'User',
    },
    {
      code: 'Soap_User',
      text: 'SOAP User',
    },
    {
      code: 'Organisation',
      text: 'Organisation',
    },
    {
      code: 'Message_Profiles',
      text: 'Message Profile',
    },
    {
      code: 'Message_Mailbox',
      text: 'Message Mailbox',
    },
  ],
};

export default menu;
