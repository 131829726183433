// @flow
import { apiGet, apiPut } from './api';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes';

const AUTHORITY_URL = `${environment.identityServiceUrl}/api/v1/authorities`;

export const getAuthorities = () => {
  return apiGet(AUTHORITY_URL);
};

export type EditAuthorityObj = {
  authority_id: string,
  authority_name: string,
  application: string,
  allocated_licences: number,
  unallocated_licences: number,
  max_licences: number,
};

export function editAuthority(formValues: EditAuthorityObj): ThunkAction {
  return async function action() {
    const authority = {
      id: formValues.authority_id,
      application: formValues.application.toUpperCase(),
      max_licences: Number(formValues.max_licences),
    };

    await apiPut(AUTHORITY_URL, authority);
  };
}
