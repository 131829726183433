// @flow
import environment from '~/environment';
import { apiGet } from '~/api/api';
import type { SoapMailbox } from '~/types/SoapMailbox';

const SOAP_USERS_MAILBOX_URL = `${environment.soapApiUrl}/api/v1/mailboxes`;

export type LoadSoapMailboxResponse = {
  data: {
    _embedded: SoapMailbox,
    _links: {
      self: {
        href: string,
      },
    },
  },
};

export async function loadSoapMailbox(mailboxAddress: string): Promise<LoadSoapMailboxResponse> {
  if (!environment.soapApiUrl) {
    throw new Error('Unable to retrieve SOAP user mailbox data; config not provided');
  }

  return apiGet(`${SOAP_USERS_MAILBOX_URL}/${mailboxAddress.toUpperCase()}`);
}
