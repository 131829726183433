// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { DataTable, Notification } from '@nats/webclient-common';

import { loadRoles } from '~/api/roleApi';
import { setPageHeaderControls } from '~/actions/headerControlActions';
import type { RoleInfo, RoleDataTableRecord } from '~/types/RoleType';
import type { PageHeaderControls } from '~/types/state';
import { canEditMessageProfile } from '~/utilities/permissions';
import { selectPanel } from '../../actions/panelActions';
import { setPageTitle, setSelectedRecord } from '../../actions/appActions';
import { setModal, setModalForm, toggleModalVisible } from '../../actions/modalActions';
import getErrorMessageForErrorCode from '../../utilities/errorCodeMapping';
import { exportToCSV } from '../../utilities/exportToCSV';
import messageProfilesMapperModule from '../../utilities/csvMappers/messageProfilesMapper';
import { FORM_CODE, FORM_MODE } from '../../components/forms/getFormFromType';
import type { FormCode, FormMode } from '../../components/forms/getFormFromType';
import type { State as Store } from '../../types/ReduxStateType';

type State = {
  errorNotificationId: string | null,
};

type Props = {
  isLoading: boolean,
  roles: Array<RoleInfo>,
  error: ?Error,
  loadRoles: () => void,
  setPageTitle: string => void,
  selectedRecord: RoleInfo,
  selectPanel: (?FormCode, {}) => mixed,
  setSelectedRecord: RoleInfo => mixed,
  setPageHeaderControls: PageHeaderControls => void,
  toggleModalVisible: () => void,
  setModalForm: (FormCode, FormMode, {}) => void,
  pageTitle: string,
};

class MessageProfilesScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorNotificationId: null,
    };

    this.props.setPageHeaderControls(this.getSubheaderControls());
  }

  clearErrorNotificationIfSet = () => {
    if (this.state.errorNotificationId) {
      Notification.dismiss(this.state.errorNotificationId);
      this.setState({ errorNotificationId: null });
    }
  };

  async componentDidMount() {
    this.props.setPageTitle('Message Profiles');

    try {
      await this.props.loadRoles();
    } catch (error) {
      this.clearErrorNotificationIfSet();

      const notificationId = Notification.error(getErrorMessageForErrorCode(get(error, 'response.data.error')), {
        autoClose: false,
      });
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ errorNotificationId: notificationId });
    }
  }

  componentWillUnmount() {
    this.clearErrorNotificationIfSet();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.selectedRecord !== prevProps.selectedRecord || this.props.isLoading !== prevProps.isLoading) {
      const controls = this.getSubheaderControls();
      this.props.setPageHeaderControls(controls);
    }
  }

  handleClickEdit = () => {
    const { selectedRecord } = this.props;
    if (!selectedRecord) {
      return;
    }

    this.props.toggleModalVisible();
    this.props.setModalForm(FORM_CODE.MESSAGE_PROFILES, FORM_MODE.EDIT, {
      roleName: selectedRecord.name,
    });
  };

  exportViewClickHandler = () => {
    const { pageTitle, roles } = this.props;
    const dataToExport = messageProfilesMapperModule.mapMessageProfiles(roles);

    exportToCSV(pageTitle, dataToExport);
  };

  getSubheaderControls = (): PageHeaderControls => {
    const { selectedRecord, roles, isLoading, error } = this.props;

    const exportControls = {
      name: 'Export',
      icon: ['fas', 'download'],
      onClick: this.exportViewClickHandler,
      isDisabled: isLoading || roles.length === 0 || !!error,
    };

    const editControls = {
      name: 'Edit',
      icon: ['fab', 'wpforms'],
      onClick: this.handleClickEdit,
      isDisabled: !canEditMessageProfile(),
    };

    return selectedRecord ? [[exportControls, editControls]] : [[exportControls]];
  };

  selectRow = (roleRecord: RoleDataTableRecord) => {
    if (!roleRecord) {
      return;
    }

    const { _original: role } = roleRecord;

    this.props.setSelectedRecord(role);
    this.props.selectPanel(FORM_CODE.MESSAGE_PROFILES, { roleName: role.name });
  };

  isRowSelected = (role: RoleInfo): boolean => {
    return !!(this.props.selectedRecord && this.props.selectedRecord.name === role.name);
  };

  render() {
    const tableColumns = [
      {
        Header: 'Profile Name',
        id: 'profileName',
        accessor: (role: RoleInfo) => role.name,
      },
    ];

    return (
      <DataTable
        columns={tableColumns}
        tableData={this.props.roles}
        isLoading={this.props.isLoading}
        sortable={false}
        filterable={false}
        viewState={{ pageNumber: 1, pageCount: 1 }}
        updatePageNumber={noop}
        updateFilterModel={noop}
        onRowSelected={this.selectRow}
        getTdClass={(state, rowInfo) => `${this.isRowSelected(rowInfo.original) ? 'selectedRow' : ''}`}
      />
    );
  }
}

export const mapStateToProps = (state: Store) => ({
  pageTitle: state.app.title,
  isLoading: state.roleState.isLoading,
  roles: state.roleState.roles,
  error: state.roleState.error,
  selectedRecord: state.app.selectedRecord,
});

export const mapDispatchToProps = {
  loadRoles,
  setPageTitle,
  selectPanel,
  setSelectedRecord,
  setPageHeaderControls,
  toggleModalVisible,
  setModalForm,
  setModal,
};

export { MessageProfilesScreen as PureMessageProfilesScreen };

export default connect(mapStateToProps, mapDispatchToProps)(MessageProfilesScreen);
