// @flow

import { SET_PAGE_TITLE, SET_SELECTED_RECORD } from './actionTypes';

type setPageTitleAction = { type: typeof SET_PAGE_TITLE, newTitle: string };
type setSelectedRecordAction = { type: typeof SET_SELECTED_RECORD, selectedRecord: ?{} };

export type AppAction = setPageTitleAction | setSelectedRecordAction;

export function setPageTitle(newTitle: string): setPageTitleAction {
  return {
    type: SET_PAGE_TITLE,
    newTitle,
  };
}

export function setSelectedRecord(selectedRecord: ?{}): setSelectedRecordAction {
  return {
    type: SET_SELECTED_RECORD,
    selectedRecord,
  };
}
