// @flow

import {
  MESSAGE_PARAMETERS_LOAD_STARTED,
  MESSAGE_PARAMETERS_LOAD_SUCCESSFUL,
  MESSAGE_PARAMETERS_LOAD_FAILED,
  MESSAGE_PARAMETERS_EDIT_FAILED,
} from '../actions/actionTypes';

import type { MessageParametersAction } from '../types/MessageParameterTypes';

import type { MessageParametersState } from '../types/state';

export const initialState: MessageParametersState = {
  isBusy: false,
  error: null,
  isFetchError: null,
  id: '',
  notamOffice: '',
  notamOfficeMailbox: '',
  aspCollectiveAddresses: '',
  ifpsFpl: [],
  fplValidationUnit: '',
  ifpsAftm: [],
  ifpsStates: '',
  vfrDepMailboxSuffix: '',
  vfrDestMailboxSuffix: '',
  vfrAltnMailboxSuffix: '',
  savedByUsername: '',
  updatedTime: '',
};

export default function messageParametersReducer(
  state: MessageParametersState = initialState,
  action: MessageParametersAction
): MessageParametersState {
  switch (action.type) {
    case MESSAGE_PARAMETERS_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        error: null,
        isFetchError: null,
      };
    case MESSAGE_PARAMETERS_LOAD_FAILED:
    case MESSAGE_PARAMETERS_EDIT_FAILED: {
      const isFetchError = action.type === MESSAGE_PARAMETERS_LOAD_FAILED;
      const { error } = action;
      return { ...state, isBusy: false, error, isFetchError };
    }
    case MESSAGE_PARAMETERS_LOAD_SUCCESSFUL: {
      const { messageParameters } = action.payload.data._embedded;
      return { ...state, isBusy: false, error: null, isFetchError: null, ...messageParameters };
    }
    default:
      return state;
  }
}
