// @flow
import React from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { MemoryRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import History from './history/history';

type GlobalProps = {
  children: React$Node,
  store?: Object,
  routePath?: string,
};

// Define any global providers etc.
// Use for both tests and production code
const Global = (props: GlobalProps) => {
  const content = props.children;

  if (!props.store) {
    return content;
  }

  // If a routePath prop is specified, then use a memory router starting at that path
  const routed = ui =>
    props.routePath ? (
      <MemoryRouter initialEntries={[props.routePath]}>{ui}</MemoryRouter>
    ) : (
      <ConnectedRouter history={History}>{ui}</ConnectedRouter>
    );

  return <Provider store={props.store}>{routed(content)}</Provider>;
};

Global.defaultProps = {
  store: null,
  routePath: '',
};

export default Global;
