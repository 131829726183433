// @flow
import { saveCSVFile } from '../utilities/exportToCSV';

import {
  MESSAGE_PARAMETERS_AUDIT_LOAD_STARTED,
  MESSAGE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL,
  MESSAGE_PARAMETERS_AUDIT_LOAD_FAILED,
} from './actionTypes';

import type {
  LoadStartedAction,
  LoadSuccessAction,
  LoadFailedAction,
  LoadMessageParametersAuditResponse,
} from '../types/MessageParameterAuditTypes';

export const saveAuditToFile = (csvData: string): void => {
  saveCSVFile('Message Parameters Audit', csvData);
};

export const loadMessageParametersAuditStarted = (): LoadStartedAction => {
  return {
    type: MESSAGE_PARAMETERS_AUDIT_LOAD_STARTED,
  };
};

export const loadMessageParametersAuditSuccessful = (
  response: LoadMessageParametersAuditResponse
): LoadSuccessAction => {
  saveAuditToFile(response.data._embedded.auditLog);
  return {
    type: MESSAGE_PARAMETERS_AUDIT_LOAD_SUCCESSFUL,
    payload: response,
  };
};

export const loadMessageParametersAuditFailed = (error: string): LoadFailedAction => {
  return {
    type: MESSAGE_PARAMETERS_AUDIT_LOAD_FAILED,
    error,
  };
};
