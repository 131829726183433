// @flow
import { helper } from '@nats/webclient-common';

import { sortModelToUrl } from '@nats/webclient-common/lib/helpers/Urls';
import { applicationTypes } from '@nats/nats-service-sdk/lib/types/Applications';
import { userTypes } from '@nats/nats-service-sdk/lib/types/User';
import { USER_DETAILS_URL } from '~/api/userDetailsApi';
import type { UserServiceConfig } from '~/types/MessageFacade';

import { loadUsersStarted, loadUsersFailed, loadUsersSuccessful } from '../actions/userActions';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes';
import type { BasicUser } from '../types/User';
import type { UserOrganisationMailbox } from '../types/Mailbox';
import { handleApiError } from '../actions/errorActions';
import { apiGet, apiDelete, apiPost, apiPut } from './api';
import { editFacadeUser, deleteMessageFacadeUser, createFacadeUser } from './messageFacadeApi';
import { canDeleteMessageFacadeUser } from '../utilities/permissions';

export const USERS_URL = `${environment.identityServiceUrl}/api/v1/users`;

export type LoadUsersResponse = {
  data: {
    _embedded: {
      users: Array<BasicUser>,
    },
    _links: {
      self: {
        href: string,
        currentPage: number,
        pageCount: number,
      },
      nextPage: {
        href: string,
      },
    },
  },
};

export function loadUsers(): ThunkAction {
  return function action(dispatch: Function, getState: Function) {
    if (!environment.identityServiceUrl) {
      throw new Error('Unable to retrieve user data; config not provided');
    }

    dispatch(loadUsersStarted());

    const { userState } = getState();
    const { viewState } = userState;
    const selectionParams = viewState.sortModel.length !== 0 ? { sort: sortModelToUrl(viewState.sortModel) } : {};
    const filterParams = helper.Urls.filterModelToUrlParams(viewState.filterModel);

    // TODO we should move the display name to the identity service for the type so we don't have to use replace here
    if (filterParams.type) {
      filterParams.type = filterParams.type.trim().replace(/ /g, '_');
    }

    return apiGet(USERS_URL, {
      ...filterParams,
      ...selectionParams,
      page: viewState.pageNumber,
    })
      .then((response: LoadUsersResponse) => {
        dispatch(loadUsersSuccessful(response));
      })
      .catch(error => {
        dispatch(loadUsersFailed());
        dispatch(handleApiError(error));

        throw error;
      });
  };
}

export type UserFormValues = {
  userId: string,
  username: string,
  email: string,
  organisationId: ?string,
  userType: string,
  adminLicence: boolean,
  laraLicence: boolean,
  localAvoidsLicence: boolean,
  messagesLicence: boolean,
  messagesFacadeLicence: boolean,
  transitRoutesLicence: boolean,
  visualLicence: boolean,
  wingboardLicence: boolean,
  profile: string,
  mailboxes: Array<UserOrganisationMailbox>,
  defaultMailbox: string,
  userConfigRecord: UserServiceConfig,
  mailboxToAddress0: string,
  mailboxToDescription0: string,
  mailboxToAddress1: string,
  mailboxToDescription1: string,
  mailboxToAddress2: string,
  mailboxToDescription2: string,
};

const licencesMapping = {
  adminLicence: applicationTypes.ADMIN,
  laraLicence: applicationTypes.LARA,
  localAvoidsLicence: applicationTypes.LOCAL_AVOIDS,
  messagesLicence: applicationTypes.MESSAGES,
  transitRoutesLicence: applicationTypes.TRANSIT_ROUTES,
  visualLicence: applicationTypes.VISUAL,
  wingboardLicence: applicationTypes.WINGBOARD,
  messagesFacadeLicence: applicationTypes.MESSAGES_FACADE,
};

const getLicencesArray = (formValues: UserFormValues): Array<string> =>
  Object.keys(licencesMapping)
    .filter(key => Object.keys(formValues).includes(key) && formValues[key])
    .map(licence => licencesMapping[licence]);

export function createUser(formValues: UserFormValues): ThunkAction {
  return async function action(dispatch: Function) {
    const {
      username,
      email,
      userType,
      organisationId,
      messagesLicence,
      messagesFacadeLicence,
      profile,
      mailboxes,
      defaultMailbox,
      userConfigRecord,
    } = formValues;

    // Helpdesk users do not have an organisation, but it may have been left over when the user is editing the form.
    const orgId = userType !== userTypes.NATS_HELP_DESK ? organisationId : undefined;

    const user = {
      username,
      email,
      userType,
      defaultMailbox,
      organisationId: orgId,
      licences: getLicencesArray(formValues),
    };

    try {
      const postUserResponse = await apiPost(USERS_URL, user);

      if (messagesLicence) {
        const { userId } = postUserResponse.data._embedded;

        const userDetails = { userId, userType, profile: { id: profile }, mailboxes };

        await apiPut(USER_DETAILS_URL, userDetails);
      }

      if (messagesFacadeLicence && userConfigRecord != null) {
        await createFacadeUser(userConfigRecord);
      }
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export function editUser(formValues: UserFormValues): ThunkAction {
  return async function action(dispatch: Function) {
    const {
      userId,
      username,
      userType,
      messagesLicence,
      messagesFacadeLicence,
      profile,
      mailboxes,
      defaultMailbox,
      userConfigRecord,
    } = formValues;

    const user = {
      userId,
      userType,
      defaultMailbox,
      licences: getLicencesArray(formValues),
    };

    try {
      await apiPut(USERS_URL, user);

      const userDetails = messagesLicence
        ? { userId, userType, profile: { id: profile }, mailboxes }
        : { userId, userType, profile: null, mailboxes: [] };

      await apiPut(USER_DETAILS_URL, userDetails);

      if (messagesFacadeLicence) {
        await editFacadeUser(userConfigRecord);
      } else if (canDeleteMessageFacadeUser()) {
        await deleteMessageFacadeUser(username);
      }
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export const deleteUser = async (userId: string, username: string, isFacadeUser: boolean) => {
  // Delete messages Facade data first so if it fails, the primary user record still exists and we're not left with zombie message records
  if (isFacadeUser) {
    await deleteMessageFacadeUser(username);
  }
  // Delete messages data before identity user data so if it fails,
  // the primary user record still exists and we're not left with zombie message records
  await apiDelete(`${USER_DETAILS_URL}/${userId}`);
  await apiDelete(`${environment.identityServiceUrl}/api/v1/users/${userId}`);
  return { id: userId };
};
