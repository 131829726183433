// @flow
import { handle } from 'redux-pack';
import { GET_AUTHORITIES } from '../actions/authorityActions';
import type { AuthorityState } from '../types/state/index';
import type {
  AuthorityAction,
  GetAuthoritiesActionSuccess,
  GetAuthoritiesActionFailure,
} from '../actions/authorityActions';

const initialState: AuthorityState = {
  isLoading: false,
  authorities: [],
  error: null,
};

export default function authorityReducer(
  state: AuthorityState = initialState,
  action: AuthorityAction
): AuthorityState {
  switch (action.type) {
    case GET_AUTHORITIES: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          isLoading: true,
        }),
        finish: prevState => ({
          ...prevState,
          isLoading: false,
        }),
        failure: (prevState, response: GetAuthoritiesActionFailure) => ({
          ...prevState,
          authorities: initialState.authorities,
          error: response.payload,
        }),
        success: (prevState, response: GetAuthoritiesActionSuccess) => ({
          ...prevState,
          authorities: response.payload.data._embedded.authorities,
          error: initialState.error,
        }),
      });
    }
    default:
      return state;
  }
}
