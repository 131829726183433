// @flow
import { handle } from 'redux-pack';
import {
  MAILBOX_CREATE_FAILED,
  MAILBOX_CREATE_STARTED,
  MAILBOX_CREATE_SUCCESSFUL,
  MAILBOX_LOAD_FAILED,
  MAILBOX_LOAD_STARTED,
  MAILBOX_LOAD_SUCCESSFUL,
  MAILBOX_DELETE_PENDING,
  MAILBOX_DELETE_CONFIRMED,
  MAILBOX_DELETE_RESET,
} from '../actions/actionTypes';
import type { MailboxState } from '../types/state';
import type { MailboxAction, MailboxDeleteConfirmedAction } from '../actions/mailboxActions';

const initialState: MailboxState = {
  isBusy: false,
  error: null,
  mailboxes: [],
  delete: {
    deleted: false,
    isLoading: false,
    error: null,
  },
  timeCreated: null,
};

export default function mailboxReducer(state: MailboxState = initialState, action: MailboxAction): MailboxState {
  switch (action.type) {
    case MAILBOX_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case MAILBOX_LOAD_SUCCESSFUL: {
      const { mailboxes, timeCreated } = action.payload;
      return {
        ...state,
        isBusy: false,
        mailboxes,
        timeCreated,
      };
    }
    case MAILBOX_LOAD_FAILED:
      return {
        ...initialState,
      };
    case MAILBOX_CREATE_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case MAILBOX_CREATE_SUCCESSFUL:
      return {
        ...state,
        isBusy: false,
      };
    case MAILBOX_CREATE_FAILED:
      return {
        ...state,
        isBusy: false,
        error: action.error,
      };
    case MAILBOX_DELETE_PENDING: {
      return handle(state, action, {
        always: prevState => ({
          ...prevState,
          delete: initialState.delete,
        }),
      });
    }
    case MAILBOX_DELETE_RESET:
      return {
        ...state,
        delete: {
          ...initialState.delete,
        },
      };
    case MAILBOX_DELETE_CONFIRMED: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          delete: {
            ...initialState.delete,
            isLoading: true,
          },
        }),
        finish: prevState => ({
          ...prevState,
          delete: { ...prevState.delete, isLoading: false },
        }),
        failure: (prevState, response: MailboxDeleteConfirmedAction) => ({
          ...prevState,
          delete: { ...prevState.delete, error: response.payload },
        }),
        success: (prevState, response: MailboxDeleteConfirmedAction) => ({
          ...prevState,
          mailboxes: prevState.mailboxes.filter(u => u.id !== response.payload.id),
          delete: { ...prevState.delete, deleted: true },
        }),
      });
    }
    default:
      return state;
  }
}
