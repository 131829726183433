// @flow
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import moment from 'moment';
import { Switch } from '@nats/webclient-common';
import { connect } from 'react-redux';
import type { FeatureToggle } from '../../../types/FeatureTogglesTypes';
import styles from './FeatureTogglesSection.module.scss';
import {
  setModal as setModalAction,
  toggleModalVisible as toggleModalVisibleAction,
} from '../../../actions/modalActions';
import ConfirmationModal from './ConfirmationModal';

type Props = {
  featureToggle: FeatureToggle,
  setModal: Function,
  toggleModalVisibility: Function,
};

export function FeatureToggleSection({ featureToggle, setModal, toggleModalVisibility }: Props) {
  function onChange() {
    toggleModalVisibility();
    setModal(featureToggle.name, <ConfirmationModal featureToggle={featureToggle} />);
  }

  const timestamp = moment(featureToggle.updatedAt).utc().format('YYYY.MM.DD HH:mm');

  return (
    <div data-qa-id={`feature-toggle-section-${featureToggle.id}`} className={styles.section}>
      <p className={styles.sectionHeading}>{featureToggle.name}</p>
      <div className={styles.sectionContents}>
        <div className={styles.contentLeft}>
          <p className={styles.label}>Description</p>
          <p className={`${styles.description} ${styles.input}`}>{featureToggle.description}</p>
        </div>
        <div className={styles.contentRight}>
          <div>
            <p className={styles.label}>Status</p>
            <Switch isChecked={featureToggle.enabled} onChange={onChange} />
          </div>
          <div className={styles.bottom}>
            <p className={styles.label}>Last Changed</p>
            <p className={styles.input}>{timestamp}</p>
          </div>
        </div>
      </div>
      <div className={styles.spacer} />
    </div>
  );
}

const mapDispatchToProps = {
  setModal: setModalAction,
  toggleModalVisibility: toggleModalVisibleAction,
};

export default connect(null, mapDispatchToProps)(FeatureToggleSection);
