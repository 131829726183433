// @flow

import type { LoadMailboxesPayload } from '~/api/mailboxApi';
import { deleteMailbox as deleteMailboxApi } from '~/api/mailboxApi';
import {
  MAILBOX_LOAD_FAILED,
  MAILBOX_LOAD_STARTED,
  MAILBOX_LOAD_SUCCESSFUL,
  MAILBOX_CREATE_FAILED,
  MAILBOX_CREATE_STARTED,
  MAILBOX_CREATE_SUCCESSFUL,
  MAILBOX_EDIT_FAILED,
  MAILBOX_EDIT_STARTED,
  MAILBOX_EDIT_SUCCESSFUL,
  MAILBOX_DELETE_PENDING,
  MAILBOX_DELETE_CONFIRMED,
  MAILBOX_DELETE_RESET,
} from './actionTypes';

type loadStartedAction = { type: typeof MAILBOX_LOAD_STARTED };
type loadSuccessAction = {
  type: typeof MAILBOX_LOAD_STARTED,
  payload: LoadMailboxesPayload,
};
type LoadFailedAction = { type: typeof MAILBOX_LOAD_FAILED };
type CreateStartedAction = { type: typeof MAILBOX_CREATE_STARTED };
type CreateSuccessAction = { type: typeof MAILBOX_CREATE_SUCCESSFUL };
type CreateFailedAction = { type: typeof MAILBOX_CREATE_FAILED, error: string };
type EditStartedAction = { type: typeof MAILBOX_EDIT_STARTED };
type EditSuccessAction = { type: typeof MAILBOX_EDIT_SUCCESSFUL };
type EditFailedAction = { type: typeof MAILBOX_EDIT_FAILED, error: string };

export type MailboxDeletePendingAction = {
  type: typeof MAILBOX_DELETE_PENDING,
  payload: { id: string },
};

export type MailboxDeleteConfirmedAction = {
  type: typeof MAILBOX_DELETE_CONFIRMED,
  payload: any,
};

export type MailboxAction = loadStartedAction | loadSuccessAction | LoadFailedAction;

export function loadMailboxesStarted(): loadStartedAction {
  return {
    type: MAILBOX_LOAD_STARTED,
  };
}

export function loadMailboxesSuccessful(response: LoadMailboxesPayload): loadSuccessAction {
  return {
    type: MAILBOX_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadMailboxesFailed(): LoadFailedAction {
  return {
    type: MAILBOX_LOAD_FAILED,
  };
}

export function createMailboxesStarted(): CreateStartedAction {
  return {
    type: MAILBOX_CREATE_STARTED,
  };
}

export function createMailboxesSuccessful(): CreateSuccessAction {
  return {
    type: MAILBOX_CREATE_SUCCESSFUL,
  };
}

export function createMailboxesFailed(error: string): CreateFailedAction {
  return {
    type: MAILBOX_CREATE_FAILED,
    error,
  };
}

export function editMailboxesStarted(): EditStartedAction {
  return {
    type: MAILBOX_EDIT_STARTED,
  };
}

export function editMailboxesSuccessful(): EditSuccessAction {
  return {
    type: MAILBOX_EDIT_SUCCESSFUL,
  };
}

export function editMailboxesFailed(error: string): EditFailedAction {
  return {
    type: MAILBOX_EDIT_FAILED,
    error,
  };
}

export const setMailboxToBeDeleted = (id: string) => ({
  type: MAILBOX_DELETE_PENDING,
  promise: Promise.resolve({ id }),
});

export const deleteMailboxConfirm = (id: string) => ({
  type: MAILBOX_DELETE_CONFIRMED,
  promise: deleteMailboxApi(id),
});

export const deleteMailboxReset = () => ({
  type: MAILBOX_DELETE_RESET,
});
