// @flow

import { Notification } from '@nats/webclient-common';
import environment from '../environment';
import type { ThunkAction } from '../types/ReduxActionTypes';
import {
  loadFeatureTogglesFailed,
  loadFeatureTogglesStarted,
  loadFeatureTogglesSuccessful,
  updateFeatureTogglesFailed,
  updateFeatureTogglesStarted,
  updateFeatureTogglesSuccessful,
} from '../actions/FeatureTogglesActions';
import { apiGet, apiPut } from './api';
import type { FeatureTogglesUpdate } from '../types/FeatureTogglesTypes';

export const FEATURE_TOGGLES_URL = `${environment.messagesServiceUrl}/api/v1/feature-toggles`;

const errorMessage =
  'An error occurred. To try again please refresh your browser. ' +
  'If this persists please contact your organisation admin.';

export function loadFeatureToggles(): ThunkAction {
  return (dispatch: Function) => {
    dispatch(loadFeatureTogglesStarted());
    return apiGet(FEATURE_TOGGLES_URL)
      .then(response => dispatch(loadFeatureTogglesSuccessful(response.data._embedded)))
      .catch(err => {
        dispatch(loadFeatureTogglesFailed(err.response.data));
        Notification.error(errorMessage);
      });
  };
}

export function updateFeatureToggles(data: Array<FeatureTogglesUpdate>): ThunkAction {
  return (dispatch: Function) => {
    dispatch(updateFeatureTogglesStarted());
    return apiPut(FEATURE_TOGGLES_URL, data)
      .then(response => {
        dispatch(updateFeatureTogglesSuccessful(response.data._embedded));
        Notification.success('Feature toggles updated successfully.');
        dispatch(loadFeatureToggles());
      })
      .catch(err => {
        dispatch(updateFeatureTogglesFailed(err.response.data));
        Notification.error(errorMessage);
      });
  };
}
