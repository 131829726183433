// @flow
import get from 'lodash/get';

import {
  loadMessageParametersStarted,
  loadMessageParametersSuccessful,
  loadMessageParametersFailed,
  editMessageParametersSuccessful,
  editMessageParametersFailed,
} from '../actions/messageParametersActions';
import environment from '../environment';
import { apiGet, apiPost } from './api';

import type { ThunkAction } from '../types/ReduxActionTypes';
import type { LoadMessageParametersResponse, NewMessageParameters } from '../types/MessageParameterTypes';

export const MESSAGE_PARAMETERS_URL = `${environment.messagesServiceUrl}/api/v1/message-parameters`;

export function loadMessageParameters(): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve Message Parameters; config not provided');
    }

    dispatch(loadMessageParametersStarted());

    return apiGet(MESSAGE_PARAMETERS_URL)
      .then((response: LoadMessageParametersResponse) => dispatch(loadMessageParametersSuccessful(response)))
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(loadMessageParametersFailed(errorCode));
      });
  };
}

export function editMessageParameters(newMessageParameters: NewMessageParameters): ThunkAction {
  return function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to edit Message Parameters; config not provided');
    }

    return apiPost(MESSAGE_PARAMETERS_URL, newMessageParameters)
      .then(() => {
        editMessageParametersSuccessful();
      })
      .catch(error => {
        const errorCode = get(error, 'response.data.error', '');
        dispatch(editMessageParametersFailed(errorCode));
      });
  };
}
