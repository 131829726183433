// @flow
import { apiGet } from '../api/api';
import environment from '../environment';

const { identityServiceUrl } = environment;

const MESSAGES_HELP_API_URL = `${identityServiceUrl}/api/v1/help`;

export default function getHelp(): () => Promise<string> {
  return apiGet(MESSAGES_HELP_API_URL);
}
