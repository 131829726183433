// @flow
import { merge, cloneDeep } from 'lodash';
import type { FeatureTogglesState } from '../types/state';
import {
  LOAD_FEATURE_TOGGLES_FAILED,
  LOAD_FEATURE_TOGGLES_STARTED,
  LOAD_FEATURE_TOGGLES_SUCCESSFUL,
  UPDATE_FEATURE_TOGGLES_FAILED,
  UPDATE_FEATURE_TOGGLES_STARTED,
  UPDATE_FEATURE_TOGGLES_SUCCESSFUL,
} from '../actions/FeatureTogglesActions';

type Actions = any;

const initialState: FeatureTogglesState = {
  featureToggles: [],
  formLoading: false,
  loadError: false,
  loading: false,
};

export default function featureTogglesReducer(
  state: FeatureTogglesState = initialState,
  action: Actions
): FeatureTogglesState {
  switch (action.type) {
    case LOAD_FEATURE_TOGGLES_STARTED:
      return { ...state, loading: true };

    case LOAD_FEATURE_TOGGLES_SUCCESSFUL:
      return { ...state, featureToggles: action.payload, loading: false };

    case LOAD_FEATURE_TOGGLES_FAILED:
      return { ...state, loadError: true, loading: false };

    case UPDATE_FEATURE_TOGGLES_STARTED:
      return { ...state, formLoading: true };

    case UPDATE_FEATURE_TOGGLES_SUCCESSFUL:
      return {
        ...state,
        featureToggles: merge(cloneDeep(state.featureToggles), action.payload),
        loading: false,
        formLoading: false,
      };

    case UPDATE_FEATURE_TOGGLES_FAILED:
      return { ...state, loading: false, formLoading: false };

    default:
      return state;
  }
}
