// @flow
import React from 'react';
import get from 'lodash/get';
import { DataTable, Portal } from '@nats/webclient-common';
import { FORM_MODE } from '~/components/forms/getFormFromType';

import type { UserOrganisationMailbox } from '~/types/Mailbox';
import type { FormMode } from '~/components/forms/getFormFromType';

import './UserOrganisationMailboxTable.module.scss';

const NO_DATA_TEXT = "No mailboxes assigned to user's organisation";

type Props = {
  portalId: ?string,
  mailboxes: Array<UserOrganisationMailbox>,
  mode: FormMode,
  onIsAssignedChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onIsDefaultChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
  onIsMonitoredChange: (e: SyntheticInputEvent<HTMLInputElement>) => void,
};

const getInputCell = (
  mailboxAddress: string,
  columnProperty: string,
  inputType: string,
  value: boolean,
  isDisabled: boolean = true,
  onChangeHandler: Function
) => (
  <input
    id={mailboxAddress}
    name={columnProperty}
    type={inputType}
    checked={value}
    disabled={isDisabled}
    onChange={onChangeHandler}
  />
);

const textColumns: Array<any> = [
  {
    id: 'address',
    Header: 'Address',
    property: 'address',
    className: 'addressColumn',
    width: 80,
  },
  {
    id: 'name',
    Header: 'Name',
    property: 'description',
    className: 'ellipsesText',
    width: undefined,
  },
].map(({ id, Header, property, className, width }) => ({
  accessor: (mailbox: UserOrganisationMailbox) => get(mailbox, property),
  className,
  Header,
  id,
  property,
  width,
  resizable: false,
}));

const getInputColumns = (
  isDisabled: boolean = true,
  assignedCount: number,
  mailboxes: Array<UserOrganisationMailbox>,
  onIsAssignedChangeHandler: Function,
  onIsDefaultChangeHandler: Function,
  onIsMonitoredChangeHandler: Function
): Array<any> => {
  return [
    {
      Header: 'Assigned',
      property: 'isAssigned',
      Cell: (props: any) => {
        const { column, original, value } = props;

        return getInputCell(original.address, column.id, 'checkbox', value, isDisabled, onIsAssignedChangeHandler);
      },
    },
    {
      Header: 'Default',
      property: 'isDefault',
      Cell: (props: any) => {
        const { column, original, value } = props;
        const mailbox = mailboxes.find(m => m.address === original.address);
        const isMailboxAssigned = mailbox ? mailbox.isAssigned : false;
        const isInputDisabled = isDisabled || !isMailboxAssigned || (isMailboxAssigned && assignedCount < 2);

        return getInputCell(original.address, column.id, 'radio', value, isInputDisabled, onIsDefaultChangeHandler);
      },
    },
    {
      Header: 'Monitored',
      property: 'isMonitored',
      Cell: (props: any) => {
        const { column, original, value } = props;
        const mailbox = mailboxes.find(m => m.address === original.address);
        const isMailboxAssigned = mailbox ? mailbox.isAssigned : false;
        const isInputDisabled = isDisabled || !isMailboxAssigned;
        return getInputCell(
          original.address,
          column.id,
          'checkbox',
          value,
          isInputDisabled,
          onIsMonitoredChangeHandler
        );
      },
    },
  ].map(({ Header, property, Cell }) => ({
    accessor: (mailbox: UserOrganisationMailbox) => get(mailbox, property),
    Cell,
    className: 'toggleInput',
    Header,
    headerClassName: 'toggleInputHeader',
    id: property,
    maxWidth: 75,
    property,
    resizable: false,
  }));
};

const getClassName = (rowCount: number) =>
  [
    ...(rowCount === 0 ? ['userOrganisationMailboxTableNoData'] : []),
    ...(rowCount > 5 ? ['userOrganisationMailboxTableScroll'] : []),
  ]
    .reduce((acc, val) => acc.concat(val), ['userOrganisationMailboxTable'])
    .join(' ');

const UserOrganisationMailboxTable = (props: Props) => {
  const { portalId, mailboxes, mode, onIsAssignedChange, onIsDefaultChange, onIsMonitoredChange } = props;

  const isDisabled = mode === FORM_MODE.VIEW;

  const assignedCount = mailboxes.filter(m => m.isAssigned).length;

  const inputColumns = getInputColumns(
    isDisabled,
    assignedCount,
    mailboxes,
    onIsAssignedChange,
    onIsDefaultChange,
    onIsMonitoredChange
  );

  const mailboxTable = (
    <DataTable
      className={getClassName(mailboxes.length)}
      isClientSide
      sortable={false}
      filterable={false}
      resizable={false}
      disableFilteringAndPagination
      noDataText={NO_DATA_TEXT}
      tableData={mailboxes}
      columns={[...textColumns, ...inputColumns]}
    />
  );

  return portalId ? <Portal to={portalId}>{mailboxTable}</Portal> : mailboxTable;
};

export default UserOrganisationMailboxTable;
