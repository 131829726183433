// @flow
import type { RoleInfo, RoleInfoExport, RolePermissions } from '~/types/RoleType';
import upperFirst from 'lodash/upperFirst';

const formatPermissionValues = (permissions: Array<RolePermissions>): RoleInfoExport => {
  const permissionsForRole = {};

  permissions.forEach(rolePermission => {
    permissionsForRole[rolePermission.name.replace('/', '')] = upperFirst(String(rolePermission.value));
  });

  return permissionsForRole;
};

const mapMessageProfiles = (roles: Array<RoleInfo>): Array<RoleInfoExport> => {
  return roles.map(role => ({
    name: role.name,
    description: role.description,
    ...formatPermissionValues(role.permissions),
  }));
};

const messageProfilesMapperModule = {
  mapMessageProfiles,
};

export default messageProfilesMapperModule;
