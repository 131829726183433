// @flow
import { FormBuilder } from '@nats/webclient-common';
import { object, string } from 'yup';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import type { State as Store } from '~/types/ReduxStateType';
import {
  editVisualParameters as editVisualParametersAction,
  loadVisualParameters as loadVisualParametersAction,
} from '~/api/visualParametersAPI';
import { setPageTitle as setPageTitleAction } from '~/actions/appActions';
import type { NewVisualParameters } from '~/types/VisualParameterType';
import type { VisualParametersState } from '~/types/state/VisualParameters';

type Props = {
  loadVisualParameters: () => void,
  editVisualParameters: (newVisualParameters: NewVisualParameters) => void,
  visualParameters: VisualParametersState,
  setPageTitle: string => void,
};
class VisualParametersForm extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const { setPageTitle, loadVisualParameters } = props;

    loadVisualParameters();

    setPageTitle('Visual Parameters');
  }
  getValidationSchema = () => {
    const urlSchema = (label: string) => string().url().label(label);
    return object()
      .noUnknown()
      .shape({
        aiduFlightDocumentWebsite: urlSchema('AIDU Flight Document Website'),
      });
  };
  getFields = () => {
    const visualFields = [
      {
        name: 'aiduFlightDocumentWebsite',
        label: 'AIDU Flight Document Website',
        initialValueFrom: 'aiduFlightDocumentWebsite',
        shouldStartNewRow: true,
        type: 'textarea',
        cols: 6,
        rows: 4,
      },
    ];
    return [...visualFields];
  };
  fields = this.getFields();
  validationSchema = this.getValidationSchema();

  render() {
    const { editVisualParameters, visualParameters } = this.props;

    const isComposing = visualParameters.error != null ? !visualParameters.isFetchError : true;

    if (!visualParameters.isBusy) {
      return (
        <div>
          <FormBuilder
            isComposing={isComposing}
            name="visualParameters"
            fields={this.fields}
            initialValues={visualParameters}
            submitButtonText="Save"
            onSubmit={values => editVisualParameters(values)}
            validationSchema={this.validationSchema}
          />
        </div>
      );
    }

    return null;
  }
}
const mapStateToProps = ({ visualParameters }: Store) => ({ visualParameters });

const mapDispatchToProps = {
  loadVisualParameters: loadVisualParametersAction,
  setPageTitle: setPageTitleAction,
  editVisualParameters: editVisualParametersAction,
};
export { VisualParametersForm as PureVisualParameters };

export default connect(mapStateToProps, mapDispatchToProps)(VisualParametersForm);
