// @flow
import environment from '../environment';
import { apiGet } from './api';
import { handleApiError } from '../actions/errorActions';

import type { ThunkAction } from '../types/ReduxActionTypes';

const PERMISSIONS_URL = `${environment.messagesServiceUrl}/api/v1/permissions`;

export function getPermissions(): ThunkAction {
  return async function action(dispatch: Function) {
    try {
      const response = await apiGet(PERMISSIONS_URL);

      const {
        data: {
          _embedded: { permissions },
        },
      } = response;

      return permissions;
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}
