// @flow

import type { ThunkAction } from '../types/ReduxActionTypes';
import environment from '../environment';
import { exportDataFailure, exportDataLoading, exportDataSuccess } from './organisationDetailsActions';
import { apiPost } from '../api/api';
import { handleApiError } from './errorActions';
import { exportToCSV } from '../utilities/exportToCSV';
import organisationsMapperModule from '../utilities/csvMappers/organisationScreen';

const ORGANISATIONS_MAILBOXES_URL = `${environment.messagesServiceUrl}/api/v1/organisations-mailboxes`;

type idName = {
  id: string,
  name: string,
};

export function exportOrganisationsMailboxes(organisationIds: Array<string>, namedIdList: Array<idName>): ThunkAction {
  return async function action(dispatch: Function) {
    if (!environment.messagesServiceUrl) {
      throw new Error('Unable to retrieve organisation details data; config not provided');
    }

    try {
      dispatch(exportDataLoading());

      const mailboxDetailsResponse = await apiPost(ORGANISATIONS_MAILBOXES_URL, organisationIds);

      dispatch(exportDataSuccess());
      const mailboxDetails = mailboxDetailsResponse.data._embedded.organisationMailboxes;

      exportToCSV(
        'Organisations_Mailboxes',
        organisationsMapperModule.mapMailboxesByOrganisation(namedIdList, mailboxDetails)
      );
    } catch (err) {
      dispatch(exportDataFailure());
      dispatch(handleApiError(err));
      throw err;
    }
  };
}
