// @flow
import { Notification } from '@nats/webclient-common';

import {
  TRANSIT_ROUTE_PARAMETERS_LOAD_STARTED,
  TRANSIT_ROUTE_PARAMETERS_LOAD_SUCCESSFUL,
  TRANSIT_ROUTE_PARAMETERS_LOAD_FAILED,
  TRANSIT_ROUTE_PARAMETERS_EDIT_FAILED,
} from './actionTypes';

import type {
  LoadTransitRouteParametersResponse,
  LoadStartedAction,
  LoadSuccessAction,
  LoadFailedAction,
  EditFailedAction,
} from '../types/TransitRouteParameterTypes';

import { getErrorMessage } from '../components/forms/getFormFromType';

const errorMap = {
  GENERIC_ERROR: 'An error occurred. Please reload the page.',
  FAILED_TO_RETRIEVE_TRANSIT_ROUTE_PARAMETERS: 'Unable to load Transit Route Parameters. Please reload the page.',
};

const displayErrorNotification = (error: string) => {
  Notification.error(getErrorMessage(errorMap, error, errorMap.GENERIC_ERROR), {
    autoClose: false,
  });
};

export const loadTransitRouteParametersStarted = (): LoadStartedAction => {
  return {
    type: TRANSIT_ROUTE_PARAMETERS_LOAD_STARTED,
  };
};

export const loadTransitRouteParametersSuccessful = (
  response: LoadTransitRouteParametersResponse
): LoadSuccessAction => {
  return {
    type: TRANSIT_ROUTE_PARAMETERS_LOAD_SUCCESSFUL,
    payload: response,
  };
};

export const loadTransitRouteParametersFailed = (error: string): LoadFailedAction => {
  displayErrorNotification(error);
  return {
    type: TRANSIT_ROUTE_PARAMETERS_LOAD_FAILED,
    error,
  };
};

export const editTransitRouteParametersSuccessful = () => {
  Notification.success('Transit Route Parameters updated successfully');
};

export const editTransitRouteParametersFailed = (error: string): EditFailedAction => {
  displayErrorNotification(error);
  return {
    type: TRANSIT_ROUTE_PARAMETERS_EDIT_FAILED,
    error,
  };
};
