// @flow

import type { ThunkAction } from '~/types/ReduxActionTypes';
import { handleApiError } from '~/actions/errorActions';
import environment from '~/environment';
import { loadSerialCodesFailed, loadSerialCodesStarted, loadSerialCodesSuccessful } from '../actions/serialCodeActions';
import { apiGet, apiPut } from './api';

const SERIAL_CODES_GET_URL = `${environment.localAvoidsUrl}/api/admin/serial-codes`;
const ORGANISATION_SERIAL_CODES_URL = `${environment.localAvoidsUrl}/api/admin/organisation-serial-codes`;

export function loadSerialCodes(): ThunkAction {
  return async function action(dispatch: Function) {
    dispatch(loadSerialCodesStarted());

    try {
      const response = await apiGet(SERIAL_CODES_GET_URL);
      dispatch(loadSerialCodesSuccessful(response));
      return response.data;
    } catch (error) {
      dispatch(loadSerialCodesFailed(error));
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export function loadOrganisationSerialCodes(organisationId: string): ThunkAction {
  return async function action(dispatch: Function) {
    dispatch(loadSerialCodesStarted());

    try {
      const response = await apiGet(`${ORGANISATION_SERIAL_CODES_URL}/${organisationId}`);
      dispatch(loadSerialCodesSuccessful(response));
      return response.data;
    } catch (error) {
      dispatch(loadSerialCodesFailed(error));
      dispatch(handleApiError(error));
      throw error;
    }
  };
}

export function upsertOrganisationSerialCodes(organisationId: string, serialCodes: string[]): ThunkAction {
  return async function action(dispatch: Function) {
    try {
      const response = await apiPut(`${ORGANISATION_SERIAL_CODES_URL}/${organisationId}`, { serialCodes });
      return response.data;
    } catch (error) {
      dispatch(handleApiError(error));
      throw error;
    }
  };
}
