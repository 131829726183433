// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import { FormBuilder } from '@nats/webclient-common';
import { mailboxAddressTest, ifpsStatesTest } from '@nats/nats-service-sdk/lib/validation/common';

import { setPageTitle as setPageTitleAction } from '~/actions/appActions';
import {
  loadMessageParameters as loadMessageParametersAction,
  editMessageParameters as editMessageParametersAction,
} from '~/api/messageParametersApi';

import type { State as Store } from '~/types/ReduxStateType';
import type { MessageParametersState } from '~/types/state/MessageParameters';
import type { NewMessageParameters } from '~/types/MessageParameterTypes';

import formStyles from '../SectionStyleForm.module.scss';

type Props = {
  setPageTitle: string => void,
  loadMessageParameters: () => void,
  editMessageParameters: (newMessageParameters: NewMessageParameters) => void,
  messageParameters: MessageParametersState,
};

class MessageParameters extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const { setPageTitle, loadMessageParameters } = props;

    setPageTitle('Message Parameters');
    loadMessageParameters();
  }

  getValidationSchema = () => {
    const mailboxAddressSchema = (label: string) =>
      string()
        .label(label)
        .required()
        .max(8)
        .test('mailbox-is-valid', `${label} address must be 8 letters`, mailboxAddressTest);

    const fourLetterAddressSchema = (label: string) =>
      string()
        .label(label)
        .required()
        .max(4)
        .transform(value => value.toUpperCase())
        .matches(/^([A-Z]{4})$/, `${label} must be 4 letters`);

    const aspCollectiveAddressesSchema = (label: string) =>
      string()
        .required(`${label} is a required field`)
        .trim()
        .max(188)
        .matches(/^[a-z\s]*$/i, `${label} must be a space separated list of up to 21 mailboxes, each 8 letters`)
        .test(
          'is-valid-address',
          `${label} must be a space separated list of up to 21 mailboxes, each 8 letters`,
          addrs => !addrs || addrs.split(' ').every(a => a.length === 8)
        )
        .test('is-unique', `${label} must be unique`, addrs => {
          if (!addrs) return true;
          const addresses = addrs.split(' ').map(a => a.toUpperCase());
          if (addresses.length === 0) return true;
          return new Set(addresses).size === addresses.length;
        });

    return object()
      .noUnknown()
      .shape({
        notamOffice: fourLetterAddressSchema('NOTAM Office'),
        notamOfficeMailbox: mailboxAddressSchema('NOTAM Office Mailbox'),
        ifpsFplAddress1: mailboxAddressSchema('IFPS FPL'),
        ifpsFplAddress2: mailboxAddressSchema('IFPS FPL'),
        fplValidationUnit: mailboxAddressSchema('FPL Validation Unit'),
        ifpsAftm: mailboxAddressSchema('IFPS AFTM'),
        ifpsStates: string()
          .label('IFPS States')
          .required()
          .transform(values => values.toUpperCase().trim())
          .test(
            'ifps-states-valid',
            'IFPS states must be a space separated list of max 60 unique two letter codes',
            ifpsStatesTest
          ),
        vfrDepMailboxSuffix: fourLetterAddressSchema('DEP'),
        vfrDestMailboxSuffix: fourLetterAddressSchema('DEST'),
        vfrAltnMailboxSuffix: fourLetterAddressSchema('ALTN'),
        aspCollectiveAddresses: aspCollectiveAddressesSchema('ASP Collective Addresses'),
      });
  };

  getFields = () => {
    const outputValueAt = (path: string) => ({
      path,
      withTransformation: (value: mixed) => typeof value === 'string' && value.toUpperCase(),
    });

    const notamOfficeFields = [
      {
        name: 'notamOfficeSectionLabel',
        label: 'NOTAM Office',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: true,
      },
      {
        name: 'notamOffice',
        label: 'NOTAM Office',
        initialValueFrom: 'notamOffice',
        shouldStartNewRow: false,
        cols: 1,
      },
      {
        name: 'notamOfficeMailbox',
        label: 'NOTAM Office Mailbox',
        initialValueFrom: 'notamOfficeMailbox',
        shouldStartNewRow: true,
      },
    ].map(field =>
      field.isLabelOnly
        ? field
        : {
            type: 'text',
            cols: 2,
            initialValue: '',
            ...field,
            outputValueAt: outputValueAt(field.initialValueFrom),
          }
    );

    const ifpsAddressesFields = [
      {
        name: 'ifpsAddressesSectionLabel',
        label: 'IFPS Addresses',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: true,
        labelPaddingAbove: 'double',
      },
      {
        name: 'ifpsFplAddress1',
        label: 'IFPS FPL',
        initialValueFrom: 'ifpsFpl[0]',
        shouldStartNewRow: true,
      },
      {
        name: 'ifpsFplAddress2',
        label: undefined,
        initialValueFrom: 'ifpsFpl[1]',
        shouldStartNewRow: false,
      },
      {
        name: 'fplValidationUnit',
        label: 'FPL Validation Unit',
        initialValueFrom: 'fplValidationUnit',
        shouldStartNewRow: true,
      },
      {
        name: 'ifpsAftm',
        label: 'IFPS AFTM',
        initialValueFrom: 'ifpsAftm[0]',
        shouldStartNewRow: true,
      },
      {
        name: 'ifpsStates',
        label: 'IFPS States',
        initialValueFrom: 'ifpsStates',
        shouldStartNewRow: true,
        type: 'textarea',
        cols: 6,
        rows: 4,
      },
    ].map(field =>
      field.isLabelOnly
        ? field
        : {
            type: 'text',
            cols: 2,
            initialValue: '',
            ...field,
            outputValueAt: outputValueAt(field.initialValueFrom),
          }
    );

    const vfrMailboxSuffixFields = [
      {
        name: 'vfrMailboxSuffixesSectionLabel',
        label: 'VFR Mailbox Suffixes',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: true,
        labelPaddingAbove: 'double',
      },
      {
        type: 'text',
        name: 'vfrDepMailboxSuffix',
        label: 'DEP',
        initialValueFrom: 'vfrDepMailboxSuffix',
        initialValue: '',
        shouldStartNewRow: false,
        cols: 1,
        outputValueAt: outputValueAt('vfrDepMailboxSuffix'),
      },
      {
        type: 'text',
        name: 'vfrDestMailboxSuffix',
        label: 'DEST',
        initialValueFrom: 'vfrDestMailboxSuffix',
        initialValue: '',
        shouldStartNewRow: false,
        cols: 1,
        outputValueAt: outputValueAt('vfrDestMailboxSuffix'),
      },
      {
        type: 'text',
        name: 'vfrAltnMailboxSuffix',
        label: 'ALTN',
        initialValueFrom: 'vfrAltnMailboxSuffix',
        initialValue: '',
        shouldStartNewRow: false,
        cols: 1,
        outputValueAt: outputValueAt('vfrAltnMailboxSuffix'),
      },
    ];

    const aspCollectiveAddressesFields = [
      {
        name: 'aspCollectiveAddressesSectionLabel',
        label: 'ASP Collective Addresses',
        isLabelOnly: true,
        cols: 12,
        shouldStartNewRow: false,
        labelPaddingAbove: 'double',
      },
      {
        name: 'aspCollectiveAddresses',
        label: null,
        initialValueFrom: 'aspCollectiveAddresses',
        initialValue: '',
        type: 'textarea',
        cols: 6,
        rows: 4,
      },
    ];

    return [...notamOfficeFields, ...ifpsAddressesFields, ...vfrMailboxSuffixFields, ...aspCollectiveAddressesFields];
  };

  fields = this.getFields();

  validationSchema = this.getValidationSchema();

  render() {
    const { editMessageParameters, messageParameters } = this.props;

    const isComposing = messageParameters.error != null ? !messageParameters.isFetchError : true;

    if (!messageParameters.isBusy) {
      return (
        <div className={formStyles.sectionStyleForm}>
          <FormBuilder
            isComposing={isComposing}
            name="messageParameters"
            fields={this.fields}
            initialValues={messageParameters}
            submitButtonText="Save"
            onSubmit={values => editMessageParameters(values)}
            validationSchema={this.validationSchema}
          />
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ messageParameters }: Store) => ({ messageParameters });

const mapDispatchToProps = {
  setPageTitle: setPageTitleAction,
  loadMessageParameters: loadMessageParametersAction,
  editMessageParameters: editMessageParametersAction,
};

export { MessageParameters as PureMessageParameters };

export default connect(mapStateToProps, mapDispatchToProps)(MessageParameters);
