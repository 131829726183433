// @flow

import {
  SOAP_USER_CONFIG_VALIDATE,
  SOAP_USER_LOAD_FAILED,
  SOAP_USER_LOAD_STARTED,
  SOAP_USER_LOAD_SUCCESSFUL,
} from '../actions/actionTypes';

import type { SoapUserState } from '../types/state/index';
import type { SoapUserAction } from '../actions/soapUserActions';

const initialState: SoapUserState = {
  isBusy: false,
  soapUsers: [],
  isValidConfig: true, // avoid showing the error message until proven otherwise
};

export default function soapUserReducer(state: SoapUserState = initialState, action: SoapUserAction): SoapUserState {
  switch (action.type) {
    case SOAP_USER_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
        isValidConfig: true,
      };
    case SOAP_USER_LOAD_SUCCESSFUL: {
      return {
        ...state,
        isBusy: false,
        soapUsers: action.payload.data._embedded.soapUsers,
      };
    }
    case SOAP_USER_LOAD_FAILED:
      return {
        ...initialState,
      };
    case SOAP_USER_CONFIG_VALIDATE:
      return {
        ...state,
        isValidConfig: action.isValid,
      };
    default:
      return state;
  }
}
