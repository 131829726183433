// @flow
import {
  USER_DETAILS_LOAD_STARTED,
  USER_DETAILS_LOAD_SUCCESSFUL,
  USER_DETAILS_LOAD_FAILED,
  RESET_USER_DETAILS,
} from './actionTypes';

import type { UserDetails } from '../types/UserDetails';

export type LoadUserDetailsResponse = {
  data: {
    _embedded: {
      userDetails: UserDetails,
    },
    _links: {
      self: {
        href: string,
      },
    },
  },
};

type loadStartedAction = { type: typeof USER_DETAILS_LOAD_STARTED };
type loadSuccessAction = { type: typeof USER_DETAILS_LOAD_STARTED, payload: LoadUserDetailsResponse };
type loadFailedAction = { type: typeof USER_DETAILS_LOAD_FAILED, error: string };
type resetAction = { type: typeof RESET_USER_DETAILS };

export type UserDetailsAction = loadStartedAction | loadSuccessAction | loadFailedAction | resetAction;

export function loadUserDetailsStarted(): loadStartedAction {
  return {
    type: USER_DETAILS_LOAD_STARTED,
  };
}

export function loadUserDetailsSuccessful(response: LoadUserDetailsResponse): loadSuccessAction {
  return {
    type: USER_DETAILS_LOAD_SUCCESSFUL,
    payload: response,
  };
}

export function loadUserDetailsFailed(error: string): loadFailedAction {
  return {
    type: USER_DETAILS_LOAD_FAILED,
    error,
  };
}

export function resetUserDetailsAction(): resetAction {
  return {
    type: RESET_USER_DETAILS,
  };
}
