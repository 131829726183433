// @flow
import React from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';
import styles from './exportOrganisationsView.module.scss';

import type { State } from '../../types/ReduxStateType';
import type { Organisation } from '../../types/Organisation';

import { exportToCSV } from '../../utilities/exportToCSV';
import organisationsMapperModule from '../../utilities/csvMappers/organisationScreen';
import { exportOrganisationsMailboxes } from '../../actions/exportOrganisationsMailboxes';
import { exportOrganisationsProfiles } from '../../actions/exportOrganisationsProfiles';

type Props = {
  organisationState: {
    organisations: Array<Organisation>,
    error: string | null,
    isBusy: boolean,
  },
  pageTitle: string,
  exportOrganisationsMailboxesDispatch: Function,
  exportOrganisationsProfilesDispatch: Function,
};

const getOrganisationIds = organisationState => {
  return organisationState.organisations.map(org => org.id);
};

const organisationNameIdList = organisationState => {
  return organisationState.organisations.map(org => ({ id: org.id, name: org.name }));
};

const exportOrganisations = (organisationState: Object, pageTitle: string) => {
  exportToCSV(pageTitle, organisationsMapperModule.mapOrganisations(organisationState.organisations));
};

const validKeyEntry = e => {
  return e.keyCode === 13;
};

const listMenuItems = (
  organisationState,
  pageTitle,
  exportOrganisationsMailboxesDispatch,
  exportOrganisationsProfilesDispatch
) => {
  const organisationIds = getOrganisationIds(organisationState);
  const organisationNameIds = organisationNameIdList(organisationState);

  const menuItems = [
    {
      name: 'Organisations',
      qaId: 'organisationsExport',
      onActionHandler: () => exportOrganisations(organisationState, pageTitle),
    },
    {
      name: 'Mailboxes by Organisation',
      qaId: 'mailboxesByOrganisationExport',
      onActionHandler: () => exportOrganisationsMailboxesDispatch(organisationIds, organisationNameIds),
    },
    {
      name: 'Profiles by Organisation',
      qaId: 'profilesByOrganisationExport',
      onActionHandler: () => exportOrganisationsProfilesDispatch(organisationIds, organisationNameIds),
    },
  ];

  return menuItems.map(menuItem => (
    <li className={styles.printOption} key={uuid()}>
      <span
        data-qa-id={menuItem.qaId}
        tabIndex={0}
        role="button"
        onClick={() => menuItem.onActionHandler()}
        onKeyDown={e => (validKeyEntry(e) ? menuItem.onActionHandler() : null)}
      >
        {menuItem.name}
      </span>
    </li>
  ));
};

export const ExportOrganisationsView = ({
  organisationState,
  pageTitle,
  exportOrganisationsMailboxesDispatch,
  exportOrganisationsProfilesDispatch,
}: Props) => (
  <div className={styles.printMenuWrapper}>
    <ul className={styles.printMenuList}>
      {listMenuItems(
        organisationState,
        pageTitle,
        exportOrganisationsMailboxesDispatch,
        exportOrganisationsProfilesDispatch
      )}
    </ul>
  </div>
);

export const mapStateToProps = (state: State) => ({
  organisationState: state.organisationState,
  pageTitle: state.app.title,
  profiles: state.organisationDetailsState.profiles,
  mailboxes: state.organisationDetailsState.mailboxes,
});

export const mapDispatchToProps = {
  exportOrganisationsMailboxesDispatch: exportOrganisationsMailboxes,
  exportOrganisationsProfilesDispatch: exportOrganisationsProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportOrganisationsView);
