// @flow
import {
  SERIAL_CODES_LOAD_STARTED,
  SERIAL_CODES_LOAD_SUCCESSFUL,
  SERIAL_CODES_LOAD_FAILED,
} from '../actions/actionTypes';

import type { SerialCodeState } from '../types/state';
import type { SerialCodeAction } from '../actions/serialCodeActions';

export const initialState: SerialCodeState = {
  isBusy: false,
  error: null,
  serialCodes: [],
};

export default function serialCodesReducer(
  state: SerialCodeState = initialState,
  action: SerialCodeAction
): SerialCodeState {
  switch (action.type) {
    case SERIAL_CODES_LOAD_STARTED:
      return {
        ...state,
        isBusy: true,
      };
    case SERIAL_CODES_LOAD_SUCCESSFUL: {
      const serialCodes = action.payload.data;

      return {
        ...state,
        isBusy: false,
        error: null,
        serialCodes: serialCodes
          .map(serialCode => ({ ...serialCode, isAssigned: serialCode.isAssigned || false }))
          .sort((s1, s2) => s1.code.localeCompare(s2.code)),
      };
    }
    case SERIAL_CODES_LOAD_FAILED:
      return {
        ...state,
        serialCodes: initialState.serialCodes,
        isBusy: false,
        error: action.error,
      };
    default:
      return state;
  }
}
