// @flow
import React from 'react';
import { Button } from '@nats/webclient-common';
import getFormFromType, { FORM_CODE, FORM_MODE } from '~/components/forms/getFormFromType';
import styles from './SoapUserView.module.scss';

type SoapUserViewProps = {
  username: string,
  onDelete: () => void,
};

const SoapUserView = (props: SoapUserViewProps) => {
  const panelProps = {
    isComposing: false,
    username: props.username,
  };

  const [, soapUserViewForm] = getFormFromType(FORM_CODE.SOAP_USER, FORM_MODE.VIEW, panelProps);

  return (
    <div data-qa-id="soap-user-view" className={styles.soapUserView}>
      {soapUserViewForm}
      <Button onClick={() => props.onDelete()}>Delete</Button>
    </div>
  );
};

export default SoapUserView;
