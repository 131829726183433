// @flow
import type { ReferenceDataType, ReferenceDataExport } from '~/types/ReferenceDataType';
import { formatDisplayDate, formatFileSize } from '../format';

const mapReferenceData = (referenceData: Array<ReferenceDataType>): Array<ReferenceDataExport> => {
  return referenceData.map(referenceDataItem => ({
    dataArea: referenceDataItem.dataArea.replace(/_/g, ' '),
    currentFileName: referenceDataItem.currentFileName,
    currentFileSize: formatFileSize(referenceDataItem.currentFileSize),
    fileExtension: referenceDataItem.fileExtension,
    lastUploadedTimestamp: formatDisplayDate(referenceDataItem.lastUploadedTimestamp),
    lastUploadedUsername: referenceDataItem.lastUploadedUsername,
  }));
};

const referenceDataMapperModule = {
  mapReferenceData,
};

export default referenceDataMapperModule;
